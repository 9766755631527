import { api } from "service/api"
import { ActionIcon, Title, Text, Image } from "@mantine/core"
import { IconTrash } from "@tabler/icons"
import { useRecoilValue, useRecoilValueLoadable, useRecoilState } from "recoil"
import { currentProject, resourceRequestAtom, projectForceUpdaterAtom } from "service/appstate/project/atoms"
import { Loader } from "react-feather"
import { AssetLoader } from "components/assetLoader"

interface IAssetItemProps {
  id: string
  url: string
  fileName: string
  onDelete: (id: string) => void
}

const AssetItem = ({ id, url, fileName, onDelete }: IAssetItemProps) => {
  return (
    <div
      style={{
        borderRadius: "8px",
        backgroundColor: "#fff",
        width: "200px",
        height: "200px",
        padding: "12px",
        margin: "15px 1rem",
        position: "relative"
      }}
    >
      <img style={{ width: "100%", maxHeight: "84%", objectFit: "contain" }} src={url} />

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: 0,
          borderTop: "1px solid #d2cece",
          padding: "4px 10px",
          position: "absolute",
          bottom: 0,
          left: 0
        }}
      >
        <span style={{ paddingLeft: 10, fontSize: 14 }}>{fileName}</span>
        <ActionIcon onClick={() => onDelete(id)}>
          <IconTrash color="red" />
        </ActionIcon>
      </div>
    </div>
  )
}

export const AssetManager = (): JSX.Element => {
  const [, projectForceEmitter] = useRecoilState(projectForceUpdaterAtom)

  const projectState = useRecoilValueLoadable(currentProject)
  const resourceRequest = useRecoilValue(resourceRequestAtom)

  const project = projectState.state === "hasValue" ? projectState.getValue() : undefined

  if (!project) {
    return <Loader />
  }

  const assetList = project.assetList

  const deleteImage = (id: string) => {
    api.deleteAsset(resourceRequest, id).then(() => {
      project.removeAsset(id)
      projectForceEmitter((val) => val + 1)
    })
  }

  return (
    <div style={{ backgroundColor: "#E4E4E9", width: "100%", overflow: "auto" }}>
      <div
        style={{
          padding: "24px",
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#fff"
        }}
        className="uploader-header"
      >
        <div>
          <Title
            sx={{
              fontFamily: "Syne",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 20
            }}
          >
            Upload media
          </Title>
          <Text
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 12,
              color: "#697786"
            }}
          >
            Here all color style you are using in your project are collected
          </Text>
        </div>
        <AssetLoader multiple resourceRequest={resourceRequest} />
      </div>
      {assetList.length ? (
        <div style={{ padding: "24px", display: "flex", flexWrap: "wrap" }} className="assets-list">
          {assetList.map((item) => {
            return (
              <AssetItem
                key={item.id}
                id={item.id}
                url={item.getThumbnailCDNUrl()}
                fileName={item.fileName}
                onDelete={deleteImage}
              />
            )
          })}
        </div>
      ) : (
        <div className="assets-list">
          <Image
            radius="md"
            src={process.env.PUBLIC_URL + "/assets/upload.png"}
            sx={{
              margin: "200px auto"
            }}
            height={300}
            width={345}
            styles={{
              root: {
                width: ""
              }
            }}
            alt="Random unsplash image"
          />
        </div>
      )}
    </div>
  )
}
