import { Button, ActionIcon, Group } from "@mantine/core"

interface ZoomControllerProps {
  min?: number
  max?: number
  value: number
  observer: (v: number) => void
}

export const ZoomController = ({ min, max, value = 100, observer }: ZoomControllerProps): JSX.Element => {
  const onChange = (value: number) => {
    if ((min !== undefined && value < min) || (max !== undefined && value > max)) {
      return
    }
    observer(value)
  }

  return (
    <>
      <Group
        sx={() => ({
          background: "black",
          borderRadius: "10px",
          float: "left",
          padding: "5px"
        })}
        // styles={(theme) => ({
        //   root: {}
        // })}
      >
        <ActionIcon
          sx={() => ({
            background: "black",
            borderRadius: "10px",
            fontSize: "20px",
            "&:hover": {
              background: "none"
            }
          })}
          variant="light"
          onClick={() => onChange(value - 20)}
        >
          –
        </ActionIcon>

        <Button
          sx={() => ({
            background: "black",
            fontFamily: "Syne",
            padding: 0,
            fontSize: 18
          })}
          styles={{
            root: {
              "&:hover": {
                background: "none"
              }
            }
          }}
        >
          {value}%
        </Button>

        <ActionIcon
          sx={() => ({
            background: "black",
            borderRadius: "10px",
            fontSize: "20px",
            "&:hover": {
              background: "none"
            }
          })}
          variant="light"
          onClick={() => onChange(value + 20)}
        >
          +
        </ActionIcon>
      </Group>
    </>
  )
}
