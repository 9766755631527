import { useEffect, useState, useCallback } from "react"
import CSS from "csstype"
import { ENodeEventList, TNodeEvent } from "model/entityEvent"
import { PageWidget } from "model/pageWidget"
import {
  CNumber,
  CString,
  CBoolean,
  ProjectColor,
  WidgetDynamicValue,
  ProjectFont,
  Size,
  PlainSize,
  Space,
  AlignmentExpanded,
  Icon,
  Image,
  PrimitiveArray
} from "model/widgetProperty/type"
import { useRecoilValueLoadable } from "recoil"
import { currentPage, currentProject } from "service/appstate/project/atoms"
import * as enums from "model/widgetProperty/enum"
import PropertyPannel from "./propertyPannel"
import { IProperty } from "./propertyPannel"
import { Title } from "@mantine/core"
import { PageSettings } from "./pageSettings"

const enumsMap = {
  AxisEnum: enums.AxisEnum,
  textAlignmentEnum: enums.TextAlignmentEnum,
  MainAxisSizeEnum: enums.MainAxisSizeEnum,
  MainAxisAlignmentEnum: enums.MainAxisAlignmentEnum,
  CrossAxisAlignmentEnum: enums.CrossAxisAlignmentEnum,
  ResourceTypeEnum: enums.ResourceTypeEnum,
  BoxFitEnum: enums.BoxFitEnum,
  BoxShapeEnum: enums.BoxShapeEnum,
  WidgetDynamicValueSourceEnum: enums.WidgetDynamicValueSourceEnum,
  FontWeightEnum: enums.FontWeightEnum,
  FontStyleEnum: enums.FontStyleEnum
}

const drillDownContainer: CSS.Properties = {
  overflowY: "auto",
  height: "100%",
  width: "23%",
  flexShrink: 0
}

const propertiesPanel: CSS.Properties = {
  backgroundColor: "#fff",
  color: "#fff",
  boxSizing: "border-box",
  cursor: "default",
  borderLeft: "1px solid #e5e5e5",
  minHeight: "100%",
  padding: "18px 18px"
}

const RightPanel = (): JSX.Element => {
  const [selectedWidget, setSelectedWidget] = useState<PageWidget | null>(null)
  const [, _forceUpdate] = useState(0)

  const projectState = useRecoilValueLoadable(currentProject)
  const pageState = useRecoilValueLoadable(currentPage)
  const project = projectState.state === "hasValue" ? projectState.getValue() : undefined
  const page = pageState.state === "hasValue" ? pageState.getValue() : undefined
  const tree = page?.data

  const forceUpdate = useCallback(() => {
    _forceUpdate(Math.random())
  }, [])

  useEffect(() => {
    const cb = (event: TNodeEvent) => {
      if (event.node) {
        setSelectedWidget(event.node as PageWidget)
      }
    }

    if (tree) {
      setSelectedWidget(null)
      tree.e?.on(ENodeEventList.NodeSelected, cb)
    }

    return () => {
      if (tree) {
        tree.e?.off(ENodeEventList.NodeSelected, cb)
      }
    }
  }, [page])

  const onChange = (updatedItem: IProperty): void => {
    if (selectedWidget?.id === updatedItem.id && selectedWidget?.property) {
      if (updatedItem.class === "CString") {
        const prop = selectedWidget?.property[updatedItem.title] as CString
        prop.set(String(updatedItem.value))
      } else if (updatedItem.class === "CNumber") {
        const prop = selectedWidget?.property[updatedItem.title] as CNumber
        prop.set(Number(updatedItem.value))
      } else if (updatedItem.class === "CBoolean") {
        const prop = selectedWidget?.property[updatedItem.title] as CBoolean
        prop.set(Boolean(updatedItem.value))
      } else if (updatedItem.class === "WidgetDynamicValue") {
        const prop = selectedWidget?.property[updatedItem.title] as WidgetDynamicValue
        prop.set(updatedItem.value?.toString() || "")
      }

      tree?.e?.emit(ENodeEventList.NodePropertyUpdated, { node: selectedWidget })
      forceUpdate()
    }
  }

  const collectWidgetProps = (): IProperty[] => {
    const result: IProperty[] = []
    if (selectedWidget?.property && selectedWidget.type) {
      for (const [key, prop] of Object.entries(selectedWidget.property)) {
        if (!prop) {
          continue
        }
        let _enum
        const propertyInfo = project?.getWidget(selectedWidget.type)?.getPropertyByKey(key)
        if (propertyInfo?.enum) {
          _enum = enumsMap[propertyInfo?.enum as keyof typeof enumsMap]
        }
        if (prop instanceof CString) {
          const val = {
            title: key,
            id: selectedWidget.id,
            value: prop.get(),
            enum: _enum,
            class: "CString",
            type: selectedWidget.type,
            group: propertyInfo?.group || ""
          }

          result.push(val)
        } else if (prop instanceof CBoolean) {
          const val = {
            title: key,
            id: selectedWidget.id,
            value: prop.get(),
            enum: _enum,
            class: "CBoolean",
            type: selectedWidget.type,
            group: propertyInfo?.group || ""
          }
          result.push(val)
        } else if (prop instanceof CNumber) {
          const val = {
            title: key,
            id: selectedWidget.id,
            value: prop.get(),
            enum: _enum,
            class: "CNumber",
            type: selectedWidget.type,
            group: propertyInfo?.group || ""
          }

          result.push(val)
        } else if (prop instanceof ProjectColor) {
          const val = {
            title: key,
            id: selectedWidget.id,
            value: prop,
            class: "ProjectColor",
            type: selectedWidget.type,
            group: propertyInfo?.group || ""
          }

          result.push(val)
        } else if (prop instanceof WidgetDynamicValue) {
          const val = {
            title: key,
            id: selectedWidget.id,
            value: prop.render(),
            class: "WidgetDynamicValue",
            type: selectedWidget.type,
            group: propertyInfo?.group || ""
          }

          result.push(val)
        } else if (prop instanceof ProjectFont) {
          const val = {
            title: key,
            id: selectedWidget.id,
            value: prop,
            class: "ProjectFont",
            type: selectedWidget.type,
            group: propertyInfo?.group || ""
          }

          result.push(val)
        } else if (prop instanceof Size) {
          const val = {
            title: key,
            id: selectedWidget.id,
            value: prop,
            class: "Size",
            type: selectedWidget.type,
            group: propertyInfo?.group || ""
          }

          if (selectedWidget.type === "Container") {
            const boxShape = selectedWidget?.property?.boxShape as CString
            if (boxShape.get() === enums.BoxShapeEnum.circle) result.push(val)
          }
        } else if (prop instanceof PlainSize) {
          const val = {
            title: key,
            id: selectedWidget.id,
            value: prop,
            class: "PlainSize",
            type: selectedWidget.type,
            group: propertyInfo?.group || ""
          }

          if (selectedWidget.type === "Container") {
            const boxShape = selectedWidget?.property?.boxShape as CString

            if (boxShape.get() !== enums.BoxShapeEnum.rectangle) continue
          }

          result.push(val)
        } else if (prop instanceof AlignmentExpanded) {
          const val = {
            title: key,
            id: selectedWidget.id,
            value: prop,
            class: "AlignmentExpanded",
            type: selectedWidget.type,
            group: propertyInfo?.group || ""
          }
          result.push(val)
        } else if (prop instanceof Icon) {
          const val = {
            title: key,
            id: selectedWidget.id,
            value: prop,
            class: "Icon",
            type: selectedWidget.type,
            group: propertyInfo?.group || ""
          }
          result.push(val)
        } else if (prop instanceof Space) {
          const val = {
            title: key,
            id: selectedWidget.id,
            value: prop,
            class: "Space",
            type: selectedWidget.type,
            group: propertyInfo?.group || ""
          }
          result.push(val)
        } else if (prop instanceof Image) {
          const imageVal = {
            title: key + "Url",
            id: selectedWidget.id,
            value: prop,
            class: "AssetSelect",
            type: selectedWidget.type,
            group: propertyInfo?.group || "",
            enum: enumsMap.ResourceTypeEnum
          }

          result.push(imageVal)
        } else if (prop instanceof PrimitiveArray && key === "gradientColorList") {
          const val = {
            title: key,
            id: selectedWidget.id,
            value: prop as PrimitiveArray<ProjectColor>,
            class: "GradientColorList",
            type: selectedWidget.type,
            group: propertyInfo?.group || ""
          }

          result.push(val)
        }
      }

      return result
    }
    return result
  }

  return (
    <div style={drillDownContainer}>
      <div style={propertiesPanel}>
        <Title
          order={4}
          color="black"
          sx={{
            fontFamily: "Syne"
          }}
        >
          {selectedWidget ? `${selectedWidget?.type} Widget Properties` : page ? `Page Settings` : null}
        </Title>
        <div>
          {selectedWidget ? (
            <PropertyPannel onChange={onChange} propertyList={collectWidgetProps()} />
          ) : page ? (
            <PageSettings page={page} />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default RightPanel
