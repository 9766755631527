// source: ide.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.AssetUploadUrlInfo', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.CreateIdeAssetRequest', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.CreateIdeEntityRequest', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.GetIdeAssetListResponse', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.GetIdeAssetResponse', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.GetIdeEntityListResponse', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.GetIdeEntityOrderResponse', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.GetIdeSettingColorResponse', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.GetIdeSettingFontResponse', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.GetIdeSettingNavBarResponse', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.GetIdeSettingResponse', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.IdResourceRequest', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.IdeAssetInfo', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.IdeEntity', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.IdeEntityData', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.IdeEntityInfo', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.IdeSettingAppAsset', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.IdeSettingColor', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.IdeSettingFont', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.IdeSettingNavBar', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.IdeSettingNavBarItem', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.ResourceRequest', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.SetIdeEntityDataRequest', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.SetIdeEntityOrderRequest', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.SetIdeSettingNavBarRequest', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.ResourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jupiter.builder.v1.ResourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.ResourceRequest.displayName = 'proto.jupiter.builder.v1.ResourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.IdResourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jupiter.builder.v1.IdResourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.IdResourceRequest.displayName = 'proto.jupiter.builder.v1.IdResourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.IdeEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jupiter.builder.v1.IdeEntity.repeatedFields_, null);
};
goog.inherits(proto.jupiter.builder.v1.IdeEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.IdeEntity.displayName = 'proto.jupiter.builder.v1.IdeEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.IdeEntityInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jupiter.builder.v1.IdeEntityInfo.repeatedFields_, null);
};
goog.inherits(proto.jupiter.builder.v1.IdeEntityInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.IdeEntityInfo.displayName = 'proto.jupiter.builder.v1.IdeEntityInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.IdeEntityData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jupiter.builder.v1.IdeEntityData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.IdeEntityData.displayName = 'proto.jupiter.builder.v1.IdeEntityData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.GetIdeEntityListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jupiter.builder.v1.GetIdeEntityListResponse.repeatedFields_, null);
};
goog.inherits(proto.jupiter.builder.v1.GetIdeEntityListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.GetIdeEntityListResponse.displayName = 'proto.jupiter.builder.v1.GetIdeEntityListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.GetIdeEntityOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jupiter.builder.v1.GetIdeEntityOrderResponse.repeatedFields_, null);
};
goog.inherits(proto.jupiter.builder.v1.GetIdeEntityOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.GetIdeEntityOrderResponse.displayName = 'proto.jupiter.builder.v1.GetIdeEntityOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.CreateIdeEntityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jupiter.builder.v1.CreateIdeEntityRequest.repeatedFields_, null);
};
goog.inherits(proto.jupiter.builder.v1.CreateIdeEntityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.CreateIdeEntityRequest.displayName = 'proto.jupiter.builder.v1.CreateIdeEntityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.repeatedFields_, null);
};
goog.inherits(proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.displayName = 'proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.SetIdeEntityDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jupiter.builder.v1.SetIdeEntityDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.SetIdeEntityDataRequest.displayName = 'proto.jupiter.builder.v1.SetIdeEntityDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.SetIdeEntityOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jupiter.builder.v1.SetIdeEntityOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.SetIdeEntityOrderRequest.displayName = 'proto.jupiter.builder.v1.SetIdeEntityOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.IdeSettingColor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jupiter.builder.v1.IdeSettingColor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.IdeSettingColor.displayName = 'proto.jupiter.builder.v1.IdeSettingColor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.IdeSettingFont = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jupiter.builder.v1.IdeSettingFont, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.IdeSettingFont.displayName = 'proto.jupiter.builder.v1.IdeSettingFont';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.GetIdeSettingColorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jupiter.builder.v1.GetIdeSettingColorResponse.repeatedFields_, null);
};
goog.inherits(proto.jupiter.builder.v1.GetIdeSettingColorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.GetIdeSettingColorResponse.displayName = 'proto.jupiter.builder.v1.GetIdeSettingColorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.GetIdeSettingFontResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jupiter.builder.v1.GetIdeSettingFontResponse.repeatedFields_, null);
};
goog.inherits(proto.jupiter.builder.v1.GetIdeSettingFontResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.GetIdeSettingFontResponse.displayName = 'proto.jupiter.builder.v1.GetIdeSettingFontResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.IdeSettingAppAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jupiter.builder.v1.IdeSettingAppAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.IdeSettingAppAsset.displayName = 'proto.jupiter.builder.v1.IdeSettingAppAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse.displayName = 'proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest.displayName = 'proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.IdeSettingNavBarItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jupiter.builder.v1.IdeSettingNavBarItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.IdeSettingNavBarItem.displayName = 'proto.jupiter.builder.v1.IdeSettingNavBarItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.IdeSettingNavBar = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jupiter.builder.v1.IdeSettingNavBar.repeatedFields_, null);
};
goog.inherits(proto.jupiter.builder.v1.IdeSettingNavBar, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.IdeSettingNavBar.displayName = 'proto.jupiter.builder.v1.IdeSettingNavBar';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.SetIdeSettingNavBarRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jupiter.builder.v1.SetIdeSettingNavBarRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.SetIdeSettingNavBarRequest.displayName = 'proto.jupiter.builder.v1.SetIdeSettingNavBarRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.GetIdeSettingNavBarResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jupiter.builder.v1.GetIdeSettingNavBarResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.GetIdeSettingNavBarResponse.displayName = 'proto.jupiter.builder.v1.GetIdeSettingNavBarResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.GetIdeSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jupiter.builder.v1.GetIdeSettingResponse.repeatedFields_, null);
};
goog.inherits(proto.jupiter.builder.v1.GetIdeSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.GetIdeSettingResponse.displayName = 'proto.jupiter.builder.v1.GetIdeSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.IdeAssetInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jupiter.builder.v1.IdeAssetInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.IdeAssetInfo.displayName = 'proto.jupiter.builder.v1.IdeAssetInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.GetIdeAssetListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jupiter.builder.v1.GetIdeAssetListResponse.repeatedFields_, null);
};
goog.inherits(proto.jupiter.builder.v1.GetIdeAssetListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.GetIdeAssetListResponse.displayName = 'proto.jupiter.builder.v1.GetIdeAssetListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.GetIdeAssetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jupiter.builder.v1.GetIdeAssetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.GetIdeAssetResponse.displayName = 'proto.jupiter.builder.v1.GetIdeAssetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest.displayName = 'proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.repeatedFields_, null);
};
goog.inherits(proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.displayName = 'proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.AssetUploadUrlInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jupiter.builder.v1.AssetUploadUrlInfo.repeatedFields_, null);
};
goog.inherits(proto.jupiter.builder.v1.AssetUploadUrlInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.AssetUploadUrlInfo.displayName = 'proto.jupiter.builder.v1.AssetUploadUrlInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.displayName = 'proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.CreateIdeAssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jupiter.builder.v1.CreateIdeAssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.CreateIdeAssetRequest.displayName = 'proto.jupiter.builder.v1.CreateIdeAssetRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.ResourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.ResourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.ResourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.ResourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resource: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.ResourceRequest}
 */
proto.jupiter.builder.v1.ResourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.ResourceRequest;
  return proto.jupiter.builder.v1.ResourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.ResourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.ResourceRequest}
 */
proto.jupiter.builder.v1.ResourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.ResourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.ResourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.ResourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.ResourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string resource = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.ResourceRequest.prototype.getResource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.ResourceRequest} returns this
 */
proto.jupiter.builder.v1.ResourceRequest.prototype.setResource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.IdResourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.IdResourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdResourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resource: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.IdResourceRequest}
 */
proto.jupiter.builder.v1.IdResourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.IdResourceRequest;
  return proto.jupiter.builder.v1.IdResourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.IdResourceRequest}
 */
proto.jupiter.builder.v1.IdResourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.IdResourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.IdResourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdResourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string resource = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.IdResourceRequest.prototype.getResource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdResourceRequest} returns this
 */
proto.jupiter.builder.v1.IdResourceRequest.prototype.setResource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.jupiter.builder.v1.IdResourceRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdResourceRequest} returns this
 */
proto.jupiter.builder.v1.IdResourceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jupiter.builder.v1.IdeEntity.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.IdeEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.IdeEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.IdeEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tagList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    data: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isLocked: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.IdeEntity}
 */
proto.jupiter.builder.v1.IdeEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.IdeEntity;
  return proto.jupiter.builder.v1.IdeEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.IdeEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.IdeEntity}
 */
proto.jupiter.builder.v1.IdeEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addTag(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLocked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.IdeEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.IdeEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.IdeEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTagList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsLocked();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeEntity.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeEntity} returns this
 */
proto.jupiter.builder.v1.IdeEntity.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeEntity.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeEntity} returns this
 */
proto.jupiter.builder.v1.IdeEntity.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeEntity.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeEntity} returns this
 */
proto.jupiter.builder.v1.IdeEntity.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string tag = 4;
 * @return {!Array<string>}
 */
proto.jupiter.builder.v1.IdeEntity.prototype.getTagList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.jupiter.builder.v1.IdeEntity} returns this
 */
proto.jupiter.builder.v1.IdeEntity.prototype.setTagList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.IdeEntity} returns this
 */
proto.jupiter.builder.v1.IdeEntity.prototype.addTag = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.IdeEntity} returns this
 */
proto.jupiter.builder.v1.IdeEntity.prototype.clearTagList = function() {
  return this.setTagList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.jupiter.builder.v1.IdeEntity.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.jupiter.builder.v1.IdeEntity} returns this
*/
proto.jupiter.builder.v1.IdeEntity.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jupiter.builder.v1.IdeEntity} returns this
 */
proto.jupiter.builder.v1.IdeEntity.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jupiter.builder.v1.IdeEntity.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.jupiter.builder.v1.IdeEntity.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.jupiter.builder.v1.IdeEntity} returns this
*/
proto.jupiter.builder.v1.IdeEntity.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jupiter.builder.v1.IdeEntity} returns this
 */
proto.jupiter.builder.v1.IdeEntity.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jupiter.builder.v1.IdeEntity.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string data = 7;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeEntity.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeEntity} returns this
 */
proto.jupiter.builder.v1.IdeEntity.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_locked = 8;
 * @return {boolean}
 */
proto.jupiter.builder.v1.IdeEntity.prototype.getIsLocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jupiter.builder.v1.IdeEntity} returns this
 */
proto.jupiter.builder.v1.IdeEntity.prototype.setIsLocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jupiter.builder.v1.IdeEntityInfo.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.IdeEntityInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.IdeEntityInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.IdeEntityInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeEntityInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tagList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isLocked: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.IdeEntityInfo}
 */
proto.jupiter.builder.v1.IdeEntityInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.IdeEntityInfo;
  return proto.jupiter.builder.v1.IdeEntityInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.IdeEntityInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.IdeEntityInfo}
 */
proto.jupiter.builder.v1.IdeEntityInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addTag(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLocked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.IdeEntityInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.IdeEntityInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.IdeEntityInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeEntityInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTagList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsLocked();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeEntityInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeEntityInfo} returns this
 */
proto.jupiter.builder.v1.IdeEntityInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeEntityInfo.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeEntityInfo} returns this
 */
proto.jupiter.builder.v1.IdeEntityInfo.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeEntityInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeEntityInfo} returns this
 */
proto.jupiter.builder.v1.IdeEntityInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string tag = 4;
 * @return {!Array<string>}
 */
proto.jupiter.builder.v1.IdeEntityInfo.prototype.getTagList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.jupiter.builder.v1.IdeEntityInfo} returns this
 */
proto.jupiter.builder.v1.IdeEntityInfo.prototype.setTagList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.IdeEntityInfo} returns this
 */
proto.jupiter.builder.v1.IdeEntityInfo.prototype.addTag = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.IdeEntityInfo} returns this
 */
proto.jupiter.builder.v1.IdeEntityInfo.prototype.clearTagList = function() {
  return this.setTagList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.jupiter.builder.v1.IdeEntityInfo.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.jupiter.builder.v1.IdeEntityInfo} returns this
*/
proto.jupiter.builder.v1.IdeEntityInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jupiter.builder.v1.IdeEntityInfo} returns this
 */
proto.jupiter.builder.v1.IdeEntityInfo.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jupiter.builder.v1.IdeEntityInfo.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.jupiter.builder.v1.IdeEntityInfo.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.jupiter.builder.v1.IdeEntityInfo} returns this
*/
proto.jupiter.builder.v1.IdeEntityInfo.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jupiter.builder.v1.IdeEntityInfo} returns this
 */
proto.jupiter.builder.v1.IdeEntityInfo.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jupiter.builder.v1.IdeEntityInfo.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool is_locked = 7;
 * @return {boolean}
 */
proto.jupiter.builder.v1.IdeEntityInfo.prototype.getIsLocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jupiter.builder.v1.IdeEntityInfo} returns this
 */
proto.jupiter.builder.v1.IdeEntityInfo.prototype.setIsLocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.IdeEntityData.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.IdeEntityData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.IdeEntityData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeEntityData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isLocked: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.IdeEntityData}
 */
proto.jupiter.builder.v1.IdeEntityData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.IdeEntityData;
  return proto.jupiter.builder.v1.IdeEntityData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.IdeEntityData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.IdeEntityData}
 */
proto.jupiter.builder.v1.IdeEntityData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLocked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.IdeEntityData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.IdeEntityData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.IdeEntityData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeEntityData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsLocked();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeEntityData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeEntityData} returns this
 */
proto.jupiter.builder.v1.IdeEntityData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeEntityData.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeEntityData} returns this
 */
proto.jupiter.builder.v1.IdeEntityData.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_locked = 3;
 * @return {boolean}
 */
proto.jupiter.builder.v1.IdeEntityData.prototype.getIsLocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jupiter.builder.v1.IdeEntityData} returns this
 */
proto.jupiter.builder.v1.IdeEntityData.prototype.setIsLocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jupiter.builder.v1.GetIdeEntityListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.GetIdeEntityListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.GetIdeEntityListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.GetIdeEntityListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeEntityListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityList: jspb.Message.toObjectList(msg.getEntityList(),
    proto.jupiter.builder.v1.IdeEntityInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.GetIdeEntityListResponse}
 */
proto.jupiter.builder.v1.GetIdeEntityListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.GetIdeEntityListResponse;
  return proto.jupiter.builder.v1.GetIdeEntityListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.GetIdeEntityListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.GetIdeEntityListResponse}
 */
proto.jupiter.builder.v1.GetIdeEntityListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jupiter.builder.v1.IdeEntityInfo;
      reader.readMessage(value,proto.jupiter.builder.v1.IdeEntityInfo.deserializeBinaryFromReader);
      msg.addEntity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.GetIdeEntityListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.GetIdeEntityListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.GetIdeEntityListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeEntityListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.jupiter.builder.v1.IdeEntityInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IdeEntityInfo entity = 1;
 * @return {!Array<!proto.jupiter.builder.v1.IdeEntityInfo>}
 */
proto.jupiter.builder.v1.GetIdeEntityListResponse.prototype.getEntityList = function() {
  return /** @type{!Array<!proto.jupiter.builder.v1.IdeEntityInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jupiter.builder.v1.IdeEntityInfo, 1));
};


/**
 * @param {!Array<!proto.jupiter.builder.v1.IdeEntityInfo>} value
 * @return {!proto.jupiter.builder.v1.GetIdeEntityListResponse} returns this
*/
proto.jupiter.builder.v1.GetIdeEntityListResponse.prototype.setEntityList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.jupiter.builder.v1.IdeEntityInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.IdeEntityInfo}
 */
proto.jupiter.builder.v1.GetIdeEntityListResponse.prototype.addEntity = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jupiter.builder.v1.IdeEntityInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.GetIdeEntityListResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeEntityListResponse.prototype.clearEntityList = function() {
  return this.setEntityList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jupiter.builder.v1.GetIdeEntityOrderResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.GetIdeEntityOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.GetIdeEntityOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.GetIdeEntityOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeEntityOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    idList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.GetIdeEntityOrderResponse}
 */
proto.jupiter.builder.v1.GetIdeEntityOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.GetIdeEntityOrderResponse;
  return proto.jupiter.builder.v1.GetIdeEntityOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.GetIdeEntityOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.GetIdeEntityOrderResponse}
 */
proto.jupiter.builder.v1.GetIdeEntityOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.GetIdeEntityOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.GetIdeEntityOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.GetIdeEntityOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeEntityOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string id = 1;
 * @return {!Array<string>}
 */
proto.jupiter.builder.v1.GetIdeEntityOrderResponse.prototype.getIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.jupiter.builder.v1.GetIdeEntityOrderResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeEntityOrderResponse.prototype.setIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.GetIdeEntityOrderResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeEntityOrderResponse.prototype.addId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.GetIdeEntityOrderResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeEntityOrderResponse.prototype.clearIdList = function() {
  return this.setIdList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jupiter.builder.v1.CreateIdeEntityRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.CreateIdeEntityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.CreateIdeEntityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.CreateIdeEntityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.CreateIdeEntityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resource: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tagList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    clone: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.CreateIdeEntityRequest}
 */
proto.jupiter.builder.v1.CreateIdeEntityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.CreateIdeEntityRequest;
  return proto.jupiter.builder.v1.CreateIdeEntityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.CreateIdeEntityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.CreateIdeEntityRequest}
 */
proto.jupiter.builder.v1.CreateIdeEntityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addTag(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.CreateIdeEntityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.CreateIdeEntityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.CreateIdeEntityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.CreateIdeEntityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTagList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getClone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string resource = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.CreateIdeEntityRequest.prototype.getResource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.CreateIdeEntityRequest} returns this
 */
proto.jupiter.builder.v1.CreateIdeEntityRequest.prototype.setResource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.jupiter.builder.v1.CreateIdeEntityRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.CreateIdeEntityRequest} returns this
 */
proto.jupiter.builder.v1.CreateIdeEntityRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.jupiter.builder.v1.CreateIdeEntityRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.CreateIdeEntityRequest} returns this
 */
proto.jupiter.builder.v1.CreateIdeEntityRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string tag = 4;
 * @return {!Array<string>}
 */
proto.jupiter.builder.v1.CreateIdeEntityRequest.prototype.getTagList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.jupiter.builder.v1.CreateIdeEntityRequest} returns this
 */
proto.jupiter.builder.v1.CreateIdeEntityRequest.prototype.setTagList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.CreateIdeEntityRequest} returns this
 */
proto.jupiter.builder.v1.CreateIdeEntityRequest.prototype.addTag = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.CreateIdeEntityRequest} returns this
 */
proto.jupiter.builder.v1.CreateIdeEntityRequest.prototype.clearTagList = function() {
  return this.setTagList([]);
};


/**
 * optional string clone = 5;
 * @return {string}
 */
proto.jupiter.builder.v1.CreateIdeEntityRequest.prototype.getClone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.CreateIdeEntityRequest} returns this
 */
proto.jupiter.builder.v1.CreateIdeEntityRequest.prototype.setClone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resource: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tagList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest}
 */
proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest;
  return proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest}
 */
proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTagList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional string resource = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.prototype.getResource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest} returns this
 */
proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.prototype.setResource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest} returns this
 */
proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest} returns this
 */
proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest} returns this
 */
proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string tag = 5;
 * @return {!Array<string>}
 */
proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.prototype.getTagList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest} returns this
 */
proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.prototype.setTagList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest} returns this
 */
proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.prototype.addTag = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest} returns this
 */
proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest.prototype.clearTagList = function() {
  return this.setTagList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.SetIdeEntityDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.SetIdeEntityDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.SetIdeEntityDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.SetIdeEntityDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resource: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.SetIdeEntityDataRequest}
 */
proto.jupiter.builder.v1.SetIdeEntityDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.SetIdeEntityDataRequest;
  return proto.jupiter.builder.v1.SetIdeEntityDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.SetIdeEntityDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.SetIdeEntityDataRequest}
 */
proto.jupiter.builder.v1.SetIdeEntityDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.SetIdeEntityDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.SetIdeEntityDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.SetIdeEntityDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.SetIdeEntityDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string resource = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.SetIdeEntityDataRequest.prototype.getResource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.SetIdeEntityDataRequest} returns this
 */
proto.jupiter.builder.v1.SetIdeEntityDataRequest.prototype.setResource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.jupiter.builder.v1.SetIdeEntityDataRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.SetIdeEntityDataRequest} returns this
 */
proto.jupiter.builder.v1.SetIdeEntityDataRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string data = 3;
 * @return {string}
 */
proto.jupiter.builder.v1.SetIdeEntityDataRequest.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.SetIdeEntityDataRequest} returns this
 */
proto.jupiter.builder.v1.SetIdeEntityDataRequest.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.SetIdeEntityOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.SetIdeEntityOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.SetIdeEntityOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.SetIdeEntityOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resource: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    beforeId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.SetIdeEntityOrderRequest}
 */
proto.jupiter.builder.v1.SetIdeEntityOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.SetIdeEntityOrderRequest;
  return proto.jupiter.builder.v1.SetIdeEntityOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.SetIdeEntityOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.SetIdeEntityOrderRequest}
 */
proto.jupiter.builder.v1.SetIdeEntityOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeforeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.SetIdeEntityOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.SetIdeEntityOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.SetIdeEntityOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.SetIdeEntityOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBeforeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string resource = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.SetIdeEntityOrderRequest.prototype.getResource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.SetIdeEntityOrderRequest} returns this
 */
proto.jupiter.builder.v1.SetIdeEntityOrderRequest.prototype.setResource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.jupiter.builder.v1.SetIdeEntityOrderRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.SetIdeEntityOrderRequest} returns this
 */
proto.jupiter.builder.v1.SetIdeEntityOrderRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string before_id = 3;
 * @return {string}
 */
proto.jupiter.builder.v1.SetIdeEntityOrderRequest.prototype.getBeforeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.SetIdeEntityOrderRequest} returns this
 */
proto.jupiter.builder.v1.SetIdeEntityOrderRequest.prototype.setBeforeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.IdeSettingColor.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.IdeSettingColor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.IdeSettingColor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeSettingColor.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isCustom: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.IdeSettingColor}
 */
proto.jupiter.builder.v1.IdeSettingColor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.IdeSettingColor;
  return proto.jupiter.builder.v1.IdeSettingColor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.IdeSettingColor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.IdeSettingColor}
 */
proto.jupiter.builder.v1.IdeSettingColor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCustom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.IdeSettingColor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.IdeSettingColor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.IdeSettingColor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeSettingColor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsCustom();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeSettingColor.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeSettingColor} returns this
 */
proto.jupiter.builder.v1.IdeSettingColor.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeSettingColor.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeSettingColor} returns this
 */
proto.jupiter.builder.v1.IdeSettingColor.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_custom = 3;
 * @return {boolean}
 */
proto.jupiter.builder.v1.IdeSettingColor.prototype.getIsCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jupiter.builder.v1.IdeSettingColor} returns this
 */
proto.jupiter.builder.v1.IdeSettingColor.prototype.setIsCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.IdeSettingFont.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.IdeSettingFont.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.IdeSettingFont} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeSettingFont.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rawValue: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.IdeSettingFont}
 */
proto.jupiter.builder.v1.IdeSettingFont.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.IdeSettingFont;
  return proto.jupiter.builder.v1.IdeSettingFont.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.IdeSettingFont} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.IdeSettingFont}
 */
proto.jupiter.builder.v1.IdeSettingFont.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRawValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.IdeSettingFont.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.IdeSettingFont.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.IdeSettingFont} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeSettingFont.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRawValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeSettingFont.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeSettingFont} returns this
 */
proto.jupiter.builder.v1.IdeSettingFont.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string raw_value = 2;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeSettingFont.prototype.getRawValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeSettingFont} returns this
 */
proto.jupiter.builder.v1.IdeSettingFont.prototype.setRawValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jupiter.builder.v1.GetIdeSettingColorResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.GetIdeSettingColorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.GetIdeSettingColorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.GetIdeSettingColorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeSettingColorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    colorList: jspb.Message.toObjectList(msg.getColorList(),
    proto.jupiter.builder.v1.IdeSettingColor.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.GetIdeSettingColorResponse}
 */
proto.jupiter.builder.v1.GetIdeSettingColorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.GetIdeSettingColorResponse;
  return proto.jupiter.builder.v1.GetIdeSettingColorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.GetIdeSettingColorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.GetIdeSettingColorResponse}
 */
proto.jupiter.builder.v1.GetIdeSettingColorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jupiter.builder.v1.IdeSettingColor;
      reader.readMessage(value,proto.jupiter.builder.v1.IdeSettingColor.deserializeBinaryFromReader);
      msg.addColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.GetIdeSettingColorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.GetIdeSettingColorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.GetIdeSettingColorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeSettingColorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColorList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.jupiter.builder.v1.IdeSettingColor.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IdeSettingColor color = 1;
 * @return {!Array<!proto.jupiter.builder.v1.IdeSettingColor>}
 */
proto.jupiter.builder.v1.GetIdeSettingColorResponse.prototype.getColorList = function() {
  return /** @type{!Array<!proto.jupiter.builder.v1.IdeSettingColor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jupiter.builder.v1.IdeSettingColor, 1));
};


/**
 * @param {!Array<!proto.jupiter.builder.v1.IdeSettingColor>} value
 * @return {!proto.jupiter.builder.v1.GetIdeSettingColorResponse} returns this
*/
proto.jupiter.builder.v1.GetIdeSettingColorResponse.prototype.setColorList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.jupiter.builder.v1.IdeSettingColor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.IdeSettingColor}
 */
proto.jupiter.builder.v1.GetIdeSettingColorResponse.prototype.addColor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jupiter.builder.v1.IdeSettingColor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.GetIdeSettingColorResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeSettingColorResponse.prototype.clearColorList = function() {
  return this.setColorList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jupiter.builder.v1.GetIdeSettingFontResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.GetIdeSettingFontResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.GetIdeSettingFontResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.GetIdeSettingFontResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeSettingFontResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fontList: jspb.Message.toObjectList(msg.getFontList(),
    proto.jupiter.builder.v1.IdeSettingFont.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.GetIdeSettingFontResponse}
 */
proto.jupiter.builder.v1.GetIdeSettingFontResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.GetIdeSettingFontResponse;
  return proto.jupiter.builder.v1.GetIdeSettingFontResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.GetIdeSettingFontResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.GetIdeSettingFontResponse}
 */
proto.jupiter.builder.v1.GetIdeSettingFontResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jupiter.builder.v1.IdeSettingFont;
      reader.readMessage(value,proto.jupiter.builder.v1.IdeSettingFont.deserializeBinaryFromReader);
      msg.addFont(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.GetIdeSettingFontResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.GetIdeSettingFontResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.GetIdeSettingFontResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeSettingFontResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFontList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.jupiter.builder.v1.IdeSettingFont.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IdeSettingFont font = 1;
 * @return {!Array<!proto.jupiter.builder.v1.IdeSettingFont>}
 */
proto.jupiter.builder.v1.GetIdeSettingFontResponse.prototype.getFontList = function() {
  return /** @type{!Array<!proto.jupiter.builder.v1.IdeSettingFont>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jupiter.builder.v1.IdeSettingFont, 1));
};


/**
 * @param {!Array<!proto.jupiter.builder.v1.IdeSettingFont>} value
 * @return {!proto.jupiter.builder.v1.GetIdeSettingFontResponse} returns this
*/
proto.jupiter.builder.v1.GetIdeSettingFontResponse.prototype.setFontList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.jupiter.builder.v1.IdeSettingFont=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.IdeSettingFont}
 */
proto.jupiter.builder.v1.GetIdeSettingFontResponse.prototype.addFont = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jupiter.builder.v1.IdeSettingFont, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.GetIdeSettingFontResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeSettingFontResponse.prototype.clearFontList = function() {
  return this.setFontList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.IdeSettingAppAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.IdeSettingAppAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.IdeSettingAppAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeSettingAppAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    splash: jspb.Message.getFieldWithDefault(msg, 1, ""),
    launcher: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.IdeSettingAppAsset}
 */
proto.jupiter.builder.v1.IdeSettingAppAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.IdeSettingAppAsset;
  return proto.jupiter.builder.v1.IdeSettingAppAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.IdeSettingAppAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.IdeSettingAppAsset}
 */
proto.jupiter.builder.v1.IdeSettingAppAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSplash(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLauncher(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.IdeSettingAppAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.IdeSettingAppAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.IdeSettingAppAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeSettingAppAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSplash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLauncher();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string splash = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeSettingAppAsset.prototype.getSplash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeSettingAppAsset} returns this
 */
proto.jupiter.builder.v1.IdeSettingAppAsset.prototype.setSplash = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string launcher = 2;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeSettingAppAsset.prototype.getLauncher = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeSettingAppAsset} returns this
 */
proto.jupiter.builder.v1.IdeSettingAppAsset.prototype.setLauncher = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.jupiter.builder.v1.IdeSettingAppAsset.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse}
 */
proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse;
  return proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse}
 */
proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jupiter.builder.v1.IdeSettingAppAsset;
      reader.readMessage(value,proto.jupiter.builder.v1.IdeSettingAppAsset.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.jupiter.builder.v1.IdeSettingAppAsset.serializeBinaryToWriter
    );
  }
};


/**
 * optional IdeSettingAppAsset data = 1;
 * @return {?proto.jupiter.builder.v1.IdeSettingAppAsset}
 */
proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse.prototype.getData = function() {
  return /** @type{?proto.jupiter.builder.v1.IdeSettingAppAsset} */ (
    jspb.Message.getWrapperField(this, proto.jupiter.builder.v1.IdeSettingAppAsset, 1));
};


/**
 * @param {?proto.jupiter.builder.v1.IdeSettingAppAsset|undefined} value
 * @return {!proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse} returns this
*/
proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resource: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: (f = msg.getData()) && proto.jupiter.builder.v1.IdeSettingAppAsset.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest}
 */
proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest;
  return proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest}
 */
proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResource(value);
      break;
    case 2:
      var value = new proto.jupiter.builder.v1.IdeSettingAppAsset;
      reader.readMessage(value,proto.jupiter.builder.v1.IdeSettingAppAsset.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.jupiter.builder.v1.IdeSettingAppAsset.serializeBinaryToWriter
    );
  }
};


/**
 * optional string resource = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest.prototype.getResource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest} returns this
 */
proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest.prototype.setResource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional IdeSettingAppAsset data = 2;
 * @return {?proto.jupiter.builder.v1.IdeSettingAppAsset}
 */
proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest.prototype.getData = function() {
  return /** @type{?proto.jupiter.builder.v1.IdeSettingAppAsset} */ (
    jspb.Message.getWrapperField(this, proto.jupiter.builder.v1.IdeSettingAppAsset, 2));
};


/**
 * @param {?proto.jupiter.builder.v1.IdeSettingAppAsset|undefined} value
 * @return {!proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest} returns this
*/
proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest} returns this
 */
proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.IdeSettingNavBarItem.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.IdeSettingNavBarItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.IdeSettingNavBarItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeSettingNavBarItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 2, ""),
    iconActive: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.IdeSettingNavBarItem}
 */
proto.jupiter.builder.v1.IdeSettingNavBarItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.IdeSettingNavBarItem;
  return proto.jupiter.builder.v1.IdeSettingNavBarItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.IdeSettingNavBarItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.IdeSettingNavBarItem}
 */
proto.jupiter.builder.v1.IdeSettingNavBarItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.IdeSettingNavBarItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.IdeSettingNavBarItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.IdeSettingNavBarItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeSettingNavBarItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIconActive();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string page_id = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeSettingNavBarItem.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeSettingNavBarItem} returns this
 */
proto.jupiter.builder.v1.IdeSettingNavBarItem.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string icon = 2;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeSettingNavBarItem.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeSettingNavBarItem} returns this
 */
proto.jupiter.builder.v1.IdeSettingNavBarItem.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string icon_active = 3;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeSettingNavBarItem.prototype.getIconActive = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeSettingNavBarItem} returns this
 */
proto.jupiter.builder.v1.IdeSettingNavBarItem.prototype.setIconActive = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jupiter.builder.v1.IdeSettingNavBar.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.IdeSettingNavBar.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.IdeSettingNavBar.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.IdeSettingNavBar} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeSettingNavBar.toObject = function(includeInstance, msg) {
  var f, obj = {
    backgroundColor: jspb.Message.getFieldWithDefault(msg, 1, ""),
    iconColor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    iconColorActive: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isShowLabel: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isShowLabelActive: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.jupiter.builder.v1.IdeSettingNavBarItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.IdeSettingNavBar}
 */
proto.jupiter.builder.v1.IdeSettingNavBar.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.IdeSettingNavBar;
  return proto.jupiter.builder.v1.IdeSettingNavBar.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.IdeSettingNavBar} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.IdeSettingNavBar}
 */
proto.jupiter.builder.v1.IdeSettingNavBar.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundColor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconColor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconColorActive(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsShowLabel(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsShowLabelActive(value);
      break;
    case 6:
      var value = new proto.jupiter.builder.v1.IdeSettingNavBarItem;
      reader.readMessage(value,proto.jupiter.builder.v1.IdeSettingNavBarItem.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.IdeSettingNavBar.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.IdeSettingNavBar.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.IdeSettingNavBar} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeSettingNavBar.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBackgroundColor();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIconColor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIconColorActive();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsShowLabel();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsShowLabelActive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.jupiter.builder.v1.IdeSettingNavBarItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string background_color = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeSettingNavBar.prototype.getBackgroundColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeSettingNavBar} returns this
 */
proto.jupiter.builder.v1.IdeSettingNavBar.prototype.setBackgroundColor = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string icon_color = 2;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeSettingNavBar.prototype.getIconColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeSettingNavBar} returns this
 */
proto.jupiter.builder.v1.IdeSettingNavBar.prototype.setIconColor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string icon_color_active = 3;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeSettingNavBar.prototype.getIconColorActive = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeSettingNavBar} returns this
 */
proto.jupiter.builder.v1.IdeSettingNavBar.prototype.setIconColorActive = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_show_label = 4;
 * @return {boolean}
 */
proto.jupiter.builder.v1.IdeSettingNavBar.prototype.getIsShowLabel = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jupiter.builder.v1.IdeSettingNavBar} returns this
 */
proto.jupiter.builder.v1.IdeSettingNavBar.prototype.setIsShowLabel = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_show_label_active = 5;
 * @return {boolean}
 */
proto.jupiter.builder.v1.IdeSettingNavBar.prototype.getIsShowLabelActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jupiter.builder.v1.IdeSettingNavBar} returns this
 */
proto.jupiter.builder.v1.IdeSettingNavBar.prototype.setIsShowLabelActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated IdeSettingNavBarItem list = 6;
 * @return {!Array<!proto.jupiter.builder.v1.IdeSettingNavBarItem>}
 */
proto.jupiter.builder.v1.IdeSettingNavBar.prototype.getListList = function() {
  return /** @type{!Array<!proto.jupiter.builder.v1.IdeSettingNavBarItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jupiter.builder.v1.IdeSettingNavBarItem, 6));
};


/**
 * @param {!Array<!proto.jupiter.builder.v1.IdeSettingNavBarItem>} value
 * @return {!proto.jupiter.builder.v1.IdeSettingNavBar} returns this
*/
proto.jupiter.builder.v1.IdeSettingNavBar.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.jupiter.builder.v1.IdeSettingNavBarItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.IdeSettingNavBarItem}
 */
proto.jupiter.builder.v1.IdeSettingNavBar.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.jupiter.builder.v1.IdeSettingNavBarItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.IdeSettingNavBar} returns this
 */
proto.jupiter.builder.v1.IdeSettingNavBar.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.SetIdeSettingNavBarRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.SetIdeSettingNavBarRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.SetIdeSettingNavBarRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.SetIdeSettingNavBarRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resource: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: (f = msg.getData()) && proto.jupiter.builder.v1.IdeSettingNavBar.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.SetIdeSettingNavBarRequest}
 */
proto.jupiter.builder.v1.SetIdeSettingNavBarRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.SetIdeSettingNavBarRequest;
  return proto.jupiter.builder.v1.SetIdeSettingNavBarRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.SetIdeSettingNavBarRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.SetIdeSettingNavBarRequest}
 */
proto.jupiter.builder.v1.SetIdeSettingNavBarRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResource(value);
      break;
    case 2:
      var value = new proto.jupiter.builder.v1.IdeSettingNavBar;
      reader.readMessage(value,proto.jupiter.builder.v1.IdeSettingNavBar.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.SetIdeSettingNavBarRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.SetIdeSettingNavBarRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.SetIdeSettingNavBarRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.SetIdeSettingNavBarRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.jupiter.builder.v1.IdeSettingNavBar.serializeBinaryToWriter
    );
  }
};


/**
 * optional string resource = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.SetIdeSettingNavBarRequest.prototype.getResource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.SetIdeSettingNavBarRequest} returns this
 */
proto.jupiter.builder.v1.SetIdeSettingNavBarRequest.prototype.setResource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional IdeSettingNavBar data = 2;
 * @return {?proto.jupiter.builder.v1.IdeSettingNavBar}
 */
proto.jupiter.builder.v1.SetIdeSettingNavBarRequest.prototype.getData = function() {
  return /** @type{?proto.jupiter.builder.v1.IdeSettingNavBar} */ (
    jspb.Message.getWrapperField(this, proto.jupiter.builder.v1.IdeSettingNavBar, 2));
};


/**
 * @param {?proto.jupiter.builder.v1.IdeSettingNavBar|undefined} value
 * @return {!proto.jupiter.builder.v1.SetIdeSettingNavBarRequest} returns this
*/
proto.jupiter.builder.v1.SetIdeSettingNavBarRequest.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jupiter.builder.v1.SetIdeSettingNavBarRequest} returns this
 */
proto.jupiter.builder.v1.SetIdeSettingNavBarRequest.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jupiter.builder.v1.SetIdeSettingNavBarRequest.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.GetIdeSettingNavBarResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.GetIdeSettingNavBarResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.GetIdeSettingNavBarResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeSettingNavBarResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.jupiter.builder.v1.IdeSettingNavBar.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.GetIdeSettingNavBarResponse}
 */
proto.jupiter.builder.v1.GetIdeSettingNavBarResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.GetIdeSettingNavBarResponse;
  return proto.jupiter.builder.v1.GetIdeSettingNavBarResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.GetIdeSettingNavBarResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.GetIdeSettingNavBarResponse}
 */
proto.jupiter.builder.v1.GetIdeSettingNavBarResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jupiter.builder.v1.IdeSettingNavBar;
      reader.readMessage(value,proto.jupiter.builder.v1.IdeSettingNavBar.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.GetIdeSettingNavBarResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.GetIdeSettingNavBarResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.GetIdeSettingNavBarResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeSettingNavBarResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.jupiter.builder.v1.IdeSettingNavBar.serializeBinaryToWriter
    );
  }
};


/**
 * optional IdeSettingNavBar data = 1;
 * @return {?proto.jupiter.builder.v1.IdeSettingNavBar}
 */
proto.jupiter.builder.v1.GetIdeSettingNavBarResponse.prototype.getData = function() {
  return /** @type{?proto.jupiter.builder.v1.IdeSettingNavBar} */ (
    jspb.Message.getWrapperField(this, proto.jupiter.builder.v1.IdeSettingNavBar, 1));
};


/**
 * @param {?proto.jupiter.builder.v1.IdeSettingNavBar|undefined} value
 * @return {!proto.jupiter.builder.v1.GetIdeSettingNavBarResponse} returns this
*/
proto.jupiter.builder.v1.GetIdeSettingNavBarResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jupiter.builder.v1.GetIdeSettingNavBarResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeSettingNavBarResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jupiter.builder.v1.GetIdeSettingNavBarResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jupiter.builder.v1.GetIdeSettingResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.GetIdeSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.GetIdeSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.GetIdeSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    colorList: jspb.Message.toObjectList(msg.getColorList(),
    proto.jupiter.builder.v1.IdeSettingColor.toObject, includeInstance),
    fontList: jspb.Message.toObjectList(msg.getFontList(),
    proto.jupiter.builder.v1.IdeSettingFont.toObject, includeInstance),
    appAsset: (f = msg.getAppAsset()) && proto.jupiter.builder.v1.IdeSettingAppAsset.toObject(includeInstance, f),
    navBar: (f = msg.getNavBar()) && proto.jupiter.builder.v1.IdeSettingNavBar.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.GetIdeSettingResponse}
 */
proto.jupiter.builder.v1.GetIdeSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.GetIdeSettingResponse;
  return proto.jupiter.builder.v1.GetIdeSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.GetIdeSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.GetIdeSettingResponse}
 */
proto.jupiter.builder.v1.GetIdeSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jupiter.builder.v1.IdeSettingColor;
      reader.readMessage(value,proto.jupiter.builder.v1.IdeSettingColor.deserializeBinaryFromReader);
      msg.addColor(value);
      break;
    case 2:
      var value = new proto.jupiter.builder.v1.IdeSettingFont;
      reader.readMessage(value,proto.jupiter.builder.v1.IdeSettingFont.deserializeBinaryFromReader);
      msg.addFont(value);
      break;
    case 3:
      var value = new proto.jupiter.builder.v1.IdeSettingAppAsset;
      reader.readMessage(value,proto.jupiter.builder.v1.IdeSettingAppAsset.deserializeBinaryFromReader);
      msg.setAppAsset(value);
      break;
    case 4:
      var value = new proto.jupiter.builder.v1.IdeSettingNavBar;
      reader.readMessage(value,proto.jupiter.builder.v1.IdeSettingNavBar.deserializeBinaryFromReader);
      msg.setNavBar(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.GetIdeSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.GetIdeSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.GetIdeSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColorList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.jupiter.builder.v1.IdeSettingColor.serializeBinaryToWriter
    );
  }
  f = message.getFontList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.jupiter.builder.v1.IdeSettingFont.serializeBinaryToWriter
    );
  }
  f = message.getAppAsset();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.jupiter.builder.v1.IdeSettingAppAsset.serializeBinaryToWriter
    );
  }
  f = message.getNavBar();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.jupiter.builder.v1.IdeSettingNavBar.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IdeSettingColor color = 1;
 * @return {!Array<!proto.jupiter.builder.v1.IdeSettingColor>}
 */
proto.jupiter.builder.v1.GetIdeSettingResponse.prototype.getColorList = function() {
  return /** @type{!Array<!proto.jupiter.builder.v1.IdeSettingColor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jupiter.builder.v1.IdeSettingColor, 1));
};


/**
 * @param {!Array<!proto.jupiter.builder.v1.IdeSettingColor>} value
 * @return {!proto.jupiter.builder.v1.GetIdeSettingResponse} returns this
*/
proto.jupiter.builder.v1.GetIdeSettingResponse.prototype.setColorList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.jupiter.builder.v1.IdeSettingColor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.IdeSettingColor}
 */
proto.jupiter.builder.v1.GetIdeSettingResponse.prototype.addColor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jupiter.builder.v1.IdeSettingColor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.GetIdeSettingResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeSettingResponse.prototype.clearColorList = function() {
  return this.setColorList([]);
};


/**
 * repeated IdeSettingFont font = 2;
 * @return {!Array<!proto.jupiter.builder.v1.IdeSettingFont>}
 */
proto.jupiter.builder.v1.GetIdeSettingResponse.prototype.getFontList = function() {
  return /** @type{!Array<!proto.jupiter.builder.v1.IdeSettingFont>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jupiter.builder.v1.IdeSettingFont, 2));
};


/**
 * @param {!Array<!proto.jupiter.builder.v1.IdeSettingFont>} value
 * @return {!proto.jupiter.builder.v1.GetIdeSettingResponse} returns this
*/
proto.jupiter.builder.v1.GetIdeSettingResponse.prototype.setFontList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.jupiter.builder.v1.IdeSettingFont=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.IdeSettingFont}
 */
proto.jupiter.builder.v1.GetIdeSettingResponse.prototype.addFont = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.jupiter.builder.v1.IdeSettingFont, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.GetIdeSettingResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeSettingResponse.prototype.clearFontList = function() {
  return this.setFontList([]);
};


/**
 * optional IdeSettingAppAsset app_asset = 3;
 * @return {?proto.jupiter.builder.v1.IdeSettingAppAsset}
 */
proto.jupiter.builder.v1.GetIdeSettingResponse.prototype.getAppAsset = function() {
  return /** @type{?proto.jupiter.builder.v1.IdeSettingAppAsset} */ (
    jspb.Message.getWrapperField(this, proto.jupiter.builder.v1.IdeSettingAppAsset, 3));
};


/**
 * @param {?proto.jupiter.builder.v1.IdeSettingAppAsset|undefined} value
 * @return {!proto.jupiter.builder.v1.GetIdeSettingResponse} returns this
*/
proto.jupiter.builder.v1.GetIdeSettingResponse.prototype.setAppAsset = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jupiter.builder.v1.GetIdeSettingResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeSettingResponse.prototype.clearAppAsset = function() {
  return this.setAppAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jupiter.builder.v1.GetIdeSettingResponse.prototype.hasAppAsset = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional IdeSettingNavBar nav_bar = 4;
 * @return {?proto.jupiter.builder.v1.IdeSettingNavBar}
 */
proto.jupiter.builder.v1.GetIdeSettingResponse.prototype.getNavBar = function() {
  return /** @type{?proto.jupiter.builder.v1.IdeSettingNavBar} */ (
    jspb.Message.getWrapperField(this, proto.jupiter.builder.v1.IdeSettingNavBar, 4));
};


/**
 * @param {?proto.jupiter.builder.v1.IdeSettingNavBar|undefined} value
 * @return {!proto.jupiter.builder.v1.GetIdeSettingResponse} returns this
*/
proto.jupiter.builder.v1.GetIdeSettingResponse.prototype.setNavBar = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jupiter.builder.v1.GetIdeSettingResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeSettingResponse.prototype.clearNavBar = function() {
  return this.setNavBar(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jupiter.builder.v1.GetIdeSettingResponse.prototype.hasNavBar = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.IdeAssetInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.IdeAssetInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.IdeAssetInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeAssetInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contentType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    size: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fileName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.IdeAssetInfo}
 */
proto.jupiter.builder.v1.IdeAssetInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.IdeAssetInfo;
  return proto.jupiter.builder.v1.IdeAssetInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.IdeAssetInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.IdeAssetInfo}
 */
proto.jupiter.builder.v1.IdeAssetInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSize(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.IdeAssetInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.IdeAssetInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.IdeAssetInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeAssetInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContentType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeAssetInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeAssetInfo} returns this
 */
proto.jupiter.builder.v1.IdeAssetInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string content_type = 2;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeAssetInfo.prototype.getContentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeAssetInfo} returns this
 */
proto.jupiter.builder.v1.IdeAssetInfo.prototype.setContentType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 size = 3;
 * @return {number}
 */
proto.jupiter.builder.v1.IdeAssetInfo.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.jupiter.builder.v1.IdeAssetInfo} returns this
 */
proto.jupiter.builder.v1.IdeAssetInfo.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string file_name = 4;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeAssetInfo.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeAssetInfo} returns this
 */
proto.jupiter.builder.v1.IdeAssetInfo.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.jupiter.builder.v1.IdeAssetInfo.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.jupiter.builder.v1.IdeAssetInfo} returns this
*/
proto.jupiter.builder.v1.IdeAssetInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jupiter.builder.v1.IdeAssetInfo} returns this
 */
proto.jupiter.builder.v1.IdeAssetInfo.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jupiter.builder.v1.IdeAssetInfo.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jupiter.builder.v1.GetIdeAssetListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.GetIdeAssetListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.GetIdeAssetListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.GetIdeAssetListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeAssetListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetList: jspb.Message.toObjectList(msg.getAssetList(),
    proto.jupiter.builder.v1.IdeAssetInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.GetIdeAssetListResponse}
 */
proto.jupiter.builder.v1.GetIdeAssetListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.GetIdeAssetListResponse;
  return proto.jupiter.builder.v1.GetIdeAssetListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.GetIdeAssetListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.GetIdeAssetListResponse}
 */
proto.jupiter.builder.v1.GetIdeAssetListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jupiter.builder.v1.IdeAssetInfo;
      reader.readMessage(value,proto.jupiter.builder.v1.IdeAssetInfo.deserializeBinaryFromReader);
      msg.addAsset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.GetIdeAssetListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.GetIdeAssetListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.GetIdeAssetListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeAssetListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.jupiter.builder.v1.IdeAssetInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IdeAssetInfo asset = 1;
 * @return {!Array<!proto.jupiter.builder.v1.IdeAssetInfo>}
 */
proto.jupiter.builder.v1.GetIdeAssetListResponse.prototype.getAssetList = function() {
  return /** @type{!Array<!proto.jupiter.builder.v1.IdeAssetInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jupiter.builder.v1.IdeAssetInfo, 1));
};


/**
 * @param {!Array<!proto.jupiter.builder.v1.IdeAssetInfo>} value
 * @return {!proto.jupiter.builder.v1.GetIdeAssetListResponse} returns this
*/
proto.jupiter.builder.v1.GetIdeAssetListResponse.prototype.setAssetList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.jupiter.builder.v1.IdeAssetInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.IdeAssetInfo}
 */
proto.jupiter.builder.v1.GetIdeAssetListResponse.prototype.addAsset = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jupiter.builder.v1.IdeAssetInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.GetIdeAssetListResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeAssetListResponse.prototype.clearAssetList = function() {
  return this.setAssetList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.GetIdeAssetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.GetIdeAssetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.GetIdeAssetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeAssetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    asset: (f = msg.getAsset()) && proto.jupiter.builder.v1.IdeAssetInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.GetIdeAssetResponse}
 */
proto.jupiter.builder.v1.GetIdeAssetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.GetIdeAssetResponse;
  return proto.jupiter.builder.v1.GetIdeAssetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.GetIdeAssetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.GetIdeAssetResponse}
 */
proto.jupiter.builder.v1.GetIdeAssetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jupiter.builder.v1.IdeAssetInfo;
      reader.readMessage(value,proto.jupiter.builder.v1.IdeAssetInfo.deserializeBinaryFromReader);
      msg.setAsset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.GetIdeAssetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.GetIdeAssetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.GetIdeAssetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeAssetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAsset();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.jupiter.builder.v1.IdeAssetInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional IdeAssetInfo asset = 1;
 * @return {?proto.jupiter.builder.v1.IdeAssetInfo}
 */
proto.jupiter.builder.v1.GetIdeAssetResponse.prototype.getAsset = function() {
  return /** @type{?proto.jupiter.builder.v1.IdeAssetInfo} */ (
    jspb.Message.getWrapperField(this, proto.jupiter.builder.v1.IdeAssetInfo, 1));
};


/**
 * @param {?proto.jupiter.builder.v1.IdeAssetInfo|undefined} value
 * @return {!proto.jupiter.builder.v1.GetIdeAssetResponse} returns this
*/
proto.jupiter.builder.v1.GetIdeAssetResponse.prototype.setAsset = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jupiter.builder.v1.GetIdeAssetResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeAssetResponse.prototype.clearAsset = function() {
  return this.setAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jupiter.builder.v1.GetIdeAssetResponse.prototype.hasAsset = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resource: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contentType: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest}
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest;
  return proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest}
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContentType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string resource = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest.prototype.getResource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest} returns this
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest.prototype.setResource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest} returns this
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string content_type = 3;
 * @return {string}
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest.prototype.getContentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest} returns this
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest.prototype.setContentType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valueList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo}
 */
proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo;
  return proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo}
 */
proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValueList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo} returns this
 */
proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string value = 2;
 * @return {!Array<string>}
 */
proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.prototype.getValueList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo} returns this
 */
proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.prototype.setValueList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo} returns this
 */
proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.prototype.addValue = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo} returns this
 */
proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.prototype.clearValueList = function() {
  return this.setValueList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jupiter.builder.v1.AssetUploadUrlInfo.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.AssetUploadUrlInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.AssetUploadUrlInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.AssetUploadUrlInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.AssetUploadUrlInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    method: jspb.Message.getFieldWithDefault(msg, 2, ""),
    headerList: jspb.Message.toObjectList(msg.getHeaderList(),
    proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.AssetUploadUrlInfo}
 */
proto.jupiter.builder.v1.AssetUploadUrlInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.AssetUploadUrlInfo;
  return proto.jupiter.builder.v1.AssetUploadUrlInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.AssetUploadUrlInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.AssetUploadUrlInfo}
 */
proto.jupiter.builder.v1.AssetUploadUrlInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethod(value);
      break;
    case 3:
      var value = new proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo;
      reader.readMessage(value,proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.deserializeBinaryFromReader);
      msg.addHeader(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.AssetUploadUrlInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.AssetUploadUrlInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.AssetUploadUrlInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.AssetUploadUrlInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMethod();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHeaderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.AssetUploadUrlInfo.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.AssetUploadUrlInfo} returns this
 */
proto.jupiter.builder.v1.AssetUploadUrlInfo.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string method = 2;
 * @return {string}
 */
proto.jupiter.builder.v1.AssetUploadUrlInfo.prototype.getMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.AssetUploadUrlInfo} returns this
 */
proto.jupiter.builder.v1.AssetUploadUrlInfo.prototype.setMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated AssetUploadUrlHeaderInfo header = 3;
 * @return {!Array<!proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo>}
 */
proto.jupiter.builder.v1.AssetUploadUrlInfo.prototype.getHeaderList = function() {
  return /** @type{!Array<!proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo, 3));
};


/**
 * @param {!Array<!proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo>} value
 * @return {!proto.jupiter.builder.v1.AssetUploadUrlInfo} returns this
*/
proto.jupiter.builder.v1.AssetUploadUrlInfo.prototype.setHeaderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo}
 */
proto.jupiter.builder.v1.AssetUploadUrlInfo.prototype.addHeader = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.jupiter.builder.v1.AssetUploadUrlHeaderInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.AssetUploadUrlInfo} returns this
 */
proto.jupiter.builder.v1.AssetUploadUrlInfo.prototype.clearHeaderList = function() {
  return this.setHeaderList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hash: jspb.Message.getFieldWithDefault(msg, 1, ""),
    original: (f = msg.getOriginal()) && proto.jupiter.builder.v1.AssetUploadUrlInfo.toObject(includeInstance, f),
    thumbnail: (f = msg.getThumbnail()) && proto.jupiter.builder.v1.AssetUploadUrlInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse}
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse;
  return proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse}
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    case 2:
      var value = new proto.jupiter.builder.v1.AssetUploadUrlInfo;
      reader.readMessage(value,proto.jupiter.builder.v1.AssetUploadUrlInfo.deserializeBinaryFromReader);
      msg.setOriginal(value);
      break;
    case 3:
      var value = new proto.jupiter.builder.v1.AssetUploadUrlInfo;
      reader.readMessage(value,proto.jupiter.builder.v1.AssetUploadUrlInfo.deserializeBinaryFromReader);
      msg.setThumbnail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOriginal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.jupiter.builder.v1.AssetUploadUrlInfo.serializeBinaryToWriter
    );
  }
  f = message.getThumbnail();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.jupiter.builder.v1.AssetUploadUrlInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string hash = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AssetUploadUrlInfo original = 2;
 * @return {?proto.jupiter.builder.v1.AssetUploadUrlInfo}
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.prototype.getOriginal = function() {
  return /** @type{?proto.jupiter.builder.v1.AssetUploadUrlInfo} */ (
    jspb.Message.getWrapperField(this, proto.jupiter.builder.v1.AssetUploadUrlInfo, 2));
};


/**
 * @param {?proto.jupiter.builder.v1.AssetUploadUrlInfo|undefined} value
 * @return {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse} returns this
*/
proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.prototype.setOriginal = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.prototype.clearOriginal = function() {
  return this.setOriginal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.prototype.hasOriginal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AssetUploadUrlInfo thumbnail = 3;
 * @return {?proto.jupiter.builder.v1.AssetUploadUrlInfo}
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.prototype.getThumbnail = function() {
  return /** @type{?proto.jupiter.builder.v1.AssetUploadUrlInfo} */ (
    jspb.Message.getWrapperField(this, proto.jupiter.builder.v1.AssetUploadUrlInfo, 3));
};


/**
 * @param {?proto.jupiter.builder.v1.AssetUploadUrlInfo|undefined} value
 * @return {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse} returns this
*/
proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.prototype.setThumbnail = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.prototype.clearThumbnail = function() {
  return this.setThumbnail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.prototype.hasThumbnail = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.CreateIdeAssetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.CreateIdeAssetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.CreateIdeAssetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.CreateIdeAssetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resource: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hash: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.CreateIdeAssetRequest}
 */
proto.jupiter.builder.v1.CreateIdeAssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.CreateIdeAssetRequest;
  return proto.jupiter.builder.v1.CreateIdeAssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.CreateIdeAssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.CreateIdeAssetRequest}
 */
proto.jupiter.builder.v1.CreateIdeAssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.CreateIdeAssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.CreateIdeAssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.CreateIdeAssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.CreateIdeAssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string resource = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.CreateIdeAssetRequest.prototype.getResource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.CreateIdeAssetRequest} returns this
 */
proto.jupiter.builder.v1.CreateIdeAssetRequest.prototype.setResource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string hash = 2;
 * @return {string}
 */
proto.jupiter.builder.v1.CreateIdeAssetRequest.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.CreateIdeAssetRequest} returns this
 */
proto.jupiter.builder.v1.CreateIdeAssetRequest.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.jupiter.builder.v1);
