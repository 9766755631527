/* eslint-disable @typescript-eslint/ban-types */
import "reflect-metadata"
import { jsonProperty } from "ts-serializable"
import { Serializable } from "./serializable"
import { BackendRequest } from "./backend"
import { WidgetAction } from "./action"
import { EntityNode } from "./entityNode"
import { IDefault } from "./widgetProperty/type"
import { WidgetPropertyFactory } from "./widgetProperty/factory"
import { Project, IProject } from "./project"
import { ENodeEventList } from "./entityEvent"
import { v4 as uuidv4 } from "uuid"
import { Widget } from "./widget"
import AddChildValidator, { AddChildValidatorError } from "./validator/addChildValidator"
import DuplicateValidator, { DuplicateValidatorError } from "./validator/duplicateValidator"

export interface IPageWidgetSelected {
  selected?: PageWidget
  setSelected(en: PageWidget): void
  getSelected(): PageWidget | undefined
  unsetSelected(en: PageWidget): void
}

export interface IPageWidgetHovered {
  hovered?: PageWidget
  setHovered(en: PageWidget): void
  getHovered(): PageWidget | undefined
  unsetHovered(en: PageWidget): void
}

export interface IPageWidgetBuffered {
  buffered?: PageWidget
  setBuffered(en: PageWidget): void
  getBuffered(): PageWidget | undefined
  unsetBuffered(en: PageWidget): void
}

//export type WidgetPropertyList = Record<string, unknown>

export class WidgetPropertyList extends Serializable implements Record<string, unknown> {
  [key: string]: unknown

  public toJSON(): Record<string, unknown> {
    const toJson = {}

    for (const prop in this) {
      // cleanup default values
      const v = Reflect.get(this, prop)
      if (v && "isDefault" in v && !(v as IDefault).isDefault()) {
        Reflect.set(toJson, prop, Reflect.get(this, prop))
      }
    }
    return toJson
  }

  public fromJSON(json: object /*, settings?: Partial<SerializationSettings>*/): this {
    for (const prop in json) {
      Reflect.set(this, prop, Reflect.get(json, prop))
    }
    return this
  }
}

export class WidgetParameter extends Serializable {
  @jsonProperty(String)
  id?: string = undefined
}

export class PageWidgetBase extends EntityNode {
  type?: string = undefined
  parent?: PageWidget = undefined
  child?: PageWidget = undefined

  constructor() {
    super()
  }

  findById(id: string): PageWidget | undefined {
    const en = super.findById(id)
    if (en) {
      return en as PageWidget
    }
  }

  getChildrenList(): PageWidget[] {
    return super.getChildrenList() as PageWidget[]
  }

  getProject(): Project | undefined {
    return
  }

  getWidgetInfo(type: string): Widget | undefined {
    return this.getProject()?.getWidget(type)
  }

  canAddChildByType(type: string): AddChildValidatorError {
    return AddChildValidator.run(type, this)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addWidget(_: PageWidget): void {
    return
  }

  getWidgetByType(type: string): PageWidget {
    const pw: PageWidget = new PageWidget()
    pw.type = type
    pw.id = uuidv4()
    const widget = this.getWidgetInfo(type)
    // set default property
    const p = this.getProject()
    widget?.property?.forEach((prop) => {
      pw.setProperty(prop?.type || "", prop?.key || "", prop.value, p)
    })
    return pw
  }

  // add new widget
  addWidgetByType(type: string): void {
    this.addWidget(this.getWidgetByType(type))
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getChildKeyByType(_: string): string | undefined {
    return
  }
}

export class PageWidget extends PageWidgetBase {
  @jsonProperty(String)
  type?: string = undefined

  @jsonProperty([WidgetAction])
  actionList?: WidgetAction[] = undefined

  @jsonProperty(BackendRequest)
  backendRequest?: BackendRequest = undefined

  children?: PageWidget[] = undefined

  @jsonProperty(WidgetPropertyList)
  property?: WidgetPropertyList = undefined

  _fixProperty(p?: IProject): void {
    if (this.property && p) {
      const widget = p.getWidget(this.type || "")
      if (widget && widget.property) {
        const property = new WidgetPropertyList()
        widget.property.forEach((widgetPropertyInfo) => {
          if (widgetPropertyInfo.key) {
            property[widgetPropertyInfo.key] = WidgetPropertyFactory.get(
              widgetPropertyInfo.type || "",
              this.property?.[widgetPropertyInfo.key],
              p
            )
          }
        }, this)
        this.property = property
      }
    }
  }

  getProject(): Project | undefined {
    if (this.isRoot()) {
      return
    } else {
      const root = this._getRoot()
      if (root) {
        const getProject = Reflect.get(root, "getProject")
        if (getProject) {
          return getProject.call(root)
        }
      }
    }
    return
  }

  setProperty(type: string, key: string, value: unknown, p?: IProject): void {
    if (!this.property) {
      this.property = new WidgetPropertyList()
    }
    this.property[key] = WidgetPropertyFactory.get(type || "", value, p || this.getProject())
  }

  notifyOnPropertyUpdated(): void {
    this._emitOnRoot(ENodeEventList.NodePropertyUpdated, { node: this })
  }

  // clone
  clone(): PageWidget {
    const p = this.getProject()
    const node = new PageWidget().fromJSON({ ...this.toJSON() })
    node.parent = this.parent
    node.id = uuidv4()
    node?.walk((en, ctx) => {
      en.id = uuidv4()
      en.parent = ctx.parent || undefined
      en._fixProperty(p)
    })
    return node
  }

  hasChildOnly(): boolean {
    return this.getWidgetInfo(this.type || "")?.hasCapability("child") || false
  }

  hasChildrenOnly(): boolean {
    return this.getWidgetInfo(this.type || "")?.hasCapability("children") || false
  }

  // add child or children
  addWidget(en: PageWidget): void {
    if (this.hasChildOnly()) {
      this.setChild(en)
    } else if (this.hasChildrenOnly()) {
      this.pushChild(en)
    }
  }

  canDuplicate(): DuplicateValidatorError {
    return DuplicateValidator.run(this)
  }

  duplicate(): void {
    if (this.parent && this.canDuplicate()) {
      const pw = this.clone()
      return this.addChildBeforeIndex(pw, this.parent?.children?.indexOf(this))
    }
  }
}

// NOTE: hardcode to resolve undefined class on decorators for circular type linking
Reflect.defineMetadata("ts-serializable:jsonTypes", [PageWidget], PageWidget.prototype, "child")
Reflect.defineMetadata("ts-serializable:jsonTypes", [[PageWidget]], PageWidget.prototype, "children")
