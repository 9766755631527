import SizeInput from "../Size"
import { PlainSize, Size } from "model/widgetProperty/type"
import { memo } from "react"

interface IPlainSizeInputProps {
  value: PlainSize
  onChange: () => void
  label: string
}

export const PlainSizeInput = memo(({ value, onChange }: IPlainSizeInputProps): JSX.Element => {
  return (
    <div style={{ width: "100%", display: "flex", flexWrap: "wrap", alignItems: "end", gap: "12px" }}>
      <SizeInput label="Width" value={value.getWidth() as Size} onChange={onChange} />
      <SizeInput label="Height" value={value.geHeight() as Size} onChange={onChange} />
    </div>
  )
})
