import React, { createContext, useContext, useEffect, useRef, useReducer } from "react"
import MouseHandle from "utils/mouseHandle"
import { TReduceAction, TReduceStateType, TVerticalEdgeChilrenType } from "types/pages/tabTypes"
import { TVericalContext } from "types/pages/builderTypes"
import styles from "styles/builder/style.module.css"

const Context = createContext({})
export const useVerticalEdge = (): {
  widthLeftPanel: number
  dispatch: (type: { type: string; minWidth?: number; maxWidth?: number }) => void
} => useContext<TVericalContext>(Context)
const VerticalEdge = ({ children }: TVerticalEdgeChilrenType): JSX.Element => {
  const initialState = {
    width: 240,
    minWidth: 0,
    maxWidth: 0,
    isResize: false
  }
  const reducer = (state: TReduceStateType, action: TReduceAction) => {
    switch (action.type) {
      case "CHANGE":
        return {
          ...state,
          width: action.x
        }
      case "SETMIN":
        return {
          ...state,
          minWidth: action.minWidth
        }
      case "SETMAX":
        return {
          ...state,
          maxWidth: action.maxWidth
        }
      case "RESET":
        return {
          ...state,
          width: initialState.width
        }
      case "STARTRESIZE":
        return {
          ...state,
          isResize: true
        }
      case "ENDRESIZE":
        return {
          ...state,
          isResize: false
        }
      default:
        throw new Error()
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  const widthLeftPanel = state.width
  const { maxWidth, minWidth } = state
  const leftPanelEdge = useRef(null)

  useEffect(() => {
    const handler = new MouseHandle(leftPanelEdge.current)
    handler
      .on(MouseHandle.EVENTS.START, () => {
        return dispatch({ type: "STARTRESIZE" })
      })
      .on(MouseHandle.EVENTS.END, () => {
        dispatch({ type: "ENDRESIZE" })
      })
      .on(MouseHandle.EVENTS.UPDATE, ({ x }) => {
        if (x === Math.max(minWidth, Math.min(maxWidth, x || 0))) {
          dispatch({
            type: "CHANGE",
            x
          })
        }
      })

    return () => {
      handler.destroy()
    }
  }, [widthLeftPanel, maxWidth, minWidth])

  return (
    <Context.Provider value={{ widthLeftPanel, dispatch }}>
      <div className={styles.row}>{children}</div>
      <div
        className={[state.isResize ? styles.edge_resize : "", styles.edge].filter((e) => !!e).join(" ")}
        style={{ left: widthLeftPanel }}
        ref={leftPanelEdge}
      />
    </Context.Provider>
  )
}

export default VerticalEdge
