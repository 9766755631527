import { Contstraints } from "./constraints"
import { Geometry } from "./geometry"
import { GeometryPadding } from "./padding"
import { GeometrySize } from "./size"
import { Space } from "model/widgetProperty/type"

export class PaddingBox extends Geometry {
  padding: Space
  isPseudo: boolean

  private _resolvedPadding: GeometryPadding

  constructor(padding: Space) {
    super()

    this.isPseudo = true
    this.padding = padding
    this._resolvedPadding = new GeometryPadding(this.padding)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getDryLayout(constraints: Contstraints): GeometrySize {
    return new GeometrySize()
  }

  performLayout(): void {
    const { child, constraints, _resolvedPadding, offset } = this

    if (child) {
      const innerConstraints = constraints.deflate(this._resolvedPadding)
      child.layout(innerConstraints)
      child.offset = { x: offset.x + _resolvedPadding.left, y: offset.y + _resolvedPadding.top }
      this.size = constraints.constrain(
        new GeometrySize(
          _resolvedPadding.left + child.size.width + _resolvedPadding.right,
          _resolvedPadding.top + child.size.height + _resolvedPadding.bottom
        )
      )
    }
  }
}
