import { Title, ActionIcon, Button } from "@mantine/core"
import { IconArrowBack, IconHandClick } from "@tabler/icons"
import { useSetRecoilState } from "recoil"
import { selectedMainTabAtom } from "service/appstate/builder/atoms"

export const ImageAssets = (): JSX.Element => {
  const selectMainTab = useSetRecoilState(selectedMainTabAtom)
  return (
    <div
      style={{
        background: "#F7F5FF",
        borderRadius: 20,
        textAlign: "center",
        margin: "auto 15px",
        padding: "0 10px"
      }}
    >
      <ActionIcon
        sx={{
          backgroundColor: "#6549F7",
          borderRadius: 40,
          width: 48,
          height: 48,
          margin: "20px auto",
          "&:hover": {
            backgroundColor: "#6549F7"
          }
        }}
      >
        <IconHandClick color="white" />
      </ActionIcon>
      <Title
        sx={{
          fontFamily: "Syne",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 14,
          margin: "20px 0"
        }}
      >
        Here all assets you are using in your project are collected
      </Title>
      <Button
        style={{
          borderRadius: 8,
          color: "#6549F7",
          height: 40,
          fontSize: 12,
          width: 150,
          marginBottom: 20
        }}
        sx={{
          "&:hover": {
            backgroundColor: "#6549F7",
            color: "#fff !important"
          }
        }}
        variant="subtle"
        leftIcon={<IconArrowBack />}
        onClick={() => selectMainTab("layers")}
      >
        Back to builder
      </Button>
    </div>
  )
}
export default ImageAssets
