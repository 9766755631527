import { atom, RecoilState } from "recoil"
import { TBuilderAtom } from "types/utils/actionsType"

const Atoms: {
  ExpandedAssets: RecoilState<never[] | TBuilderAtom>
  listView: RecoilState<number>
} = {
  listView: atom({ key: "listView", default: 0 }),
  ExpandedAssets: atom({
    key: "ExpandedAssets",
    default: [] //["Layout Elements", "Predefined Widgets"]
  })
}

// Layers, Assets, Settings
export const selectedMainTabAtom = atom({
  key: "SelectedMainTab",
  default: "layers"
})

// Theme, General
export const selectedSettingsTabAtom = atom({
  key: "SelectedSettingsTab",
  default: "theme"
})

export const selectedPageId = atom({
  key: "SelectedLayer",
  default: ""
})

export const expandedWidgets = atom<string[]>({
  key: "ExpandedWidgets",
  default: []
})

export default Atoms
