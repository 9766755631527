/* eslint-disable @typescript-eslint/ban-types */

import { Serializable as s } from "ts-serializable"

export class Serializable extends s {
  public toJSON(): Record<string, unknown> {
    const toJson = super.toJSON()
    for (const prop in toJson) {
      //const v = Reflect.get(toJson, prop)
      if (["parent"].indexOf(prop) !== -1 /*|| v == undefined || v == null*/) {
        Reflect.deleteProperty(toJson, prop)
      }
    }
    // fix for non Serializable instances
    return JSON.parse(JSON.stringify(toJson)) as Record<string, unknown>
  }
}
