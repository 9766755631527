import { PageWidget } from "model/pageWidget"
import { Align } from "./align"
import { Contstraints } from "./constraints"
import { Geometry } from "./geometry"
import { GeometrySize } from "./size"
import { ChildLayoutHelper } from "./utils"

export class Stack extends Geometry {
  constructor(treeEntity: PageWidget) {
    super()

    this.treeEntity = treeEntity
  }

  _computeSize(constraints: Contstraints, layoutChild: typeof ChildLayoutHelper.layoutChild): GeometrySize {
    let hasNonPositionedChildren = false

    if (this.children.length === 0) {
      return constraints.biggest.isFinite ? constraints.biggest : constraints.smallest
    }

    let width = constraints.minWidth,
      height = constraints.minHeight

    const nonPositionedConstraints = constraints.loosen()

    for (const child of this.children) {
      if (child instanceof Align) {
        hasNonPositionedChildren = true

        const childSize = layoutChild(child, nonPositionedConstraints)

        width = Math.max(width, childSize.width)
        height = Math.max(height, childSize.height)
      } else {
        layoutChild(child, nonPositionedConstraints)
      }
    }

    let size
    if (hasNonPositionedChildren) {
      size = new GeometrySize(width, height)
    } else {
      size = constraints.biggest
    }

    return size
  }

  performLayout(): void {
    const size = this._computeSize(this.constraints, ChildLayoutHelper.layoutChild)

    for (const child of this.children) {
      child.offset = this.offset
    }

    this.size = size
  }
}
