import { TPrimitive, PrimitiveSerializable, IDefault, IPrimitiveSerializable, IReset } from "./primitive"
import { IProject } from "../../project"

export interface IColor extends IDefault, IPrimitiveSerializable, IReset {
  get(): string
  getRGBA(): string
  getRGB(): string
  getRGBHEX(): string
  getARGBHEX(): string
  getRGBAHEX(): string
  getR(): number
  getG(): number
  getB(): number
  getA(): number
  set(v: string): this
  setRGBHEX(v: string): this
  setARGBHEX(v: string): this
  setR(v: number): this
  setG(v: number): this
  setB(v: number): this
  setA(v: number): this
  setARGB(a: number, r: number, g: number, b: number): this
}

export class Color extends PrimitiveSerializable implements IColor {
  protected r = 255
  protected g = 255
  protected b = 255
  protected a = 1

  isDefault(): boolean {
    return this.r === 255 && this.g === 255 && this.b === 255 && this.a === 1
  }

  get(): string {
    return this.getRGBA()
  }

  getRGBA(): string {
    return `rgba(${this.r},${this.g},${this.b},${this.a})`
  }

  getRGB(): string {
    return `rgb(${this.r},${this.g},${this.b})`
  }

  getRGBHEX(): string {
    return `#${this._hex(this.r)}${this._hex(this.g)}${this._hex(this.b)}`
  }

  getARGBHEX(): string {
    return `#${this._hex(Math.round(this.a * 255))}${this._hex(this.r)}${this._hex(this.g)}${this._hex(this.b)}`
  }

  getRGBAHEX(): string {
    return `#${this._hex(this.r)}${this._hex(this.g)}${this._hex(this.b)}${this._hex(Math.round(this.a * 255))}`
  }

  getR(): number {
    return this.r
  }
  getG(): number {
    return this.g
  }
  getB(): number {
    return this.b
  }
  getA(): number {
    return this.a
  }

  private _hex(i: number): string {
    return i.toString(16).padStart(2, "0").toUpperCase()
  }

  reset(): this {
    this.r = 255
    this.g = 255
    this.b = 255
    this.a = 1
    return this
  }

  static isRGBHEX(v: string): boolean {
    return /^#[\d|a-f]{6}$/i.test(v)
  }
  static isARGBHEX(v: string): boolean {
    return /^#[\d|a-f]{8}$/i.test(v)
  }
  static isHEX(v: string): boolean {
    return Color.isRGBHEX(v) || Color.isARGBHEX(v)
  }

  set(v: string): this {
    this.reset()
    if (Color.isRGBHEX(v)) {
      this.setRGBHEX(v)
    } else if (Color.isARGBHEX(v)) {
      this.setARGBHEX(v)
    }
    return this
  }

  setRGBHEX(v: string): this {
    this.r = parseInt(v.substr(1, 2), 16)
    this.g = parseInt(v.substr(3, 2), 16)
    this.b = parseInt(v.substr(5, 2), 16)
    this.a = 1
    return this
  }

  setARGBHEX(v: string): this {
    this.r = parseInt(v.substr(3, 2), 16)
    this.g = parseInt(v.substr(5, 2), 16)
    this.b = parseInt(v.substr(7, 2), 16)
    this.a = parseFloat((parseInt(v.substr(1, 2), 16) / 255).toFixed(2))
    return this
  }

  setR(v: number): this {
    this.r = v
    return this
  }

  setG(v: number): this {
    this.g = v
    return this
  }

  setB(v: number): this {
    this.b = v
    return this
  }

  setA(v: number): this {
    this.a = v
    return this
  }

  setARGB(a: number, r: number, g: number, b: number): this {
    this.a = a
    this.r = r
    this.g = g
    this.b = b
    return this
  }

  fromJSON(v: TPrimitive): this {
    return this.set(v as string)
  }
  toJSON(): TPrimitive {
    return this.getARGBHEX()
  }
}

export interface IProjectColor extends IDefault, IPrimitiveSerializable {
  set(v: string): this
  reset(): this
  isType(): boolean
  getType(): string
  get(): IColor
}

export class ProjectColor extends PrimitiveSerializable implements IProjectColor {
  protected t?: string
  protected c: IColor

  constructor(p?: IProject) {
    super(p)
    this.c = new Color(this.p)
  }

  reset(): this {
    this.t = undefined
    this.c = new Color(this.p)
    return this
  }

  isDefault(): boolean {
    return !this.isType() && this.get().isDefault()
  }

  set(v: string): this {
    this.reset()
    if (Color.isHEX(v)) {
      this.t = undefined
      this.c = new Color(this.p).fromJSON(v)
    } else {
      const color = this.p?.getColorByKey(v)
      if (color) {
        this.t = v
        this.c = color
      }
    }
    return this
  }

  getType(): string {
    return this.t || ""
  }

  isType(): boolean {
    return !!this.t
  }

  get(): IColor {
    return this.c
  }

  fromJSON(v: TPrimitive): this {
    return this.set(v as string)
  }
  toJSON(): TPrimitive {
    if (this.isType()) {
      return `${this.t}`
    }
    return this.get().getARGBHEX()
  }
}
