import { TPrimitive, PrimitiveSerializable } from "./primitive"

export class CNumber extends PrimitiveSerializable {
  v = 0
  isDefault(): boolean {
    return this.v === 0
  }
  reset(): this {
    this.v = 0
    return this
  }
  get(): number {
    return this.v
  }
  set(v: number): this {
    this.v = v
    return this
  }
  fromJSON(v: TPrimitive): this {
    return this.set(v as number)
  }
  toJSON(): TPrimitive {
    return this.v
  }
}
