import { Link } from "react-router-dom"
import styles from "styles/builder/toolbar.module.css"
import { IconChevronLeft } from "@tabler/icons"

const LeftBar = (): JSX.Element => {
  // const { projectId } = useParams()
  return (
    <div className={styles.sideButtonGroup}>
      <Link to={`/d/project/`} className={styles.Button}>
        <IconChevronLeft size={16} strokeWidth={2.5} />
        Return to Dashboard
      </Link>
    </div>
  )
}

export default LeftBar
