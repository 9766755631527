import { memo, useState, useContext, useEffect, MouseEvent, Key, CSSProperties } from "react"
import { useRecoilState, useRecoilValue } from "recoil"

import EventEmitter from "utils/eventEmitter"
import { AppStateContext } from "service/appstate/"
import ScrollContainer from "pages/builder/leftpanel/Components/ScrollContainer"
import LayersRowItem from "pages/builder/leftpanel/Components/LayersRowItem"
import styles from "styles/builder/leftpanel.module.css"

import { ILayer } from "types"

import { TLayerContext } from "types/pages/builderTypes"
import { IPubSubResponse } from "types/pubsub/pubsubTypes"
import { IconPlus } from "@tabler/icons"
import { selectedPageId } from "service/appstate/builder/atoms"
import { currentProject, pageListForceUpdaterAtom, pagesListSelector } from "service/appstate/project/atoms"

import { Page } from "model/page"
import IdeActions from "service/appstate/application/actions"

type TLayerStateTypes = {
  dragOverPageGuid: string | null
  draggingBefore: boolean
  renamingGuid: string | null
  reorderingGuid: string | null
}
type TStreamObserverCache = {
  index: null | number
  jobId: string
  layerType: string
  title: string
}

let cachedDragOverBounds: { top: number; height: number }

interface ILayerProps {
  style?: CSSProperties
  heightPagesList: string | number | undefined
  widthLeftPanel: number
  resourceRequest: string
}

const Layers = ({ style, heightPagesList, widthLeftPanel, resourceRequest }: ILayerProps) => {
  const {
    appStates: { builderState },
    appActions: { builderActions }
  } = useContext<TLayerContext>(AppStateContext)

  const { leftLayerTab } = builderState
  const { setLeftLayerTab } = builderActions

  const [selectedLayerId, setSelectedLayerId] = useRecoilState(selectedPageId)
  const [, pageListForceUpdater] = useRecoilState(pageListForceUpdaterAtom)
  const project = useRecoilValue(currentProject)
  const pagesList = useRecoilValue(pagesListSelector)
  const componentsList: ILayer[] = []

  const initionalStateForStreamObserverCache: TStreamObserverCache = {
    jobId: "",
    layerType: "",
    title: "",
    index: null
  }
  const initionalStateForLayers: TLayerStateTypes = {
    renamingGuid: null,
    reorderingGuid: null,
    dragOverPageGuid: null,
    draggingBefore: false
  }
  const [streamObserverCache, setStreamObserverCache] = useState<TStreamObserverCache>(
    initionalStateForStreamObserverCache
  )
  const [layerState, setLayerState] = useState<TLayerStateTypes>(initionalStateForLayers)

  const onClickCallback = (id: string) => {
    setSelectedLayerId(id)
  }
  const startRenamingLayer = (id: string | null) => {
    setLayerState((state: TLayerStateTypes) => {
      return {
        ...state,
        renamingGuid: id
      }
    })
  }

  const onChangeLayerName = async () => {
    return false
  }

  const onChangeLayerPosition = async (fromNodeId: string, toNodeId: string, position: "before" | "after") => {
    // let jobId = ""
    let index = 0
    let toIndex = 0

    try {
      const layer = leftLayerTab === "pages" ? pagesList : componentsList
      index = layer.findIndex((item) => item.id === fromNodeId)
      toIndex = layer.findIndex((item) => item.id === toNodeId)
      if (position === "before" && index < toIndex) toIndex--
      if (position === "after" && index > toIndex) toIndex++

      if (index === toIndex) return
    } catch (err) {
      return false
    }
  }

  const onAddNewLayer = async (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    let newTitle = ""
    let jobId = ""
    let index = 0

    try {
      if (leftLayerTab === "pages") {
        newTitle = `Page ${pagesList.length + 1}`
        jobId = await IdeActions.createPage(newTitle, resourceRequest)

        index = pagesList.findIndex((item) => item.id === selectedLayerId)
      }

      if (leftLayerTab === "components") {
        newTitle = `Component ${componentsList.length + 1}`
        jobId = await IdeActions.createComponent(resourceRequest, newTitle)
        index = componentsList.findIndex((item: { id: string }) => item.id === selectedLayerId)
      }

      if (index === -1) {
        index = 0
      }

      setStreamObserverCache((state) => ({
        ...state,
        index,
        title: newTitle,
        layerType: leftLayerTab,
        jobId
      }))
    } catch (err) {
      return false
    }
  }

  const onMouseDownCallback = (id: string) => {
    setLayerState((state: TLayerStateTypes) => {
      return {
        ...state,
        reorderingGuid: id,
        dragOverPageGuid: null
      }
    })
  }

  const onMouseMoveCallback = (id: string, e: MouseEvent) => {
    if (layerState.reorderingGuid) {
      if (layerState.dragOverPageGuid !== id) {
        const target = e.target as HTMLElement
        cachedDragOverBounds = target.getBoundingClientRect()
      }
      const i = e.clientY - cachedDragOverBounds.top
      if (layerState.reorderingGuid !== id) {
        setLayerState((state: TLayerStateTypes) => ({
          ...state,
          dragOverPageGuid: id,
          draggingBefore: i < cachedDragOverBounds.height / 2
        }))
      }
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const onMouseUpCallback = (id: string, e: MouseEvent) => {
    if (finishLayerReorder(id)) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const finishLayerReorder = (id: string) => {
    let t = !1

    if (layerState.reorderingGuid && id && layerState.reorderingGuid !== id) {
      onChangeLayerPosition(layerState.reorderingGuid, id, layerState.draggingBefore ? "before" : "after")
      t = !0
    }
    setLayerState(initionalStateForLayers)
    return t
  }

  const showInsertBefore = (id: string) => layerState.dragOverPageGuid === id && layerState.draggingBefore

  const showInsertAfter = (id: string) => layerState.dragOverPageGuid === id && !layerState.draggingBefore

  useEffect(() => {
    const pubsubCb = (data: IPubSubResponse) => {
      if (streamObserverCache.jobId === data.jobId) {
        //create
        if (data.event === 1) {
          const page = new Page({ title: streamObserverCache.title, id: data.id })
          if (project) {
            page.setProject(project)
          }

          pagesList.push(page)
          pageListForceUpdater((val) => val + 1)

          setSelectedLayerId(data.id)
          startRenamingLayer(data.id)
          setStreamObserverCache(initionalStateForStreamObserverCache)
        }

        //update
        if (data.event === 2) {
          setLayerState((state: TLayerStateTypes) => {
            return {
              ...state,
              renamingGuid: null
            }
          })
          setStreamObserverCache(initionalStateForStreamObserverCache)
        }
      }
    }
    EventEmitter.on("streamProjectBranch/data", pubsubCb)

    return () => {
      EventEmitter.off("streamProjectBranch/data", pubsubCb)
    }
  }, [streamObserverCache])

  return (
    <div className={styles.pagesPanelContent} style={style}>
      <div className={styles.tabsHeader}>
        <div
          style={{
            margin: "0 15px 0 5px"
          }}
          onClick={() => setLeftLayerTab("pages")}
          className={[styles.tab, leftLayerTab === "pages" ? styles.tabActive : ""].join(" ")}
          data-label="Pages"
        >
          Pages
        </div>
        <div
          onClick={() => setLeftLayerTab("components")}
          className={[styles.tab, leftLayerTab === "components" ? styles.tabActive : ""].join(" ")}
          data-label="Components"
        >
          Components
        </div>
        <div className={styles.rightTab} style={{ paddingRight: 0, marginTop: -10 }}>
          <span
            className={[styles.iconButtonEnabled, styles._iconButton, styles.newPageButton].join(" ")}
            onClick={(e) => onAddNewLayer(e)}
          >
            <IconPlus size={17} strokeWidth={2} />
          </span>
        </div>
      </div>
      <div className={styles.layersList} style={{ height: heightPagesList }}>
        {leftLayerTab === "pages" && (
          <ScrollContainer>
            {pagesList.map((node, index: Key) => {
              const rowClassName = [styles.pagesRowSpacer]

              if (showInsertBefore(node.id || "")) {
                rowClassName.push(!index ? styles.pageRowSpacerInsertBeforeTop : styles.pageRowSpacerInsertBefore)
              }

              if (showInsertAfter(node.id || "")) {
                rowClassName.push(
                  index === pagesList.length - 1
                    ? styles.pageRowSpacerInsertAfterBottom
                    : styles.pageRowSpacerInsertAfter
                )
              }

              return (
                <div key={index} className={rowClassName.join(" ")}>
                  <LayersRowItem
                    width={widthLeftPanel - 64}
                    onChangeName={onChangeLayerName}
                    nodeId={node.id || ""}
                    nodeName={node.title}
                    layerType={"pages"}
                    isSelected={selectedLayerId === node.id}
                    isRenaming={layerState.renamingGuid === node.id}
                    startRenaming={startRenamingLayer}
                    onClickCallback={onClickCallback}
                    onMouseDownCallback={onMouseDownCallback}
                    onMouseMoveCallback={onMouseMoveCallback}
                    onMouseUpCallback={onMouseUpCallback}
                  />
                </div>
              )
            })}
          </ScrollContainer>
        )}

        {leftLayerTab === "components" && (
          <ScrollContainer>
            {componentsList.map((node: ILayer, index: Key) => {
              const rowClassName = [styles.pagesRowSpacer]

              if (showInsertBefore(node.id)) {
                rowClassName.push(!index ? styles.pageRowSpacerInsertBeforeTop : styles.pageRowSpacerInsertBefore)
              }

              if (showInsertAfter(node.id)) {
                rowClassName.push(
                  index === componentsList.length - 1
                    ? styles.pageRowSpacerInsertAfterBottom
                    : styles.pageRowSpacerInsertAfter
                )
              }

              return (
                <div key={index} className={rowClassName.join(" ")} style={{ padding: 10 }}>
                  <LayersRowItem
                    width={widthLeftPanel - 64}
                    nodeName={node.title}
                    onChangeName={onChangeLayerName}
                    nodeId={node.id}
                    layerType={"components"}
                    isSelected={selectedLayerId === node.id}
                    isRenaming={layerState.renamingGuid === node.id}
                    startRenaming={startRenamingLayer}
                    onClickCallback={onClickCallback}
                    onMouseDownCallback={onMouseDownCallback}
                    onMouseMoveCallback={onMouseMoveCallback}
                    onMouseUpCallback={onMouseUpCallback}
                  />
                </div>
              )
            })}
          </ScrollContainer>
        )}
      </div>
    </div>
  )
}
export default memo(Layers)
