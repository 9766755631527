import { StrictMode } from "react"
import { render } from "react-dom"
import localForage from "localforage"
import AppState from "service/appstate"
import Routing from "service/routing"
import { RecoilRoot } from "recoil"
import { api } from "service/api"
import "./styles.css"

const storage = localForage.createInstance({
  driver: localForage.LOCALSTORAGE,
  name: process.env.REACT_APP_CLIENT_VERSION
})

render(
  <StrictMode>
    <RecoilRoot>
      {/* <RecoilDebugger /> */}
      <AppState settings={{ api, storage }}>
        <Routing />
      </AppState>
    </RecoilRoot>
  </StrictMode>,
  document.getElementById("root")
)
