import { NumberInputProps } from "@mantine/core/lib/NumberInput/NumberInput"
import { NumberInput } from "@mantine/core"
import { memo } from "react"

const CNumber = memo(({ value, label, onChange }: NumberInputProps): JSX.Element => {
  return (
    <div style={{ width: "calc(50% - 6px)" }}>
      <NumberInput
        sx={() => ({
          label: {
            color: "rgba(34, 39, 48, 0.7)",
            padding: "0 0 4px 0",
            fontWeight: "bold"
          },
          input: {
            background: "#fff",
            border: "1px solid #ddd",
            borderRadius: 8,
            color: "#000"
          },
          background: "#fff",
          color: "#000"
          // marginTop: "12px"
        })}
        hideControls
        size="xs"
        value={value}
        label={label}
        onChange={onChange}
      />
    </div>
  )
})

export default CNumber
