import { IEntityNodeTree } from "./entityNode"

export enum ENodeEventList {
  TreeImported = "tree:imported",
  NodeAdded = "node:added",
  NodeUpdated = "node:updated",
  NodePropertyUpdated = "node:property:updated",
  NodeDeleted = "node:deleted",
  NodeMoved = "node:moved",
  NodeWrapped = "node:wrapped",
  NodeSelected = "node:selected",
  NodeUnselected = "node:unselected",
  NodeOver = "node:over",
  NodeOut = "node:out",
  NodeBuffered = "node:buffered",
  NodeUnBuffered = "node:unbuffered"
}

export type TEmptyEvent = Record<string, never>

export type TNodeEvent = {
  node?: IEntityNodeTree
  nodeList?: IEntityNodeTree[]
}

export type TNodeIdEvent = {
  id?: string
  idList?: string[]
  parent?: IEntityNodeTree
}

export type TNodeMoveEvent = TNodeEvent & {
  from: IEntityNodeTree
  to: IEntityNodeTree
}

export type TNodeEventTypeList = {
  [ENodeEventList.TreeImported]: TEmptyEvent
  [ENodeEventList.NodeAdded]: TNodeEvent
  [ENodeEventList.NodeAdded]: TNodeEvent
  [ENodeEventList.NodeUpdated]: TNodeEvent
  [ENodeEventList.NodePropertyUpdated]: TNodeEvent
  [ENodeEventList.NodeDeleted]: TNodeIdEvent
  [ENodeEventList.NodeMoved]: TNodeMoveEvent
  [ENodeEventList.NodeWrapped]: TNodeMoveEvent
  [ENodeEventList.NodeSelected]: TNodeEvent
  [ENodeEventList.NodeUnselected]: TNodeIdEvent
  [ENodeEventList.NodeOver]: TNodeEvent
  [ENodeEventList.NodeOut]: TNodeIdEvent
  [ENodeEventList.NodeBuffered]: TNodeEvent
  [ENodeEventList.NodeUnBuffered]: TNodeEvent
}
