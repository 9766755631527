import { Title, Grid, NumberInput, Button } from "@mantine/core"
import { Space } from "../../../model/widgetProperty/type"
import { IconLock, IconLockOpen } from "@tabler/icons"
import { useToggle } from "@mantine/hooks"

interface ISpaceInputProps {
  value: Space
  onChange: () => void
  label: string
  group: string
}

const SpaceInput = ({ value, onChange, group }: ISpaceInputProps): JSX.Element => {
  const [icon, toggle] = useToggle()
  const top = value.getTop()
  const right = value.getRight()
  const bottom = value.getBottom()
  const left = value.getLeft()

  const _onChange = (val: number, axis: string) => {
    let _val = val
    if (val === undefined || Number.isNaN(val)) {
      _val = 0
    }

    if (axis === "t") {
      value.setTop(_val)
    }
    if (axis === "r") {
      value.setRight(_val)
    }
    if (axis === "b") {
      value.setBottom(_val)
    }
    if (axis === "l") {
      value.setLeft(_val)
    }
    if (axis === "v") {
      value.setAll(_val)
    }

    onChange()
  }

  const renderSpace = () => {
    if (group === "padding" || group === "icon") {
      return (
        <Grid
          grow
          // my="xs"
          sx={() => ({
            border: "1px solid #6549F7",
            // textAlignLast: "center",
            borderRadius: "30px",
            background: "#F7F5FF",
            margin: "0",
            // marginRight: "0",
            width: "70%"
          })}
        >
          <Grid.Col
            sx={() => ({
              textAlign: "center"
            })}
          >
            <NumberInput
              sx={() => ({
                input: {
                  width: 33,
                  textAlign: "center",
                  background: "#F7F5FF",
                  border: "none",
                  padding: "0",
                  fontSize: 14,
                  color: "#6549F7"
                },
                display: "inline-block"
              })}
              value={top !== 0 ? top : undefined}
              hideControls
              placeholder="T"
              size="xs"
              onChange={icon ? (e) => _onChange(Number(e), "v") : (e) => _onChange(Number(e), "t")}
            />
          </Grid.Col>
          <Grid.Col
            sx={() => ({
              textAlign: "center",
              padding: "0 4px"
            })}
          >
            <NumberInput
              sx={() => ({
                input: {
                  width: 33,
                  textAlign: "center",
                  background: "#F7F5FF",
                  border: "none",
                  padding: "0",
                  fontSize: 14,
                  color: "#6549F7"
                },
                display: "inline-block",
                float: "left"
              })}
              value={left !== 0 ? left : undefined}
              hideControls
              placeholder="L"
              size="xs"
              onChange={icon ? (e) => _onChange(Number(e), "v") : (e) => _onChange(Number(e), "l")}
            />
            <Button
              sx={() => ({
                background: "#6549F7",
                // border: "none",
                color: "#fff",
                padding: "4px 6px",
                height: 32
              })}
              styles={{
                root: {
                  "&:hover": {
                    backgroundColor: "#6549F7"
                  }
                }
              }}
              variant="light"
              radius="lg"
              onClick={() => toggle()}
            >
              {icon ? <IconLock size={18} /> : <IconLockOpen size={18} />}
            </Button>
            <NumberInput
              sx={() => ({
                input: {
                  width: 33,
                  textAlign: "center",
                  background: "#F7F5FF",
                  border: "none",
                  padding: "0",
                  fontSize: 14,
                  color: "#6549F7"
                },
                float: "right",
                display: "inline-block"
              })}
              value={right !== 0 ? right : undefined}
              hideControls
              placeholder="R"
              size="xs"
              onChange={icon ? (e) => _onChange(Number(e), "v") : (e) => _onChange(Number(e), "r")}
            />
          </Grid.Col>
          <Grid.Col
            sx={() => ({
              textAlign: "center"
            })}
          >
            <NumberInput
              sx={() => ({
                input: {
                  width: 33,
                  textAlign: "center",
                  background: "#F7F5FF",
                  border: "none",
                  padding: "0",
                  color: "#6549F7",
                  fontSize: 14,
                  display: "inline-block"
                }
              })}
              value={bottom !== 0 ? bottom : undefined}
              hideControls
              size="xs"
              placeholder="B"
              onChange={icon ? (e) => _onChange(Number(e), "v") : (e) => _onChange(Number(e), "b")}
            />
          </Grid.Col>
        </Grid>
      )
    } else if (group === "border") {
      return (
        <Grid
          grow
          mx="auto"
          sx={() => ({
            width: "150px",
            margin: 0
          })}
        >
          <Title
            sx={{
              fontSize: 12,
              color: "rgba(34, 39, 48, 0.7)",
              marginBottom: 4
            }}
          >
            Border Radius
          </Title>
          <Grid.Col sx={{ padding: 0 }}>
            <div
              style={{
                display: "inline-flex",
                border: "1px solid #6549F7",
                paddingLeft: 5,
                borderRadius: 10,
                background: "#F7F5FF"
              }}
            >
              <NumberInput
                sx={() => ({
                  input: {
                    width: 40,
                    textAlign: "center",
                    background: "#F7F5FF",
                    border: "none",
                    padding: "0",
                    fontSize: 14,
                    color: "#6549F7"
                  },
                  display: "inline-block"
                })}
                styles={{
                  root: {
                    display: "block"
                  }
                }}
                value={top !== 0 ? top : undefined}
                hideControls
                placeholder="TL"
                size="xs"
                onChange={icon ? (e) => _onChange(Number(e), "v") : (e) => _onChange(Number(e), "t")}
              />
              <div
                style={{
                  width: 15,
                  height: 15,
                  borderTop: "2px solid #6549F7",
                  borderLeft: "2px solid #6549F7",
                  display: "inline-block",
                  margin: "10px 5px 0 0"
                }}
              ></div>
            </div>
            <div
              style={{
                display: "inline-flex",
                float: "right",
                borderRadius: 10,
                border: "1px solid #6549F7",
                paddingRight: 5,
                background: "#F7F5FF"
              }}
            >
              <div
                style={{
                  width: 15,
                  height: 15,
                  borderTop: "2px solid #6549F7",
                  borderRight: "2px solid #6549F7",
                  display: "inline-block",
                  margin: "10px 0px 0px 5px"
                }}
              ></div>
              <NumberInput
                sx={() => ({
                  input: {
                    width: 40,
                    textAlign: "center",
                    background: "#F7F5FF",
                    border: "none",
                    padding: "0",
                    fontSize: 14,
                    color: "#6549F7"
                  },
                  display: "inline-block",
                  float: "right"
                })}
                styles={{
                  root: {
                    display: "block"
                  }
                }}
                value={right !== 0 ? right : undefined}
                hideControls
                placeholder="TR"
                size="xs"
                onChange={icon ? (e) => _onChange(Number(e), "v") : (e) => _onChange(Number(e), "r")}
              />
            </div>
          </Grid.Col>
          <Grid.Col
            sx={{
              textAlign: "center",
              padding: 0
            }}
          >
            <Button
              sx={() => ({
                background: "#6549F7",
                // border: "none",
                color: "#fff",
                padding: "4px 6px",
                height: 32
              })}
              styles={{
                root: {
                  "&:hover": {
                    backgroundColor: "#6549F7"
                  }
                }
              }}
              variant="light"
              radius="lg"
              onClick={() => toggle()}
            >
              {icon ? <IconLock size={18} /> : <IconLockOpen size={18} />}
            </Button>
          </Grid.Col>
          <Grid.Col
            sx={{
              padding: 0
            }}
          >
            <div
              style={{
                display: "inline-flex",
                border: "1px solid #6549F7",
                borderRadius: 10,
                paddingLeft: 5,
                background: "#F7F5FF"
              }}
            >
              <NumberInput
                sx={() => ({
                  input: {
                    width: 40,
                    textAlign: "center",
                    background: "#F7F5FF",
                    border: "none",
                    padding: "0",
                    color: "#6549F7",
                    fontSize: 14
                  },
                  display: "inline-block"
                })}
                value={left !== 0 ? left : undefined}
                hideControls
                size="xs"
                placeholder="BL"
                onChange={icon ? (e) => _onChange(Number(e), "v") : (e) => _onChange(Number(e), "l")}
              />
              <div
                style={{
                  width: 15,
                  height: 15,
                  borderBottom: "2px solid #6549F7",
                  borderLeft: "2px solid #6549F7",
                  display: "inline-block",
                  margin: "5px 5px 0 0"
                }}
              ></div>
            </div>
            <div
              style={{
                display: "inline-flex",
                float: "right",
                paddingRight: 5,
                borderRadius: 10,
                border: "1px solid #6549F7",
                background: "#F7F5FF"
              }}
            >
              <div
                style={{
                  width: 15,
                  height: 15,
                  borderBottom: "2px solid #6549F7",
                  borderRight: "2px solid #6549F7",
                  display: "inline-block",
                  margin: "3px 0 7px 3px"
                }}
              ></div>
              <NumberInput
                sx={() => ({
                  input: {
                    width: 40,
                    textAlign: "center",
                    background: "#F7F5FF",
                    border: "none",
                    padding: "0",
                    fontSize: 14,
                    color: "#6549F7"
                  },
                  display: "inline-block",
                  float: "right"
                })}
                value={bottom !== 0 ? bottom : undefined}
                hideControls
                placeholder="BR"
                size="xs"
                onChange={icon ? (e) => _onChange(Number(e), "v") : (e) => _onChange(Number(e), "b")}
              />
            </div>
          </Grid.Col>
        </Grid>
      )
    }
  }

  return <div>{renderSpace()}</div>
}

export default SpaceInput
