/**
 * @fileoverview gRPC-Web generated client stub for jupiter.builder.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')
const proto = {};
proto.jupiter = {};
proto.jupiter.builder = {};
proto.jupiter.builder.v1 = require('./ide-resource_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.jupiter.builder.v1.IdeResourceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.jupiter.builder.v1.IdeResourcePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.VersionCursorRequest,
 *   !proto.jupiter.builder.v1.GetIdeWidgetListResponse>}
 */
const methodDescriptor_IdeResource_GetWidgetList = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.IdeResource/GetWidgetList',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.VersionCursorRequest,
  proto.jupiter.builder.v1.GetIdeWidgetListResponse,
  /**
   * @param {!proto.jupiter.builder.v1.VersionCursorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.GetIdeWidgetListResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.VersionCursorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.GetIdeWidgetListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.GetIdeWidgetListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeResourceClient.prototype.getWidgetList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.IdeResource/GetWidgetList',
      request,
      metadata || {},
      methodDescriptor_IdeResource_GetWidgetList,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.VersionCursorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.GetIdeWidgetListResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdeResourcePromiseClient.prototype.getWidgetList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.IdeResource/GetWidgetList',
      request,
      metadata || {},
      methodDescriptor_IdeResource_GetWidgetList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.VersionCursorRequest,
 *   !proto.jupiter.builder.v1.GetIdeFontListResponse>}
 */
const methodDescriptor_IdeResource_GetFontList = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.IdeResource/GetFontList',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.VersionCursorRequest,
  proto.jupiter.builder.v1.GetIdeFontListResponse,
  /**
   * @param {!proto.jupiter.builder.v1.VersionCursorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.GetIdeFontListResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.VersionCursorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.GetIdeFontListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.GetIdeFontListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeResourceClient.prototype.getFontList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.IdeResource/GetFontList',
      request,
      metadata || {},
      methodDescriptor_IdeResource_GetFontList,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.VersionCursorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.GetIdeFontListResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdeResourcePromiseClient.prototype.getFontList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.IdeResource/GetFontList',
      request,
      metadata || {},
      methodDescriptor_IdeResource_GetFontList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.VersionCursorRequest,
 *   !proto.jupiter.builder.v1.GetIdeIconListResponse>}
 */
const methodDescriptor_IdeResource_GetIconList = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.IdeResource/GetIconList',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.VersionCursorRequest,
  proto.jupiter.builder.v1.GetIdeIconListResponse,
  /**
   * @param {!proto.jupiter.builder.v1.VersionCursorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.GetIdeIconListResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.VersionCursorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.GetIdeIconListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.GetIdeIconListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeResourceClient.prototype.getIconList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.IdeResource/GetIconList',
      request,
      metadata || {},
      methodDescriptor_IdeResource_GetIconList,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.VersionCursorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.GetIdeIconListResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdeResourcePromiseClient.prototype.getIconList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.IdeResource/GetIconList',
      request,
      metadata || {},
      methodDescriptor_IdeResource_GetIconList);
};


module.exports = proto.jupiter.builder.v1;

