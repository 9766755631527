import { Space } from "model/widgetProperty/type"

export class GeometryPadding {
  private _left = 0
  private _top = 0
  private _right = 0
  private _bottom = 0

  constructor(propertyPadding: Space) {
    this._left = propertyPadding.getLeft()
    this._top = propertyPadding.getTop()
    this._right = propertyPadding.getRight()
    this._bottom = propertyPadding.getBottom()
  }

  get left(): number {
    return this._left
  }

  get top(): number {
    return this._top
  }

  get right(): number {
    return this._right
  }

  get bottom(): number {
    return this._bottom
  }

  get horizontal(): number {
    return this._left + this._right
  }

  get vertical(): number {
    return this._top + this._bottom
  }
}
