/**
 * @fileoverview gRPC-Web generated client stub for jupiter.builder.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var common_pb = require('./common_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.jupiter = {};
proto.jupiter.builder = {};
proto.jupiter.builder.v1 = require('./project_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.jupiter.builder.v1.ProjectsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.jupiter.builder.v1.ProjectsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.ListProjectsRequest,
 *   !proto.jupiter.builder.v1.ListProjectsResponse>}
 */
const methodDescriptor_Projects_ListProjects = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Projects/ListProjects',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.ListProjectsRequest,
  proto.jupiter.builder.v1.ListProjectsResponse,
  /**
   * @param {!proto.jupiter.builder.v1.ListProjectsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.ListProjectsResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.ListProjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.ListProjectsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.ListProjectsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.ProjectsClient.prototype.listProjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Projects/ListProjects',
      request,
      metadata || {},
      methodDescriptor_Projects_ListProjects,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.ListProjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.ListProjectsResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.ProjectsPromiseClient.prototype.listProjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Projects/ListProjects',
      request,
      metadata || {},
      methodDescriptor_Projects_ListProjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.IdRequest,
 *   !proto.jupiter.builder.v1.Project>}
 */
const methodDescriptor_Projects_GetProject = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Projects/GetProject',
  grpc.web.MethodType.UNARY,
  common_pb.IdRequest,
  proto.jupiter.builder.v1.Project,
  /**
   * @param {!proto.jupiter.builder.v1.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.Project.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.Project)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.Project>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.ProjectsClient.prototype.getProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Projects/GetProject',
      request,
      metadata || {},
      methodDescriptor_Projects_GetProject,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.Project>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.ProjectsPromiseClient.prototype.getProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Projects/GetProject',
      request,
      metadata || {},
      methodDescriptor_Projects_GetProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.CreateProjectRequest,
 *   !proto.jupiter.builder.v1.IdResponse>}
 */
const methodDescriptor_Projects_CreateProject = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Projects/CreateProject',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.CreateProjectRequest,
  common_pb.IdResponse,
  /**
   * @param {!proto.jupiter.builder.v1.CreateProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.IdResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.CreateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.IdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.IdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.ProjectsClient.prototype.createProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Projects/CreateProject',
      request,
      metadata || {},
      methodDescriptor_Projects_CreateProject,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.CreateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.IdResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.ProjectsPromiseClient.prototype.createProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Projects/CreateProject',
      request,
      metadata || {},
      methodDescriptor_Projects_CreateProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.CreateProjectRequest,
 *   !proto.jupiter.builder.v1.JobIdResponse>}
 */
const methodDescriptor_Projects_CreateProjectAsync = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Projects/CreateProjectAsync',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.CreateProjectRequest,
  common_pb.JobIdResponse,
  /**
   * @param {!proto.jupiter.builder.v1.CreateProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.JobIdResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.CreateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.JobIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.JobIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.ProjectsClient.prototype.createProjectAsync =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Projects/CreateProjectAsync',
      request,
      metadata || {},
      methodDescriptor_Projects_CreateProjectAsync,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.CreateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.JobIdResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.ProjectsPromiseClient.prototype.createProjectAsync =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Projects/CreateProjectAsync',
      request,
      metadata || {},
      methodDescriptor_Projects_CreateProjectAsync);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.UpdateProjectRequest,
 *   !proto.jupiter.builder.v1.Project>}
 */
const methodDescriptor_Projects_UpdateProject = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Projects/UpdateProject',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.UpdateProjectRequest,
  proto.jupiter.builder.v1.Project,
  /**
   * @param {!proto.jupiter.builder.v1.UpdateProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.Project.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.UpdateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.Project)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.Project>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.ProjectsClient.prototype.updateProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Projects/UpdateProject',
      request,
      metadata || {},
      methodDescriptor_Projects_UpdateProject,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.UpdateProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.Project>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.ProjectsPromiseClient.prototype.updateProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Projects/UpdateProject',
      request,
      metadata || {},
      methodDescriptor_Projects_UpdateProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.IdRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Projects_DeleteProject = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Projects/DeleteProject',
  grpc.web.MethodType.UNARY,
  common_pb.IdRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.jupiter.builder.v1.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.ProjectsClient.prototype.deleteProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Projects/DeleteProject',
      request,
      metadata || {},
      methodDescriptor_Projects_DeleteProject,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.ProjectsPromiseClient.prototype.deleteProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Projects/DeleteProject',
      request,
      metadata || {},
      methodDescriptor_Projects_DeleteProject);
};


module.exports = proto.jupiter.builder.v1;

