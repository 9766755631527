import { fabric } from "fabric"
import { TWidgetOptions } from "./types"
import { CNumber, Icon, ProjectColor, ProjectFont, Space, WidgetDynamicValue } from "model/widgetProperty/type"
import { WidgetParameter } from "model/pageWidget"
import { IdeIcon } from "service/pb/ide-resource_pb"

export interface IButtonWidgetProps {
  backgroundColor?: ProjectColor
  borderColor?: ProjectColor
  width?: CNumber
  height?: CNumber
  borderRadius?: Space
  borderWidth?: CNumber
  elevation?: CNumber
  value?: WidgetParameter
  font?: ProjectFont
  text?: WidgetDynamicValue
  icon?: Icon
}

export const ButtonWidget = fabric.util.createClass(fabric.Object, {
  type: "Button",
  stateProperties: fabric.Object?.prototype?.stateProperties?.concat("geometry", "width", "height", "top", "left"),
  cacheProperties: fabric.Object?.prototype?.cacheProperties?.concat("geometry", "width", "height", "top", "left"),
  initialize: function (options: TWidgetOptions) {
    if (!options?.pageWidget) {
      throw new Error("Page widget is required option")
    }

    const { pageWidget, ctx, geometry, iconsList } = options

    this.id = pageWidget.id
    this.fabricParent = ctx.getObjects().find((item) => {
      return item.id === pageWidget.parent?.id
    })

    const { size, offset } = geometry

    this.strokeWidth = 0

    this.set({ geometry, pageWidget, width: size.width, height: size.height, left: offset.x, top: offset.y, iconsList })

    if (this.fabricParent?.childClipper) {
      this.clipPath = this.fabricParent.childClipper
      this.childClipper = this.fabricParent.childClipper
    }

    this.callSuper("initialize")
  },
  _render: function (ctx: CanvasRenderingContext2D) {
    const pageWidget = this.pageWidget
    const property = pageWidget.property as IButtonWidgetProps

    const text = property?.text?.render() || ""

    if (property.borderRadius) {
      this.topLeftRadius = property.borderRadius.getTop() || 0
      this.topRightRadius = property.borderRadius.getRight() || 0
      this.bottomRightRadius = property.borderRadius.getBottom() || 0
      this.bottomLeftRadius = property.borderRadius.getLeft() || 0
    }

    const font = property.font?.get()

    const fontSize = font?.getSize() || 16,
      fontFamily = font?.getFamily() || "Roboto",
      fontStyle = font?.getStyle() || "normal",
      fontWeight = font?.getWeight() || "400",
      fontFill = font?.getColor()?.get()?.getRGBAHEX() || "black"

    const geometry = this.geometry

    let strokeWidth = property?.borderWidth?.get() || 0

    const strokeColor = property.borderColor?.get().getRGBAHEX()

    if (property.borderColor?.get().getA() === 0) {
      strokeWidth = 0
    }

    this.width = geometry.size.width
    this.height = geometry.size.height
    this.top = geometry.offset.y
    this.left = geometry.offset.x

    const tl = this.topLeftRadius ? Math.min(this.topLeftRadius, this.width / 2, this.height / 2) : 0,
      tr = this.topRightRadius ? Math.min(this.topRightRadius, this.width / 2, this.height / 2) : 0,
      br = this.bottomRightRadius ? Math.min(this.bottomRightRadius, this.width / 2, this.height / 2) : 0,
      bl = this.bottomLeftRadius ? Math.min(this.bottomLeftRadius, this.width / 2, this.height / 2) : 0,
      w = this.width,
      h = this.height,
      x = -this.width / 2,
      y = -this.height / 2,
      /* "magic number" for bezier approximations of arcs (http://itc.ktu.lt/itc354/Riskus354.pdf) */
      k = 1 - 0.5522847498

    ctx.beginPath()

    ctx.moveTo(x + tl, y)

    ctx.lineTo(x + w - tr, y)
    ctx.bezierCurveTo(x + w - k * tr, y, x + w, y + k * tr, x + w, y + tr)

    ctx.lineTo(x + w, y + h - br)
    ctx.bezierCurveTo(x + w, y + h - k * br, x + w - k * br, y + h, x + w - br, y + h)

    ctx.lineTo(x + bl, y + h)
    ctx.bezierCurveTo(x + k * bl, y + h, x, y + h - k * bl, x, y + h - bl)

    ctx.lineTo(x, y + tl)
    ctx.bezierCurveTo(x, y + k * tl, x + k * tl, y, x + tl, y)

    ctx.closePath()

    ctx.save()
    ctx.clip()

    if (property.backgroundColor?.get().getRGBAHEX()) {
      ctx.fillStyle = property.backgroundColor?.get().getRGBAHEX()
      ctx.fill("evenodd")
    }

    if (strokeWidth > 0) {
      if (this.strokeUniform) {
        ctx.scale(1 / this.scaleX, 1 / this.scaleY)
      }
      if (this.shadow && !this.shadow.affectStroke) {
        this._removeShadow(ctx)
      }
      if (strokeColor) {
        ctx.lineWidth = strokeWidth
        ctx.strokeStyle = strokeColor
        ctx.stroke()
      }
    }

    ctx.restore()

    let iconWidth = 0,
      iconFontStyleString = "",
      iconVal = "",
      iconColor = "",
      iconSize = 14

    const iconKey = property.icon?.getKey()

    if (iconKey) {
      const provider = property.icon?.getProvider()
      const iconKey = property.icon?.getKey()
      const iconsList: IdeIcon.AsObject[] = this.iconsList || []
      const _iconVal = iconsList.find((item) => item.key === iconKey)

      if (provider && _iconVal) {
        iconSize = property.icon?.getSize() || 16
        iconFontStyleString = `${iconSize}px ${provider.replace(":", "-")}`
        iconVal = String.fromCodePoint(_iconVal.codepoint)
        ctx.font = iconFontStyleString
        iconColor = property.icon?.getColor().get().getRGBHEX() || "#000"
        iconWidth = ctx.measureText(iconVal).width + 6
      }
    }

    let textWidth = 0

    ctx.save()

    ctx.textBaseline = "bottom"
    ctx.font = `${fontStyle} ${fontWeight} ${fontSize}px ${fontFamily}`
    ctx.fillStyle = fontFill

    if (text) {
      textWidth = ctx.measureText(text).width
      ctx.fillText(text, iconWidth / 2 + -textWidth / 2, fontSize / 2)
    }

    ctx.restore()

    if (iconVal && iconFontStyleString) {
      ctx.save()
      ctx.textBaseline = "bottom"
      ctx.font = iconFontStyleString
      ctx.fillStyle = iconColor
      ctx.fillText(iconVal, -iconWidth / 2 - textWidth / 2, iconSize / 2)
      ctx.restore()
    }
  }
})
