import { fabric } from "fabric"
import { TWidgetOptions } from "./types"

export const StackWidget = fabric.util.createClass(fabric.Rect, {
  type: "Stack",
  initialize: function (options: TWidgetOptions) {
    if (!options?.pageWidget) {
      throw new Error("Page widget is required option")
    }

    const { pageWidget, ctx, geometry } = options

    this.id = pageWidget.id

    this.fabricParent = ctx.getObjects().find((item) => {
      return item.id === pageWidget.parent?.id
    })

    this.fill = "transparent"

    this.geometry = geometry

    this.set({
      width: geometry.size.width,
      height: geometry.size.height,
      left: geometry.offset.x,
      top: geometry.offset.y
    })

    this.callSuper("initialize", options)
  },
  _render: function (ctx: CanvasRenderingContext2D) {
    const geometry = this.geometry

    this.width = geometry.size.width
    this.height = geometry.size.height
    this.top = geometry.offset.y
    this.left = geometry.offset.x

    this.callSuper("_render", ctx)
  }
})
