import { useState } from "react"
import FontManager, { IFontValue } from "components/fontManager"
import { Button, Group, NumberInput, Select } from "@mantine/core"
import { IFont, ProjectFont } from "model/widgetProperty/type"
import { IconChevronDown } from "@tabler/icons"
import { FontStyleEnum, FontWeightEnum } from "model/widgetProperty/enum"
import ProjectColor from "components/builder/ProjectColor"
import styles from "styles/builder/fontInput.module.css"
import { transformCammelCase } from "utils/string"
import { useRecoilValue } from "recoil"
import { projectForceUpdaterAtom } from "service/appstate/project/atoms"

const loadFont = async (font: IFont): Promise<void> => {
  let fontFamily = font.getFamily()

  const weight = font.getWeight(),
    style = font.getStyle(),
    provider = font.getProvider()

  if (!fontFamily) {
    fontFamily = "Roboto"
  }

  const family = fontFamily.replace(/ /g, "-")
  const subset = "latin"

  const url = process.env.REACT_APP_STATIC_ASSEST_URL

  const fontFace = new FontFace(
    fontFamily,
    `url(${url}/ide/res/1.0.0/font/${provider}/${family.toLocaleLowerCase()}/${subset}-${weight}-${style}.woff2?)`,
    { weight, style }
  )

  try {
    await fontFace.load()
    document.fonts.add(fontFace)
  } catch (error) {}
}

interface IFontSelectProps {
  font: ProjectFont | IFont
  onChange: () => void
  horizontal?: boolean
  withTheme?: boolean
}

const FontSelectInput = ({ font, onChange, horizontal = false, withTheme }: IFontSelectProps): JSX.Element => {
  const [isManagerOpened, toggleFontManager] = useState(false)
  const [weightList, setWeightList] = useState<string[]>([])
  const [styleList, setStyleList] = useState<string[]>([])

  useRecoilValue(projectForceUpdaterAtom)

  const getFont = (): IFont => {
    if (font instanceof ProjectFont) {
      return font.get()
    } else {
      return font as IFont
    }
  }

  const fontFamilyChange = (f: IFontValue): void => {
    const _font = font

    if (_font instanceof ProjectFont) {
      if (f.key) {
        _font.setType(f.key)
      } else {
        if (_font.hasType()) {
          _font.setFamily(f.family).setProvider(f.provider).setType("")
        } else {
          _font.setFamily(f.family).setProvider(f.provider)
        }
      }
    } else {
      _font.setFamily(f.family).setProvider(f.provider)
    }

    toggleFontManager(false)

    loadFont(getFont()).then(() => {
      onChange()
    })
  }

  const onCurrentFont = (weightList: string[], stylesList: string[]) => {
    setStyleList(stylesList)
    setWeightList(weightList)
  }

  const onWeightChange = (weight: string) => {
    const _font = font

    if (weight) {
      _font.setWeight(weight as FontWeightEnum)
      loadFont(getFont()).then(() => {
        onChange()
      })
    }
  }

  const onStyleChange = (style: string) => {
    const _font = font

    if (style) {
      _font.setStyle(style as FontStyleEnum)
      loadFont(getFont()).then(() => {
        onChange()
      })
    }
  }

  const sizeOnChange = (size?: number): void => {
    const _font = font

    if (size) {
      _font.setSize(size)
      onChange()
    }
  }

  const spacingOnChange = (spacing?: number): void => {
    const _font = font

    if (spacing) {
      _font.setSpacing(spacing)
      onChange()
    }
  }

  const getButtonTitle = (): string => {
    let title = "Select font family"

    if (font instanceof ProjectFont) {
      title = font.hasType() ? transformCammelCase(font.getType()) : font.get().getFamily() || title
    } else {
      const _font = font as IFont
      title = _font.getFamily() || title
    }

    return title
  }

  return (
    <div className={horizontal ? styles.fontInputHorizontal : styles.fontInput}>
      <FontManager
        currentFont={getFont().getFamily()}
        close={() => toggleFontManager(false)}
        onChange={fontFamilyChange}
        opened={isManagerOpened}
        onCurrentFont={onCurrentFont}
        withTheme={withTheme}
      />
      <Button
        sx={{
          backgroundColor: "#6549F7",
          borderRadius: 8
        }}
        // mr={horizontal ? "md" : ""}
        styles={() => ({
          root: {
            flexShrink: 0,
            "&:hover": {
              backgroundColor: "#6549F7"
            },
            // marginTop: "12px",
            width: horizontal ? "140px" : "100%"
          }
        })}
        onClick={() => toggleFontManager(true)}
      >
        {getButtonTitle()}
      </Button>
      <Select
        styles={() => ({
          item: {
            "&[data-selected]": {
              "&, &:hover": {
                backgroundColor: "#6549F7",
                color: "#fff"
              }
            },
            "&[data-hovered]": {}
          }
        })}
        sx={() => ({
          label: {
            color: "rgba(34, 39, 48, 0.7)",
            fontWeight: "bold",
            padding: "0 0 4px 0"
          },
          input: {
            border: "1px solid #ddd",
            borderRadius: 8
          },
          color: "#16141C",
          // marginTop: 12,
          flexShrink: 0,
          width: horizontal ? "120px" : "100%"
        })}
        size="xs"
        // mr={horizontal ? "md" : ""}
        value={getFont().getStyle()}
        label="Font Style"
        rightSection={<IconChevronDown size={14} />}
        data={styleList.map((item) => {
          return { value: item, label: item }
        })}
        onChange={onStyleChange}
      />
      <Group style={{ width: "100%", gap: "12px" }} noWrap>
        <ProjectColor
          label="Text Color"
          onChange={onChange}
          value={font instanceof ProjectFont ? font : font.getColor()}
          withProjectColors
        />
        <NumberInput
          sx={() => ({
            label: {
              color: "rgba(34, 39, 48, 0.7)",
              padding: "0 0 4px 0",
              fontWeight: "bold"
            },
            input: {
              background: "#fff",
              border: "1px solid #ddd",
              borderRadius: 8,
              color: "#000"
            },
            color: "#000",
            alignSelf: "flex-end",
            width: "calc(50% - 6px)"
          })}
          hideControls
          size="xs"
          // mx={horizontal ? "md" : ""}
          value={getFont().getSize()}
          label="Font Size"
          onChange={sizeOnChange}
        />
      </Group>
      <Group style={{ width: "100%", gap: "12px" }} noWrap>
        <Select
          styles={() => ({
            item: {
              "&[data-selected]": {
                "&, &:hover": {
                  backgroundColor: "#6549F7",
                  color: "#fff"
                }
              },
              "&[data-hovered]": {}
            }
          })}
          sx={() => ({
            label: {
              color: "rgba(34, 39, 48, 0.7)",
              fontWeight: "bold",
              padding: "0 0 4px 0"
            },
            input: {
              border: "1px solid #ddd",
              borderRadius: 8
            },
            color: "#16141C",
            // marginTop: 12,
            width: "calc(50% - 6px)"
          })}
          size="xs"
          value={getFont().getWeight()}
          label="Font Weight"
          rightSection={<IconChevronDown size={14} />}
          data={weightList.map((item) => {
            return { value: item, label: item }
          })}
          onChange={onWeightChange}
        />
        <NumberInput
          sx={() => ({
            label: {
              color: "rgba(34, 39, 48, 0.7)",
              padding: "0 0 4px 0",
              fontWeight: "bold"
            },
            input: {
              background: "#fff",
              border: "1px solid #ddd",
              borderRadius: 8,
              color: "#000"
            },
            color: "#000",
            alignSelf: "flex-end",
            // marginTop: "12px",
            width: "calc(50% - 6px)"
          })}
          hideControls
          size="xs"
          // mx={horizontal ? "md" : ""}
          value={getFont().getSpacing()}
          label="Letter Spacing"
          onChange={spacingOnChange}
        />
      </Group>
    </div>
  )
}

export default FontSelectInput
