import { Widget } from "./widget"
import { IColor, IFont } from "./widgetProperty/type"
import { Page } from "./page"
import { Asset } from "./asset"

export interface IProjectWidget {
  // storage for widget list
  widgetList?: Record<string, Widget>
  getWidget(type: string): Widget | undefined
  setWidget(w: Widget): this
}

export type TProjectFont = {
  key: string
  font: IFont
}

export interface IProjectTypographyList {
  fontList: TProjectFont[]
  setFont(key: string, font: IFont): this
  getFontByKey(key: string): IFont | undefined
}

type TProjectColor = {
  key: string
  color: IColor
}

export interface IProjectColorList {
  colorList: TProjectColor[]
  setColor(key: string, color: IColor): this
  getColorByKey(key: string): IColor | undefined
  removeColor(key: string): this
}

export interface IProjectPageList {
  pageList: Page[]
  setPage(p: Page): this
  getPage(id: string): Page | undefined
  removePage(id: string): this
}

export interface IProjectAssetList {
  assetList: Asset[]
  setAsset(a: Asset): this
  getAsset(id: string): Asset | undefined
  removeAsset(id: string): this
}

export interface IProject
  extends IProjectWidget,
    IProjectColorList,
    IProjectTypographyList,
    IProjectPageList,
    IProjectAssetList {
  getId(): string
  getCDNBaseURL(): string
}

export class Project implements IProject {
  optionList: Record<string, unknown> = {}

  widgetList: Record<string, Widget> = {}

  colorList: TProjectColor[] = []
  fontList: TProjectFont[] = []

  pageList: Page[] = []
  assetList: Asset[] = []

  constructor(optionList?: Record<string, unknown>) {
    if (optionList) {
      this.optionList = optionList
    }
  }

  getId(): string {
    return (this.optionList["id"] as string) || "-"
  }

  getCDNBaseURL(): string {
    return (this.optionList["cdnBaseURL"] as string) || "/"
  }

  getAssetCDNUrl(id: string, fileName: string, type: string): string {
    return `${this.optionList.cdnBaseURL}/asset/${this.optionList.id}/${id}/${type}/${fileName}`
  }

  getWidget(type: string): Widget | undefined {
    return this.widgetList[type]
  }

  setWidget(w: Widget): this {
    if (w.type) {
      this.widgetList[w.type] = w
    }
    return this
  }

  getWidgetList(): Widget[] {
    return (Object.keys(this.widgetList) as Array<string>)
      .map((key) => this.widgetList[key])
      .sort((a, b) => ((a.order || 0) > (b.order || 0) ? -1 : 1))
  }

  // IProjectColorList implementation
  protected getColorIndex(key: string): number {
    let index = -1
    this.colorList.some((v, i) => {
      if (v.key === key) {
        index = i
        return true
      }
    })
    return index
  }

  setColor(key: string, color: IColor): this {
    const index = this.getColorIndex(key)
    if (index > -1) {
      this.colorList[index] = { key, color }
    } else {
      this.colorList.push({ key, color })
    }
    return this
  }

  getColorByKey(key: string): IColor | undefined {
    const colorList = this.colorList.filter((v) => {
      return v.key === key
    })
    return colorList[0]?.color
  }

  removeColor(key: string): this {
    const index = this.getColorIndex(key)
    if (index > -1) {
      this.colorList.splice(index, 1)
    }
    return this
  }

  // IProjectFontList implementation
  protected getFontIndex(key: string): number {
    let index = -1
    this.fontList.some((v, i) => {
      if (v.key === key) {
        index = i
        return true
      }
    })
    return index
  }

  setFont(key: string, font: IFont): this {
    const index = this.getFontIndex(key)
    if (index > -1) {
      this.fontList[index] = { key, font }
    } else {
      this.fontList.push({ key, font })
    }
    return this
  }

  getFontByKey(key: string): IFont | undefined {
    const fontList = this.fontList.filter((v) => {
      return v.key === key
    })
    return fontList[0]?.font
  }

  // IProjectPageList implementation
  protected getPageIndex(id: string): number {
    let index = -1
    this.pageList.some((v, i) => {
      if (v.id === id) {
        index = i
        return true
      }
    })
    return index
  }

  setPage(p: Page): this {
    p.setProject(this)
    const index = this.getPageIndex(p.id || "")
    if (index > -1) {
      this.pageList[index] = p
    } else {
      this.pageList.push(p)
    }
    return this
  }

  getPage(id: string): Page | undefined {
    return this.pageList[this.getPageIndex(id)]
  }

  removePage(id: string): this {
    const index = this.getPageIndex(id)
    if (index > -1) {
      this.pageList.splice(index, 1)
    }
    return this
  }

  // IProjectAssetList implementation
  protected getAssetIndex(id: string): number {
    let index = -1
    this.assetList.some((v, i) => {
      if (v.id === id) {
        index = i
        return true
      }
    })
    return index
  }

  setAsset(a: Asset): this {
    a.setProject(this)
    const index = this.getAssetIndex(a.id || "")
    if (index > -1) {
      this.assetList[index] = a
    } else {
      this.assetList.push(a)
    }
    return this
  }

  getAsset(id: string): Asset | undefined {
    return this.assetList[this.getAssetIndex(id)]
  }

  removeAsset(id: string): this {
    const index = this.getAssetIndex(id)
    if (index > -1) {
      this.assetList.splice(index, 1)
    }
    return this
  }
}
