/**
 * @fileoverview gRPC-Web generated client stub for jupiter.builder.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var common_pb = require('./common_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.jupiter = {};
proto.jupiter.builder = {};
proto.jupiter.builder.v1 = require('./ide_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.jupiter.builder.v1.IdeClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.jupiter.builder.v1.IdePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.ResourceRequest,
 *   !proto.jupiter.builder.v1.JobIdResponse>}
 */
const methodDescriptor_Ide_Start = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/Start',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.ResourceRequest,
  common_pb.JobIdResponse,
  /**
   * @param {!proto.jupiter.builder.v1.ResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.JobIdResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.JobIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.JobIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.start =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/Start',
      request,
      metadata || {},
      methodDescriptor_Ide_Start,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.JobIdResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.start =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/Start',
      request,
      metadata || {},
      methodDescriptor_Ide_Start);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.ResourceRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Ide_StartSync = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/StartSync',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.ResourceRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.jupiter.builder.v1.ResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.startSync =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/StartSync',
      request,
      metadata || {},
      methodDescriptor_Ide_StartSync,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.startSync =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/StartSync',
      request,
      metadata || {},
      methodDescriptor_Ide_StartSync);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.ResourceRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Ide_Ping = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/Ping',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.ResourceRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.jupiter.builder.v1.ResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.ping =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/Ping',
      request,
      metadata || {},
      methodDescriptor_Ide_Ping,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.ping =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/Ping',
      request,
      metadata || {},
      methodDescriptor_Ide_Ping);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.ResourceRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Ide_Leave = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/Leave',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.ResourceRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.jupiter.builder.v1.ResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.leave =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/Leave',
      request,
      metadata || {},
      methodDescriptor_Ide_Leave,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.leave =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/Leave',
      request,
      metadata || {},
      methodDescriptor_Ide_Leave);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.ResourceRequest,
 *   !proto.jupiter.builder.v1.GetIdeEntityListResponse>}
 */
const methodDescriptor_Ide_GetPageList = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/GetPageList',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.ResourceRequest,
  proto.jupiter.builder.v1.GetIdeEntityListResponse,
  /**
   * @param {!proto.jupiter.builder.v1.ResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.GetIdeEntityListResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.GetIdeEntityListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.GetIdeEntityListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.getPageList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetPageList',
      request,
      metadata || {},
      methodDescriptor_Ide_GetPageList,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.GetIdeEntityListResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.getPageList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetPageList',
      request,
      metadata || {},
      methodDescriptor_Ide_GetPageList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.CreateIdeEntityRequest,
 *   !proto.jupiter.builder.v1.JobIdResponse>}
 */
const methodDescriptor_Ide_CreatePage = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/CreatePage',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.CreateIdeEntityRequest,
  common_pb.JobIdResponse,
  /**
   * @param {!proto.jupiter.builder.v1.CreateIdeEntityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.JobIdResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.CreateIdeEntityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.JobIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.JobIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.createPage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/CreatePage',
      request,
      metadata || {},
      methodDescriptor_Ide_CreatePage,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.CreateIdeEntityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.JobIdResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.createPage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/CreatePage',
      request,
      metadata || {},
      methodDescriptor_Ide_CreatePage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.IdResourceRequest,
 *   !proto.jupiter.builder.v1.IdeEntity>}
 */
const methodDescriptor_Ide_GetPage = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/GetPage',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.IdResourceRequest,
  proto.jupiter.builder.v1.IdeEntity,
  /**
   * @param {!proto.jupiter.builder.v1.IdResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.IdeEntity.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.IdeEntity)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.IdeEntity>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.getPage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetPage',
      request,
      metadata || {},
      methodDescriptor_Ide_GetPage,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.IdeEntity>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.getPage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetPage',
      request,
      metadata || {},
      methodDescriptor_Ide_GetPage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.IdResourceRequest,
 *   !proto.jupiter.builder.v1.IdeEntityInfo>}
 */
const methodDescriptor_Ide_GetPageInfo = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/GetPageInfo',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.IdResourceRequest,
  proto.jupiter.builder.v1.IdeEntityInfo,
  /**
   * @param {!proto.jupiter.builder.v1.IdResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.IdeEntityInfo.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.IdeEntityInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.IdeEntityInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.getPageInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetPageInfo',
      request,
      metadata || {},
      methodDescriptor_Ide_GetPageInfo,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.IdeEntityInfo>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.getPageInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetPageInfo',
      request,
      metadata || {},
      methodDescriptor_Ide_GetPageInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest,
 *   !proto.jupiter.builder.v1.JobIdResponse>}
 */
const methodDescriptor_Ide_UpdatePageInfo = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/UpdatePageInfo',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest,
  common_pb.JobIdResponse,
  /**
   * @param {!proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.JobIdResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.JobIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.JobIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.updatePageInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/UpdatePageInfo',
      request,
      metadata || {},
      methodDescriptor_Ide_UpdatePageInfo,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.JobIdResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.updatePageInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/UpdatePageInfo',
      request,
      metadata || {},
      methodDescriptor_Ide_UpdatePageInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.IdResourceRequest,
 *   !proto.jupiter.builder.v1.IdeEntityData>}
 */
const methodDescriptor_Ide_GetPageData = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/GetPageData',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.IdResourceRequest,
  proto.jupiter.builder.v1.IdeEntityData,
  /**
   * @param {!proto.jupiter.builder.v1.IdResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.IdeEntityData.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.IdeEntityData)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.IdeEntityData>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.getPageData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetPageData',
      request,
      metadata || {},
      methodDescriptor_Ide_GetPageData,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.IdeEntityData>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.getPageData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetPageData',
      request,
      metadata || {},
      methodDescriptor_Ide_GetPageData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.SetIdeEntityDataRequest,
 *   !proto.jupiter.builder.v1.JobIdResponse>}
 */
const methodDescriptor_Ide_SetPageData = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/SetPageData',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.SetIdeEntityDataRequest,
  common_pb.JobIdResponse,
  /**
   * @param {!proto.jupiter.builder.v1.SetIdeEntityDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.JobIdResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.SetIdeEntityDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.JobIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.JobIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.setPageData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/SetPageData',
      request,
      metadata || {},
      methodDescriptor_Ide_SetPageData,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.SetIdeEntityDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.JobIdResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.setPageData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/SetPageData',
      request,
      metadata || {},
      methodDescriptor_Ide_SetPageData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.IdResourceRequest,
 *   !proto.jupiter.builder.v1.JobIdResponse>}
 */
const methodDescriptor_Ide_DeletePage = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/DeletePage',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.IdResourceRequest,
  common_pb.JobIdResponse,
  /**
   * @param {!proto.jupiter.builder.v1.IdResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.JobIdResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.JobIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.JobIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.deletePage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/DeletePage',
      request,
      metadata || {},
      methodDescriptor_Ide_DeletePage,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.JobIdResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.deletePage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/DeletePage',
      request,
      metadata || {},
      methodDescriptor_Ide_DeletePage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.IdResourceRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Ide_LockPage = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/LockPage',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.IdResourceRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.jupiter.builder.v1.IdResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.lockPage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/LockPage',
      request,
      metadata || {},
      methodDescriptor_Ide_LockPage,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.lockPage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/LockPage',
      request,
      metadata || {},
      methodDescriptor_Ide_LockPage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.IdResourceRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Ide_UnlockPage = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/UnlockPage',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.IdResourceRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.jupiter.builder.v1.IdResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.unlockPage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/UnlockPage',
      request,
      metadata || {},
      methodDescriptor_Ide_UnlockPage,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.unlockPage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/UnlockPage',
      request,
      metadata || {},
      methodDescriptor_Ide_UnlockPage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.ResourceRequest,
 *   !proto.jupiter.builder.v1.GetIdeEntityOrderResponse>}
 */
const methodDescriptor_Ide_GetPageOrder = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/GetPageOrder',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.ResourceRequest,
  proto.jupiter.builder.v1.GetIdeEntityOrderResponse,
  /**
   * @param {!proto.jupiter.builder.v1.ResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.GetIdeEntityOrderResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.GetIdeEntityOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.GetIdeEntityOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.getPageOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetPageOrder',
      request,
      metadata || {},
      methodDescriptor_Ide_GetPageOrder,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.GetIdeEntityOrderResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.getPageOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetPageOrder',
      request,
      metadata || {},
      methodDescriptor_Ide_GetPageOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.SetIdeEntityOrderRequest,
 *   !proto.jupiter.builder.v1.JobIdResponse>}
 */
const methodDescriptor_Ide_SetPageOrder = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/SetPageOrder',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.SetIdeEntityOrderRequest,
  common_pb.JobIdResponse,
  /**
   * @param {!proto.jupiter.builder.v1.SetIdeEntityOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.JobIdResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.SetIdeEntityOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.JobIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.JobIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.setPageOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/SetPageOrder',
      request,
      metadata || {},
      methodDescriptor_Ide_SetPageOrder,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.SetIdeEntityOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.JobIdResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.setPageOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/SetPageOrder',
      request,
      metadata || {},
      methodDescriptor_Ide_SetPageOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.ResourceRequest,
 *   !proto.jupiter.builder.v1.GetIdeEntityListResponse>}
 */
const methodDescriptor_Ide_GetComponentList = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/GetComponentList',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.ResourceRequest,
  proto.jupiter.builder.v1.GetIdeEntityListResponse,
  /**
   * @param {!proto.jupiter.builder.v1.ResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.GetIdeEntityListResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.GetIdeEntityListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.GetIdeEntityListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.getComponentList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetComponentList',
      request,
      metadata || {},
      methodDescriptor_Ide_GetComponentList,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.GetIdeEntityListResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.getComponentList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetComponentList',
      request,
      metadata || {},
      methodDescriptor_Ide_GetComponentList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.CreateIdeEntityRequest,
 *   !proto.jupiter.builder.v1.JobIdResponse>}
 */
const methodDescriptor_Ide_CreateComponent = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/CreateComponent',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.CreateIdeEntityRequest,
  common_pb.JobIdResponse,
  /**
   * @param {!proto.jupiter.builder.v1.CreateIdeEntityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.JobIdResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.CreateIdeEntityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.JobIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.JobIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.createComponent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/CreateComponent',
      request,
      metadata || {},
      methodDescriptor_Ide_CreateComponent,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.CreateIdeEntityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.JobIdResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.createComponent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/CreateComponent',
      request,
      metadata || {},
      methodDescriptor_Ide_CreateComponent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.IdResourceRequest,
 *   !proto.jupiter.builder.v1.IdeEntity>}
 */
const methodDescriptor_Ide_GetComponent = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/GetComponent',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.IdResourceRequest,
  proto.jupiter.builder.v1.IdeEntity,
  /**
   * @param {!proto.jupiter.builder.v1.IdResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.IdeEntity.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.IdeEntity)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.IdeEntity>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.getComponent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetComponent',
      request,
      metadata || {},
      methodDescriptor_Ide_GetComponent,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.IdeEntity>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.getComponent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetComponent',
      request,
      metadata || {},
      methodDescriptor_Ide_GetComponent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.IdResourceRequest,
 *   !proto.jupiter.builder.v1.IdeEntityInfo>}
 */
const methodDescriptor_Ide_GetComponentInfo = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/GetComponentInfo',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.IdResourceRequest,
  proto.jupiter.builder.v1.IdeEntityInfo,
  /**
   * @param {!proto.jupiter.builder.v1.IdResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.IdeEntityInfo.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.IdeEntityInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.IdeEntityInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.getComponentInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetComponentInfo',
      request,
      metadata || {},
      methodDescriptor_Ide_GetComponentInfo,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.IdeEntityInfo>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.getComponentInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetComponentInfo',
      request,
      metadata || {},
      methodDescriptor_Ide_GetComponentInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest,
 *   !proto.jupiter.builder.v1.JobIdResponse>}
 */
const methodDescriptor_Ide_UpdateComponentInfo = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/UpdateComponentInfo',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest,
  common_pb.JobIdResponse,
  /**
   * @param {!proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.JobIdResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.JobIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.JobIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.updateComponentInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/UpdateComponentInfo',
      request,
      metadata || {},
      methodDescriptor_Ide_UpdateComponentInfo,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.UpdateIdeEntityInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.JobIdResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.updateComponentInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/UpdateComponentInfo',
      request,
      metadata || {},
      methodDescriptor_Ide_UpdateComponentInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.IdResourceRequest,
 *   !proto.jupiter.builder.v1.IdeEntityData>}
 */
const methodDescriptor_Ide_GetComponentData = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/GetComponentData',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.IdResourceRequest,
  proto.jupiter.builder.v1.IdeEntityData,
  /**
   * @param {!proto.jupiter.builder.v1.IdResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.IdeEntityData.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.IdeEntityData)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.IdeEntityData>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.getComponentData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetComponentData',
      request,
      metadata || {},
      methodDescriptor_Ide_GetComponentData,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.IdeEntityData>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.getComponentData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetComponentData',
      request,
      metadata || {},
      methodDescriptor_Ide_GetComponentData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.SetIdeEntityDataRequest,
 *   !proto.jupiter.builder.v1.JobIdResponse>}
 */
const methodDescriptor_Ide_SetComponentData = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/SetComponentData',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.SetIdeEntityDataRequest,
  common_pb.JobIdResponse,
  /**
   * @param {!proto.jupiter.builder.v1.SetIdeEntityDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.JobIdResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.SetIdeEntityDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.JobIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.JobIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.setComponentData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/SetComponentData',
      request,
      metadata || {},
      methodDescriptor_Ide_SetComponentData,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.SetIdeEntityDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.JobIdResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.setComponentData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/SetComponentData',
      request,
      metadata || {},
      methodDescriptor_Ide_SetComponentData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.IdResourceRequest,
 *   !proto.jupiter.builder.v1.JobIdResponse>}
 */
const methodDescriptor_Ide_DeleteComponent = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/DeleteComponent',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.IdResourceRequest,
  common_pb.JobIdResponse,
  /**
   * @param {!proto.jupiter.builder.v1.IdResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.JobIdResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.JobIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.JobIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.deleteComponent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/DeleteComponent',
      request,
      metadata || {},
      methodDescriptor_Ide_DeleteComponent,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.JobIdResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.deleteComponent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/DeleteComponent',
      request,
      metadata || {},
      methodDescriptor_Ide_DeleteComponent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.IdResourceRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Ide_LockComponent = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/LockComponent',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.IdResourceRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.jupiter.builder.v1.IdResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.lockComponent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/LockComponent',
      request,
      metadata || {},
      methodDescriptor_Ide_LockComponent,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.lockComponent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/LockComponent',
      request,
      metadata || {},
      methodDescriptor_Ide_LockComponent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.IdResourceRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Ide_UnlockComponent = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/UnlockComponent',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.IdResourceRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.jupiter.builder.v1.IdResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.unlockComponent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/UnlockComponent',
      request,
      metadata || {},
      methodDescriptor_Ide_UnlockComponent,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.unlockComponent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/UnlockComponent',
      request,
      metadata || {},
      methodDescriptor_Ide_UnlockComponent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.ResourceRequest,
 *   !proto.jupiter.builder.v1.GetIdeEntityOrderResponse>}
 */
const methodDescriptor_Ide_GetComponentOrder = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/GetComponentOrder',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.ResourceRequest,
  proto.jupiter.builder.v1.GetIdeEntityOrderResponse,
  /**
   * @param {!proto.jupiter.builder.v1.ResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.GetIdeEntityOrderResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.GetIdeEntityOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.GetIdeEntityOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.getComponentOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetComponentOrder',
      request,
      metadata || {},
      methodDescriptor_Ide_GetComponentOrder,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.GetIdeEntityOrderResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.getComponentOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetComponentOrder',
      request,
      metadata || {},
      methodDescriptor_Ide_GetComponentOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.SetIdeEntityOrderRequest,
 *   !proto.jupiter.builder.v1.JobIdResponse>}
 */
const methodDescriptor_Ide_SetComponentOrder = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/SetComponentOrder',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.SetIdeEntityOrderRequest,
  common_pb.JobIdResponse,
  /**
   * @param {!proto.jupiter.builder.v1.SetIdeEntityOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.JobIdResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.SetIdeEntityOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.JobIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.JobIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.setComponentOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/SetComponentOrder',
      request,
      metadata || {},
      methodDescriptor_Ide_SetComponentOrder,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.SetIdeEntityOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.JobIdResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.setComponentOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/SetComponentOrder',
      request,
      metadata || {},
      methodDescriptor_Ide_SetComponentOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.ResourceRequest,
 *   !proto.jupiter.builder.v1.GetIdeSettingResponse>}
 */
const methodDescriptor_Ide_GetSettingList = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/GetSettingList',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.ResourceRequest,
  proto.jupiter.builder.v1.GetIdeSettingResponse,
  /**
   * @param {!proto.jupiter.builder.v1.ResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.GetIdeSettingResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.GetIdeSettingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.GetIdeSettingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.getSettingList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetSettingList',
      request,
      metadata || {},
      methodDescriptor_Ide_GetSettingList,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.GetIdeSettingResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.getSettingList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetSettingList',
      request,
      metadata || {},
      methodDescriptor_Ide_GetSettingList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.ResourceRequest,
 *   !proto.jupiter.builder.v1.GetIdeSettingColorResponse>}
 */
const methodDescriptor_Ide_GetSettingColorList = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/GetSettingColorList',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.ResourceRequest,
  proto.jupiter.builder.v1.GetIdeSettingColorResponse,
  /**
   * @param {!proto.jupiter.builder.v1.ResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.GetIdeSettingColorResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.GetIdeSettingColorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.GetIdeSettingColorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.getSettingColorList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetSettingColorList',
      request,
      metadata || {},
      methodDescriptor_Ide_GetSettingColorList,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.GetIdeSettingColorResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.getSettingColorList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetSettingColorList',
      request,
      metadata || {},
      methodDescriptor_Ide_GetSettingColorList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.SetIdeEntityDataRequest,
 *   !proto.jupiter.builder.v1.JobIdResponse>}
 */
const methodDescriptor_Ide_SetSettingColor = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/SetSettingColor',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.SetIdeEntityDataRequest,
  common_pb.JobIdResponse,
  /**
   * @param {!proto.jupiter.builder.v1.SetIdeEntityDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.JobIdResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.SetIdeEntityDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.JobIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.JobIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.setSettingColor =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/SetSettingColor',
      request,
      metadata || {},
      methodDescriptor_Ide_SetSettingColor,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.SetIdeEntityDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.JobIdResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.setSettingColor =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/SetSettingColor',
      request,
      metadata || {},
      methodDescriptor_Ide_SetSettingColor);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.IdResourceRequest,
 *   !proto.jupiter.builder.v1.JobIdResponse>}
 */
const methodDescriptor_Ide_DeleteSettingColor = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/DeleteSettingColor',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.IdResourceRequest,
  common_pb.JobIdResponse,
  /**
   * @param {!proto.jupiter.builder.v1.IdResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.JobIdResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.JobIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.JobIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.deleteSettingColor =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/DeleteSettingColor',
      request,
      metadata || {},
      methodDescriptor_Ide_DeleteSettingColor,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.JobIdResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.deleteSettingColor =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/DeleteSettingColor',
      request,
      metadata || {},
      methodDescriptor_Ide_DeleteSettingColor);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.ResourceRequest,
 *   !proto.jupiter.builder.v1.GetIdeSettingFontResponse>}
 */
const methodDescriptor_Ide_GetSettingFontList = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/GetSettingFontList',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.ResourceRequest,
  proto.jupiter.builder.v1.GetIdeSettingFontResponse,
  /**
   * @param {!proto.jupiter.builder.v1.ResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.GetIdeSettingFontResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.GetIdeSettingFontResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.GetIdeSettingFontResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.getSettingFontList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetSettingFontList',
      request,
      metadata || {},
      methodDescriptor_Ide_GetSettingFontList,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.GetIdeSettingFontResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.getSettingFontList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetSettingFontList',
      request,
      metadata || {},
      methodDescriptor_Ide_GetSettingFontList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.SetIdeEntityDataRequest,
 *   !proto.jupiter.builder.v1.JobIdResponse>}
 */
const methodDescriptor_Ide_SetSettingFont = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/SetSettingFont',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.SetIdeEntityDataRequest,
  common_pb.JobIdResponse,
  /**
   * @param {!proto.jupiter.builder.v1.SetIdeEntityDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.JobIdResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.SetIdeEntityDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.JobIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.JobIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.setSettingFont =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/SetSettingFont',
      request,
      metadata || {},
      methodDescriptor_Ide_SetSettingFont,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.SetIdeEntityDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.JobIdResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.setSettingFont =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/SetSettingFont',
      request,
      metadata || {},
      methodDescriptor_Ide_SetSettingFont);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.ResourceRequest,
 *   !proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse>}
 */
const methodDescriptor_Ide_GetSettingAppAsset = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/GetSettingAppAsset',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.ResourceRequest,
  proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse,
  /**
   * @param {!proto.jupiter.builder.v1.ResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.getSettingAppAsset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetSettingAppAsset',
      request,
      metadata || {},
      methodDescriptor_Ide_GetSettingAppAsset,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.GetIdeSettingAppAssetResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.getSettingAppAsset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetSettingAppAsset',
      request,
      metadata || {},
      methodDescriptor_Ide_GetSettingAppAsset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest,
 *   !proto.jupiter.builder.v1.JobIdResponse>}
 */
const methodDescriptor_Ide_SetSettingAppAsset = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/SetSettingAppAsset',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest,
  common_pb.JobIdResponse,
  /**
   * @param {!proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.JobIdResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.JobIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.JobIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.setSettingAppAsset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/SetSettingAppAsset',
      request,
      metadata || {},
      methodDescriptor_Ide_SetSettingAppAsset,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.SetIdeSettingAppAssetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.JobIdResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.setSettingAppAsset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/SetSettingAppAsset',
      request,
      metadata || {},
      methodDescriptor_Ide_SetSettingAppAsset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.ResourceRequest,
 *   !proto.jupiter.builder.v1.GetIdeSettingNavBarResponse>}
 */
const methodDescriptor_Ide_GetSettingNavBar = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/GetSettingNavBar',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.ResourceRequest,
  proto.jupiter.builder.v1.GetIdeSettingNavBarResponse,
  /**
   * @param {!proto.jupiter.builder.v1.ResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.GetIdeSettingNavBarResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.GetIdeSettingNavBarResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.GetIdeSettingNavBarResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.getSettingNavBar =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetSettingNavBar',
      request,
      metadata || {},
      methodDescriptor_Ide_GetSettingNavBar,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.GetIdeSettingNavBarResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.getSettingNavBar =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetSettingNavBar',
      request,
      metadata || {},
      methodDescriptor_Ide_GetSettingNavBar);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.SetIdeSettingNavBarRequest,
 *   !proto.jupiter.builder.v1.JobIdResponse>}
 */
const methodDescriptor_Ide_SetSettingNavBar = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/SetSettingNavBar',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.SetIdeSettingNavBarRequest,
  common_pb.JobIdResponse,
  /**
   * @param {!proto.jupiter.builder.v1.SetIdeSettingNavBarRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.JobIdResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.SetIdeSettingNavBarRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.JobIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.JobIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.setSettingNavBar =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/SetSettingNavBar',
      request,
      metadata || {},
      methodDescriptor_Ide_SetSettingNavBar,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.SetIdeSettingNavBarRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.JobIdResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.setSettingNavBar =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/SetSettingNavBar',
      request,
      metadata || {},
      methodDescriptor_Ide_SetSettingNavBar);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.ResourceRequest,
 *   !proto.jupiter.builder.v1.GetIdeAssetListResponse>}
 */
const methodDescriptor_Ide_GetAssetList = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/GetAssetList',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.ResourceRequest,
  proto.jupiter.builder.v1.GetIdeAssetListResponse,
  /**
   * @param {!proto.jupiter.builder.v1.ResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.GetIdeAssetListResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.GetIdeAssetListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.GetIdeAssetListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.getAssetList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetAssetList',
      request,
      metadata || {},
      methodDescriptor_Ide_GetAssetList,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.ResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.GetIdeAssetListResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.getAssetList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetAssetList',
      request,
      metadata || {},
      methodDescriptor_Ide_GetAssetList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.IdResourceRequest,
 *   !proto.jupiter.builder.v1.GetIdeAssetResponse>}
 */
const methodDescriptor_Ide_GetAsset = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/GetAsset',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.IdResourceRequest,
  proto.jupiter.builder.v1.GetIdeAssetResponse,
  /**
   * @param {!proto.jupiter.builder.v1.IdResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.GetIdeAssetResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.GetIdeAssetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.GetIdeAssetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.getAsset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetAsset',
      request,
      metadata || {},
      methodDescriptor_Ide_GetAsset,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.GetIdeAssetResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.getAsset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetAsset',
      request,
      metadata || {},
      methodDescriptor_Ide_GetAsset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest,
 *   !proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse>}
 */
const methodDescriptor_Ide_GetAssetUploadUrl = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/GetAssetUploadUrl',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest,
  proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse,
  /**
   * @param {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.getAssetUploadUrl =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetAssetUploadUrl',
      request,
      metadata || {},
      methodDescriptor_Ide_GetAssetUploadUrl,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.GetIdeAssetUploadUrlRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.GetIdeAssetUploadUrlResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.getAssetUploadUrl =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/GetAssetUploadUrl',
      request,
      metadata || {},
      methodDescriptor_Ide_GetAssetUploadUrl);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.CreateIdeAssetRequest,
 *   !proto.jupiter.builder.v1.JobIdResponse>}
 */
const methodDescriptor_Ide_CreateAsset = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/CreateAsset',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.CreateIdeAssetRequest,
  common_pb.JobIdResponse,
  /**
   * @param {!proto.jupiter.builder.v1.CreateIdeAssetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.JobIdResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.CreateIdeAssetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.JobIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.JobIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.createAsset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/CreateAsset',
      request,
      metadata || {},
      methodDescriptor_Ide_CreateAsset,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.CreateIdeAssetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.JobIdResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.createAsset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/CreateAsset',
      request,
      metadata || {},
      methodDescriptor_Ide_CreateAsset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.IdResourceRequest,
 *   !proto.jupiter.builder.v1.JobIdResponse>}
 */
const methodDescriptor_Ide_DeleteAsset = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.Ide/DeleteAsset',
  grpc.web.MethodType.UNARY,
  proto.jupiter.builder.v1.IdResourceRequest,
  common_pb.JobIdResponse,
  /**
   * @param {!proto.jupiter.builder.v1.IdResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.JobIdResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.jupiter.builder.v1.JobIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.JobIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.IdeClient.prototype.deleteAsset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/DeleteAsset',
      request,
      metadata || {},
      methodDescriptor_Ide_DeleteAsset,
      callback);
};


/**
 * @param {!proto.jupiter.builder.v1.IdResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.jupiter.builder.v1.JobIdResponse>}
 *     Promise that resolves to the response
 */
proto.jupiter.builder.v1.IdePromiseClient.prototype.deleteAsset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/jupiter.builder.v1.Ide/DeleteAsset',
      request,
      metadata || {},
      methodDescriptor_Ide_DeleteAsset);
};


module.exports = proto.jupiter.builder.v1;

