import { Textarea } from "@mantine/core"

interface TextAreaProps {
  value?: string
  label: string
  onChange: (value: string) => void
}

const TextArea = ({ value, label, onChange }: TextAreaProps): JSX.Element => {
  return (
    <div style={{ width: "100%" }}>
      <Textarea
        sx={() => ({
          label: {
            color: "rgba(34, 39, 48, 0.7)",
            fontWeight: "bold",
            marginBottom: 4
          },
          input: {
            background: "#fff",
            border: "1px solid #ddd",
            borderRadius: 8,
            color: "#000"
          },
          background: "#fff",
          // marginTop: "12px",
          color: "#000"
        })}
        // mr="xs"
        size="xs"
        value={value}
        label={label}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  )
}

export default TextArea
