import styles from "styles/builder/settings.module.css"
import { ThemeSettings } from "./theme"
import { GeneralSettings } from "./general"
import { useRecoilValue } from "recoil"
import { selectedSettingsTabAtom } from "service/appstate/builder/atoms"

export const Settings = (): JSX.Element => {
  const selectedTab = useRecoilValue(selectedSettingsTabAtom)

  const getSelectedTab = () => {
    switch (selectedTab) {
      case "theme": {
        return <ThemeSettings />
      }
      case "general": {
        return <GeneralSettings />
      }
    }
  }
  return <div className={styles.settings}> {getSelectedTab()} </div>
}
