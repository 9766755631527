import { useReducer, useEffect, useMemo } from "react"
import Reducer, { TBuilderStateType } from "service/appstate/builder/reducer"
import Actions from "service/appstate/builder/actions"
import AsyncActions from "utils/asyncActions"
import Atoms from "service/appstate/builder/atoms"
import Selectors from "service/appstate/builder/selectors"
import { TAuthType } from "types/appstate/stateType"
import { RecoilState } from "recoil"

const STORAGE_KEY = "localBuilder"
export interface IBuilderProps {
  api: {
    host: string | undefined
    aws: {
      Auth: TAuthType
    }
  }
}
const useBuilder = (): [
  TBuilderStateType | { leftLayerTab: string },
  {
    [p: string]: (...data: (boolean | { page: number; search: string })[]) => unknown
  },
  { ExpandedAssets: RecoilState<never[]> },
  { ExpandAssetItem: RecoilState<string[]> }
] => {
  const initialState = {
    leftLayerTab: "pages"
  }

  const [state, dispatch] = useReducer(
    Reducer,
    initialState,
    (initial) =>
      ({
        ...initial,
        ...JSON.parse(localStorage.getItem(STORAGE_KEY) || "{}")
      } || initial)
  )
  useEffect(() => {
    try {
      localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify(Object.fromEntries(Object.entries(state).filter(([key]) => !key.startsWith("is"))))
      )
    } catch (e) {
      // continue regardless of error
    }
  }, [state])

  const actions = useMemo(
    () => AsyncActions({ state, dispatch }, Actions),
    // eslint-disable-next-line
    []
  )

  return [state, actions, Atoms, Selectors]
}
export default useBuilder
