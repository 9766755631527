import { SwitchProps } from "@mantine/core/lib/Switch/Switch"
import { Switch } from "@mantine/core"

const CBoolean = ({ checked, value, label, onChange }: SwitchProps): JSX.Element => {
  return (
    <div style={{ justifyContent: "center", width: "100%" }}>
      <Switch
        sx={{
          label: {
            color: "rgba(34, 39, 48, 0.7)",
            padding: 0,
            fontWeight: "bold"
          },
          input: {
            background: "#1F2428"
            // margin: "0 0 0 auto"
          },
          background: "#fff",
          // flexFlow: "row-reverse",
          justifyContent: "center",
          color: "#000",
          padding: "0 4px"
          // margin: "16px 0"
        }}
        styles={{
          body: {
            flexFlow: "row-reverse",
            gap: 12
          }
        }}
        checked={checked}
        value={value}
        label={label}
        size="xs"
        color="violet"
        onChange={onChange}
      />
    </div>
  )
}

export default CBoolean
