import { IconFilePlus } from "@tabler/icons"
import { Button, Loader, Text } from "@mantine/core"
import IdeActions from "service/appstate/application/actions"
import { useEffect, useState } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
  currentProject,
  pageListForceUpdaterAtom,
  pagesListSelector,
  resourceRequestAtom
} from "service/appstate/project/atoms"
import EventEmitter from "utils/eventEmitter"
import { IPubSubResponse } from "types/pubsub/pubsubTypes"
import { Page } from "model/page"
import { selectedPageId } from "service/appstate/builder/atoms"

export const NoPage = (): JSX.Element => {
  const [isPending, setPending] = useState(false)
  const [pendingJobId, setPendidngJobId] = useState("")
  const resourceRequest = useRecoilValue(resourceRequestAtom)
  const project = useRecoilValue(currentProject)
  const setSelectedLayerId = useSetRecoilState(selectedPageId)
  const pagesList = useRecoilValue(pagesListSelector)
  const pageListForceUpdater = useSetRecoilState(pageListForceUpdaterAtom)

  const createPage = () => {
    setPending(true)
    IdeActions.createPage("HomePage", resourceRequest).then((id) => {
      setPendidngJobId(id)
    })
  }

  useEffect(() => {
    const pubSubCb = (data: IPubSubResponse) => {
      if (pendingJobId === data.jobId) {
        if (data.event === 1) {
          const page = new Page({ title: "HomePage", id: data.id })

          if (project) {
            page.setProject(project)
          }

          pagesList.push(page)
          pageListForceUpdater((val) => val + 1)

          setPendidngJobId("")
          setPending(false)
          setSelectedLayerId(data.id)
        }
      }
    }

    EventEmitter.on("streamProjectBranch/data", pubSubCb)

    return () => {
      EventEmitter.off("streamProjectBranch/data", pubSubCb)
    }
  }, [pendingJobId])

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#F7F5FF",
          padding: "12px 24px",
          borderRadius: "4px",
          width: "300px"
        }}
      >
        <IconFilePlus size={48} color="#6549F7" />
        <Text
          style={{
            fontFamily: "Syne"
          }}
          align="center"
          size={14}
          mt={12}
        >
          Your project is empty. To get started, please create a page.
        </Text>
        <Button
          leftIcon={isPending ? <Loader color="#fff" size="xs" /> : null}
          onClick={() => createPage()}
          fullWidth
          mt={24}
        >
          Create Page
        </Button>
      </div>
    </div>
  )
}
