import { TPrimitive, PrimitiveSerializable } from "./primitive"

export class CBoolean extends PrimitiveSerializable {
  protected v = false
  isDefault(): boolean {
    return this.v === false
  }
  reset(): this {
    this.v = false
    return this
  }
  get(): boolean {
    return this.v
  }
  set(v: boolean): this {
    this.v = v
    return this
  }
  fromJSON(v: TPrimitive): this {
    return this.set(v as boolean)
  }
  toJSON(): TPrimitive {
    return this.v
  }
}
