import { Contstraints } from "./constraints"
import { Geometry } from "./geometry"
import { GeometrySize } from "./size"
import { AxisEnum } from "model/widgetProperty/enum"
import { PageWidget } from "model/pageWidget"
import { CNumber, CString } from "model/widgetProperty/type"

export class Grid extends Geometry {
  private _crossAxisCount = 3
  private _mainAxisSpacing = 10
  private _crossAxisSpacing = 10
  private _childAspectRatio = 1
  private _axis = "horizontal"

  private _overflow = {
    y: 0,
    x: 0,
    maxY: 0,
    maxX: 0
  }

  constructor(
    treeEntity: PageWidget,
    axis: CString,
    crossAxisCount: CNumber,
    mainAxisSpacing: CNumber,
    crossAxisSpacing: CNumber,
    childAspectRatio = 1
  ) {
    super()

    this.treeEntity = treeEntity
    this._axis = axis.get() || AxisEnum.horizontal
    this._crossAxisCount = crossAxisCount.get()
    this._mainAxisSpacing = mainAxisSpacing.get()
    this._crossAxisSpacing = crossAxisSpacing.get()
    this._childAspectRatio = childAspectRatio
  }

  get overflow(): typeof this._overflow {
    return this._overflow
  }

  get axis(): string {
    return this._axis
  }

  set scrollX(value: number) {
    if (this._overflow.x + value > this.overflow.maxX) {
      this._overflow.x = this.overflow.maxX
    } else if (this._overflow.x + value < 0) {
      this._overflow.x = 0
    } else {
      this._overflow.x += value
    }

    this.performLayout()
  }

  set scrollY(value: number) {
    if (this._overflow.y + value > this.overflow.maxY) {
      this._overflow.y = this.overflow.maxY
    } else if (this._overflow.y + value < 0) {
      this._overflow.y = 0
    } else {
      this._overflow.y += value
    }

    this.performLayout()
  }

  set overflowX(value: number) {
    this._overflow.maxX = value
  }

  set overflowY(value: number) {
    this._overflow.maxY = value
  }

  performLayout(): void {
    const constraints = this.constraints

    const crossSize = this._axis === AxisEnum.horizontal ? constraints.maxHeight : constraints.maxWidth
    const childSize = crossSize / this._crossAxisCount

    const totalSpacing = (this._crossAxisSpacing * (this._crossAxisCount - 1)) / this._crossAxisCount

    const childCrossSize = childSize - totalSpacing

    let childConstraints = new Contstraints()

    switch (this._axis) {
      case AxisEnum.horizontal: {
        childConstraints = childConstraints.tight(new GeometrySize(childCrossSize, childCrossSize))
        break
      }
      case AxisEnum.vertical: {
        childConstraints = childConstraints.tight(new GeometrySize(childCrossSize, childCrossSize))
        break
      }
    }

    let line = 0,
      lineFilled = 0

    const linesCount = Math.ceil(this.children.length / this._crossAxisCount),
      mainWidth = linesCount * childCrossSize + this._mainAxisSpacing * (linesCount - 1)

    switch (this._axis) {
      case AxisEnum.horizontal: {
        if (mainWidth > constraints.maxWidth) {
          this.overflowX = mainWidth - constraints.maxWidth
        } else {
          this.overflowX = 0
        }

        break
      }
      case AxisEnum.vertical: {
        if (mainWidth > constraints.maxHeight) {
          this.overflowY = mainWidth - constraints.maxHeight
        } else {
          this.overflowY = 0
        }

        break
      }
    }

    for (const child of this.children) {
      child.layout(childConstraints)

      switch (this._axis) {
        case "horizontal": {
          child.offset = {
            x: this.offset.x - this.overflow.x + childCrossSize * line + this._mainAxisSpacing * line,
            y: this.offset.y + childCrossSize * lineFilled + lineFilled * this._crossAxisSpacing
          }
          break
        }
        case "vertical": {
          child.offset = {
            x: this.offset.x + childCrossSize * lineFilled + lineFilled * this._crossAxisSpacing,
            y: this.offset.y - this.overflow.y + childCrossSize * line + this._mainAxisSpacing * line
          }
          break
        }
      }

      lineFilled += 1
      if (lineFilled === this._crossAxisCount) {
        lineFilled = 0
        line += 1
      }
    }

    this.size = new GeometrySize(constraints.maxWidth, constraints.maxHeight)
  }
}
