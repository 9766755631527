/* eslint-disable */
import { Contstraints } from "./constraints"
import { Container } from "./container"
import { Flex } from "./flex"
import { Grid } from "./grid"
import { Geometry } from "./geometry"
import { GeometryOffset } from "./offset"
import { Align } from "./align"
import { ConstrainedBox } from "./constrainedBox"
import { PaddingBox } from "./paddingBox"
import { FlexFit } from "./types"
import { Stack } from "./stack"
import { Textbox } from "./textbox"
import { Icon } from "./icon"
import { Alignment } from "model/widgetProperty/type"
import { PageWidget } from "model/pageWidget"
import { IdeIcon } from "service/pb/ide-resource_pb"

export function injectGeometry(
  element: PageWidget,
  geometryList: Geometry[],
  rootConstraints?: Contstraints,
  isRoot?: boolean,
  iconList?: IdeIcon.AsObject[]
): void {
  const { child, children, property, type } = element

  if (!property) {
    return
  }

  let geometry
  switch (type) {
    case "Column": {
      // @ts-ignore
      geometry = new Flex(
        element,
        "vertical",
        // @ts-ignore
        property.mainAxisAlignment,
        property.crossAxisAlignment,
        property.mainAxisSize
      )
      geometry.geometryList = geometryList
      break
    }
    case "Row": {
      // @ts-ignore
      geometry = new Flex(
        element,
        "horizontal",
        // @ts-ignore
        property.mainAxisAlignment,
        property.crossAxisAlignment,
        property.mainAxisSize
      )
      geometry.geometryList = geometryList
      break
    }
    case "GridView": {
      // @ts-ignore
      geometry = new Grid(
        element,
        // @ts-ignore
        property.axis,
        property.crossAxisCount,
        property.mainAxisSpacing,
        property.crossAxisSpacing
      )
      geometry.geometryList = geometryList
      break
    }
    case "Container": {
      // @ts-ignore
      const constraints = Container._extractSize(
        // @ts-ignore
        rootConstraints,
        // @ts-ignore
        property.boxShape.get(),
        property.size,
        property.radius
      )
      geometry = new ConstrainedBox(element, constraints)
      geometry.geometryList = geometryList
      break
    }
    case "Button": {
      // @ts-ignore
      const constraints = new Contstraints().tightFor({ width: property.width.get(), height: property.height.get() })
      geometry = new ConstrainedBox(element, constraints)
      geometry.geometryList = geometryList
      break
    }
    case "Image": {
      // @ts-ignore
      const constraints = Container._extractSize(rootConstraints, "rectangle", property.size)
      geometry = new ConstrainedBox(element, constraints)
      geometry.geometryList = geometryList
      break
    }
    case "Stack": {
      geometry = new Stack(element)
      geometry.geometryList = geometryList
      break
    }
    case "Icon": {
      // @ts-ignore
      geometry = new Icon(element, property.icon, iconList)
      geometry.geometryList = geometryList
      break
    }
    case "Text": {
      // @ts-ignore
      geometry = new Textbox(element, property.text, property.font)
      geometry.geometryList = geometryList
      break
    }
  }

  if (child) {
    injectGeometry(child, geometryList, rootConstraints, false, iconList)
  }

  if (children) {
    for (const child of children) {
      injectGeometry(child, geometryList, rootConstraints, false, iconList)
    }
  }

  if (element.type === "Stack") {
    // @ts-ignore
    const constraints = Container._extractSize(rootConstraints, "rectangle", property.size)
    const constrainedBox = new ConstrainedBox(null, constraints, true)
    constrainedBox.pseudoChild = geometry
    geometry = constrainedBox
    geometry.geometryList = geometryList
  }

  // @ts-ignore
  if (geometry && property.padding) {
    // @ts-ignore
    const paddingWrap = new PaddingBox(property.padding)
    paddingWrap.pseudoChild = geometry
    geometry = paddingWrap
    geometry.geometryList = geometryList
  }

  // @ts-ignore
  if (geometry && property.alignment) {
    // @ts-ignore
    const alignment: Alignment = element.property.alignment

    const y = alignment.getY()
    const x = alignment.getX()

    if (y !== undefined || x !== undefined) {
      const alignWrap = new Align(alignment)
      alignWrap.pseudoChild = geometry
      geometry = alignWrap
      geometry.geometryList = geometryList
    }
  }

  if (geometry && element.type === "GridView") {
    if (element.parent?.type === "Column" || element.parent?.type === "Row") {
      geometry.flexData = { flex: 1, fit: FlexFit.tight }
    }
  }

  if (geometry) {
    geometryList.push(geometry)
  }

  if (isRoot) {
    // @ts-ignore
    geometry.layout(rootConstraints, false)
  }
}
