import { Contstraints } from "./constraints"
import { Geometry } from "./geometry"
import { GeometrySize } from "./size"
import { ChildLayoutHelper } from "./utils"
import { FlexFit } from "./types"
import { AxisEnum, CrossAxisAlignmentEnum, MainAxisAlignmentEnum, MainAxisSizeEnum } from "model/widgetProperty/enum"
import { PageWidget } from "model/pageWidget"
import { CString } from "model/widgetProperty/type"

export class Flex extends Geometry {
  _direction = "horizontal"
  mainAxisAlignment: string
  crossAxisAlignment: string
  mainAxisSize: string

  constructor(
    treeEntity: PageWidget,
    direction: string,
    mainAxisAlignment?: CString,
    crossAxisAlignment?: CString,
    mainAxisSize?: CString
  ) {
    super()

    this.treeEntity = treeEntity
    this._direction = direction
    this.mainAxisAlignment = mainAxisAlignment?.get() || MainAxisAlignmentEnum.start
    this.crossAxisAlignment = crossAxisAlignment?.get() || CrossAxisAlignmentEnum.start
    this.mainAxisSize = mainAxisSize?.get() || MainAxisSizeEnum.max
  }

  _getIntrinsicSize(
    sizingDirection: string,
    extent: number, // the extent in the direction that isn't the sizing direction
    childSize: (child: Geometry, extent: number) => number // a method to find the size in the sizing direction
  ): number {
    if (this.crossAxisAlignment === CrossAxisAlignmentEnum.stratch) {
      // TODO__high describe error
      alert("Error")
      return 0.0
    }
    if (this._direction === sizingDirection) {
      // INTRINSIC MAIN SIZE

      let inflexibleSpace = 0

      for (const child of this.children) {
        inflexibleSpace += childSize(child, extent)
      }

      return inflexibleSpace
    } else {
      // INTRINSIC CROSS SIZE

      // Get inflexible space using the max intrinsic dimensions of fixed children in the main direction.

      let maxCrossSize = 0

      for (const child of this.children) {
        let mainSize = 0
        let crossSize = 0

        switch (this._direction) {
          case "horizontal":
            mainSize = child.getMaxIntrinsicWidth(Infinity)
            crossSize = childSize(child, mainSize)
            break
          case "vertical":
            mainSize = child.getMaxIntrinsicHeight(Infinity)
            crossSize = childSize(child, mainSize)
            break
        }

        maxCrossSize = Math.max(maxCrossSize, crossSize)
      }

      return maxCrossSize
    }
  }

  getMinIntrinsicWidth(height: number): number {
    return this._getIntrinsicSize("horizontal", height, (child: Geometry, extent: number) =>
      child.getMinIntrinsicWidth(extent)
    )
  }

  getMaxIntrinsicWidth(height: number): number {
    return this._getIntrinsicSize("horizontal", height, (child: Geometry, extent: number) =>
      child.getMaxIntrinsicWidth(extent)
    )
  }

  getMinIntrinsicHeight(width: number): number {
    return this._getIntrinsicSize("vertical", width, (child: Geometry, extent: number) =>
      child.getMinIntrinsicHeight(extent)
    )
  }

  getMaxIntrinsicHeight(width: number): number {
    return this._getIntrinsicSize("vertical", width, (child: Geometry, extent: number) =>
      child.getMaxIntrinsicHeight(extent)
    )
  }

  _getFlex(child: Geometry): number {
    return child.flexData?.flex || 0
  }

  _getFit(child: Geometry): FlexFit {
    return child.flexData?.fit || FlexFit.tight
  }

  _getCrossSize(size: GeometrySize): number {
    switch (this._direction) {
      case "horizontal":
        return size.height
      case "vertical":
        return size.width
      default:
        return 0
    }
  }

  _getMainSize(size: GeometrySize): number {
    switch (this._direction) {
      case "horizontal":
        return size.width
      case "vertical":
        return size.height
      default:
        return 0
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _computeSizes(constraints: Contstraints, layoutChild: typeof ChildLayoutHelper.layoutChild): any {
    let crossSize = 0
    let allocatedSize = 0 // Sum of the sizes of the non-flexible children.
    let totalFlex = 0
    let lastFlexChild

    const maxMainSize = this._direction === AxisEnum.horizontal ? constraints.maxWidth : constraints.maxHeight
    const canFlex = maxMainSize < Infinity

    for (const child of this.children) {
      const flex = this._getFlex(child)
      if (flex > 0) {
        totalFlex += flex
        lastFlexChild = child
      } else {
        let innerConstraints
        // TODO Create error if parent has unbounded cross axis size
        if (this.crossAxisAlignment === CrossAxisAlignmentEnum.stratch) {
          switch (this._direction) {
            case "horizontal":
              innerConstraints = new Contstraints().tightFor({ height: constraints.maxHeight })
              break
            case "vertical":
              innerConstraints = new Contstraints().tightFor({ width: constraints.maxWidth })
              break
          }
        } else {
          switch (this._direction) {
            case "horizontal":
              innerConstraints = new Contstraints({ maxHeight: constraints.maxHeight })
              break
            case "vertical":
              innerConstraints = new Contstraints({ maxWidth: constraints.maxWidth })
              break
          }
        }

        if (!innerConstraints) {
          return
        }

        const childSize = layoutChild(child, innerConstraints)
        allocatedSize += this._getMainSize(childSize)
        crossSize = Math.max(crossSize, this._getCrossSize(childSize))
      }
    }

    const freeSpace = Math.max(0.0, (canFlex ? maxMainSize : 0.0) - allocatedSize)
    let allocatedFlexSpace = 0.0

    if (totalFlex > 0) {
      const spacePerFlex = canFlex ? freeSpace / totalFlex : Number.NaN
      for (const child of this.children) {
        const flex = this._getFlex(child)
        if (flex > 0) {
          const maxChildExtent = canFlex
            ? child === lastFlexChild
              ? freeSpace - allocatedFlexSpace
              : spacePerFlex * flex
            : Infinity

          let minChildExtent
          switch (this._getFit(child)) {
            case FlexFit.tight:
              // assert(maxChildExtent < Infinity);
              minChildExtent = maxChildExtent
              break
            case FlexFit.loose:
              minChildExtent = 0.0
              break
          }
          // assert(minChildExtent != null);
          let innerConstraints

          if (this.crossAxisAlignment === CrossAxisAlignmentEnum.stratch) {
            switch (this._direction) {
              case AxisEnum.horizontal:
                innerConstraints = new Contstraints({
                  minWidth: minChildExtent,
                  maxWidth: maxChildExtent,
                  minHeight: constraints.maxHeight,
                  maxHeight: constraints.maxHeight
                })
                break
              case AxisEnum.vertical:
                innerConstraints = new Contstraints({
                  minWidth: constraints.maxWidth,
                  maxWidth: constraints.maxWidth,
                  minHeight: minChildExtent,
                  maxHeight: maxChildExtent
                })
                break
            }
          } else {
            switch (this._direction) {
              case AxisEnum.horizontal:
                innerConstraints = new Contstraints({
                  minWidth: minChildExtent,
                  maxWidth: maxChildExtent,
                  maxHeight: constraints.maxHeight
                })
                break
              case AxisEnum.vertical:
                innerConstraints = new Contstraints({
                  maxWidth: constraints.maxWidth,
                  minHeight: minChildExtent,
                  maxHeight: maxChildExtent
                })
                break
            }
          }
          if (innerConstraints) {
            const childSize = layoutChild(child, innerConstraints)
            const childMainSize = this._getMainSize(childSize)
            // assert(childMainSize <= maxChildExtent);
            allocatedSize += childMainSize
            allocatedFlexSpace += maxChildExtent
            crossSize = Math.max(crossSize, this._getCrossSize(childSize))
          }
        }
      }
    }

    const idealSize = canFlex && this.mainAxisSize === MainAxisSizeEnum.max ? maxMainSize : allocatedSize

    return {
      mainSize: idealSize,
      crossSize: crossSize,
      allocatedSize: allocatedSize
    }
  }

  getDryLayout(constraints: Contstraints): GeometrySize {
    const sizes = this._computeSizes(constraints, ChildLayoutHelper.dryLayoutChild)

    switch (this._direction) {
      case "horizontal":
        return constraints.constrain(new GeometrySize(sizes.mainSize, sizes.crossSize))
      case "vertical":
        return constraints.constrain(new GeometrySize(sizes.crossSize, sizes.mainSize))
    }

    return new GeometrySize()
  }

  performLayout(): void {
    const constraints = this.constraints

    const sizes = this._computeSizes(constraints, ChildLayoutHelper.layoutChild)

    const allocatedSize = sizes.allocatedSize
    let actualSize = sizes.mainSize
    let crossSize = sizes.crossSize

    if (this.children.length === 0) {
      switch (this._direction) {
        case "horizontal": {
          crossSize = 50
          break
        }
        case "vertical": {
          crossSize = 100
          break
        }
      }

      if (actualSize === 0) {
        actualSize = 150
      }
    }

    // Align items along the main axis.
    switch (this._direction) {
      case "horizontal": {
        this.size = constraints.constrain(new GeometrySize(actualSize, crossSize))
        actualSize = this.size.width
        crossSize = this.size.height
        break
      }
      case "vertical": {
        this.size = constraints.constrain(new GeometrySize(crossSize, actualSize))
        actualSize = this.size.height
        crossSize = this.size.width
        break
      }
    }

    const actualSizeDelta = actualSize - allocatedSize
    const remainingSpace = Math.max(0.0, actualSizeDelta)
    let leadingSpace = 0
    let betweenSpace = 0

    const childCount = this.children.length

    switch (this.mainAxisAlignment) {
      case MainAxisAlignmentEnum.start:
        leadingSpace = 0.0
        betweenSpace = 0.0
        break
      case MainAxisAlignmentEnum.end:
        leadingSpace = remainingSpace
        betweenSpace = 0.0
        break
      case MainAxisAlignmentEnum.center:
        leadingSpace = remainingSpace / 2.0
        betweenSpace = 0.0
        break
      case MainAxisAlignmentEnum.spaceBetween:
        leadingSpace = 0.0
        betweenSpace = childCount > 1 ? remainingSpace / (childCount - 1) : 0.0
        break
      case MainAxisAlignmentEnum.spaceAround:
        betweenSpace = childCount > 0 ? remainingSpace / childCount : 0.0
        leadingSpace = betweenSpace / 2.0
        break
      case MainAxisAlignmentEnum.spaceEvenly:
        betweenSpace = childCount > 0 ? remainingSpace / (childCount + 1) : 0.0
        leadingSpace = betweenSpace
        break
    }

    // Position elements
    let childMainPosition = leadingSpace

    for (const child of this.children) {
      let childCrossPosition = 0
      switch (this.crossAxisAlignment) {
        case CrossAxisAlignmentEnum.start:
          break
        case CrossAxisAlignmentEnum.end:
          childCrossPosition = crossSize - this._getCrossSize(child.size)
          break
        case CrossAxisAlignmentEnum.center:
          childCrossPosition = crossSize / 2.0 - this._getCrossSize(child.size) / 2.0
          break
        case CrossAxisAlignmentEnum.stratch:
          childCrossPosition = 0
          break
      }

      switch (this._direction) {
        case "horizontal":
          child.offset = { x: this.offset.x + childMainPosition, y: this.offset.y + childCrossPosition || 0 }
          break
        case "vertical":
          child.offset = { x: this.offset.x + childCrossPosition || 0, y: this.offset.y + childMainPosition }
          break
      }

      childMainPosition += this._getMainSize(child.size) + betweenSpace
    }
  }
}
