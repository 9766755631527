import { Size, PlainSize } from "model/widgetProperty/type"
import { Contstraints } from "./constraints"

export class Container {
  static _extractSize(screenSize: Contstraints, boxShape?: string, size?: PlainSize, radius?: Size): Contstraints {
    let width = 0,
      height = 0

    if (boxShape === "circle") {
      if (!radius) {
      } else {
        if (radius.isInfinity()) {
          width = height = Infinity
        } else {
          const _radius = radius.get()
          const mediaRadius = Math.min(screenSize.maxWidth, screenSize.maxHeight)
          const diametr = radius.isPercent() ? (mediaRadius * _radius) / 100 : radius.get()
          width = height = diametr
        }
      }
    } else {
      const sizeWidth = size?.getWidth(),
        sizeHeight = size?.geHeight()

      if (sizeWidth) {
        const mediaWidth = screenSize.maxWidth
        const widthValue = sizeWidth.get()

        if (sizeWidth.isInfinity()) {
          width = Infinity
        } else {
          width = sizeWidth.isPercent() ? (mediaWidth * widthValue) / 100 : sizeWidth.get()
        }
      } else {
        width = 0
      }

      if (sizeHeight) {
        const mediaHeight = screenSize.maxHeight
        const heightValue = sizeHeight.get() || 0

        if (sizeHeight.isInfinity()) {
          height = Infinity
        } else {
          height = sizeHeight.isPercent() ? (mediaHeight * heightValue) / 100 : sizeHeight.get() || 100
        }
      } else {
        height = 0
      }
    }

    return new Contstraints().tightFor({ width, height })
  }
}
