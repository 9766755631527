import { PageWidgetBase, PageWidget } from "../pageWidget"

export enum AddChildValidatorError {
  None = "",
  Unsupported = "unsupported", // undefined error
  NoChild = "no-child", // widget has no children/child capabillity
  RootChild = "root-child", // widget can be added only to root
  OneRootChild = "one-root-child", // root parent has only one child capabillity and has child already
  OneChild = "one-child" // parent has only one child capabillity and has child already
}
export default class AddChildValidator {
  static run(type: string, pw: PageWidgetBase): AddChildValidatorError {
    const project = pw?.getProject()
    if (!project) {
      return AddChildValidatorError.Unsupported
    }
    const parentWidgetInfo = !pw.isRoot() ? project?.getWidget(pw.type || "") : undefined
    const thisWidgetInfo = project?.getWidget(type)
    if (!thisWidgetInfo) {
      return AddChildValidatorError.Unsupported
    }
    // trying to add page widget
    if (thisWidgetInfo.hasCapability("page")) {
      // non root element
      if (pw instanceof PageWidget) {
        return AddChildValidatorError.RootChild
      } else if (pw.isRoot()) {
        const key = pw.getChildKeyByType(type)
        if (!key) {
          // no such key storage
          return AddChildValidatorError.Unsupported
        } else if (Reflect.get(pw, key)) {
          // already has child
          return AddChildValidatorError.OneChild
        } else {
          // ok
          return AddChildValidatorError.None
        }
      } else {
        return AddChildValidatorError.Unsupported
      }
    } else if (pw.isRoot() || parentWidgetInfo?.hasCapability("child")) {
      // parent has child capability
      return pw.child ? AddChildValidatorError.OneChild : AddChildValidatorError.None
    } else if (parentWidgetInfo?.hasCapability("children")) {
      // parent has children capability
      return AddChildValidatorError.None
    } else {
      // parent has no child capability
      return AddChildValidatorError.NoChild
    }
  }
}
