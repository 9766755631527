import { useEffect, useState } from "react"
import { useRecoilValueLoadable, useSetRecoilState } from "recoil"
import { useParams } from "react-router"
import { currentProject, projectIdAtom, resourceRequestAtom } from "service/appstate/project/atoms"
import Ide from "./ide"
import { api } from "service/api"
import { JupiterLoader } from "components/loader"

const Builder = (): JSX.Element => {
  const params = useParams()
  const projectId = params.projectId

  //Prefetch everything we need for builder
  const projectState = useRecoilValueLoadable(currentProject)
  const setProjectId = useSetRecoilState(projectIdAtom)
  const setResourceRequest = useSetRecoilState(resourceRequestAtom)

  const [currentPageReady, setCurrentPageReady] = useState(false)

  useEffect(() => {
    if (projectId) {
      const resource = `p/${projectId}/r/${String(Math.floor(Math.random() * 100000))}`
      setResourceRequest(resource)
      setProjectId(projectId)

      api.createProjectBranchStream(projectId, "master")
    }

    return () => {
      setProjectId("")
      setResourceRequest("")
      api.cancelProjectBranchStream()
    }
  }, [projectId])

  return (
    <>
      {!currentPageReady ? <JupiterLoader /> : null}
      {projectState.state === "hasValue" ? (
        <Ide isLoaded={currentPageReady} readyCb={() => setCurrentPageReady(true)} />
      ) : null}
    </>
  )
}

export default Builder

export type TypeBuilder = typeof Builder
