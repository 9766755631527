import React, { useEffect, useRef, MouseEvent, useState } from "react"
import styles from "styles/builder/leftpanel.module.css"
import { IconCheck } from "@tabler/icons"
export interface ILayersRowItemProps {
  width: number
  isRenaming?: boolean
  nodeId: string
  nodeName: string
  layerType?: string
  isSelected?: boolean
  startRenaming: (nodeId: string | null) => void
  onChangeName: (nodeId: string, value: string) => void
  onClickCallback: (nodeId: string, e: MouseEvent) => void
  onMouseDownCallback: (nodeId: string, e?: MouseEvent) => void
  onMouseMoveCallback: (nodeId: string, e: MouseEvent) => void
  onMouseUpCallback: (nodeId: string, e: MouseEvent) => void
}
const LayersRowItem = (props: ILayersRowItemProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [inputValue, setInputValue] = useState("")

  useEffect(() => {
    setInputValue(props.nodeName)
  }, [props.nodeName])

  const renameableNodeInputHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  useEffect(() => {
    if (props.isRenaming && inputRef.current) {
      inputRef.current.select()
    }
    // eslint-disable-next-line
  }, [props.isRenaming])

  const onClick = (e: never) => {
    null == props.onClickCallback || props.isRenaming || props.onClickCallback(props.nodeId, e)
  }
  const onDoubleClickName = () => {
    props.startRenaming(props.nodeId)
  }
  const onChangeLayerName = () => {
    if (props.nodeName !== inputValue) props.onChangeName(props.nodeId, inputValue)
    else props.startRenaming(null)
  }

  const onMouseDown = () => {
    null == props.onMouseDownCallback || props.isRenaming || props.onMouseDownCallback(props.nodeId)
  }

  const onMouseMove = (e: MouseEvent) => {
    null == props.onMouseMoveCallback || props.isRenaming || props.onMouseMoveCallback(props.nodeId, e)
  }

  const onMouseUp = (e: MouseEvent) => {
    null == props.onMouseUpCallback || props.isRenaming || props.onMouseUpCallback(props.nodeId, e)
  }

  return (
    <div
      className={[styles.pageRow, props.isSelected ? styles.pageRowSelected : ""].filter((e) => !!e).join(" ")}
      draggable="false"
      onClick={onClick}
      onDoubleClick={onDoubleClickName}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      <span className={styles.pageRowSvg}>
        <IconCheck />
      </span>
      {props.isRenaming && (
        <input
          className={styles.renameableNodeInput}
          ref={inputRef}
          type="text"
          spellCheck={false}
          autoCorrect={"off"}
          autoCapitalize={"off"}
          value={inputValue}
          onBlur={onChangeLayerName}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              onChangeLayerName()
            }
          }}
          onChange={renameableNodeInputHandleChange}
        />
      )}
      {!props.isRenaming && (
        <div className={styles.renameableNode}>
          <span className={styles.renameableNodeText} style={{ maxWidth: props.width, fontSize: 14 }}>
            {props.nodeName}
          </span>
        </div>
      )}
    </div>
  )
}
export default LayersRowItem
