import React, { memo, useState, MouseEvent, createContext, useEffect, useCallback } from "react"
import styles from "styles/builder/leftpanel.module.css"
import { Hash } from "react-feather"
import { Page, PageData } from "model/page"
import { ComponentData } from "model/component"
import { PageWidget } from "model/pageWidget"
import ScrollContainer from "./ScrollContainer"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { Oval } from "react-loader-spinner"
import NodeContextMenu from "./NodeContextMenu"
import { AddChildValidatorError } from "model/validator/addChildValidator"
import { ENodeEventList } from "model/entityEvent"
import { Menu } from "@mantine/core"
import { useRecoilState } from "recoil"
import { expandedWidgets } from "service/appstate/builder/atoms"

import {
  IconGridDots,
  IconLayoutDistributeHorizontal,
  IconLayoutDistributeVertical,
  IconLetterT,
  IconList,
  IconPhoto,
  IconRectangle,
  IconServer,
  IconSettings,
  IconSquare,
  IconCaretDown,
  IconCaretRight
} from "@tabler/icons"

export interface IObjectsPanelViewProps {
  isReadOnly: boolean
  isHovered: boolean
  drag: boolean
}

type TPanelViewItem = {
  tree: PageData | ComponentData
  key?: number
  item?: PageWidget
  openContextMenu?: (e: string) => void
  menuOpenedEl: string
  updatePageData: () => void
}

const ICON_SIZE = 12

type TMenuContext = {
  selectedID: string
  updateSelectedID: (c: string) => void
}
export const MenuContext = createContext<TMenuContext>({
  selectedID: "", // set a default value
  updateSelectedID: () => undefined
})

const SubTreeView = ({ tree, item, openContextMenu, menuOpenedEl, updatePageData }: TPanelViewItem): JSX.Element => {
  const [expandedWidgetsList, setExpandedWidgets] = useRecoilState(expandedWidgets)
  const childrenVisible = expandedWidgetsList.find((widgetId) => widgetId === item?.id)

  const onClickNode = (e: MouseEvent, widget?: PageWidget) => {
    if (widget) {
      tree.setSelected(widget)
    }
    e.preventDefault()
    e.stopPropagation()
  }

  const toggleChildrenVisibilty = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (!item?.id) {
      return
    }

    if (childrenVisible) {
      setExpandedWidgets(expandedWidgetsList.filter((listItem) => listItem !== item.id))
    } else {
      setExpandedWidgets(expandedWidgetsList.concat(item.id))
    }
  }

  const onMouseOver = (e: MouseEvent, widget?: PageWidget) => {
    e.preventDefault()
    e.stopPropagation()
    if (widget) {
      if (widget.id !== tree.getHovered()?.id) {
        tree.setHovered(widget)
      }
    }
  }

  const onMouseLeave = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    tree.unsetHovered()
  }

  const dragDropHandler = (ev: React.DragEvent<HTMLDivElement>, type?: string): void => {
    //TODO implement case when item is null

    if (!type) {
      return
    }

    const dropData = JSON.parse(ev.dataTransfer.getData("text/plain"))

    // get new widget by dropdata.from info
    let nw: PageWidget = new PageWidget()
    let cw: PageWidget = new PageWidget()
    switch (dropData?.from) {
      case "tree":
        const node = tree?.findById(dropData.id)
        if (node) {
          cw = node
          nw = node.clone()
        }
        break
      case "widget":
        nw = tree.getWidgetByType(dropData.type)
        break
    }

    // TODO internal error handler?
    if (!nw.id || !nw.type) return

    let targetElement: PageWidget | PageData | undefined

    if (type === "parent") {
      targetElement = item ? item : tree
    } else {
      targetElement = item?.parent?.isRoot() ? tree : item?.parent
    }

    // TODO create error
    if (!targetElement) {
      throw new Error("Target element didnt exist")
    }
    // validate operation
    const validationResult = targetElement.canAddChildByType(nw.type)

    switch (validationResult) {
      case AddChildValidatorError.None:
        switch (type) {
          case "parent":
            if (!item) {
              tree.addRootChild(nw)
            } else {
              item?.addWidget(nw)
            }

            break
          // TODO please separate inserting before node in children and inserting into root node!
          case "tmpNode":
            if (item?.parent?.isRoot()) {
              item?.parent?.addWidget(nw)
            } else if (item?.parent?.hasChildrenOnly()) {
              // TOOD is here ONLY insert before child in childern?
              item?.parent?.addChildBefore(nw, item)
            }
            break
        }

        // remove current moved node
        // TODO reimplement this by using moveToParent methods!!
        if (dropData.from === "tree" && cw && cw.id) {
          cw.remove()
        }

        break
    }

    updatePageData()
  }

  const dragOverHandler = (ev: React.DragEvent<HTMLDivElement>): void => {
    ev.stopPropagation()
    ev.preventDefault()
  }

  const contextMenuHandler = (e: MouseEvent) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()

      if (item && item.id) {
        openContextMenu && openContextMenu(item.id)
      }
    }
  }

  // const dragHandler = (ev: React.DragEvent<HTMLDivElement>, id?: string): void => {
  //   if (!id) {
  //     return
  //   }

  //   const dragdata = {
  //     from: "tree",
  //     id
  //   }

  //   ev.dataTransfer.setData("text/plain", JSON.stringify(dragdata))
  // }

  const isElementSelected = item?.id === tree.getSelected()?.id,
    isElementHovered = item?.id === tree.getHovered()?.id

  if (!item) {
    return (
      <div
        className={styles.panelBodyEmpty}
        onDrop={(e) => dragDropHandler(e, "parent")}
        onDragOver={(e) => dragOverHandler(e)}
      >
        <span> Please drags element here</span>
      </div>
    )
  }

  return (
    <div className="PanelViewWrap" data-id={item?.id}>
      <div className={[styles.panelType].join(" ")}>
        <Menu opened={menuOpenedEl === item?.id}>
          <Menu.Target>
            <div id="contextmenu">
              <div
                className={[
                  childrenVisible ? styles.panelBody1 : styles.panelBody,
                  isElementSelected && styles.panelBodySelected,
                  isElementHovered && styles.panelBodyHovered
                ].join(" ")}
                onClick={(e) => onClickNode(e, item)}
                onDrop={(e) => dragDropHandler(e, "parent")}
                onDragOver={(e) => dragOverHandler(e)}
                // onDrag={(e) => dragHandler(e, item?.id)}
                onContextMenu={contextMenuHandler}
                onMouseOver={(e) => onMouseOver(e, item)}
                onMouseLeave={(e) => onMouseLeave(e)}
                // draggable
              >
                <div className={styles.panelLeftIcon}>
                  {item?.isRoot() ? (
                    <Hash size={ICON_SIZE} strokeWidth={1} />
                  ) : item?.type === "Column" ? (
                    <IconLayoutDistributeVertical color="gray" />
                  ) : item?.type === "Text" ? (
                    <IconLetterT color="gray" />
                  ) : item?.type === "Container" ? (
                    <IconSquare color="gray" />
                  ) : item?.type === "Icon" ? (
                    <IconSettings color="gray" />
                  ) : item?.type === "Button" ? (
                    <IconRectangle color="gray" />
                  ) : item?.type === "Stack" ? (
                    <IconServer color="gray" />
                  ) : item?.type === "GridView" ? (
                    <IconGridDots color="gray" />
                  ) : item?.type === "Row" ? (
                    <IconLayoutDistributeHorizontal color="gray" />
                  ) : item?.type === "ListView" ? (
                    <IconList color="gray" />
                  ) : item?.type === "Image" ? (
                    <IconPhoto color="gray" />
                  ) : null}
                </div>

                <div
                  style={{
                    flex: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    paddingLeft: 10,
                    paddingRight: 10
                  }}
                  className={styles.topLevel}
                >
                  {item?.type || "type is undefined"}
                </div>
                {item?.children || item?.child ? (
                  <div onClick={toggleChildrenVisibilty} className={styles.panelArrow}>
                    <span>
                      {!childrenVisible ? (
                        <IconCaretRight size={28} style={{ paddingRight: 10, paddingTop: 4 }} />
                      ) : (
                        <IconCaretDown size={28} style={{ paddingRight: 10, paddingTop: 4 }} />
                      )}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </Menu.Target>
          <NodeContextMenu tree={tree} item={item} />
        </Menu>

        <div className={[childrenVisible ? styles.panelChildrenActive : "", styles.panelChildren].join(" ")}>
          {item?.getChildrenList().map((pw, key) => {
            return (
              <SubTreeView
                key={key}
                item={pw}
                tree={tree}
                menuOpenedEl={menuOpenedEl}
                openContextMenu={openContextMenu}
                updatePageData={updatePageData}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

interface ITreeViewProps {
  page: Page
  pageId?: string
}

const TreeView = ({ page }: ITreeViewProps) => {
  const tree = page.data

  const [, _forceUpdate] = useState(0)
  const [isOpened, setOpenedElement] = useState("")

  const forceUpdate = useCallback(() => {
    _forceUpdate(Math.random())
  }, [])

  const close = useCallback(() => {
    setOpenedElement("")
  }, [setOpenedElement])

  const open = (widgetId: string) => {
    if (!isOpened) {
      setOpenedElement(widgetId)

      document.addEventListener(
        "click",
        function callee() {
          close()
          document.removeEventListener("click", callee)
        },
        { once: true /*, capture: true*/ }
      )
    }
  }

  useEffect(() => {
    if (tree) {
      tree?.e?.on(ENodeEventList.TreeImported, forceUpdate)
      tree?.e?.on(ENodeEventList.NodeAdded, forceUpdate)
      tree?.e?.on(ENodeEventList.NodeDeleted, forceUpdate)
      tree?.e?.on(ENodeEventList.NodeSelected, forceUpdate)
      tree?.e?.on(ENodeEventList.NodeOver, forceUpdate)
      tree?.e?.on(ENodeEventList.NodeOut, forceUpdate)
      tree?.e?.on(ENodeEventList.NodeBuffered, forceUpdate)
      return () => {
        tree?.e?.off(ENodeEventList.TreeImported, forceUpdate)
        tree?.e?.off(ENodeEventList.NodeAdded, forceUpdate)
        tree?.e?.off(ENodeEventList.NodeSelected, forceUpdate)
        tree?.e?.off(ENodeEventList.NodeDeleted, forceUpdate)
        tree?.e?.off(ENodeEventList.NodeOver, forceUpdate)
        tree?.e?.off(ENodeEventList.NodeBuffered, forceUpdate)
      }
    }
  }, [tree])

  const updatePageData = () => {
    return
  }

  return (
    <>
      {tree != undefined ? (
        tree?.getChild() ? (
          <ScrollContainer>
            <SubTreeView
              item={tree?.getChild() as PageWidget}
              tree={tree}
              openContextMenu={open}
              menuOpenedEl={isOpened}
              updatePageData={updatePageData}
            />
          </ScrollContainer>
        ) : (
          <SubTreeView updatePageData={updatePageData} menuOpenedEl={isOpened} tree={tree} />
        )
      ) : (
        <div className={styles.loader}>
          <Oval color="black" secondaryColor="rgba(255,255,255,.5)" />
        </div>
      )}
    </>
  )
}

export default memo(TreeView)
