import { useState, memo, useEffect, ChangeEvent } from "react"
import { useRecoilValue, useRecoilValueLoadable } from "recoil"
import { currentProject, fontsList, projectForceUpdaterAtom } from "service/appstate/project/atoms"
import { IdeFont } from "service/pb/ide-resource_pb"
import styles from "styles/builder/fontmanager.module.css"
import { SegmentedControl, Input, Modal, Center, Loader } from "@mantine/core"
import { IconSearch } from "@tabler/icons"

export interface IFontValue {
  title: string
  family: string
  provider: string
  key?: string
}

interface IFontItem {
  font: IFontValue
  onClick: (font: IFontValue) => void
}

const FontItem = ({ font, onClick }: IFontItem): JSX.Element => {
  return (
    <div className={styles.fontItem}>
      <div onClick={() => onClick(font)} style={{ fontFamily: font.family }}>
        {font.title}
      </div>
    </div>
  )
}

interface IFontManagerProps {
  opened: boolean
  currentFont?: string
  withTheme?: boolean
  onCurrentFont: (weightList: string[], stylesList: string[]) => void
  onChange: (font: IFontValue) => void
  close: () => void
}

const FontManager = memo(
  ({ onChange, close, opened, currentFont, onCurrentFont, withTheme }: IFontManagerProps): JSX.Element => {
    const [currentTab, toggleTab] = useState("google")
    const [searchValue, _onSearchValueChange] = useState("")
    const [filteredFonts, setFilteredFonts] = useState<IFontValue[]>([])

    const fontsState = useRecoilValueLoadable(fontsList)
    const project = useRecoilValue(currentProject)
    useRecoilValue(projectForceUpdaterAtom)

    const projectFonts = project?.fontList || []

    const fonts = fontsState.state === "hasValue" ? fontsState.getValue() : []

    useEffect(() => {
      if (opened) {
        toggleTab("google")
      }
    }, [opened])

    useEffect(() => {
      if (currentFont && fonts) {
        const font = fonts.find((item) => item.family === currentFont)

        if (font) {
          onCurrentFont(font.weightList, font.styleList)
        }
      }
    }, [currentFont, fonts])

    const onSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
      _onSearchValueChange(e.target.value)
    }

    useEffect(() => {
      if (fonts.length > 0) {
        let parsedArray: IdeFont.AsObject[] = JSON.parse(JSON.stringify(fonts))
        if (currentTab === "google") {
          if (searchValue) {
            parsedArray = parsedArray.filter((item) => {
              return item.family.toLocaleLowerCase().indexOf(searchValue.toLocaleLowerCase()) >= 0
            })
          }

          const filteredFonts = parsedArray
            .sort((a, b) => {
              return b.order - a.order
            })
            .slice(0, 20)

          const result: IFontValue[] = []

          filteredFonts.forEach((font: IdeFont.AsObject) => {
            const family = font.family.replace(/ /g, "-").toLowerCase()

            const subset = font.subsetList.includes("latin") ? "latin" : font.subsetList[0]

            const url = process.env.REACT_APP_STATIC_ASSEST_URL

            try {
              const _font = new FontFace(
                font.family,
                `url(${url}/ide/res/1.0.0/font/${font.provider}/${family}/${subset}-400-normal.woff2)`,
                { weight: "400", style: "normal" }
              )

              _font.load().then(() => {
                document.fonts.add(_font)
              })
            } catch (err) {}

            const fontObj = {
              title: font.family,
              provider: "google",
              family: font.family
            }

            result.push(fontObj as IFontValue)
          })

          setFilteredFonts(result)
        } else if (currentTab === "theme") {
          const fonts = projectFonts.map((_font) => {
            const { font, key } = _font

            const fontObj = {
              title: key,
              provider: "theme",
              family: font.getFamily(),
              key: key
            }

            return fontObj as IFontValue
          })

          setFilteredFonts(fonts)
        }
      }
    }, [fonts, searchValue, currentTab])

    const renderFonts = () => {
      const _fonts = filteredFonts

      if (_fonts) {
        return _fonts.map((item, index: number) => {
          return <FontItem key={index} font={item} onClick={onChange} />
        })
      } else {
        return null
      }
    }

    return (
      <Modal
        withinPortal
        styles={{
          title: {
            fontFamily: "Syne",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 24
          }
        }}
        title="Select font"
        size={600}
        radius={20}
        opened={opened}
        onClose={close}
      >
        <Input.Wrapper>
          <Input
            radius={10}
            icon={<IconSearch />}
            value={searchValue}
            sx={{
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 14
            }}
            onChange={onSearchValueChange}
            placeholder="Search font..."
          />
        </Input.Wrapper>
        {withTheme ? (
          <div style={{ marginTop: "20px" }}>
            <Center>
              <SegmentedControl
                styles={{
                  label: {
                    fontFamily: "Syne",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: 14
                  },
                  root: {
                    backgroundColor: "#F7F5FF"
                  }
                }}
                color="violet"
                size="xl"
                data={[
                  { label: "Google Fonts", value: "google" },
                  { label: "Theme Fonts", value: "theme" }
                ]}
                onChange={(e) => toggleTab(e)}
              />
            </Center>
          </div>
        ) : null}
        {fontsState.state === "hasValue" ? <div className={styles.fontManager_List}>{renderFonts()}</div> : <Loader />}
      </Modal>
    )
  }
)

export default FontManager
