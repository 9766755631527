import { clampNumber } from "../component/utils"
import { GeometrySize } from "./size"
import { GeometryPadding } from "./padding"

interface IConstraints {
  minWidth?: number
  minHeight?: number
  maxWidth?: number
  maxHeight?: number
}
export class Contstraints {
  private _minWidth = 0
  private _minHeight = 0
  private _maxWidth = 0
  private _maxHeight = 0

  // Creates box constraints with the given constraints.
  constructor(constraints?: IConstraints) {
    this._minWidth = constraints?.minWidth || 0
    this._maxWidth = constraints?.maxWidth || Infinity
    this._minHeight = constraints?.minHeight || 0
    this._maxHeight = constraints?.maxHeight || Infinity
  }

  get minWidth(): number {
    return this._minWidth
  }

  get maxWidth(): number {
    return this._maxWidth
  }

  get minHeight(): number {
    return this._minHeight
  }

  get maxHeight(): number {
    return this._maxHeight
  }

  // Creates box constraints that is respected only by the given size.
  tight(size: GeometrySize): Contstraints {
    this._minWidth = size.width
    this._maxWidth = size.width
    this._minHeight = size.height
    this._maxHeight = size.height

    return this
  }

  // Creates box constraints that require the given width or height.
  tightFor({ width, height }: { width?: number; height?: number }): Contstraints {
    this._minWidth = width || 0
    this._maxWidth = width || Infinity
    this._minHeight = height || 0
    this._maxHeight = height || Infinity

    return this
  }

  expand({ width, height }: { width?: number; height?: number }): void {
    this._minWidth = width || Infinity
    this._maxWidth = width || Infinity
    this._minHeight = height || Infinity
    this._maxHeight = height || Infinity
  }

  loosen(): Contstraints {
    return new Contstraints({
      maxWidth: this.maxWidth,
      maxHeight: this.maxHeight
    })
  }

  tighten(width: number, height: number): Contstraints {
    return new Contstraints({
      minWidth: width == null ? this.minWidth : clampNumber(width, this._minWidth, this._maxWidth),
      minHeight: height == null ? this._minHeight : clampNumber(height, this._minHeight, this._maxHeight),
      maxWidth: width == null ? this._maxWidth : clampNumber(width, this.minWidth, this._maxWidth),
      maxHeight: height == null ? this._maxHeight : clampNumber(height, this._minHeight, this._maxHeight)
    })
  }

  /// Returns the width that both satisfies the constraints and is as close as
  /// possible to the given width.
  constrainWidth(width = Infinity): number {
    return clampNumber(width, this.minWidth, this.maxWidth)
  }

  /// Returns the height that both satisfies the constraints and is as close as
  /// possible to the given height.
  constrainHeight(height = Infinity): number {
    return clampNumber(height, this.minHeight, this.maxHeight)
  }

  constrain(size: GeometrySize): GeometrySize {
    const result = new GeometrySize(this.constrainWidth(size.width), this.constrainHeight(size.height))
    return result
  }

  enforce(constraints: Contstraints): Contstraints {
    return new Contstraints({
      minWidth: clampNumber(this.minWidth, constraints.minWidth, constraints.maxWidth),
      maxWidth: clampNumber(this.maxWidth, constraints.minWidth, constraints.maxWidth),
      minHeight: clampNumber(this.minHeight, constraints.minHeight, constraints.maxHeight),
      maxHeight: clampNumber(this.maxHeight, constraints.minHeight, constraints.maxHeight)
    })
  }

  deflate(padding: GeometryPadding): Contstraints {
    const horizontal = padding.horizontal
    const vertical = padding.vertical
    const deflatedMinWidth = Math.max(0.0, this.minWidth - horizontal)
    const deflatedMinHeight = Math.max(0.0, this.minHeight - vertical)

    return new Contstraints({
      minWidth: deflatedMinWidth,
      maxWidth: Math.max(deflatedMinWidth, this.maxWidth - horizontal),
      minHeight: deflatedMinHeight,
      maxHeight: Math.max(deflatedMinHeight, this.maxHeight - vertical)
    })
  }

  get hasBoundedWidth(): boolean {
    return this._maxWidth < Infinity
  }

  get hasBoundedHeight(): boolean {
    return this._maxHeight < Infinity
  }

  get hasTightWidth(): boolean {
    return this.minWidth >= this.maxWidth
  }

  get hasTightHeight(): boolean {
    return this.minHeight >= this.maxHeight
  }

  get hasInfiniteWidth(): boolean {
    return this.minWidth >= Infinity
  }

  get hasInfiniteHeight(): boolean {
    return this.minHeight >= Infinity
  }

  get isTight(): boolean {
    return this.hasTightWidth && this.hasTightHeight
  }

  // The biggest size that satisfies the constraints.
  get biggest(): GeometrySize {
    return new GeometrySize(this.constrainWidth(), this.constrainHeight())
  }

  // The smallest size that satisfies the constraints.
  get smallest(): GeometrySize {
    return new GeometrySize(this.constrainWidth(0), this.constrainHeight(0))
  }
}
