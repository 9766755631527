import { lazy, Suspense, FC } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"

// import { AppStateContext, TAppStateType } from "./appstate/"
import Loader from "pages/Components/loader"
// import { TAppActionType, TPageType } from "types/service/routingType"
import { TTopPageLazyType } from "types/utils/actionsType"

// import type { TypeBuilder } from "pages/builder/"
import Builder from "pages/builder/"

const TopPage = lazy(() => import("pages/toppage/")) as TTopPageLazyType
const DashBoard = lazy(() => import("pages/dashboard/"))
// const Builder = lazy(() => import("pages/builder/")) as TTopPageLazyType

const NotFound: FC<{ default: boolean }> = () => <p>Sorry, nothing here</p>

// const AuthRoute = (() => {
//   let status = 0
//   let bypassCache = true

//   return ({ page: Page, ...rest }: TPageType) => {
//     const {
//       appActions: { authActions }
//     }: TAppActionType = useContext<TAppStateType>(AppStateContext)

//     useEffect(() => {
//       status = 0
//       return () => {
//         status = 0
//       }
//     }, [])

//     if (status === 1) return <Page {...rest} />
//     if (status === 2) return <Redirect noThrow to="/" />

//     throw authActions.currentAuthenticatedUser(bypassCache).then(
//       (dispatchSuccess: () => void) => {
//         dispatchSuccess()
//         status = 1
//         bypassCache = false
//       },
//       async () => {
//         await authActions.signOut()
//         status = 2
//       }
//     )
//   }
// })()

const Routing: FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route element={<TopPage />} path="/" />

          <Route element={<DashBoard />} path="/d/*" />

          <Route element={<Builder />} path="/b/:projectId" />
          <Route path="*" element={NotFound} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default Routing
