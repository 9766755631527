import { IDefault, IPrimitiveSerializable, IReset, PrimitiveSerializable, TPrimitive } from "./primitive"
import { IProject } from "../../project"

export interface IArray<T extends PrimitiveSerializable> extends IDefault, IPrimitiveSerializable, IReset {
  reset(): this
  delete(i: number): this
  has(i: number): boolean
  get(i: number): T | undefined
  add(): T
  forEach(callback: (item: T, index: number, array: T[]) => void): void
  length(): number
}

export class PrimitiveArray<T extends PrimitiveSerializable> implements IArray<T> {
  protected p?: IProject
  protected l: T[] = []

  constructor(private t: new (p?: IProject) => T, p?: IProject) {
    this.p = p
  }

  isDefault(): boolean {
    return !this.l.length
    // let ret = true
    // this.l.forEach((e: T) => {
    //   ret = ret && e.isDefault()
    // })
    // return ret
  }

  reset(): this {
    this.l.forEach((e: T) => {
      e.reset()
    })
    return this
  }

  has(i: number): boolean {
    return !!this.l[i]
  }

  get(i: number): T | undefined {
    return this.l[i]
  }

  add(): T {
    this.l.push(new this.t(this.p))
    return this.l[this.l.length - 1]
  }

  delete(i: number): this {
    if (this.has(i)) {
      this.l.splice(i, 1)
    }
    return this
  }

  length(): number {
    return this.l.length
  }

  forEach(callback: (item: T, index: number, array: T[]) => void): void {
    this.l.forEach(callback)
  }

  fromJSON(v: TPrimitive[]): this {
    this.l = []
    v.forEach((e: TPrimitive) => {
      this.l.push(new this.t(this.p).fromJSON(e))
    }, this)
    return this
  }
  toJSON(): TPrimitive[] {
    const ret: TPrimitive[] = []
    this.l.forEach((e: T) => {
      ret.push(e.toJSON())
    }, this)
    return ret
  }
}
