import { createPortal } from "react-dom"
import CSS from "csstype"

const wrapperStyle: CSS.Properties = {
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "white",
  zIndex: 1000
}

const containerStyle: CSS.Properties = {
  position: "relative",
  zIndex: 100001,
  borderRadius: "15px",
  backgroundColor: "rgb(255, 255, 255)",
  boxShadow: "rgb(0 0 0 / 30%) 0px 0px 7px 0px",
  padding: "9px"
}

const Loader = (): JSX.Element => {
  return createPortal(
    <div style={wrapperStyle}>
      <div style={containerStyle}>Loading..</div>
    </div>,
    document.getElementById("root") as Element
  )
}

export default Loader
