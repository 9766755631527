import { EntityData } from "model/entity"
import { Menu } from "@mantine/core"
import styles from "styles/builder/leftpanel.module.css"
import { PageWidget } from "model/pageWidget"

const NodeContextMenu = ({ tree, item }: { tree: EntityData; item: PageWidget }): JSX.Element => {
  return (
    <Menu.Dropdown>
      <Menu.Item
        className={[styles.reactContextmenuItem, !item ? styles.reactContextMenuDisable : " "].join(" ")}
        onClick={() => {
          tree.setBuffered(item.clone())
          item.remove()
        }}
      >
        <span>Cut</span>
      </Menu.Item>
      <Menu.Item
        className={[styles.reactContextmenuItem, !item ? styles.reactContextMenuDisable : " "].join(" ")}
        onClick={() => {
          const clonedItem = item.clone()

          tree.setBuffered(clonedItem)
        }}
      >
        <span>Copy</span>
      </Menu.Item>
      <Menu.Item
        className={[styles.reactContextmenuItem, !tree.getBuffered() ? styles.reactContextMenuDisable : " "].join(" ")}
        onClick={() => {
          const buffered = tree.getBuffered()
          if (buffered) {
            item.addWidget(buffered)
            tree.setBuffered(buffered.clone())
          }
        }}
      >
        <span>Paste</span>
      </Menu.Item>
      <Menu.Item
        className={[
          styles.reactContextmenuItem,
          !tree.getSelected()?.canDuplicate() ? styles.reactContextMenuDisable : " "
        ].join(" ")}
        onClick={() => {
          tree.getSelected()?.duplicate()
        }}
      >
        <span>Duplicate</span>
      </Menu.Item>
      <Menu.Item
        className={styles.reactContextmenuItem}
        onClick={() => {
          if (item) {
            item.remove()
          }
        }}
      >
        <span>Delete</span>
      </Menu.Item>
    </Menu.Dropdown>
  )
}

export default NodeContextMenu
