interface IParentSizeOffset {
  x: number
  y: number
  w: number
  h: number
}

// TODO Should be refactored
// Offset should calculated after sizes

interface ImageBounds {
  sx: number
  sy: number
  sw: number
  sh: number
  dx: number
  dy: number
  dw: number
  dh: number
}

export const getImageBoxFitProperty = (
  image: HTMLImageElement,
  parentSizeOffset: IParentSizeOffset,
  type: string
): ImageBounds => {
  const originalWidth = image.width,
    originalHeight = image.height

  const parentX = parentSizeOffset.x,
    parentY = parentSizeOffset.y,
    parentWidth = parentSizeOffset.w,
    parentHeight = parentSizeOffset.h

  let sx = 0,
    sy = 0,
    sw = originalWidth,
    sh = originalHeight,
    dx = parentX,
    dy = parentY,
    dw = parentWidth,
    dh = parentHeight

  if (type === "fill") {
    //
  } else if (type === "contain") {
    if (originalWidth > originalHeight) {
      dh = originalHeight * (parentWidth / originalWidth)
      dy = dy + parentHeight / 2 - dh / 2
    } else if (originalHeight > originalWidth) {
      dw = originalWidth * (parentHeight / originalHeight)
      dx = parentHeight / 2 - dw / 2
    } else {
      if (parentWidth > parentHeight) {
        dw = originalWidth * (parentHeight / originalHeight)
        dx = parentHeight / 2 - dw / 2
      } else if (parentHeight > parentWidth) {
        dh = originalHeight * (parentWidth / originalWidth)
        dy = dy + parentHeight / 2 - dh / 2
      } else {
        //
      }
    }
  } else if (type === "cover") {
    if (parentWidth / parentHeight > originalWidth / originalHeight) {
      sh = (originalWidth * parentHeight) / parentWidth
      sy = originalHeight / 2 - sh / 2
    } else {
      sw = (originalHeight * parentWidth) / parentHeight
      sx = originalWidth / 2 - sw / 2
    }
  } else if (type === "fitWidth") {
    sh = originalWidth * (parentHeight / parentWidth)
    dh = sh * (parentWidth / originalWidth)

    if (sh > originalHeight) {
      dy = dy + (parentHeight - parentHeight * (originalHeight / sh)) / 2
    } else {
      sy = (originalHeight - sh) / 2
    }
  } else if (type === "fitHeight") {
    sw = originalHeight * (parentWidth / parentHeight)
    dw = sw * (parentHeight / originalHeight)

    if (sw > originalWidth) {
      dx = dx + (parentWidth - parentWidth * (originalWidth / sw)) / 2
    } else {
      sx = (originalWidth - sw) / 2
    }
  } else if (type === "none") {
    dw = sw = Math.min(originalWidth, parentWidth)
    dh = sh = Math.min(originalHeight, parentHeight)

    if (originalWidth >= parentWidth) {
      sx = (dw * (originalWidth / parentWidth) - parentWidth) / 2
    } else {
      dx = dx + (parentWidth - originalWidth) / 2
    }

    if (originalHeight >= parentHeight) {
      sy = (dh * (originalHeight / parentHeight) - parentHeight) / 2
    } else {
      dy = dy + (parentHeight - originalHeight) / 2
    }
  } else if (type === "scaleDown") {
    dw = sw
    dh = sh
    const aspectRatio = sw / sh

    if (dh > parentHeight) {
      dw = parentHeight * aspectRatio
      dh = parentHeight
    }

    if (dw > parentWidth) {
      dw = parentWidth
      dh = parentWidth / aspectRatio
    }

    if (parentWidth > dw) {
      dx = dx + (parentWidth - dw) / 2
    }

    if (parentHeight > dh) {
      dy = dy + (parentHeight - dh) / 2
    }
  }

  return { sx, sy, sw, sh, dx, dy, dw, dh }
}

export function clampNumber(x: number, min: number, max: number): number {
  if (min > max || Number.isNaN(min) || Number.isNaN(max) || Number.isNaN(x)) {
    return x
  }

  if (x < min) {
    return min
  }
  if (x > max) {
    return max
  }

  return x
}
