import { Geometry } from "./geometry"
import { GeometrySize } from "./size"
import { ProjectFont, WidgetDynamicValue } from "model/widgetProperty/type"
import { PageWidget } from "model/pageWidget"

export class Textbox extends Geometry {
  private _ctx: CanvasRenderingContext2D
  private _text: string
  private _textLines: string[] = []
  private _lineHeight = 1.16
  private _fontSizeMult = 1.13
  private _isFullWidth = false

  private _fontProps = {
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "normal",
    fontFamily: "serif"
  }

  constructor(treeEntity: PageWidget, text: WidgetDynamicValue, projectFont: ProjectFont) {
    super()

    this.treeEntity = treeEntity
    const fontStyles = this._generateFontPropString(projectFont)

    this._ctx = document.createElement("canvas").getContext("2d") as CanvasRenderingContext2D

    this._ctx.font = fontStyles

    this._text = text?.render() || ""
  }

  get ctx(): CanvasRenderingContext2D {
    return this._ctx
  }

  _generateFontPropString(projectFont: ProjectFont): string {
    const font = projectFont?.get()

    const fontSize = font?.getSize() || 16
    const fontFamily = font?.getFamily() || "Roboto"
    const fontStyle = font?.getStyle() || "normal"
    const fontWeight = font?.getWeight() || "400"

    this._fontProps = {
      fontSize,
      fontFamily,
      fontStyle,
      fontWeight
    }

    return fontStyle + " " + fontWeight + " " + fontSize + "px " + fontFamily
  }

  graphemeSplit(textstring: string): string[] {
    const graphemes: string[] = []

    for (let i = 0; i < textstring.length; i++) {
      const chr = this.getWholeChar(textstring, i)

      if (!chr) {
        continue
      }
      graphemes.push(chr)
    }
    return graphemes
  }

  // taken from mdn in the charAt doc page.
  getWholeChar(str: string, i: number): string | undefined {
    const code = str.charCodeAt(i)

    if (isNaN(code)) {
      return "" // Position not found
    }
    if (code < 0xd800 || code > 0xdfff) {
      return str.charAt(i)
    }

    // High surrogate (could change last hex to 0xDB7F to treat high private
    // surrogates as single characters)
    if (0xd800 <= code && code <= 0xdbff) {
      if (str.length <= i + 1) {
        throw Error("High surrogate without following low surrogate")
      }
      const next = str.charCodeAt(i + 1)
      if (0xdc00 > next || next > 0xdfff) {
        throw Error("High surrogate without following low surrogate")
      }
      return str.charAt(i) + str.charAt(i + 1)
    }
    // Low surrogate (0xDC00 <= code && code <= 0xDFFF)
    if (i === 0) {
      throw Error("Low surrogate without preceding high surrogate")
    }
    const prev = str.charCodeAt(i - 1)

    // (could change last hex to 0xDB7F to treat high private
    // surrogates as single characters)
    if (0xd800 > prev || prev > 0xdbff) {
      throw Error("Low surrogate without preceding high surrogate")
    }
    // We can pass over low surrogates now as the second component
    // in a pair which we have already processed
    return
  }

  private _wrapLine(_line: string, desiredWidth: number) {
    let lineWidth = 0,
      lineJustStarted = true

    const graphemeLines: string[][] = [[]],
      words = _line.split(" ")

    for (let i = 0; i < words.length; i++) {
      const word = words[i]
      let wordWidth = 0

      if (i + 1 === words.length) {
        wordWidth = this._ctx.measureText(word).width
      } else {
        wordWidth = this._ctx.measureText(word + " ").width
      }

      lineWidth += wordWidth
      if (lineWidth > desiredWidth && !lineJustStarted) {
        graphemeLines.push([] as string[])
        lineWidth = wordWidth
        lineJustStarted = true
        this._isFullWidth = true
      }

      if (!lineJustStarted) {
        graphemeLines[graphemeLines.length - 1].push(" ")
      }

      graphemeLines[graphemeLines.length - 1].push(word)

      lineJustStarted = false
    }

    return graphemeLines
  }

  private _splitTextIntoLines() {
    const textLines = this._text.split(/\r?\n/)
    const result = []

    const { maxWidth } = this.constraints

    for (let i = 0; i < textLines.length; i++) {
      const lineWidth = this._ctx.measureText(textLines[i]).width

      if (lineWidth > maxWidth) {
        const splitedLines = this._wrapLine(textLines[i], maxWidth)

        splitedLines.forEach((item) => result.push(item.join("")))
      } else {
        result.push(textLines[i])
      }
    }

    this._textLines = result
  }

  _getHeightOfText(): number {
    const lines = this._textLines
    let height = 0

    for (let i = 0; i < lines.length; i++) {
      const _height = this._fontProps.fontSize * this._lineHeight * this._fontSizeMult

      height += i === lines.length - 1 ? _height / this._lineHeight : _height
    }

    return height
  }

  performLayout(): void {
    this._splitTextIntoLines()

    let width = 0

    const height = this._getHeightOfText()

    for (const line of this._textLines) {
      const lineWidth = this._ctx.measureText(line).width

      if (this._isFullWidth) {
        const { maxWidth } = this.constraints
        width = maxWidth
      } else {
        width = Math.max(width, lineWidth)
      }
    }

    this.size = new GeometrySize(width, height)
  }
}
