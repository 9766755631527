import { Icon } from "model/widgetProperty/type"
import React, { useState } from "react"
import { useRecoilValueLoadable } from "recoil"
import { iconList } from "service/appstate/project/atoms"
import { Modal, SegmentedControl, Center, TextInput } from "@mantine/core"
import { IdeIcon } from "service/pb/ide-resource_pb"
import { IconSearch } from "@tabler/icons"
import styles from "styles/builder/iconmanager.module.css"

interface IIConManagerProps {
  value: Icon
  onChange: () => void
  onClose: () => void
  isOpened: boolean
}

const providers = [
  { title: "Font Awesome", value: "fa" },
  { title: "Material Design", value: "material" },
  { title: "Custom", value: "custom" }
]

const IconManager = ({ isOpened, value, onChange, onClose }: IIConManagerProps): JSX.Element => {
  const iconsState = useRecoilValueLoadable(iconList)
  const [currentProvider, setCurrentTab] = useState("fa")
  const [searchValue, searchValueOnChange] = useState("")

  const icons = iconsState.state === "hasValue" ? iconsState.getValue() : []

  const onIconSelect = (icon: IdeIcon.AsObject, style: string): void => {
    value.setKey(icon.key).setProvider(icon.provider + ":" + style)

    onChange()

    onClose()
  }

  const renderIcons = () => {
    const result: JSX.Element[] = []
    icons
      .filter((icon) => {
        if (searchValue) {
          const isInSearch = icon.tagList.some((tag) => {
            if (tag.toLocaleLowerCase().indexOf(searchValue.toLocaleLowerCase()) >= 0) return true
            else return false
          })

          if (!isInSearch) {
            return false
          }
        }
        return icon.provider === currentProvider
      })
      .forEach((icon) => {
        icon.styleList.forEach((style) => {
          result.push(
            <div
              key={icon.key + style}
              style={{
                borderRadius: 8,
                fontFamily: icon.provider + "-" + style,
                margin: "8px",
                width: "50px",
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "24px",
                cursor: "pointer",
                color: "#333"
              }}
              className={styles.iconManager_List}
              onClick={() => onIconSelect(icon, style)}
            >
              {String.fromCodePoint(icon.codepoint)}
            </div>
          )
        })
      })

    return result
  }
  return (
    <Modal
      styles={{
        title: {
          fontFamily: "Syne",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: 24
        }
      }}
      radius={20}
      size={600}
      opened={isOpened}
      onClose={onClose}
      style={{ fill: "#333" }}
      title="Select icon"
    >
      <div className="tabs">
        <TextInput
          radius={10}
          icon={<IconSearch />}
          mb={20}
          sx={{
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 14
          }}
          value={searchValue}
          onChange={(e) => searchValueOnChange(e.target.value)}
          placeholder="Search icons.."
          style={{ marginTop: "20px" }}
        />
        <Center>
          <SegmentedControl
            styles={{
              label: {
                fontFamily: "Syne",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 14
              },
              root: {
                backgroundColor: "#F7F5FF"
              }
            }}
            color="violet"
            size="xl"
            data={providers.map((item) => {
              return { label: item.title, value: item.value }
            })}
            onChange={(e) => setCurrentTab(e)}
          />
        </Center>
        {/*<TextInput*/}
        {/*  radius={10}*/}
        {/*  icon={<IconSearch />}*/}
        {/*  sx={{*/}
        {/*    fontFamily: "Open Sans",*/}
        {/*    fontStyle: "normal",*/}
        {/*    fontWeight: 700,*/}
        {/*    fontSize: 14*/}
        {/*  }}*/}
        {/*  value={searchValue}*/}
        {/*  onChange={(e) => searchValueOnChange(e.target.value)}*/}
        {/*  placeholder="Search icons.."*/}
        {/*  style={{ marginTop: "20px" }}*/}
        {/*/>*/}
        <div
          style={{
            height: "360px",
            overflowY: "auto",
            marginTop: "20px"
          }}
          className="icons-list"
        >
          <div
            style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}
            className="icon-list-innter"
          >
            {renderIcons()}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default IconManager
