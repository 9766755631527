import React from "react"
import "./index.css"

export const JupiterLoader = ({ size = "big" }: { size?: string }): JSX.Element => {
  const loader = (
    <div className="content">
      <div className="planet">
        <div className="ring"></div>
        <div className="cover-ring"></div>
        <div className="spots">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <p>loading</p>
    </div>
  )

  return <> {size === "big" ? <div className="loader-wrapper">{loader} </div> : loader} </>
}
