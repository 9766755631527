import { Contstraints } from "./constraints"
import { Geometry } from "./geometry"
import { GeometrySize } from "./size"

export class ChildLayoutHelper {
  static dryLayoutChild(child: Geometry, constraints: Contstraints): GeometrySize {
    return child.getDryLayout(constraints)
  }

  static layoutChild(child: Geometry, constraints: Contstraints): GeometrySize {
    child.layout(constraints)
    return child.size
  }
}
