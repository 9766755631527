import { useState } from "react"
import { Button, NumberInput, Group } from "@mantine/core"
import { Icon } from "model/widgetProperty/type"
import IconManager from "components/iconManager"
import ProjectColor from "components/builder/ProjectColor"

interface IIconSelectProps {
  value: Icon
  onChange: () => void
  label: string
}

const IconSelectInput = ({ value, onChange }: IIconSelectProps): JSX.Element => {
  const [isManagerOpened, toggleManager] = useState(false)

  const sizeOnChange = (size?: number): void => {
    if (size) {
      value.setSize(size)
      onChange()
    }
  }

  return (
    <div style={{ display: "grid", gap: 12 }}>
      <IconManager value={value} isOpened={isManagerOpened} onClose={() => toggleManager(false)} onChange={onChange} />
      <Button
        sx={{
          backgroundColor: "#6549F7",
          borderRadius: 8,
          width: "100%"
        }}
        styles={() => ({
          root: {
            "&:hover": {
              backgroundColor: "#6549F7"
            }
          }
        })}
        size="xs"
        onClick={() => toggleManager(true)}
      >
        {" "}
        {"Select icon "}{" "}
      </Button>
      <Group style={{ width: "100%" }} noWrap>
        <NumberInput
          sx={() => ({
            label: {
              color: "rgba(34, 39, 48, 0.7)",
              padding: "0 0 4px 0",
              fontWeight: "bold"
            },
            input: {
              background: "#fff",
              border: "1px solid #ddd",
              borderRadius: 8,
              color: "#000"
            },
            background: "#fff",
            color: "#000",
            // marginTop: "12px",
            width: "calc(50% - 6px)"
          })}
          hideControls
          size="xs"
          value={value.getSize()}
          label="Icon Size"
          onChange={sizeOnChange}
        />
        <ProjectColor withProjectColors label="Icon Color" onChange={onChange} value={value.getColor()} />
      </Group>
    </div>
  )
}

export default IconSelectInput
