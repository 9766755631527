import { api } from "service/api"
import { Project } from "model/project"
import { Asset } from "model/asset"

import { Color, Font } from "model/widgetProperty/type"
const Actions = {
  setLeftLayerTab:
    (tabName: string) =>
    ({ dispatch }: { dispatch: (type: { type: string; payload: string }) => void }): void => {
      dispatch({ type: "setLeftLayerTab", payload: tabName })
    },
  applyProjectAssets: async (resourceRequest: string, project: Project, callback: () => void): Promise<void> => {
    try {
      const assets = await api.getProjectAssets(resourceRequest)

      if (project) {
        assets.forEach((item) => {
          const _asset = new Asset().setProject(project).fromJSON({
            id: item.id,
            fileName: item.fileName,
            contentType: item.contentType,
            size: item.size
          })
          project.setAsset(_asset)
        })

        callback()
      }
    } catch (error) {
      throw error
    }
  },
  applyProjectFonts: async (resourceRequest: string, project: Project, callback: () => void): Promise<void> => {
    try {
      const fonts = await api.getProjectFonts(resourceRequest)
      if (project) {
        project.fontList = []
        fonts.forEach((font) => {
          const _font = new Font(project).fromJSON(JSON.parse(font.rawValue))
          project?.setFont(font.key, _font)
        })
      }
      callback()
    } catch (error) {
      throw error
    }
  },
  applyProjectColors: async (resourceRequest: string, project: Project, callback: () => void): Promise<void> => {
    try {
      const colors = await api.getSettingColorList(resourceRequest)
      if (project) {
        project.colorList = []
      }
      colors.forEach((color) => {
        const _color = new Color(project).setARGBHEX(color.value)
        project?.setColor(color.key, _color)
      })
      callback()
    } catch (error) {
      throw error
    }
  }
}
export default Actions
