import { DefaultValue, RecoilState, selector } from "recoil"
import Atoms from "service/appstate/builder/atoms"

const Selectors: { ExpandAssetItem: RecoilState<string[]> } = {
  ExpandAssetItem: selector({
    key: "ExpandAssetItem",
    get: ({ get }) => get(Atoms.ExpandedAssets),
    set: ({ get, set }, name) => {
      const list = get(Atoms.ExpandedAssets)
      const index = list.findIndex((listItem: string[] | DefaultValue) => listItem === name)
      if (~index) set(Atoms.ExpandedAssets, [...list.slice(0, index), ...list.slice(index + 1)])
      else {
        set(Atoms.ExpandedAssets, [...list, name])
      }
    }
  })
}

export default Selectors
