import { Button, Grid, Stack, Text, Textarea, TextInput, Title } from "@mantine/core"
import { useNavigate } from "react-router"
import { FormEvent, Fragment, SyntheticEvent, useEffect, useState } from "react"
import { TErrorDashboard } from "types/pages/dashboardTypes"
import useModal from "utils/useModal"

import _button from "styles/button.module.css"
import style from "styles/dashboard/HandlingProject.module.css"
import settings from "styles/builder/settings.module.css"
import { api } from "service/api"
import { useRecoilValueLoadable } from "recoil"
import { currentProject } from "service/appstate/project/atoms"
import { ModalConfirmInner } from "components/modalConfirm"

export const GeneralSettings = (): JSX.Element => {
  const navigate = useNavigate()

  const projectState = useRecoilValueLoadable(currentProject)
  const project = projectState.state === "hasValue" ? projectState.getValue() : undefined

  const [isLoading] = useState(false)

  const [error, setError] = useState<TErrorDashboard>("")
  const [fields, setFields] = useState({
    title: "",
    description: ""
  })

  const [ModalConfirm, openModalConfirm, closeModalConfirm] = useModal()

  const handleChange = (e: SyntheticEvent) => {
    const { id, value } = e.target as HTMLInputElement
    setFields((state) => ({ ...state, [id]: value }))
    if (error) setError("")
  }
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { title, description } = fields

    if (!title) return setError("Please enter title")
    if (!description) return setError("Please enter description")

    const projectId = project?.getId()

    if (!projectId) {
      return
    }

    try {
      await api.updateProject(projectId, title, description)
    } catch (err: TErrorDashboard) {
      setError(typeof err === "string" ? err : err?.message || "Cannot update")
    }
  }
  useEffect(() => {
    if (project) {
      setFields({
        title: project?.optionList["title"] as string,
        description: project?.optionList["description"] as string
      })
    }
  }, [project])

  const confirmProjectDelete = () => {
    const projectId = project?.getId()
    if (projectId) {
      api.deleteProject(projectId).then(() => {
        navigate("/d/project")
      })
    }
  }

  return (
    <div style={{ padding: "18px 24px" }}>
      <Title
        order={3}
        sx={{
          fontFamily: "Syne",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: 20
        }}
      >
        General Settings
      </Title>
      <ModalConfirm>
        <ModalConfirmInner isPending={isLoading} onConfirm={confirmProjectDelete} onClose={closeModalConfirm}>
          Are you sure you want to delete project<Text sx={{ fontSize: 20 }}>{fields.title}</Text>
        </ModalConfirmInner>
      </ModalConfirm>
      <main className={settings.content}>
        <div>
          <div className={settings.card}>
            <form onSubmit={handleSubmit}>
              {error && <div className={style.error_msg}>{error}</div>}
              <div>
                <div className={settings.form_row}>
                  <Stack
                    sx={{
                      width: "100%"
                    }}
                  >
                    <TextInput
                      sx={{
                        input: {
                          height: 48
                        },
                        label: {
                          fontSize: 16,
                          fontFamily: "Syne"
                        }
                      }}
                      radius="md"
                      id="title"
                      value={fields.title}
                      onChange={handleChange}
                      required
                      withAsterisk
                      label="Project title"
                    />
                    <Textarea
                      sx={{
                        label: {
                          fontSize: 16,
                          fontFamily: "Syne"
                        }
                      }}
                      radius="md"
                      size="md"
                      id="description"
                      value={fields.description}
                      onChange={handleChange}
                      required
                      withAsterisk
                      label="Project description"
                    />
                  </Stack>
                </div>
              </div>
              <div className={style.card_footer}>
                <div className={style.btn_group}>
                  <Fragment>
                    <Grid
                      sx={{
                        textAlign: "center",
                        justifyContent: "end"
                      }}
                    >
                      <Grid.Col span={1}>
                        <Button color="grape" onClick={openModalConfirm} disabled={isLoading}>
                          Delete
                        </Button>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Button color="violet" className={_button.btn_save} type="submit" disabled={isLoading}>
                          Save
                        </Button>
                      </Grid.Col>
                    </Grid>
                  </Fragment>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  )
}
