/* eslint-disable @typescript-eslint/ban-types */
import { IProject } from "../project"
import {
  CBoolean,
  CString,
  CNumber,
  Color,
  ProjectColor,
  Size,
  PlainSize,
  Space,
  Alignment,
  AlignmentExpanded,
  Font,
  ProjectFont,
  Icon,
  WidgetDynamicValue,
  Image,
  PrimitiveArray,
  TPrimitive
} from "./type"

export class WidgetPropertyFactory {
  static get(
    type: string,
    params: unknown,
    p?: IProject
  ):
    | CBoolean
    | CString
    | CNumber
    | Color
    | ProjectColor
    | Size
    | PlainSize
    | Space
    | Alignment
    | AlignmentExpanded
    | Font
    | ProjectFont
    | Icon
    | WidgetDynamicValue
    | Image
    | PrimitiveArray<ProjectColor>
    | undefined {
    switch (type) {
      case "Boolean":
        return new CBoolean(p).fromJSON(typeof params === "boolean" ? params : false)
      case "String":
        return new CString(p).fromJSON(typeof params === "string" ? params : "")
      case "Integer":
      case "Double":
        return new CNumber(p).fromJSON(typeof params === "number" ? params : 0)
      case "Color":
        return new Color(p).fromJSON(typeof params == "string" && params ? params : "")
      case "ProjectColor":
        return new ProjectColor(p).fromJSON(typeof params == "string" && params ? params : "")
      case "Size":
        return new Size(p).fromJSON(typeof params == "string" && params ? params : "")
      case "PlainSize":
        return new PlainSize(p).fromJSON(typeof params == "string" && params ? params : "")
      case "Space":
        return new Space(p).fromJSON(typeof params == "string" && params ? params : "")
      case "Alignment":
        return new Alignment(p).fromJSON(typeof params == "string" && params ? params : "")
      case "AlignmentExpanded":
        return new AlignmentExpanded(p).fromJSON(typeof params == "string" && params ? params : "")
      case "Font":
        return new Font(p).fromJSON(typeof params == "object" && params ? (params as Record<string, unknown>) : {})
      case "ProjectFont":
        return new ProjectFont(p).fromJSON(
          typeof params == "object" && params ? (params as Record<string, unknown>) : {}
        )
      case "Icon":
        return new Icon(p).fromJSON(typeof params == "object" && params ? (params as Record<string, unknown>) : {})
      case "WidgetDynamicValue":
        return new WidgetDynamicValue(p).fromJSON(
          (typeof params == "string" || typeof params == "object") && params
            ? (params as string | Record<string, unknown>)
            : ""
        )
      case "Image":
        return new Image(p).fromJSON(typeof params == "object" && params ? (params as Record<string, unknown>) : {})
      case "ProjectColor[]":
        return new PrimitiveArray<ProjectColor>(ProjectColor, p).fromJSON(
          Array.isArray(params) && params ? (params as TPrimitive[]) : []
        )
    }
    return
  }
}
