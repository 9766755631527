import { fabric } from "fabric"
import { ExtendedPageWidget } from "./types"

export interface IWidgetProps {
  pageWidget: ExtendedPageWidget
  ctx: fabric.Canvas
}
export const Hightlight = fabric.util.createClass(fabric.Rect, {
  elementName: "Element",
  stateProperties: fabric.Rect?.prototype?.stateProperties?.concat("elementName"),
  cacheProperties: fabric.Rect?.prototype?.stateProperties?.concat("elementName"),
  initialize: function (options: IWidgetProps) {
    this.callSuper("initialize", options)
  },
  _render: function (ctx: CanvasRenderingContext2D) {
    const rx = this.rx ? Math.min(this.rx, this.width / 2) : 0,
      ry = this.ry ? Math.min(this.ry, this.height / 2) : 0,
      w = this.width,
      h = this.height,
      x = -this.width / 2,
      y = -this.height / 2,
      isRounded = rx !== 0 || ry !== 0,
      /* "magic number" for bezier approximations of arcs (http://itc.ktu.lt/itc354/Riskus354.pdf) */
      k = 1 - 0.5522847498

    ctx.beginPath()

    ctx.moveTo(x + rx, y)

    ctx.lineTo(x + w - rx, y)
    isRounded && ctx.bezierCurveTo(x + w - k * rx, y, x + w, y + k * ry, x + w, y + ry)

    ctx.lineTo(x + w, y + h - ry)
    isRounded && ctx.bezierCurveTo(x + w, y + h - k * ry, x + w - k * rx, y + h, x + w - rx, y + h)

    ctx.lineTo(x + rx, y + h)
    isRounded && ctx.bezierCurveTo(x + k * rx, y + h, x, y + h - k * ry, x, y + h - ry)

    ctx.lineTo(x, y + ry)
    isRounded && ctx.bezierCurveTo(x, y + k * ry, x + k * rx, y, x + rx, y)

    ctx.closePath()
    this._renderPaintInOrder(ctx)

    const fontSize = 10,
      text = this.elementName
    ctx.save()

    ctx.fillStyle = this.stroke

    ctx.fillRect(x, y, 8, 8)
    ctx.fillRect(x + w - 8, y, 8, 8)
    ctx.fillRect(x, y + h - 8, 8, 8)
    ctx.fillRect(x + w - 8, y + h - 8, 8, 8)

    ctx.font = "normal 10px Arial"
    const textWidth = ctx.measureText(text).width

    ctx.fillRect(x, y, textWidth + 8, fontSize + 4)

    ctx.fillStyle = "#fff"
    ctx.fillText(text, x + 3, y + fontSize)

    ctx.restore()
  }
})
