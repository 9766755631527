import { TPrimitive, PrimitiveSerializable, IDefault, IPrimitiveSerializable, IReset } from "./primitive"
import { WidgetDynamicValueSourceEnum } from "../enum"

export interface IWidgetDynamicValue extends IDefault, IPrimitiveSerializable, IReset {
  get(): string | Record<string, unknown>
  set(v: string): this
  render(): string
}

// TODO: implement
export class WidgetDynamicValue extends PrimitiveSerializable implements IWidgetDynamicValue {
  protected s: WidgetDynamicValueSourceEnum = WidgetDynamicValueSourceEnum.static
  protected v?: string = undefined

  reset(): this {
    this.s = WidgetDynamicValueSourceEnum.static
    this.v = undefined
    return this
  }

  isDefault(): boolean {
    return this.s === WidgetDynamicValueSourceEnum.static && !this.v
  }

  get(): string | Record<string, unknown> {
    if (this.s === WidgetDynamicValueSourceEnum.static && this.v) {
      return "" + this.v
    }
    return ""
  }

  set(v: string): this {
    this.s = WidgetDynamicValueSourceEnum.static
    this.v = v
    return this
  }

  render(): string {
    if (this.s === WidgetDynamicValueSourceEnum.static && this.v) {
      return "" + this.v
    }
    return ""
  }

  fromJSON(v: TPrimitive): this {
    if (typeof v === "string") {
      this.s = WidgetDynamicValueSourceEnum.static
      this.v = v
    }
    return this
  }
  toJSON(): TPrimitive {
    if (this.s === WidgetDynamicValueSourceEnum.static && this.v) {
      return "" + this.v
    }
    return null
  }
}
