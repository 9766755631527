import { useParams } from "react-router"

import CenterBar from "pages/builder/toolbar/centerbar"
import RightBar from "pages/builder/toolbar/rightbar"
import LeftBar from "pages/builder/toolbar/leftbar"

import styles from "styles/builder/toolbar.module.css"

const ToolBar = (): JSX.Element => {
  const params = useParams()

  const { projectId } = params

  return (
    <div className={styles.toolbar}>
      <LeftBar />
      <CenterBar>toolbar {projectId}</CenterBar>
      <RightBar />
    </div>
  )
}

export default ToolBar
