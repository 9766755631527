import { TAuthType, IApiType, TClient, TError, TObserverFun } from "types"
import Amplify, { Auth } from "aws-amplify"
import { ProjectsPromiseClient } from "service/pb/project_grpc_web_pb"
import ProjectsPromiseMessages from "service/pb/project_pb"
import { IdeResourcePromiseClient } from "service/pb/ide-resource_grpc_web_pb"
import IdeResoursePromiseMessages from "service/pb/ide-resource_pb"
import { IdePromiseClient } from "service/pb/ide_grpc_web_pb"
import IdePromiseMessages from "service/pb/ide_pb"
import { PubSubsClient } from "service/pb/pubsub_grpc_web_pb"
import PubSubMessage from "service/pb/pubsub_pb"

import { IdeWidget, IdeFont, IdeIcon } from "service/pb/ide-resource_pb"
import { Project } from "service/pb/project_pb"
import { ClientReadableStream } from "grpc-web"
import { IdeEntityInfo } from "service/pb/ide_pb"
import { JobIdResponse, OrderType } from "./pb/common_pb"
import EventEmitter from "utils/eventEmitter"
import { Empty } from "google-protobuf/google/protobuf/empty_pb"

interface IServiceProp<Client, Message> {
  client: {
    new (
      hostname: string,
      credentials?: null | { [index: string]: string },
      options?: null | { [index: string]: unknown }
    ): Client
  }
  message: Message
}

interface IServicesListProps {
  IdeResourseService: IServiceProp<IdeResourcePromiseClient, typeof IdeResoursePromiseMessages>
  ProjectService: IServiceProp<ProjectsPromiseClient, typeof ProjectsPromiseMessages>
  PubSubService: IServiceProp<PubSubsClient, typeof PubSubMessage>
  IdeService: IServiceProp<IdePromiseClient, typeof IdePromiseMessages>
}

interface Service<Client, Messages> {
  client: Client
  message: Messages
}

class EventObserver {
  private observers: TObserverFun[]

  constructor() {
    this.observers = []
  }
  subscribe(fn: TObserverFun) {
    if (!this.observers.some((obj) => obj === fn)) this.observers.push(fn)
  }
  unsubscribe(fn: TObserverFun) {
    this.observers = this.observers.filter((obj) => obj !== fn)
  }

  broadcast(...rest: TError) {
    this.observers.forEach((fn) => fn(...rest))
  }
}

class API {
  host: string
  aws: {
    Auth: TAuthType
  }

  observer: EventObserver
  gRPCServices: {
    [name: string]: { client: void; message: string }
  }

  ideResourseService: Service<IdeResourcePromiseClient, typeof IdeResoursePromiseMessages>
  projectService: Service<ProjectsPromiseClient, typeof ProjectsPromiseMessages>
  pubSubService: Service<PubSubsClient, typeof PubSubMessage>
  ideService: Service<IdePromiseClient, typeof IdePromiseMessages>

  accountSteam?: ClientReadableStream<PubSubMessage.PubSubResponse>

  projectStream: { projectId: string; stream?: ClientReadableStream<PubSubMessage.PubSubResponse> } = {
    projectId: ""
  }

  projectBranchStream: {
    projectId: string
    branch: string
    stream?: ClientReadableStream<PubSubMessage.PubSubResponse>
  } = {
    projectId: "",
    branch: ""
  }

  constructor({ host, aws }: IApiType, serviceList: IServicesListProps) {
    this.host = host
    this.aws = aws
    this.observer = new EventObserver()
    this.gRPCServices = {}

    this.ideResourseService = {
      client: new serviceList.IdeResourseService.client(this.host),
      message: serviceList.IdeResourseService.message
    }

    this.projectService = {
      client: new serviceList.ProjectService.client(this.host),
      message: serviceList.ProjectService.message
    }

    this.pubSubService = {
      client: new serviceList.PubSubService.client(this.host),
      message: serviceList.PubSubService.message
    }

    this.ideService = {
      client: new serviceList.IdeService.client(this.host),
      message: serviceList.IdeService.message
    }

    setInterval(this.refreshStreams.bind(this), 20000)
  }

  async refreshStreams(): Promise<void> {
    if (this.accountSteam) {
      this.accountSteam.cancel()
      this.createAccountStream()
    }

    if (this.projectStream?.stream) {
      const { projectId, stream } = this.projectStream
      stream.cancel()
      this.createProjectStream(projectId)
    }

    if (this.projectBranchStream?.stream) {
      const { projectId, branch, stream } = this.projectBranchStream
      stream.cancel()
      this.createProjectBranchStream(projectId, branch)
    }
  }

  async createProjectBranchStream(projectId: string, branchId: string): Promise<void> {
    const { client, message } = this.pubSubService

    const token = await this.getToken()
    const request = new message.PubSubProjectBranchRequest()

    request.setProjectId(projectId)
    request.setBranchId(branchId)

    try {
      const stream = await client.pubSubProjectBranchSubscribe(request, { Authorization: `Bearer ${token}` })

      stream
        .on("data", (response) => {
          const data = response.toObject()
          if (data.status === 1) EventEmitter.trigger("streamProjectBranch/data", data)

          if (data.status === 2) EventEmitter.trigger("streamProjectBranch/data/error", data)
        })
        .on("error", (err) => {
          throw err
        })

      this.projectBranchStream = {
        projectId: projectId,
        branch: branchId,
        stream
      }
    } catch (err) {
      throw err
    }
  }

  cancelProjectBranchStream(): void {
    if (this.projectBranchStream.stream) {
      this.projectBranchStream.stream.cancel()
      delete this.projectBranchStream.stream
    }
  }

  async createProjectStream(projectId: string): Promise<ClientReadableStream<PubSubMessage.PubSubResponse>> {
    let stream
    try {
      const { client, message } = this.pubSubService

      const token = await this.getToken()
      const request = new message.PubSubProjectRequest()
      request.setProjectId(projectId)

      stream = await client.pubSubProjectSubscribe(request, { Authorization: `Bearer ${token}` })

      stream
        .on("data", (response) => {
          const data = response.toObject()
          if (data.status === 1) EventEmitter.trigger("streamProject/data", data)
        })
        .on("error", (err) => {
          throw err
        })

      this.projectStream = { projectId, stream }
    } catch (err) {
      throw err
    }

    return stream
  }

  cancelProjectStream(): void {
    if (this.projectStream.stream) {
      this.projectStream.stream.cancel()
      delete this.projectStream.stream
    }
  }

  async createAccountStream(): Promise<ClientReadableStream<PubSubMessage.PubSubResponse>> {
    const { client } = this.pubSubService

    try {
      const token = await this.getToken()
      const request = new Empty()
      const stream = await client.pubSubAccountSubscribe(request, { Authorization: `Bearer ${token}` })
      stream
        .on("data", (response) => {
          const data = response.toObject()
          if (data.status === 1) EventEmitter.trigger("streamAccount/data", data)
        })
        .on("error", (err) => {
          throw err
        })

      this.accountSteam = stream
      return Promise.resolve(stream)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  cancelAccountStream(): void {
    if (this.accountSteam) {
      this.accountSteam.cancel()
      delete this.accountSteam
    }
  }

  async startIde(resource: string): Promise<void> {
    const { client, message } = this.ideService
    const token = await this.getToken()

    const request = new message.IdResourceRequest()
    request.setResource(resource)

    try {
      await client.startSync(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }
  }

  async leaveIde(resource: string): Promise<void> {
    const { client, message } = this.ideService
    const token = await this.getToken()

    const request = new message.IdResourceRequest()
    request.setResource(resource)

    try {
      await client.leave(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }
  }

  async getPagesList(resource: string): Promise<IdeEntityInfo.AsObject[]> {
    const { client, message } = this.ideService
    const token = await this.getToken()
    const request = new message.IdResourceRequest()
    request.setResource(resource)

    let response

    try {
      response = await client.getPageList(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }

    return response.toObject().entityList
  }

  async getPageData(resource: string, id: string): Promise<IdePromiseMessages.IdeEntityData.AsObject> {
    const { client, message } = this.ideService
    const token = await this.getToken()
    const request = new message.IdResourceRequest()
    request.setResource(resource)
    request.setId(id)

    let response

    try {
      response = await client.getPageData(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }

    return response.toObject()
  }

  async createPage(resource: string, title: string): Promise<JobIdResponse> {
    const { client, message } = this.ideService
    const token = await this.getToken()
    const request = new message.CreateIdeEntityRequest()
    request.setResource(resource)
    request.setTitle(title)

    let response

    try {
      response = await client.createPage(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }

    return response
  }

  async deletePage(resource: string, id: string): Promise<JobIdResponse> {
    const { client, message } = this.ideService
    const token = await this.getToken()
    const request = new message.IdResourceRequest()
    request.setResource(resource)
    request.setId(id)

    let response

    try {
      response = await client.deletePage(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }

    return response
  }

  async getProjectFonts(resource: string): Promise<IdePromiseMessages.IdeSettingFont.AsObject[]> {
    const { client, message } = this.ideService
    const token = await this.getToken()
    const request = new message.ResourceRequest()
    request.setResource(resource)

    let response

    try {
      response = await client.getSettingFontList(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }

    return response.toObject().fontList
  }

  async setSettingFont(resource: string, key: string, color: string): Promise<JobIdResponse> {
    const { client, message } = this.ideService
    const token = await this.getToken()
    const request = new message.SetIdeEntityDataRequest()
    request.setResource(resource)
    request.setData(color)
    request.setId(key)
    let response

    try {
      response = await client.setSettingFont(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }

    return response
  }

  async getSettingColorList(resource: string): Promise<IdePromiseMessages.IdeSettingColor.AsObject[]> {
    const { client, message } = this.ideService
    const token = await this.getToken()
    const request = new message.ResourceRequest()
    request.setResource(resource)

    let response

    try {
      response = await client.getSettingColorList(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }

    return response.toObject().colorList
  }

  async setSettingColor(resource: string, key: string, color: string): Promise<JobIdResponse> {
    const { client, message } = this.ideService
    const token = await this.getToken()
    const request = new message.SetIdeEntityDataRequest()
    request.setResource(resource)
    request.setData(color)
    request.setId(key)
    let response

    try {
      response = await client.setSettingColor(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }

    return response
  }

  async deleteColorSetting(resource: string, key: string): Promise<JobIdResponse> {
    const { client, message } = this.ideService
    const token = await this.getToken()
    const request = new message.IdResourceRequest()
    request.setResource(resource)
    request.setId(key)
    let response

    try {
      response = await client.deleteSettingColor(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }

    return response
  }

  async getProjectAssets(resource: string): Promise<IdePromiseMessages.IdeAssetInfo.AsObject[]> {
    const { client, message } = this.ideService
    const token = await this.getToken()
    const request = new message.ResourceRequest()
    request.setResource(resource)

    let response

    try {
      response = await client.getAssetList(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }

    return response.toObject().assetList
  }

  async getAssetUploadUrl(resource: string, file: File): Promise<IdePromiseMessages.GetIdeAssetUploadUrlResponse> {
    const { client, message } = this.ideService
    const token = await this.getToken()
    const request = new message.GetIdeAssetUploadUrlRequest()
    request.setResource(resource)
    request.setFileName(file.name)
    request.setContentType(file.type)

    let response

    try {
      response = await client.getAssetUploadUrl(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }

    return response
  }

  async createAsset(resource: string, hash: string): Promise<JobIdResponse> {
    const { client, message } = this.ideService
    const token = await this.getToken()
    const request = new message.CreateIdeAssetRequest()
    request.setResource(resource)
    request.setHash(hash)

    let response

    try {
      response = await client.createAsset(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }

    return response
  }

  async deleteAsset(resource: string, id: string): Promise<JobIdResponse> {
    const { client, message } = this.ideService
    const token = await this.getToken()
    const request = new message.IdResourceRequest()
    request.setResource(resource)
    request.setId(id)

    let response

    try {
      response = await client.deleteAsset(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }

    return response
  }

  async createComponent(resource: string, title: string): Promise<JobIdResponse> {
    const { client, message } = this.ideService
    const token = await this.getToken()
    const request = new message.CreateIdeEntityRequest()
    request.setResource(resource)
    request.setTitle(title)
    let response

    try {
      response = await client.createComponent(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }

    return response
  }

  async updatePageInfo(resource: string, id: string, title: string): Promise<JobIdResponse> {
    const { client, message } = this.ideService
    const token = await this.getToken()
    const request = new message.UpdateIdeEntityInfoRequest()
    request.setResource(resource)
    request.setId(id)
    request.setTitle(title)

    let response

    try {
      response = await client.updatePageInfo(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }

    return response
  }

  async setPageData(resource: string, id: string, data: string): Promise<JobIdResponse> {
    const { client, message } = this.ideService
    const token = await this.getToken()
    const request = new message.SetIdeEntityDataRequest()
    request.setResource(resource)
    request.setId(id)
    request.setData(data)

    let response

    try {
      response = await client.setPageData(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }

    return response
  }

  async getWidgetList(): Promise<IdeWidget[]> {
    const { client, message } = this.ideResourseService
    const token = await this.getToken()
    const request = new message.GetIdeWidgetListResponse()

    let response

    try {
      response = await client.getWidgetList(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }

    return response.getWidgetList()
  }

  async getFontsList(version: string): Promise<IdeFont.AsObject[]> {
    const { client, message } = this.ideResourseService
    const token = await this.getToken()
    const request = new message.GetIdeFontListResponse()
    request.setVersion(version)
    let response

    try {
      response = await client.getFontList(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }

    return response.toObject().fontList
  }

  async getIconsList(version: string): Promise<IdeIcon.AsObject[]> {
    const { client, message } = this.ideResourseService
    const token = await this.getToken()
    const request = new message.GetIdeIconListResponse()
    request.setVersion(version)
    let response

    try {
      response = await client.getIconList(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }

    return response.toObject().iconList
  }

  async getProject(id: string): Promise<Project> {
    const { client, message } = this.projectService
    const token = await this.getToken()
    const request = new message.Project()
    request.setId(id)
    let response

    try {
      response = await client.getProject(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      throw err
    }

    return response
  }

  async getProjectList(page = 1, search?: string): Promise<ProjectsPromiseMessages.ListProjectsResponse.AsObject> {
    const { client, message } = this.projectService
    try {
      const request = new message.ListProjectsRequest()
      request.setPage(page)

      if (search) {
        const requestFilter = new message.ListProjectsRequest.Filter()
        requestFilter.setSearch(search)
        request.setFilter(requestFilter)
      }

      const requestOrder = new message.ListProjectsRequest.Order()
      requestOrder.setLatestActivityAt(OrderType.ORDER_TYPE_DESC)
      request.setOrder(requestOrder)

      const token = await this.getToken()
      const response = await client.listProjects(request, { Authorization: `Bearer ${token}` })

      return Promise.resolve(response.toObject())
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async createProject(title: string, description?: string): Promise<JobIdResponse.AsObject> {
    const { client, message } = this.projectService

    try {
      const token = await this.getToken()
      const request = new message.CreateProjectRequest()
      request.setTitle(title)

      if (description) request.setDescription(description)
      const response = await client.createProjectAsync(request, { Authorization: `Bearer ${token}` })

      return Promise.resolve(response.toObject())
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async updateProject(id: string, title: string, description: string): Promise<ProjectsPromiseMessages.Project> {
    const { client, message } = this.projectService

    try {
      const token = await this.getToken()
      const request = new message.UpdateProjectRequest()
      request.setId(id)
      request.setTitle(title)
      if (description) request.setDescription(description)
      const response = await client.updateProject(request, { Authorization: `Bearer ${token}` })
      return response
    } catch (err) {
      throw err
    }
  }

  async deleteProject(id: string): Promise<void> {
    const { client, message } = this.projectService

    try {
      const token = await this.getToken()
      const request = new message.Project()
      request.setId(id)

      await client.deleteProject(request, { Authorization: `Bearer ${token}` })
    } catch (err) {
      //
    }
  }

  async getToken(): Promise<string | undefined> {
    try {
      const session = await this.aws.Auth.currentSession()
      return session.getIdToken().getJwtToken()
    } catch (err) {
      // continue regardless of error
    }
  }

  subscribeResponse(fn: TObserverFun): void {
    this.observer.subscribe(fn)
  }
  unsubscribeResponse(fn: TObserverFun): void {
    this.observer.unsubscribe(fn)
  }
  addgRPCService(name: string, Client: TClient, message: string): void {
    if (!(name in this.gRPCServices)) {
      this.gRPCServices[name] = {
        client: this.proxygRPCService(Client),
        message
      }
    }
  }
  proxygRPCService(Client: TClient): void {
    /* eslint "@typescript-eslint/no-this-alias": 0 */
    const self = this
    return new Proxy(new Client(this.host), {
      get: function (target, name) {
        if (name in target.__proto__) {
          return async (...args: string[]): Promise<string | undefined> => {
            try {
              const response = await target[name].apply(target, [
                ...args,
                {
                  Authorization: `Bearer ${await self.getToken()}`
                }
              ])
              return Promise.resolve(response)
            } catch (err) {
              self.observer.broadcast(err)

              return Promise.reject(err)
            }
          }
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line prefer-rest-params
          return Reflect.get(...arguments)
        }
      }
    })
  }
  getgRPCService(name: string): { client: void; message: string } {
    return this.gRPCServices[name]
  }
}

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USERPOOL_WEBCLIENT_ID
  }
})

export const api = new API(
  { host: process.env.REACT_APP_HOST_BACKEND, aws: { Auth } },
  {
    IdeResourseService: {
      client: IdeResourcePromiseClient,
      message: IdeResoursePromiseMessages
    },
    ProjectService: {
      client: ProjectsPromiseClient,
      message: ProjectsPromiseMessages
    },
    PubSubService: {
      client: PubSubsClient,
      message: PubSubMessage
    },
    IdeService: {
      client: IdePromiseClient,
      message: IdePromiseMessages
    }
  }
)

export default API
