import { PrimitiveArray, ProjectColor } from "model/widgetProperty/type"
import ProjectColorInput from "../ProjectColor"
import { Button, Grid } from "@mantine/core"
import { ReactNode, useEffect } from "react"
import { IconTrash } from "@tabler/icons"

interface IProjectColorProps {
  value: PrimitiveArray<ProjectColor>
  label: string
  onChange: () => void
}
const GradientInput = ({ value, label, onChange }: IProjectColorProps): JSX.Element => {
  useEffect(() => {
    if (!value.length()) {
      value.add()
      value.add()
    }
  }, [value])

  const addColor = () => {
    value.add()
    onChange()
  }

  const deleteColor = (i: number) => {
    value.delete(i)
    onChange()
  }

  const getItems = () => {
    const reactNodes: ReactNode[] = []

    value.forEach((item, index) =>
      reactNodes.push(
        <Grid mt="md" ml={3} mb="sx" key={index}>
          <ProjectColorInput label={label} onChange={onChange} value={item.get()} />
          <Button
            mt={28}
            mb={10}
            ml={20}
            styles={{
              root: {
                padding: 0,
                "&:hover": {
                  backgroundColor: "#F7F5FF"
                },
                "span:first-of-type": {
                  margin: 0
                }
              }
            }}
            variant="subtle"
            leftIcon={<IconTrash color="red" />}
            onClick={() => deleteColor(index)}
          />
        </Grid>
      )
    )

    return reactNodes
  }

  return (
    <>
      <Button
        mt={20}
        px={5}
        styles={{
          root: {
            color: "#6549F7",
            "&:hover": {
              backgroundColor: "#F7F5FF"
            }
          }
        }}
        variant="subtle"
        onClick={() => addColor()}
      >
        Add color
      </Button>
      {getItems().map((item) => item)}
    </>
  )
}

export default GradientInput
