const _fontsList = [
  {
    provider: "fa",
    weight: "400",
    type: "regular"
  },
  {
    provider: "fa",
    weight: "400",
    type: "brands"
  },
  {
    provider: "fa",
    weight: "900",
    type: "solid"
  },
  {
    provider: "material",
    weight: "400",
    type: "base"
  },
  {
    provider: "material",
    weight: "400",
    type: "outline"
  },
  {
    provider: "material",
    weight: "400",
    type: "round"
  },
  {
    provider: "material",
    weight: "400",
    type: "sharp"
  },
  {
    provider: "material",
    weight: "400",
    type: "twotone"
  }
]

const url = process.env.REACT_APP_STATIC_ASSEST_URL

export const loadIconFonts = async (version: string): Promise<void> => {
  for (const iconFont of _fontsList) {
    try {
      const fontName = iconFont.provider === "awesome" ? "fa" : iconFont.provider
      const _font = new FontFace(
        fontName + "-" + iconFont.type,
        `url(${url}/ide/res/${version}/font/${iconFont.provider}/icon/${iconFont.weight}-${iconFont.type}.woff2)`,
        {
          weight: iconFont.weight
        }
      )

      document.fonts.add(_font)

      await _font.load()
    } catch (err) {}
  }
}
