import { TextInputProps } from "@mantine/core/lib/TextInput/TextInput"
import { TextInput } from "@mantine/core"
import { memo } from "react"

const CString = memo(({ value, label, onChange, width = "50%" }: TextInputProps): JSX.Element => {
  return (
    <div style={{ width }}>
      <TextInput
        sx={() => ({
          label: {
            color: "rgba(34, 39, 48, 0.7)",
            fontWeight: "bold"
          },
          input: {
            background: "#fff",
            border: "1px solid #ddd",
            borderRadius: 8,
            color: "#000"
          },
          background: "#fff",
          // marginTop: "12px",
          color: "#000"
        })}
        // mr="xs"
        size="xs"
        value={value}
        label={label}
        onChange={onChange}
      />
    </div>
  )
})

export default CString
