import React, { memo, useState, useEffect, useContext, Key } from "react"
import { useRecoilState } from "recoil"
import { AppStateContext } from "service/appstate/"
import AssetsSearch from "pages/builder/leftpanel/Components/AssetsSearch"
import ScrollContainer from "pages/builder/leftpanel/Components/ScrollContainer"
import styles from "styles/builder/leftpanel.module.css"
import list from "styles/builder/list.module.css"
import { TCacheListType, TEventIsExpand, TExpandAssetType, TGenerateItemType } from "types/pages/tabTypes"
import { TAssetContext, TAssetList } from "types/pages/builderTypes"
import { Project } from "model/project"
import {
  IconArrowBarUp,
  IconCaretDown,
  IconCaretRight,
  IconChevronDown,
  IconChevronRight,
  IconGridDots,
  IconLayoutDistributeHorizontal,
  IconLayoutDistributeVertical,
  IconLetterT,
  IconList,
  IconRectangle,
  IconServer,
  IconSettings,
  IconSlideshow,
  IconSquare
} from "@tabler/icons"
import { Button } from "@mantine/core"

const Assets = (props: { project: Project; setLeftTopTab: (value: string) => void }) => {
  const {
    appAtoms: { builderAtoms },
    appSelectors: { builderSelectors }
  } = useContext<TAssetContext>(AppStateContext)

  const [expandAssetItem, setExpandAssetItem]: TExpandAssetType = useRecoilState(builderSelectors.ExpandAssetItem)

  const [listView, setListView] = useRecoilState<boolean>(builderAtoms.listView)

  const [assetsList, setAssetsList]: TAssetList = useState([
    {
      name: "Predefined Widgets",
      children: [
        {
          name: "Layout Elements",
          children: []
        },
        {
          name: "Base Elements",
          children: []
        }
      ]
    },
    {
      name: "Predefined Components",
      children: []
    },
    {
      name: "Custom Components",
      children: []
    }
  ])
  const [cachedList, setCachedList]: TCacheListType = useState([])
  const [filterKeyWord, setFilterKeyword] = useState("")

  const handleFiltering = (keyword: string) => {
    setFilterKeyword(keyword)
  }

  const toggleListView = () => {
    setListView(!listView)
  }

  const generateItemsList = (
    list:
      | TGenerateItemType
      | unknown
      | {
          isExpanded?: boolean
          name: string
          children: { name: string; children: never[] }[]
        }[]
  ) => {
    const stack = [...list]
    const res = []
    while (stack.length) {
      const next = stack.shift()
      if (next.children && Array.isArray(next.children)) {
        next.isExpanded = !!~expandAssetItem.indexOf(next.name)
        if (next.isExpanded && next.children.length) {
          stack.unshift(
            ...next.children.map((e: TEventIsExpand) => ({
              ...e,
              parent: next
            }))
          )
        }
      }
      res.push(next)
    }
    return res
  }

  useEffect(() => {
    if (!filterKeyWord) {
      setCachedList(generateItemsList(assetsList))
      return
    }
    const assetsExpand: TExpandAssetType = []
    const filteredAssetsList = assetsList.map((asset: TAssetList) => {
      asset.children.length && assetsExpand.push(asset.name)
      return {
        ...asset,
        children: asset.children.map((child: TAssetList) => {
          child.children.length && assetsExpand.push(child.name)
          return {
            ...child,
            children: child.children.filter((item: TAssetList) => {
              return item.name.toLowerCase().includes(filterKeyWord.toLowerCase())
            })
          }
        })
      }
    })
    !expandAssetItem.length &&
      assetsExpand.map((item: TExpandAssetType) => {
        setExpandAssetItem(item)
      })
    setCachedList(generateItemsList(filteredAssetsList))
    // eslint-disable-next-line
  }, [filterKeyWord, assetsList, expandAssetItem])

  // Fill assets list (ListWidgets)
  useEffect(() => {
    const widgetsInProject = props.project.getWidgetList()
    if (widgetsInProject.length) {
      const stack = [...assetsList]
      for (const obj of widgetsInProject) {
        if (obj.tag?.indexOf("layout") !== -1) {
          stack[0].children[0].children.push({ name: obj.type })
        } else if (obj.tag?.indexOf("base") !== -1) {
          stack[0].children[1].children.push({ name: obj.type })
        }
      }
      setAssetsList(stack)
    }
    // eslint-disable-next-line
  }, [])

  const handleDrag = (ev: React.DragEvent<HTMLDivElement>): void => {
    const el = ev.target as HTMLDivElement
    const dataset = el.dataset
    const dragdata = {
      from: "widget",
      type: dataset.type
    }
    ev.dataTransfer.setData("text/plain", JSON.stringify(dragdata))
    ev.dataTransfer.setDragImage(el.getElementsByTagName("span")[0], el.offsetWidth / 2, el.offsetHeight / 2)
    setTimeout(() => {
      props.setLeftTopTab("layers")
    }, 500)
  }

  return (
    <div className={styles.assetsContainer}>
      <AssetsSearch listView={listView} toggleListView={toggleListView} handleFiltering={handleFiltering} />
      <ScrollContainer style={{ position: "absolute", height: "auto", inset: 0, top: "41px", marginTop: "10px" }}>
        <div className={styles.contentContainer} style={{ padding: "10px 14px" }}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {cachedList.map((node: { isExpanded: boolean; name: string }, index: Key) => {
              if ("isExpanded" in node) {
                return (
                  <div
                    key={index}
                    className={[list.itemRow, node.isExpanded ? list.expandCaretOpen : list.expandCaretOpenHover]
                      .filter((e) => !!e)
                      .join(" ")}
                    data-header={"parent" in node ? "h3" : "h1"}
                    onClick={() => setExpandAssetItem(node.name)}
                    draggable="false"
                  >
                    <span>
                      {(() => {
                        if ("parent" in node) {
                          if (node.isExpanded) {
                            return <IconCaretDown fill="#000" />
                          } else {
                            return <IconCaretRight fill="#000" />
                          }
                        } else {
                          if (node.isExpanded) {
                            return <IconChevronDown />
                          } else {
                            return <IconChevronRight />
                          }
                        }
                      })()}
                    </span>
                    <header>{node.name}</header>
                  </div>
                )
              }
              return (
                <div
                  key={index}
                  className={!listView ? list.itemRow : list.itemRowGrid}
                  data-item={"h3"}
                  onDragStart={handleDrag}
                  draggable="true"
                  data-type={node.name}
                >
                  <span>
                    {(() => {
                      if (node.name === "GridView") {
                        return <IconGridDots color="gray" />
                      } else if (node.name === "Container") {
                        return <IconSquare color="gray" />
                      } else if (node.name === "ListView") {
                        return <IconList color="gray" />
                      } else if (node.name === "Column") {
                        return <IconLayoutDistributeVertical color="gray" />
                      } else if (node.name === "Row") {
                        return <IconLayoutDistributeHorizontal color="gray" />
                      } else if (node.name === "Stack") {
                        return <IconServer color="gray" />
                      } else if (node.name === "Icon") {
                        return <IconSettings color="gray" />
                      } else if (node.name === "Image") {
                        return <IconSlideshow color="gray" />
                      } else if (node.name === "Text") {
                        return <IconLetterT color="gray" />
                      } else if (node.name === "Button") {
                        return <IconRectangle color="gray" />
                      }
                    })()}
                  </span>
                  <header style={{ width: "100%" }}>{node.name}</header>
                </div>
              )
            })}
            <Button
              style={{
                backgroundColor: "#6549F7",
                borderRadius: 8,
                height: 44,
                fontSize: 12,
                width: 160,
                margin: "10px auto"
              }}
              leftIcon={<IconArrowBarUp />}
              onClick={() => props.setLeftTopTab("images")}
            >
              Upload image
            </Button>
          </div>
        </div>
      </ScrollContainer>
    </div>
  )
}
export default memo(Assets)
