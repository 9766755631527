import styles from "styles/builder/toolbar.module.css"
import { IconCode } from "@tabler/icons"

const RightBar = (): JSX.Element => {
  const onClick = (e: never) => e
  return (
    <div className={styles.sideButtonGroup}>
      <div className={styles.userIconView}>
        <img src={"https://i.pravatar.cc/100"} alt="Current user" className={styles.userIconAvatar} />
        Current user
      </div>
      <div className={styles.textButtonContainer}>
        <button className={styles.textButton} onClick={onClick}>
          <IconCode size={20} /> Get code
        </button>
      </div>
    </div>
  )
}

export default RightBar
