import { Title, Text, Button, Loader } from "@mantine/core"
import modal from "styles/modal.module.css"
import settings from "styles/builder/settings.module.css"
import { IconArrowNarrowRight } from "@tabler/icons"

interface IPropsModalConfirm {
  children: React.ReactNode
  isPending: boolean
  onConfirm: () => void
  onClose: () => void
}

export const ModalConfirmInner = ({
  children,
  isPending = false,
  onConfirm,
  onClose
}: IPropsModalConfirm): JSX.Element => {
  return (
    <div className={settings.delete_form}>
      <div className={modal.close_modal} onClick={onClose}>
        <i className="fas fa-times" />
      </div>
      <Title
        mb="lg"
        sx={{
          fontFamily: "Zen Dots",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 24
        }}
      >
        Please Confirm
      </Title>
      <Text
        sx={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 12,
          lineHeight: 2,
          textAlign: "center",
          color: "#697786"
        }}
        mb="lg"
        mx={70}
      >
        {children}
      </Text>
      <div className={modal.input_group}>
        <Button
          sx={{
            height: 48,
            borderRadius: 12
          }}
          color="violet"
          onClick={onConfirm}
          mb={40}
          disabled={isPending}
          leftIcon={isPending ? <Loader color="white" size="sm" /> : <IconArrowNarrowRight />}
          fullWidth
        >
          Confirm
        </Button>
      </div>
    </div>
  )
}
