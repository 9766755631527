/**
 * @fileoverview gRPC-Web generated client stub for jupiter.builder.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.jupiter = {};
proto.jupiter.builder = {};
proto.jupiter.builder.v1 = require('./pubsub_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.jupiter.builder.v1.PubSubsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.jupiter.builder.v1.PubSubsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.PubSubProjectBranchRequest,
 *   !proto.jupiter.builder.v1.PubSubResponse>}
 */
const methodDescriptor_PubSubs_PubSubProjectBranchSubscribe = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.PubSubs/PubSubProjectBranchSubscribe',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.jupiter.builder.v1.PubSubProjectBranchRequest,
  proto.jupiter.builder.v1.PubSubResponse,
  /**
   * @param {!proto.jupiter.builder.v1.PubSubProjectBranchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.PubSubResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.PubSubProjectBranchRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.PubSubResponse>}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.PubSubsClient.prototype.pubSubProjectBranchSubscribe =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/jupiter.builder.v1.PubSubs/PubSubProjectBranchSubscribe',
      request,
      metadata || {},
      methodDescriptor_PubSubs_PubSubProjectBranchSubscribe);
};


/**
 * @param {!proto.jupiter.builder.v1.PubSubProjectBranchRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.PubSubResponse>}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.PubSubsPromiseClient.prototype.pubSubProjectBranchSubscribe =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/jupiter.builder.v1.PubSubs/PubSubProjectBranchSubscribe',
      request,
      metadata || {},
      methodDescriptor_PubSubs_PubSubProjectBranchSubscribe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.PubSubProjectRequest,
 *   !proto.jupiter.builder.v1.PubSubResponse>}
 */
const methodDescriptor_PubSubs_PubSubProjectSubscribe = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.PubSubs/PubSubProjectSubscribe',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.jupiter.builder.v1.PubSubProjectRequest,
  proto.jupiter.builder.v1.PubSubResponse,
  /**
   * @param {!proto.jupiter.builder.v1.PubSubProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.PubSubResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.PubSubProjectRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.PubSubResponse>}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.PubSubsClient.prototype.pubSubProjectSubscribe =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/jupiter.builder.v1.PubSubs/PubSubProjectSubscribe',
      request,
      metadata || {},
      methodDescriptor_PubSubs_PubSubProjectSubscribe);
};


/**
 * @param {!proto.jupiter.builder.v1.PubSubProjectRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.PubSubResponse>}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.PubSubsPromiseClient.prototype.pubSubProjectSubscribe =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/jupiter.builder.v1.PubSubs/PubSubProjectSubscribe',
      request,
      metadata || {},
      methodDescriptor_PubSubs_PubSubProjectSubscribe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.jupiter.builder.v1.PubSubResponse>}
 */
const methodDescriptor_PubSubs_PubSubAccountSubscribe = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.PubSubs/PubSubAccountSubscribe',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.jupiter.builder.v1.PubSubResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.PubSubResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.PubSubResponse>}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.PubSubsClient.prototype.pubSubAccountSubscribe =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/jupiter.builder.v1.PubSubs/PubSubAccountSubscribe',
      request,
      metadata || {},
      methodDescriptor_PubSubs_PubSubAccountSubscribe);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.PubSubResponse>}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.PubSubsPromiseClient.prototype.pubSubAccountSubscribe =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/jupiter.builder.v1.PubSubs/PubSubAccountSubscribe',
      request,
      metadata || {},
      methodDescriptor_PubSubs_PubSubAccountSubscribe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.jupiter.builder.v1.PubSubRequest,
 *   !proto.jupiter.builder.v1.PubSubResponse>}
 */
const methodDescriptor_PubSubs_PubSub = new grpc.web.MethodDescriptor(
  '/jupiter.builder.v1.PubSubs/PubSub',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.jupiter.builder.v1.PubSubRequest,
  proto.jupiter.builder.v1.PubSubResponse,
  /**
   * @param {!proto.jupiter.builder.v1.PubSubRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.jupiter.builder.v1.PubSubResponse.deserializeBinary
);


/**
 * @param {!proto.jupiter.builder.v1.PubSubRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.PubSubResponse>}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.PubSubsClient.prototype.pubSub =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/jupiter.builder.v1.PubSubs/PubSub',
      request,
      metadata || {},
      methodDescriptor_PubSubs_PubSub);
};


/**
 * @param {!proto.jupiter.builder.v1.PubSubRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.jupiter.builder.v1.PubSubResponse>}
 *     The XHR Node Readable Stream
 */
proto.jupiter.builder.v1.PubSubsPromiseClient.prototype.pubSub =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/jupiter.builder.v1.PubSubs/PubSub',
      request,
      metadata || {},
      methodDescriptor_PubSubs_PubSub);
};


module.exports = proto.jupiter.builder.v1;

