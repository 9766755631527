import { useEffect, useState } from "react"
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil"
import ToolBar from "pages/builder/toolbar/"
import CenterPanel from "pages/builder/centerpanel/"
import RightPanel from "pages/builder/rightpanel/"
import LeftPanel from "pages/builder/leftpanel/"
import { AssetManager } from "./assets"
import VerticalEdge from "pages/builder/Components/verticalEdge"
import styles from "styles/builder/style.module.css"
import { Page } from "model/page"
import {
  currentPage,
  ideStatusAtom,
  pagesListSelector,
  resourceRequestAtom,
  iconList,
  fontsList,
  currentProject,
  projectForceUpdaterAtom
} from "service/appstate/project/atoms"
import IdeActions from "service/appstate/application/actions"
import { selectedMainTabAtom, selectedPageId } from "service/appstate/builder/atoms"
import { ENodeEventList } from "model/entityEvent"
import { Settings } from "./settings"
import BuilderActions from "service/appstate/builder/actions"
import EventEmitter from "utils/eventEmitter"
import { IPubSubResponse } from "types/pubsub/pubsubTypes"
import { useAssetUploadObserver } from "utils/useAssetUploadObserver"

interface IdeProps {
  readyCb: () => void
  isLoaded: boolean
}

const Ide = ({ readyCb, isLoaded }: IdeProps): JSX.Element => {
  const setCurrentPageId = useSetRecoilState<string>(selectedPageId)
  const selectedMainTab = useRecoilValue(selectedMainTabAtom)

  const pagesListState = useRecoilValueLoadable(pagesListSelector)

  const pagesList = pagesListState.state === "hasValue" ? pagesListState.getValue() : undefined

  const [ideStatus, setIdeStatus] = useRecoilState(ideStatusAtom)
  const resourceRequest = useRecoilValue(resourceRequestAtom)
  const [pageShouldBeUpdate, togglePageDataUpdate] = useState<Page | null>(null)
  const pageState = useRecoilValueLoadable(currentPage)
  const iconListState = useRecoilValueLoadable(iconList)
  const fontListState = useRecoilValueLoadable(fontsList)
  const projectState = useRecoilValueLoadable(currentProject)
  const projectForceEmitter = useSetRecoilState(projectForceUpdaterAtom)

  const project = projectState.state === "hasValue" ? projectState.getValue() : undefined

  const page = pageState.state === "hasValue" ? pageState.getValue() : undefined

  useAssetUploadObserver(resourceRequest, project)

  const isIdeReady = () => {
    const fontsReady = fontListState.state === "hasValue",
      iconReady = iconListState.state === "hasValue",
      pageListReady = pagesListState.state === "hasValue",
      pageStateReady = pageState.state === "hasValue"

    if (iconReady && fontsReady && pageListReady && pageStateReady) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    const pubSubCb = (data: IPubSubResponse) => {
      const { entity, event } = data

      if (!project) {
        return
      }

      if (entity === 7 && event === 1) {
        BuilderActions.applyProjectColors(resourceRequest, project, () => projectForceEmitter((val) => val + 1))
        BuilderActions.applyProjectFonts(resourceRequest, project, () => projectForceEmitter((val) => val + 1))
      }
    }

    const onBeforeOnLoad = async () => {
      IdeActions.leaveIde(resourceRequest, setIdeStatus)
      if (pageShouldBeUpdate?.id) {
        await IdeActions.setPageData(resourceRequest, pageShouldBeUpdate.id, pageShouldBeUpdate)
      }
    }

    window.addEventListener("beforeunload", onBeforeOnLoad)
    EventEmitter.on("streamProjectBranch/data", pubSubCb)

    return () => {
      window.removeEventListener("beforeunload", onBeforeOnLoad)
      EventEmitter.off("streamProjectBranch/data", pubSubCb)
    }
  }, [])

  useEffect(() => {
    if (isIdeReady() && ideStatus) {
      readyCb()
    }
  }, [pagesListState, pageState, ideStatus, fontListState, iconListState])

  useEffect(() => {
    const interval = setInterval(() => {
      if (pageShouldBeUpdate?.id) {
        IdeActions.setPageData(resourceRequest, pageShouldBeUpdate.id, pageShouldBeUpdate).then(() => {
          togglePageDataUpdate(null)
        })
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [pageShouldBeUpdate])

  useEffect(() => {
    async function ideStart() {
      await IdeActions.startIde(resourceRequest, setIdeStatus)
    }

    if (!ideStatus && resourceRequest) {
      ideStart()
    }

    async function ideLeave() {
      IdeActions.leaveIde(resourceRequest, setIdeStatus)
    }

    return () => {
      if (ideStatus) {
        ideLeave()
      }

      setCurrentPageId("")
    }
  }, [ideStatus, resourceRequest])

  useEffect(() => {
    if (ideStatus && pagesList && pagesList.length > 0 && pagesList[0].id) {
      setCurrentPageId(pagesList[0].id)
    }
  }, [pagesList, ideStatus])

  useEffect(() => {
    if (resourceRequest && ideStatus && project) {
      BuilderActions.applyProjectColors(resourceRequest, project, () => projectForceEmitter((val) => val + 1))
      BuilderActions.applyProjectFonts(resourceRequest, project, () => projectForceEmitter((val) => val + 1))
      BuilderActions.applyProjectAssets(resourceRequest, project, () => projectForceEmitter((val) => val + 1))
    }
  }, [resourceRequest, ideStatus, project])

  useEffect(() => {
    const updatePageDataCb = () => {
      if (page?.data) {
        togglePageDataUpdate(page)
      }
    }

    if (page?.data) {
      page.data.unsetSelected()
      page.data.e?.on(ENodeEventList.NodeAdded, updatePageDataCb)
      page.data.e?.on(ENodeEventList.NodePropertyUpdated, updatePageDataCb)
      page.data.e?.on(ENodeEventList.NodeDeleted, updatePageDataCb)
    }

    return () => {
      if (page?.data) {
        page.data.e?.off(ENodeEventList.NodeAdded, updatePageDataCb)
        page.data.e?.off(ENodeEventList.NodePropertyUpdated, updatePageDataCb)
        page.data.e?.off(ENodeEventList.NodeDeleted, updatePageDataCb)
      }
    }
  }, [page])

  const renderChildren = () => {
    switch (selectedMainTab) {
      case "settings": {
        return <Settings />
      }
      case "images": {
        return <AssetManager />
      }
    }

    return (
      <>
        <CenterPanel />
        <RightPanel />
      </>
    )
  }

  return (
    <>
      {isLoaded ? (
        <div className={styles.wrapper}>
          <ToolBar />
          <VerticalEdge>
            <LeftPanel />
            {renderChildren()}
          </VerticalEdge>
        </div>
      ) : null}
    </>
  )
}

export default Ide
