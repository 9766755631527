import { FC, useEffect, useState } from "react"
import { Screen } from "./screen"
import { DeviceListSelector } from "./device"
import { ZoomController } from "./zoom"
import "./wysiwyg.css"
import Logger, { ILogger } from "js-logger"
import { PageData } from "model/page"
import Iphone11 from "./assets/frames/iphone_11.png"
import Ioskeyboard from "./assets/frames/ios_keyboard.png"
import { Button, Grid } from "@mantine/core"
import { IconDeviceMobile, IconKeyboard, IconMoon, IconSun } from "@tabler/icons"
import { IdeIcon } from "service/pb/ide-resource_pb"
import { useToggle } from "@mantine/hooks"

const deviceList = [
  {
    type: "iphone13",
    title: "iPhone 13",
    width: 390,
    height: 844,
    topSafeSpace: 47,
    bottomSafeSpace: 34,
    keyboardHeight: 336,
    keyboardImage: Ioskeyboard
  },
  {
    type: "iphone13pro",
    title: "iPhone 13 Pro",
    width: 390,
    height: 844,
    topSafeSpace: 47,
    bottomSafeSpace: 34,
    keyboardHeight: 336,
    keyboardImage: Ioskeyboard
  },
  {
    type: "iphone13mini",
    title: "iPhone 13 Mini",
    width: 375,
    height: 812,
    topSafeSpace: 50,
    bottomSafeSpace: 34,
    keyboardHeight: 336,
    keyboardImage: Ioskeyboard
  },
  {
    type: "iphone13promax",
    title: "iPhone 13 Pro Max",
    width: 428,
    height: 926,
    topSafeSpace: 47,
    bottomSafeSpace: 34,
    keyboardHeight: 346,
    keyboardImage: Ioskeyboard
  }
]

interface WysiwygProps {
  logger?: ILogger
  zoomValue?: 1
  deviceType?: string
  data: PageData
  iconsList: IdeIcon.AsObject[]
}

export const Wysiwyg: FC<WysiwygProps> = ({ data, iconsList }) => {
  const [icon, toggle] = useToggle()
  const [zoom, setZoom] = useState(100)
  const [device, setDevice] = useState<string>("")
  const [screen, setScreen] = useState<Screen | null>(null)
  const [isKeyboardVisible, toggleKeyboard] = useState(false)
  const [isFrameVisible, toggleFrame] = useState(false)

  const onZoomSet = (v: number): void => {
    setZoom(() => {
      screen?.setZoom(v / 100)
      return v
    })
  }

  const onDeviceSelected = (v: string): void => {
    const device = deviceList.find((item) => item.type === v)

    setDevice(() => {
      if (device) {
        screen?.setDevice(device)
      }

      return v
    })
  }

  const onKeyabordToggled = (): void => {
    toggleKeyboard((v) => {
      screen?.setKeyboardVisible(!v)

      return !v
    })
  }

  useEffect(() => {
    const _screen = new Screen(
      "screen",
      {
        canvasOptions: {
          width: 414,
          height: 896,
          backgroundColor: "white",
          allowTouchScrolling: true
        },
        logger: Logger.get("wysiwyg:screen")
      },
      iconsList
    )

    setScreen(_screen)
  }, [])

  useEffect(() => {
    if (data && screen) {
      screen.lastSelectedEl = undefined
      screen.cleanup()
      screen.init(data)
    }
  }, [screen, data])

  const activeDevice = deviceList.find((item) => item.type === device)

  return (
    <div className="wysiwyg">
      <Grid
        justify="space-between"
        align="center"
        sx={{
          padding: "15px 20px",
          width: "100%",
          margin: 0
        }}
      >
        <Grid.Col span={4}>
          <ZoomController min={40} max={200} value={zoom} observer={onZoomSet} />
        </Grid.Col>
        <Grid.Col
          span={3}
          sx={{
            textAlignLast: "center"
          }}
        >
          {/*<SizeController data={[]} />*/}
        </Grid.Col>
        <Grid.Col
          span={5}
          sx={{
            textAlign: "end"
          }}
        >
          <Button
            variant={icon ? "white" : "filled"}
            styles={{
              root: {
                borderRadius: 10,
                padding: "0 12px",
                height: 46
              }
            }}
            color="dark"
            onClick={() => toggle()}
          >
            {icon ? <IconSun size={25} /> : <IconMoon size={25} />}
          </Button>
          <Button
            variant="filled"
            styles={{
              root: {
                borderRadius: 10,
                height: 46,
                padding: "0 12px"
              }
            }}
            ml="md"
            color="dark"
            onClick={() => onKeyabordToggled()}
          >
            <IconKeyboard size={25} />
          </Button>
          <Button
            variant="filled"
            sx={{
              height: 46,
              borderRadius: "10px 0 0 10px",
              padding: "0 10px"
            }}
            ml="md"
            color="dark"
            onClick={() => toggleFrame(!isFrameVisible)}
          >
            <IconDeviceMobile size={25} />
          </Button>
          <DeviceListSelector list={deviceList} observer={onDeviceSelected} value={device} />
        </Grid.Col>
      </Grid>
      <div className="screen-wrapper">
        <div className="frame-wrapper">
          <canvas id="screen" />
          {isFrameVisible ? <img className="device-frame" src={Iphone11} alt="" /> : null}
          {isKeyboardVisible ? (
            <img
              style={{
                height: activeDevice?.keyboardHeight ? activeDevice?.keyboardHeight * (zoom / 100) : 0
              }}
              className="device-keyboard"
              src={Ioskeyboard}
              alt=""
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}
