import { fabric } from "fabric"
import { Icon } from "model/widgetProperty/type"
import { IdeIcon } from "service/pb/ide-resource_pb"
import { TWidgetOptions } from "./types"

export interface IIconWidgetProps {
  icon?: Icon
}

export const IconWidget = fabric.util.createClass(fabric.Object, {
  type: "Icon",
  initialize: function (options: TWidgetOptions) {
    if (!options?.pageWidget) {
      throw new Error("Page widget is required option")
    }

    const { pageWidget, ctx, geometry, iconsList } = options

    this.id = pageWidget.id
    this.fabricParent = ctx.getObjects().find((item) => {
      return item.id === pageWidget.parent?.id
    })

    this.strokeWidth = 0

    this.set({
      left: geometry.offset.x,
      top: geometry.offset.y,
      width: geometry.size.width,
      height: geometry.size.height,
      pageWidget,
      iconsList
    })

    this.originY = "top"
    this.originX = "left"
    this.callSuper("initialize", options)
  },
  _render: function (ctx: CanvasRenderingContext2D) {
    const pageWidget = this.pageWidget
    const property = pageWidget.property as IIconWidgetProps

    const geometry = this.geometry

    const provider = property.icon?.getProvider()
    const iconKey = property.icon?.getKey()

    const iconsList: IdeIcon.AsObject[] = this.iconsList || []

    if (!provider || !iconKey) {
      return this.set({ text: "" })
    }

    const iconVal = iconsList.find((item) => item.key === iconKey)

    if (!iconVal) {
      return this.set({ text: "" })
    }

    this.set({
      height: geometry.size.height,
      width: geometry.size.width,
      top: geometry.offset.y,
      left: geometry.offset.x
    })

    ctx.save()

    ctx.textBaseline = "bottom"
    ctx.font = `${property.icon?.getSize() || 16}px ${provider.replace(":", "-")}`
    ctx.fillStyle = property.icon?.getColor().get().getRGBHEX() || "#000"
    ctx.fillText(String.fromCodePoint(iconVal.codepoint), -this.width / 2, this.height / 2, 500)

    ctx.restore()
  }
})
