import "reflect-metadata"
import { jsonProperty, jsonIgnore, Serializable } from "ts-serializable"
import { IProject } from "./project"

export class Asset extends Serializable {
  @jsonProperty(String)
  id = ""
  @jsonProperty(String)
  fileName = ""
  @jsonProperty(String)
  contentType = ""
  @jsonProperty(Number)
  size = 0
  @jsonProperty([String])
  readonly tagList: string[] = []
  @jsonProperty(Date)
  readonly createdAt?: Date = undefined
  @jsonIgnore()
  private project?: IProject

  setProject(p: IProject): this {
    this.project = p
    return this
  }

  getCDNUrl(type?: string | undefined): string {
    return `${this.project?.getCDNBaseURL()}/asset/${this.project?.getId()}/${this.id}/${type || "thumbnail"}/${
      this.fileName
    }`
  }
  getOriginCDNUrl(): string {
    return this.getCDNUrl("origin")
  }
  getThumbnailCDNUrl(): string {
    return this.getCDNUrl("thumbnail")
  }
}
