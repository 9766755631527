import { fabric } from "fabric"
import { AlignmentExpanded, CString, ProjectFont, Space, WidgetDynamicValue } from "model/widgetProperty/type"
import { WidgetParameter } from "model/pageWidget"
import { TWidgetOptions } from "./types"

export interface ITextWidgetProps {
  padding?: Space
  alignment?: AlignmentExpanded
  value?: WidgetParameter
  font?: ProjectFont
  textAlignment?: CString
  text?: WidgetDynamicValue
}

export const TextWidget = fabric.util.createClass(fabric.Textbox, {
  type: "Text",
  initialize: function (options: TWidgetOptions) {
    if (!options?.pageWidget) {
      throw new Error("Page widget is required option")
    }

    const { pageWidget, ctx, geometry } = options

    this.id = pageWidget.id
    this.pageWidget = pageWidget

    this.fabricParent = ctx.getObjects().find((item) => {
      return item.id === pageWidget.parent?.id
    })

    this.geometry = geometry

    const property = pageWidget.property as ITextWidgetProps
    const text = property?.text?.render() || ""
    const font = property.font?.get()

    this.set({
      text: text,
      width: this.geometry.size.width,
      height: this.geometry.size.height,
      left: this.geometry.offset.x,
      top: this.geometry.offset.y,
      fontSize: font?.getSize() || 16,
      fontFamily: font?.getFamily() || "Roboto",
      fontStyle: font?.getStyle() || "normal",
      fontWeight: font?.getWeight() || "400"
    })

    this.fill = font?.getColor()?.get()?.getRGBAHEX() || "black"

    this.textAlign = property.textAlignment?.get() || "left"

    this.callSuper("initialize", this.text, options)
  },
  _render: function (ctx: CanvasRenderingContext2D) {
    const property = this.pageWidget.property as ITextWidgetProps
    const text = property?.text?.render() || ""
    const font = property.font?.get()

    const geometry = this.geometry

    this.fill = font?.getColor()?.get()?.getRGBAHEX() || "black"

    this.textAlign = property.textAlignment?.get() || "left"

    this.fontSize = font?.getSize() || 16
    this.fontFamily = font?.getFamily() || "Roboto"
    this.fontStyle = font?.getStyle() || "normal"
    this.fontWeight = font?.getWeight() || "400"

    this.text = text
    this.width = geometry.size.width
    this.height = geometry.size.height
    this.top = geometry.offset.y
    this.left = geometry.offset.x

    this.callSuper("_render", ctx)
  },
  _wrapLine: function (_line: string) {
    let lineWidth = 0,
      wordWidth = 0,
      lineJustStarted = true

    const graphemeLines: string[][] = [[]],
      words = _line.split(" "),
      infix = " "

    for (let i = 0; i < words.length; i++) {
      const word = words[i]

      if (this.geometry.ctx) {
        const ctx = this.geometry.ctx
        if (i + 1 === words.length) {
          wordWidth = ctx.measureText(word).width
        } else {
          wordWidth = ctx.measureText(word + " ").width
        }
      } else {
        wordWidth = 0
      }

      lineWidth += wordWidth

      if (lineWidth > this.geometry.size.width && !lineJustStarted) {
        graphemeLines.push([] as string[])
        lineWidth = wordWidth
        lineJustStarted = true
      }

      if (!lineJustStarted) {
        graphemeLines[graphemeLines.length - 1].push(infix)
      }

      graphemeLines[graphemeLines.length - 1].push(word)

      lineJustStarted = false
    }

    return graphemeLines
  },
  calcTextHeight: function () {
    return this.height
  }
})
