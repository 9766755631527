export type TBuilderStateType = {
  leftLayerTab: string
}
const Reduser: (state: TBuilderStateType, action: { type: string; payload: string }) => { leftLayerTab: string } = (
  state: TBuilderStateType,
  action: { type: string; payload: string }
) => {
  switch (action.type) {
    case "setLeftLayerTab":
      return {
        ...state,
        leftLayerTab: action.payload
      }
    default:
      throw new Error()
  }
}
export default Reduser
