import "reflect-metadata"
import { jsonProperty, SerializationSettings } from "ts-serializable"

import { PageWidget } from "./pageWidget"
import { Entity, EntityData } from "./entity"

export class PageData extends EntityData {
  @jsonProperty(Boolean)
  isSafeArea?: boolean = undefined

  @jsonProperty(PageWidget)
  appBarChild?: PageWidget = undefined

  @jsonProperty(PageWidget)
  floatingActionButtonChild?: PageWidget = undefined

  @jsonProperty(PageWidget)
  drawerChild?: PageWidget = undefined

  @jsonProperty(PageWidget)
  endDrawerChild?: PageWidget = undefined

  @jsonProperty(PageWidget)
  child?: PageWidget = undefined

  fromJSON(json: Record<string, unknown>, settings?: Partial<SerializationSettings> | undefined): this {
    // TODO has to fix it in ts-serializable???
    // cleanup old values = set default
    this.isSafeArea = undefined
    this.appBarChild = undefined
    this.floatingActionButtonChild = undefined
    this.drawerChild = undefined
    this.endDrawerChild = undefined
    this.child = undefined
    this.children = undefined

    return super.fromJSON(json, settings)
  }
}

export class Page extends Entity {
  @jsonProperty(PageData)
  data: PageData = new PageData()
}
