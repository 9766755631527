import { TAsyncAct } from "types/utils/actionsType"

type ActionFun = (value: Action) => void

type Action = {
  [key: string]: ActionFun | Action | TAsyncAct
}

export type TActions = {
  // eslint-disable-next-line
  [key: string]: (...value: /*TInitServiceProps[] | string[]*/ any) => any
}

const AsyncActions = (props: Action, Actions: TActions): TActions =>
  Object.fromEntries(
    Object.entries(Actions).map(([key, value]) => {
      const proxyDispatch = (action: Action | ActionFun) => {
        if (typeof action === "function") {
          return action(props)
        }
        const handle = props?.dispatch as ActionFun
        return handle(action)
      }
      return [key, (...data) => proxyDispatch(value(...data))]
    })
  )

export default AsyncActions
