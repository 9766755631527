import { EventEmitterClass } from "utils/eventEmitter"
import { TEventType, TMouseRef, TRefType } from "types/utils/actionsType"

class MouseHandle extends EventEmitterClass {
  static EVENTS = {
    START: "start",
    UPDATE: "update",
    END: "end"
  }
  private $ref: TRefType

  constructor(ref: TMouseRef) {
    super()
    this.$ref = ref
    this.init()
  }
  init(): void {
    this.$ref.addEventListener("mousedown", this, true)
  }
  destroy(): void {
    this.$ref.removeEventListener("mousedown", this, true)
    this.$ref = null
  }
  handleEvent(e: TEventType): void {
    switch (e.type) {
      case "mousedown":
        if (e.target !== this.$ref) return
        if (e.button || e.ctrlKey) return
        this._registerEvents()
        this.trigger(MouseHandle.EVENTS.START, e)
        break
      case "mousemove":
        // e.preventDefault()
        // e.stopPropagation()
        this.trigger(MouseHandle.EVENTS.UPDATE, e)

        break
      case "mouseup":
        this.trigger(MouseHandle.EVENTS.END, e, this.$ref)
        this._unregisterEvents()
        break
      default:
    }
  }
  _registerEvents(): void {
    document.addEventListener("mousemove", this)
    document.addEventListener("mouseup", this)
  }
  _unregisterEvents(): void {
    document.removeEventListener("mousemove", this)
    document.removeEventListener("mouseup", this)
  }
}

export default MouseHandle
