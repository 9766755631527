import { TPrimitive, PrimitiveSerializable, IDefault, IPrimitiveSerializable, IReset } from "./primitive"
import { IProject } from "../../project"
import { IProjectColor, ProjectColor } from "./color"

export interface IIcon extends IDefault, IPrimitiveSerializable, IReset {
  getProvider(): string | undefined
  setProvider(provider: string): this
  getKey(): string | undefined
  setKey(key: string): this
  getSize(): number | undefined
  setSize(size: number): this
  getColor(): IProjectColor | undefined
}

export class Icon extends PrimitiveSerializable implements IIcon {
  protected provider?: string = undefined
  protected key?: string = undefined
  protected size?: number = undefined
  protected color: IProjectColor

  constructor(p?: IProject) {
    super(p)
    this.color = new ProjectColor(this.p)
  }

  reset(): this {
    this.provider = undefined
    this.key = undefined
    this.size = undefined
    this.color = new ProjectColor(this.p)
    return this
  }

  isDefault(): boolean {
    return (!this.color || this.color.isDefault()) && !this.provider && !this.key && !this.size
  }

  getProvider(): string | undefined {
    return this.provider
  }

  setProvider(provider: string): this {
    this.provider = provider
    return this
  }

  getKey(): string | undefined {
    return this.key
  }

  setKey(key: string): this {
    this.key = key
    return this
  }

  getSize(): number | undefined {
    return this.size
  }

  setSize(size: number): this {
    this.size = size > 0 ? size : 0
    return this
  }

  getColor(): IProjectColor {
    return this.color
  }

  fromJSON(v: TPrimitive): this {
    const data = v as Record<string, TPrimitive>
    if (data["p"]) {
      this.setProvider(data["p"] as string)
    }
    if (data["k"]) {
      this.setKey(data["k"] as string)
    }
    if (data["z"]) {
      this.setSize(data["z"] as number)
    }
    if (data["c"]) {
      this.color = new ProjectColor(this.p).fromJSON(data["c"])
    }
    return this
  }
  toJSON(): TPrimitive {
    const data: Record<string, TPrimitive> = {}
    if (this.provider) {
      data["p"] = this.provider
    }
    if (this.key) {
      data["k"] = this.key
    }
    if (this.size) {
      data["z"] = this.size
    }
    if (this.color) {
      data["c"] = this.color.toJSON() as TPrimitive
    }
    return data
  }
}
