import { Button, Title, Text, Divider } from "@mantine/core"
import { Color, IColor, IFont } from "model/widgetProperty/type"
import { useRecoilState, useRecoilValue } from "recoil"
import { currentProject, projectForceUpdaterAtom, resourceRequestAtom } from "service/appstate/project/atoms"
import styles from "styles/builder/settings.module.css"
import ProjectColor from "components/builder/ProjectColor"
import { api } from "service/api"
import FontSelectInput from "components/fontInput"
import { IconTrash } from "@tabler/icons"
import { transformCammelCase } from "utils/string"

interface IColorItemProps {
  label: string
  value: IColor
  onChange: (key: string, color: IColor) => void
  onDelete: (key: string) => void
  isMain: boolean
}

const ColorItem = ({ label, value, onChange, onDelete, isMain }: IColorItemProps) => {
  const onColorChange = () => {
    onChange(label, value)
  }

  return (
    <div className={styles.colorItem}>
      <ProjectColor label={label} value={value} onChange={() => onColorChange()} />
      {!isMain ? (
        <Button
          ml={12}
          px={5}
          styles={{
            root: {
              "&:hover": {
                backgroundColor: "#F0EDFE"
              },
              span: {
                margin: 0
              }
            }
          }}
          variant="subtle"
          leftIcon={<IconTrash style={{ color: "#6549F7" }} />}
          onClick={() => onDelete(label)}
        />
      ) : null}
    </div>
  )
}

interface IFontsItemProps {
  label: string
  value: IFont
  onChange: (key: string, color: IFont) => void
}

const FontItem = ({ label, value, onChange }: IFontsItemProps) => {
  const onFontChange = () => {
    onChange(label, value)
  }

  return (
    <div style={{ display: "flex", alignItems: "flex-start" }}>
      <span
        style={{
          width: "200px",
          flexShrink: 0,
          flexGrow: 0,
          alignSelf: "end",
          lineHeight: "40px",
          fontSize: value.getSize(),
          fontFamily: value.getFamily(),
          fontWeight: Number(value.getWeight())
        }}
      >
        {transformCammelCase(label)}
      </span>
      <FontSelectInput font={value} onChange={onFontChange} horizontal />
    </div>
  )
}

export const ThemeSettings = (): JSX.Element => {
  const project = useRecoilValue(currentProject)
  const [, projectForceEmitter] = useRecoilState(projectForceUpdaterAtom)
  const resouceRequest = useRecoilValue(resourceRequestAtom)

  const colors = project?.colorList || [],
    fonts = project?.fontList || []

  const addColor = () => {
    if (!project) {
      return
    }

    const randomColor = Math.floor(Math.random() * 16777215).toString(16)
    const color = new Color(project).setRGBHEX(randomColor)

    const key = `customColor${colors.length + 1}`
    project.setColor(key, color)
    projectForceEmitter((val) => val + 1)

    api.setSettingColor(resouceRequest, key, color.getARGBHEX())
  }

  const colorOnChange = (key: string, color: IColor) => {
    projectForceEmitter((val) => val + 1)

    api.setSettingColor(resouceRequest, key, color.getARGBHEX())
  }

  const fontOnChange = (key: string, font: IFont) => {
    projectForceEmitter((val) => val + 1)

    api.setSettingFont(resouceRequest, key, JSON.stringify(font))
  }

  const deleteColor = (key: string) => {
    api.deleteColorSetting(resouceRequest, key).then(() => {
      const deletedIndex = colors.findIndex((item) => item.key === key)
      project?.colorList.splice(deletedIndex, 1)
      projectForceEmitter((val) => val + 1)
    })
  }

  return (
    <div>
      <div style={{ padding: "18px 24px" }}>
        <Title
          order={3}
          sx={{
            fontFamily: "Syne",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 20
          }}
        >
          Project Colors
        </Title>
        <Text
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 12,
            color: "#697786"
          }}
        >
          Here all color style you are using in your project are collected
        </Text>
        <div className={styles.colorsInner}>
          {colors.map((item, index) => {
            return (
              <ColorItem
                key={item.key}
                value={item.color}
                label={item.key}
                onChange={colorOnChange}
                onDelete={deleteColor}
                isMain={index <= 2}
              />
            )
          })}
        </div>
        <Button
          mt={12}
          sx={{
            borderRadius: 10,
            backgroundColor: "#6549F7",
            "&:hover": {
              backgroundColor: "#6549F7"
            }
          }}
          onClick={addColor}
        >
          Add new color
        </Button>
      </div>
      <Divider my="sm" />
      <div style={{ padding: "18px 24px", display: "grid", gap: 12 }}>
        <Title
          order={3}
          mt={24}
          sx={{
            fontFamily: "Syne",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 20
          }}
        >
          Project Typography
        </Title>
        <Text
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 12,
            color: "#697786"
          }}
        >
          Here all typography style you are using in your project are collected
        </Text>
        {fonts.map((item) => {
          return <FontItem key={item.key} value={item.font as IFont} onChange={fontOnChange} label={item.key} />
        })}
      </div>
    </div>
  )
}
