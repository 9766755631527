import { Tabs } from "@mantine/core"
import { IconPaint, IconSettings } from "@tabler/icons"
import { useRecoilState } from "recoil"
import { selectedSettingsTabAtom } from "service/appstate/builder/atoms"

export const SettingsSideBar = (): JSX.Element => {
  const [selectedTab, selectTab] = useRecoilState(selectedSettingsTabAtom)

  return (
    <Tabs
      value={selectedTab}
      styles={{
        tab: {
          "&[data-active]": {
            backgroundColor: "#F0EDFE",
            color: "#000"
          },
          "&[data-active]:hover": {
            backgroundColor: "#6549F7",
            color: "#fff"
          },
          "&:hover": {
            backgroundColor: "#6549F7",
            color: "#fff"
          }
        }
      }}
      mt={10}
      variant="pills"
      defaultValue="theme"
      onTabChange={(tab) => selectTab(tab || "theme")}
    >
      <Tabs.Tab icon={<IconPaint />} style={{ width: "95%", justifyContent: "start", borderRadius: 10 }} value="theme">
        Theme settings
      </Tabs.Tab>
      <Tabs.Tab
        icon={<IconSettings />}
        style={{ width: "95%", justifyContent: "start", borderRadius: 10 }}
        value="general"
      >
        General settings
      </Tabs.Tab>
    </Tabs>
  )
}
