import { TextWidget } from "./text"
import { ContainerWidget } from "./container"
import { ButtonWidget } from "./button"
import { ImageWidget } from "./image"
import { IconWidget } from "./icon"
import { RowWidget } from "./row"
import { ColumnWidget } from "./column"
import { GridViewWidget } from "./grid"
import { StackWidget } from "./stack"
import { TWidgetOptions } from "./types"

export class WidgetFactory {
  static get(type: string, options: TWidgetOptions): fabric.Object | undefined {
    switch (type) {
      case "Text":
        return new TextWidget(options)
      case "Button":
        return new ButtonWidget(options)
      case "Image":
        return new ImageWidget(options)
      case "Icon":
        return new IconWidget(options)
      case "Row":
        return new RowWidget(options)
      case "Column":
        return new ColumnWidget(options)
      case "GridView":
        return new GridViewWidget(options)
      case "Stack":
        return new StackWidget(options)
      case "Container":
        return new ContainerWidget(options)
    }
    return
  }
}
