import { fabric } from "fabric"
import { getImageBoxFitProperty } from "./utils"
import { CNumber, Space, Size, ProjectColor, Image, CString } from "model/widgetProperty/type"
import { BoxShapeEnum } from "model/widgetProperty/enum"
import { TWidgetOptions } from "./types"

interface IContainerWidgetProps {
  boxShape?: CString
  radius?: Size
  backgroundColor?: ProjectColor
  borderColor?: ProjectColor
  borderWidth?: CNumber
  borderRadius?: Space
  elevation?: CNumber
  shadowBlurRadius?: CNumber
  shadowSpreadRadius?: CNumber
  shadowOffsetX?: CNumber
  shadowOffsetY?: CNumber
  shadowColor?: ProjectColor
  gradientAngle?: CNumber
  gradientColorList?: ProjectColor[]
  backgroundImage?: Image
  backgroundImageBoxFit?: CString
}

export const ContainerWidget = fabric.util.createClass(fabric.Object, {
  type: "Container",
  cacheProperties: fabric.Object?.prototype?.cacheProperties?.concat("geometry"),
  stateProperties: fabric.Object?.prototype?.stateProperties?.concat("geometry"),
  initialize: function (options: TWidgetOptions) {
    if (!options?.pageWidget) {
      throw new Error("Page widget is required option")
    }

    const { pageWidget, ctx, geometry } = options

    this.id = pageWidget.id

    this.fabricParent = ctx.getObjects().find((item) => {
      return item.id === pageWidget.parent?.id
    })

    const property = pageWidget.property as IContainerWidgetProps

    this.backgroundImageBoxFit = property.backgroundImageBoxFit?.get() || "fill"

    const borderColor = property.borderColor?.get()?.getRGBAHEX()

    if (borderColor) {
      this.strokeWidth = property.borderWidth?.get() || 0
      this.stroke = borderColor
    }

    this.fill = property.backgroundColor?.get()?.getRGBAHEX()

    this.geometry = geometry

    this.set({
      width: geometry.size.width,
      height: geometry.size.height,
      left: geometry.offset.x,
      top: geometry.offset.y
    })

    if (this.fabricParent?.childClipper) {
      this.clipPath = this.fabricParent.childClipper
      this.childClipper = this.fabricParent.childClipper
    }

    if (property.shadowColor) {
      const shadowColor = property.shadowColor.get()?.getRGBAHEX()

      if (shadowColor) {
        const shadow = new fabric.Shadow({
          color: shadowColor,
          blur: property.shadowBlurRadius?.get() || 0,
          offsetX: property.shadowOffsetX?.get() || 0,
          offsetY: property.shadowOffsetY?.get() || 0
        })

        this.shadow = shadow
      }
    }

    const backgroundImage = property?.backgroundImage?.getURL()
    this.backgroundImageBoxFit = property.backgroundImageBoxFit?.get() || "fill"

    this.imageLoaded = false

    if (backgroundImage && backgroundImage !== "//") {
      fabric.util.loadImage(
        backgroundImage,
        (img) => {
          this.set("backgroundImageSrc", img)
          this.imageLoaded = true
          this.dirty = true
          //if canvas is global
          if (this.canvas) {
            this.canvas?.renderAll()
          }
        },
        {
          crossOrigin: "annonymous"
        }
      )
    }

    this.callSuper("initialize", options)
  },
  _renderRect: function (ctx: CanvasRenderingContext2D) {
    const pageWidget = this.pageWidget
    const property = pageWidget.property as IContainerWidgetProps

    if (property.borderRadius) {
      this.topLeftRadius = property.borderRadius.getTop() || 0
      this.topRightRadius = property.borderRadius.getRight() || 0
      this.bottomRightRadius = property.borderRadius.getBottom() || 0
      this.bottomLeftRadius = property.borderRadius.getLeft() || 0
    }

    if (property.borderColor) {
      this.buttonStrokeColor = property.borderColor?.get().getRGBAHEX()
    }

    this.strokeWidth = property.borderWidth?.get()

    const borderColor = property.borderColor?.get()?.getRGBAHEX()
    if (borderColor) {
      this.stroke = borderColor
    }

    const geometry = this.geometry

    this.width = geometry.size.width
    this.height = geometry.size.height
    this.top = geometry.offset.y
    this.left = geometry.offset.x

    this.fill = property.backgroundColor?.get()?.getRGBAHEX()

    if (property.shadowColor) {
      const shadowColor = property.shadowColor.get()?.getRGBAHEX()
      // const spread = property.shadowSpreadRadius?.get() || 0
      const blur = property.shadowBlurRadius?.get() || 0

      if (shadowColor) {
        const shadow = new fabric.Shadow({
          color: shadowColor,
          blur: blur,
          offsetX: property.shadowOffsetX?.get(),
          offsetY: property.shadowOffsetY?.get()
        })

        this.set({ shadow })
      }
    }

    const tl = this.topLeftRadius ? Math.min(this.topLeftRadius, this.width / 2, this.height / 2) : 0,
      tr = this.topRightRadius ? Math.min(this.topRightRadius, this.width / 2, this.height / 2) : 0,
      br = this.bottomRightRadius ? Math.min(this.bottomRightRadius, this.width / 2, this.height / 2) : 0,
      bl = this.bottomLeftRadius ? Math.min(this.bottomLeftRadius, this.width / 2, this.height / 2) : 0,
      w = this.width - this.strokeWidth,
      h = this.height - this.strokeWidth,
      x = -this.width / 2,
      y = -this.height / 2,
      /* "magic number" for bezier approximations of arcs (http://itc.ktu.lt/itc354/Riskus354.pdf) */
      k = 1 - 0.5522847498

    ctx.beginPath()

    ctx.moveTo(x + tl, y)

    ctx.lineTo(x + w - tr, y)
    ctx.bezierCurveTo(x + w - k * tr, y, x + w, y + k * tr, x + w, y + tr)

    ctx.lineTo(x + w, y + h - br)
    ctx.bezierCurveTo(x + w, y + h - k * br, x + w - k * br, y + h, x + w - br, y + h)

    ctx.lineTo(x + bl, y + h)
    ctx.bezierCurveTo(x + k * bl, y + h, x, y + h - k * bl, x, y + h - bl)

    ctx.lineTo(x, y + tl)
    ctx.bezierCurveTo(x, y + k * tl, x + k * tl, y, x + tl, y)

    ctx.closePath()

    ctx.save()
    ctx.clip()

    if (property.backgroundColor?.get()?.getRGBAHEX()) {
      ctx.fillStyle = property.backgroundColor?.get()?.getRGBAHEX()
      ctx.fill("evenodd")
    }

    ctx.restore()

    const backgroundImage = property?.backgroundImage?.getURL()
    this.backgroundImageBoxFit = property.backgroundImageBoxFit?.get() || "fill"

    if (backgroundImage && !property.backgroundImage?.isDefault()) {
      if (backgroundImage !== this.backgroundImage) {
        this.backgroundImage = backgroundImage
        this.imageLoaded = false
        fabric.util.loadImage(
          backgroundImage,
          (img) => {
            this.set("backgroundImageSrc", img)
            this.imageLoaded = true
            this.dirty = true
            if (this.canvas) {
              this.canvas?.renderAll()
            }
          },
          {
            crossOrigin: "annonymous"
          }
        )
      }
    }

    if (this.imageLoaded && this.backgroundImageSrc) {
      ctx.save()
      ctx.clip()

      const imageSizeOffset = getImageBoxFitProperty(
        this.backgroundImageSrc,
        { x, y, w, h },
        this.backgroundImageBoxFit
      )

      const { sx, sy, sw, sh, dx, dy, dw, dh } = imageSizeOffset

      ctx.drawImage(this.backgroundImageSrc, sx, sy, sw, sh, dx, dy, dw, dh)
      ctx.restore()
    }
  },
  _render: function (ctx: CanvasRenderingContext2D) {
    const pageWidget = this.pageWidget
    const property = pageWidget.property as IContainerWidgetProps

    if (property?.boxShape?.get() === BoxShapeEnum.circle) {
      this._renderCircle(ctx)
    } else {
      this._renderRect(ctx)
    }
  },
  _renderCircle: function (ctx: CanvasRenderingContext2D) {
    const pageWidget = this.pageWidget
    const property = pageWidget.property as IContainerWidgetProps

    if (property.borderWidth?.get() !== undefined && !Number.isNaN(property.borderWidth?.get())) {
      this.strokeWidth = property.borderWidth?.get()
      if (property.borderColor) {
        this.buttonStrokeColor = property.borderColor?.get().getRGBAHEX()
      }
    } else {
      this.buttonStrokeColor = "transparent"
      this.strokeWidth = 0
    }

    const borderColor = property.borderColor?.get()?.getRGBAHEX()
    if (borderColor) {
      this.stroke = borderColor
    }

    const geometry = this.geometry

    this.width = geometry.size.width
    this.height = geometry.size.height
    this.top = geometry.offset.y
    this.left = geometry.offset.x

    this.fill = property.backgroundColor?.get()?.getRGBAHEX()

    this.radius = this.width / 2 - this.strokeWidth / 2

    if (this.radius < 0) {
      this.radius = 0
    }

    this.startAngle = 0
    this.endAngle = 360
    ctx.beginPath()
    ctx.arc(0, 0, this.radius, this.startAngle, this.endAngle, false)
    ctx.closePath()

    this._renderPaintInOrder(ctx)

    if (property.shadowColor) {
      const shadowColor = property.shadowColor.get()?.getRGBAHEX()
      // const spread = property.shadowSpreadRadius?.get() || 0
      const blur = property.shadowBlurRadius?.get() || 0

      if (shadowColor) {
        const shadow = new fabric.Shadow({
          color: shadowColor,
          blur: blur,
          offsetX: property.shadowOffsetX?.get(),
          offsetY: property.shadowOffsetY?.get()
        })

        this.set({ shadow })
      }
    }

    const backgroundImage = property?.backgroundImage?.getURL()
    this.backgroundImageBoxFit = property.backgroundImageBoxFit?.get() || "fill"

    if (backgroundImage && !property.backgroundImage?.isDefault()) {
      if (backgroundImage !== this.backgroundImage) {
        this.backgroundImage = backgroundImage
        this.imageLoaded = false
        fabric.util.loadImage(
          backgroundImage,
          (img) => {
            this.set("backgroundImageSrc", img)
            this.imageLoaded = true
            this.dirty = true
            //if canvas is global
            if (this.canvas) {
              this.canvas?.renderAll()
            }
          },
          {
            crossOrigin: "annonymous"
          }
        )
      }
    }

    if (this.imageLoaded && this.backgroundImageSrc) {
      ctx.save()

      ctx.clip()

      const imageSizeOffset = getImageBoxFitProperty(
        this.backgroundImageSrc,
        { x: -this.width / 2, y: -this.height / 2, w: this.width, h: this.height },
        this.backgroundImageBoxFit
      )

      const { sx, sy, sw, sh, dx, dy, dw, dh } = imageSizeOffset

      ctx.drawImage(this.backgroundImageSrc, sx, sy, sw, sh, dx, dy, dw, dh)

      ctx.restore()
    }
  }
})
