import "reflect-metadata"
import { jsonProperty, SerializationSettings, jsonIgnore } from "ts-serializable"
import { Serializable } from "./serializable"
import mitt from "mitt"
import { ENodeEventList } from "./entityEvent"
import { Project } from "./project"
import { PageWidgetBase, PageWidget, IPageWidgetSelected, IPageWidgetHovered, IPageWidgetBuffered } from "./pageWidget"

// common entity for Page/Component/?Asset

export type TEntity = {
  id: string
  title: string
  description?: string
  tagList?: string[]
  createdAt?: Date
  updatedAt?: Date
  isLocked?: boolean
}

export class EntityData extends PageWidgetBase implements IPageWidgetSelected, IPageWidgetHovered, IPageWidgetBuffered {
  constructor() {
    super()
    this.e = mitt()
  }

  fromJSON(json: Record<string, unknown>, settings?: Partial<SerializationSettings> | undefined): this {
    //const self = this
    const o = super.fromJSON(json, settings)
    //o.fixParent()
    o.walk((node, ctx) => {
      // fix parent
      node.parent = ctx.parent || undefined
      // fix property enities
      node._fixProperty(o.getProject())
    })
    this._emitOnRoot(ENodeEventList.TreeImported, {})
    return o
  }

  // IEntityNodeSelected implementation
  @jsonIgnore()
  selected?: PageWidget

  getSelected(): PageWidget | undefined {
    return this.selected
  }

  setSelected(en: PageWidget): void {
    this.selected = en
    this._emitOnRoot(ENodeEventList.NodeSelected, { node: en })
  }

  unsetSelected(): void {
    if (this.selected) {
      const id = this.selected.id
      const parent = this.selected.parent
      delete this.selected
      this._emitOnRoot(ENodeEventList.NodeUnselected, { id: id, parent: parent })
    }
  }

  // IEntityNodeHovered implementation
  @jsonIgnore()
  hovered?: PageWidget

  getHovered(): PageWidget | undefined {
    return this.hovered
  }

  setHovered(en: PageWidget): void {
    this.hovered = en
    this._emitOnRoot(ENodeEventList.NodeOver, { node: en })
  }

  unsetHovered(): void {
    if (this.hovered) {
      const id = this.hovered.id
      const parent = this.hovered.parent
      delete this.hovered
      this._emitOnRoot(ENodeEventList.NodeOut, { id: id, parent: parent })
    }
  }

  @jsonIgnore()
  buffered?: PageWidget

  setBuffered(en: PageWidget | undefined): void {
    this.buffered = en
    this._emitOnRoot(ENodeEventList.NodeBuffered, { node: en })
  }

  getBuffered(): PageWidget | undefined {
    return this.buffered
  }

  unsetBuffered(): void {
    if (this.buffered) {
      const id = this.buffered.id
      const parent = this.buffered.parent
      delete this.buffered
      this._emitOnRoot(ENodeEventList.NodeUnBuffered, { id: id, parent: parent })
    }
  }

  @jsonIgnore()
  private project?: Project

  setProject(p: Project): this {
    this.project = p
    return this
  }

  getProject(): Project | undefined {
    return this.project
  }

  getChildKeyByType(type: string): string | undefined {
    if (!type) {
      return undefined
    }
    let key = `${type}Child`
    key = key.charAt(0).toLowerCase() + key.slice(1)
    return this.hasOwnProperty(key) ? key : undefined
  }

  // add child
  addRootChild(pw: PageWidget): void {
    const widgetInfo = this.getProject()?.getWidget(pw.type || "")
    if (widgetInfo) {
      if (widgetInfo.hasCapability("page")) {
        const key = this.getChildKeyByType(widgetInfo.type || "")
        if (key) {
          Reflect.set(this, key, pw)
          // emit event
          this._emitOnRoot(ENodeEventList.NodeAdded, { node: pw })
        }
      } else {
        this.setChild(pw)
      }
    }
  }

  addWidget(pw: PageWidget): void {
    this.addRootChild(pw)
  }

  removeSelectedNode(): boolean {
    if (this.selected) {
      this.selected.remove()
      this.unsetSelected()
      return true
    }
    return false
  }

  cutSelectedNode(): boolean {
    if (this.selected) {
      this.setBuffered(this.selected.clone())
      this.selected.remove()
      this.unsetSelected()
      return true
    }
    return false
  }

  copySelectedNode(): boolean {
    if (this.selected) {
      this.setBuffered(this.selected.clone())
      return true
    }
    return false
  }

  pastBufferedNodeBeforeSelectedNode(): boolean {
    if (this.selected?.parent?.children && this.buffered) {
      this.selected.parent.addChildBefore(this.buffered, this.selected)
      this.unsetBuffered()
      return true
    }
    return false
  }

  pastBufferedNodeIntoSelectedNode(): boolean {
    if (this.selected && this.buffered) {
      this.selected.addWidget(this.buffered)
      this.unsetBuffered()
      return true
    }
    return false
  }
}

export class Entity extends Serializable {
  @jsonProperty(String)
  id?: string = undefined

  @jsonProperty(String)
  title = ""

  @jsonProperty(String)
  description?: string = undefined

  @jsonProperty([String])
  tagList?: string[] = undefined

  @jsonProperty(Date)
  readonly createdAt?: Date = undefined

  @jsonProperty(Date)
  readonly updatedAt?: Date = undefined

  @jsonProperty(Boolean)
  isLocked?: boolean = undefined

  data: EntityData = new EntityData()

  constructor(e: TEntity) {
    super()
    this.id = e?.id
    this.title = e?.title || ""
    this.description = e?.description
    this.tagList = e?.tagList
    this.createdAt = e?.createdAt
    this.updatedAt = e?.updatedAt
    this.isLocked = e?.isLocked
  }

  static fromDataString(e: TEntity, d?: string): Entity {
    const entity = new this(e)
    try {
      if (d) {
        entity.data.fromJSON(JSON.parse(d))
      }
    } catch (e) {}
    return entity
  }

  setProject(p: Project): this {
    this.data.setProject(p)
    return this
  }

  getProject(): Project | undefined {
    return this.data.getProject()
  }
}
