import { TPrimitive, PrimitiveSerializable, IDefault, IPrimitiveSerializable, IReset } from "./primitive"

export interface IAlignment extends IDefault, IPrimitiveSerializable, IReset {
  set(x: number, y: number): this
  getX(): number | undefined
  setX(x: number): this
  getY(): number | undefined
  setY(y: number): this
}

export class Alignment extends PrimitiveSerializable implements IAlignment {
  protected x?: number = undefined
  protected y?: number = undefined

  reset(): this {
    this.x = undefined
    this.y = undefined
    return this
  }

  isDefault(): boolean {
    return !(this.x || this.y)
  }

  private _filter(v: number): number {
    return Math.min(Math.max(v, -1), 1)
  }

  set(x: number, y: number): this {
    return this.setX(x).setY(y)
  }

  getX(): number | undefined {
    return this.x
  }

  setX(x: number): this {
    this.x = this._filter(x)
    return this
  }

  getY(): number | undefined {
    return this.y
  }

  setY(y: number): this {
    this.y = this._filter(y)
    return this
  }

  fromJSON(v: TPrimitive): this {
    const data: string[] = (v as string).split(" ")
    if (data[0]) {
      this.x = parseFloat(data[0])
    }
    if (data[1]) {
      this.y = parseFloat(data[1])
    }
    return this
  }
  toJSON(): TPrimitive {
    return `${this.x || 0} ${this.y || 0}`
  }
}

export interface IAlignmentExtended extends IAlignment {
  getExtended(): boolean | undefined
  setExtended(e: boolean): IAlignmentExtended
}

export class AlignmentExpanded extends Alignment implements IAlignmentExtended {
  protected e = false

  reset(): this {
    super.reset()
    this.e = false
    return this
  }

  isDefault(): boolean {
    return super.isDefault() && !this.e
  }

  getExtended(): boolean {
    return this.e
  }

  setExtended(e: boolean): this {
    this.e = e
    return this
  }

  fromJSON(v: TPrimitive): this {
    const data: string[] = (v as string).split("/")
    if (data[0]) {
      super.fromJSON(data[0])
    }
    if (data[1]) {
      this.e = data[1] === "x"
    }
    return this
  }
  toJSON(): TPrimitive {
    return `${super.toJSON()}${this.e ? "/x" : ""}`
  }
}
