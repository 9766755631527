import { PageWidget } from "../pageWidget"

export enum DuplicateValidatorError {
  None = "",
  Unsupported = "unsupported"
}

export default class DuplicateValidator {
  static run(pw: PageWidget): DuplicateValidatorError {
    if (pw.parent?.children) {
      return DuplicateValidatorError.None
    }
    return DuplicateValidatorError.Unsupported
  }
}
