import React, { useRef, useEffect } from "react"
import MouseHandle from "utils/mouseHandle"

import styles from "styles/builder/leftpanel.module.css"
import { IResizeLineProps } from "types/pages/resizeLineType"

const ResizeLine = ({ setHeightPagesList, style }: IResizeLineProps): JSX.Element => {
  const resizeLineContainer = useRef(null)

  useEffect(() => {
    const handler = new MouseHandle(resizeLineContainer.current)

    handler
      .on(MouseHandle.EVENTS.UPDATE, ({ movementY }) => {
        setHeightPagesList((heightPagesList: number) => heightPagesList + movementY)
      })
      .on(MouseHandle.EVENTS.END, (e, ref) => {
        setHeightPagesList(
          Number(
            window
              .getComputedStyle(ref.parentElement.previousSibling.children[1], null)
              .getPropertyValue("height")
              .slice(0, -2)
          ) ?? 0
        )
      })

    return () => {
      handler.destroy()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div {...style} className={styles.resizeLineContainer}>
      <div className={styles.resizeLine} ref={resizeLineContainer} />
    </div>
  )
}
export default ResizeLine
