import { Wysiwyg } from "pages/builder/centerpanel/wysiwyg"
import { useRecoilValueLoadable } from "recoil"
import { currentPage, iconList } from "service/appstate/project/atoms"
import { memo } from "react"
import { JupiterLoader } from "components/loader"
import { NoPage } from "./noPage"

const CenterPanel = memo((): JSX.Element => {
  const iconsList = useRecoilValueLoadable(iconList)
  const pageState = useRecoilValueLoadable(currentPage)

  const renderChild = () => {
    if (pageState.state === "loading" || iconsList.state !== "hasValue") {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <JupiterLoader size="small" />{" "}
        </div>
      )
    } else if (pageState.state === "hasValue" && !pageState.contents) {
      return <NoPage />
    } else {
      return <Wysiwyg data={pageState.contents.data} iconsList={iconsList.contents} />
    }
  }

  return (
    <div
      style={{
        position: "relative",
        width: "70%",
        overflowY: "auto",
        backgroundColor: "#ccc"
      }}
    >
      {renderChild()}
    </div>
  )
})

export default CenterPanel
