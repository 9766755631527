import { PageWidget } from "model/pageWidget"
import { Contstraints } from "./constraints"
import { GeometryOffset } from "./offset"
import { GeometrySize } from "./size"
import { FlexFit } from "./types"

interface MouseEvent {
  x: number
  y: number
}

// Geometry build layout by the width-in-height-out data flow. Thats means:
// Firstly layout should be calculated with width [Constraints][tight]
// to be  and set the height constraint to be loose
export class Geometry {
  private _constraints?: Contstraints

  private _offset: GeometryOffset = {
    x: 0,
    y: 0
  }

  private _size = new GeometrySize()

  flexData?: {
    flex: number
    fit: FlexFit.tight
  }

  isPseudo = false

  pseudoChild?: Geometry
  // Pseudo elements must not have an id

  treeEntity?: PageWidget

  geometryList: Geometry[] = []

  get id(): string {
    if (this.treeEntity) {
      return this.treeEntity.id || ""
    } else {
      return ""
    }
  }

  get offset(): GeometryOffset {
    return this._offset
  }

  set offset(offset: GeometryOffset) {
    if (this._offset.x !== offset.x || this._offset.y !== offset.y) {
      this._offset = offset
      this.performLayout()
    }
  }

  get size(): GeometrySize {
    return this._size
  }

  set size(size: GeometrySize) {
    this._size = size
  }

  get constraints(): Contstraints {
    return this._constraints || new Contstraints()
  }

  set constraints(constraints: Contstraints) {
    this._constraints = constraints
  }

  get child(): Geometry | undefined {
    if (this.pseudoChild) {
      return this.pseudoChild
    } else if (this.treeEntity?.child?.id) {
      const childGeometry = this.getGeometryById(this.treeEntity.child.id)
      return childGeometry
    } else {
      return undefined
    }
  }

  get children(): Geometry[] {
    const geometryArray = []

    if (this.treeEntity?.children) {
      for (const child of this.treeEntity?.children) {
        if (child.id) {
          const childGeometry = this.getGeometryById(child.id)
          if (childGeometry) {
            geometryArray.push(childGeometry)
          }
        }
      }
    }

    return geometryArray
  }

  // remove() {
  //   this.parent?.removeChildById(this.id)
  // }

  // removeChildById(id?: string) {
  //   if (this.child) {
  //     if (this.child.id === id) {
  //       delete this.child
  //     }
  //   }

  //   if (this.children.length > 0) {
  //     const indexShouldBeDeleted = this.children.findIndex((item) => {
  //       if (item.extract().id === id) {
  //         return true
  //       } else {
  //         return false
  //       }
  //     })
  //   }
  // }

  getGeometryById(id: string): Geometry | undefined {
    const geometry = this.geometryList.find((item) => {
      const extractedItem = item.extract()
      if (extractedItem) {
        return extractedItem.id === id
      }
    })
    return geometry
  }

  isCursorInRect(pos: MouseEvent): string | undefined {
    const { x, y } = this.offset
    const { width, height } = this.size

    if (pos.x >= x && pos.x <= x + width && pos.y >= y && pos.y <= y + height) {
      if (this.child) {
        const isChildInPos = this.child?.isCursorInRect(pos)
        if (isChildInPos) {
          return isChildInPos
        } else {
          return this.id
        }
      }

      if (this.children.length > 0) {
        for (const child of this.children) {
          const isChildInPos = child?.extract().isCursorInRect(pos)
          if (isChildInPos) {
            return isChildInPos
          }
        }

        return this.id
      }

      return this.id
    } else {
      return
    }
  }

  extract(): Geometry {
    if (this.isPseudo && this.pseudoChild) {
      if (this.pseudoChild.isPseudo) {
        return this.pseudoChild.extract()
      } else {
        return this.pseudoChild
      }
    } else {
      return this
    }
  }

  getDryLayout(constraints: Contstraints): GeometrySize {
    constraints
    return this._size
  }

  performLayout(): void {
    return
  }

  getMinIntrinsicWidth(height: number): number {
    return height
  }

  getMinIntrinsicHeight(width: number): number {
    return width
  }

  getMaxIntrinsicWidth(height: number): number {
    return height
  }

  getMaxIntrinsicHeight(width: number): number {
    return width
  }

  layout(constraints: Contstraints): void {
    this.constraints = constraints
    this.performLayout()
  }
}
