import { fabric } from "fabric"

interface IOptions {
  canvas: fabric.Canvas
  screenWidth: number
  screenHeight: number
}

export const EmptyScreenWidget = fabric.util.createClass(fabric.Rect, {
  type: "EmptyScreen",
  initialize: function (options: IOptions) {
    const { canvas, screenWidth, screenHeight } = options

    const text = new fabric.Text("Empty Screen", {
      fontSize: 24,
      fill: "#000",
      fontFamily: "Roboto",
      selectable: false
    })

    const text2 = new fabric.Textbox("Drag a layout element from the left in order to get started", {
      fontSize: 14,
      fill: "#333",
      fontFamily: "Roboto",
      selectable: false,
      textAlign: "center",
      width: screenWidth * 0.8
    })

    if (text.width && text.height) {
      const left = screenWidth / 2 - text.width / 2
      const top = screenHeight / 2 - text.height / 2

      text.set({ left, top })
      text2.set({})
      canvas.add(text)
    }

    if (text2.width && text2.height) {
      const left = screenWidth / 2 - text2.width / 2
      const top = screenHeight / 2 - text2.height / 2 + 40

      text2.set({ left, top })
      canvas.add(text2)
    }

    this.callSuper("initialize")
  }
})
