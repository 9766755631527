import { Geometry } from "./geometry"
import { GeometryOffset } from "./offset"
import { GeometrySize } from "./size"
import { Alignment } from "model/widgetProperty/type"

export class Align extends Geometry {
  alignment: Alignment
  isPseudo = true

  constructor(alignment: Alignment) {
    super()

    this.alignment = alignment
  }

  _alongOffset(other: GeometryOffset): GeometryOffset {
    const alignX = this.alignment.getX() || 0,
      alignY = this.alignment.getY() || 0

    const centerX = other.x / 2.0
    const centerY = other.y / 2.0
    return { x: centerX + alignX * centerX, y: centerY + alignY * centerY }
  }

  getMinIntrinsicWidth(height: number): number {
    if (this.child != null) {
      return this.child.getMinIntrinsicWidth(height)
    }
    return 0.0
  }

  getMaxIntrinsicWidth(height: number): number {
    if (this.child != null) {
      return this.child.getMaxIntrinsicWidth(height)
    }
    return 0.0
  }

  getMinIntrinsicHeight(width: number): number {
    if (this.child != null) {
      return this.child.getMinIntrinsicHeight(width)
    }
    return 0.0
  }

  getMaxIntrinsicHeight(width: number): number {
    if (this.child != null) {
      return this.child.getMaxIntrinsicHeight(width)
    }
    return 0.0
  }

  alignChild(): void {
    if (this.child) {
      const preOffset = this._alongOffset({
        x: this.size.width - this.child.size.width,
        y: this.size.height - this.child.size.height
      })

      this.child.offset = { x: this.offset.x + preOffset.x, y: this.offset.y + preOffset.y }
    }
  }

  getDryLayout(): GeometrySize {
    return new GeometrySize()
  }

  performLayout(): void {
    if (this.child) {
      const constraints = this.constraints

      const shrinkWrapWidth = constraints.maxWidth === Infinity
      const shrinkWrapHeight = constraints.maxHeight === Infinity

      this.child?.layout(constraints.loosen())

      this.size = constraints.constrain(
        new GeometrySize(
          shrinkWrapWidth ? this.child.size.width * 1 : Infinity,
          shrinkWrapHeight ? this.child.size.height * 1 : Infinity
        )
      )

      this.alignChild()
    }
  }
}
