import React, { useCallback, useState, ReactNode } from "react"
import { createPortal } from "react-dom"
import CSS from "csstype"

const wrapperStyle: CSS.Properties = {
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000
}

const maskStyle: CSS.Properties = {
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 100000
}

const containerStyle: CSS.Properties = {
  position: "relative",
  zIndex: 100001,
  borderRadius: "20px",
  background: "#fff",
  boxShadow: "5px 5px 7px 0 rgba(0, 0, 0, 0.3)",
  padding: 0
}
interface IModalProps {
  children: ReactNode
  isOpen: boolean
  close: () => void
  elementId: string
}
const Modal = (props: IModalProps) => {
  const { children, isOpen = false, close, elementId = "root" } = props
  if (!isOpen) {
    return null
  }
  return createPortal(
    <div style={wrapperStyle}>
      <div style={maskStyle} onClick={close} />
      <div style={containerStyle}>{children}</div>
    </div>,
    document.getElementById(elementId) as Element
  )
}

const useModal: (
  elementId?: string
) => [({ children }: { children: ReactNode }) => JSX.Element, () => void, () => void, boolean] = (
  elementId = "root"
) => {
  const [isOpen, setOpen] = useState(false)
  const open = useCallback(() => {
    setOpen(true)
  }, [setOpen])
  const close = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const ModalWrapper = useCallback(
    ({ children }) => {
      return (
        <Modal isOpen={isOpen} close={close} elementId={elementId}>
          {children}
        </Modal>
      )
    },
    [isOpen, close, elementId]
  )

  return [ModalWrapper, open, close, isOpen]
}

export default useModal
