import { TPrimitive, PrimitiveSerializable, IDefault, IPrimitiveSerializable, IReset } from "./primitive"
import { IProject } from "../../project"
import { ResourceTypeEnum } from "../enum"
import { IWidgetDynamicValue, WidgetDynamicValue } from "."

export interface IImage extends IDefault, IPrimitiveSerializable, IReset {
  reset(): this
  getResourceType(): ResourceTypeEnum
  setResourceType(resourceType: ResourceTypeEnum): this
  getPath(): IWidgetDynamicValue | undefined
  getURL(): string | undefined
}

export class Image extends PrimitiveSerializable implements IImage {
  protected resourceType: ResourceTypeEnum = ResourceTypeEnum.asset
  protected path: IWidgetDynamicValue

  constructor(p?: IProject) {
    super(p)
    this.path = new WidgetDynamicValue(this.p)
  }

  reset(): this {
    this.resourceType = ResourceTypeEnum.asset
    this.path = new WidgetDynamicValue(this.p)
    return this
  }

  isDefault(): boolean {
    return this.resourceType === ResourceTypeEnum.asset && this.path.isDefault()
  }

  getResourceType(): ResourceTypeEnum {
    return this.resourceType
  }

  setResourceType(resourceType: ResourceTypeEnum): this {
    this.resourceType = resourceType
    return this
  }

  getPath(): IWidgetDynamicValue | undefined {
    return this.path
  }

  setPath(path: IWidgetDynamicValue): this {
    this.path = path
    return this
  }

  // TODO update on further IWidgetDynamicValue implementation
  getURL(): string | undefined {
    if (this.resourceType === ResourceTypeEnum.asset) {
      return this.p?.getAsset(this.getPath()?.render() || "")?.getThumbnailCDNUrl()
    } else if (this.resourceType === ResourceTypeEnum.network) {
      return `${this.getPath()?.render()}`
    }
    return
  }

  fromJSON(v: TPrimitive): this {
    const data = v as Record<string, TPrimitive>
    if (data["r"]) {
      this.setResourceType(data["r"] as ResourceTypeEnum)
    }
    if (data["p"]) {
      this.path.fromJSON(data["p"] as TPrimitive)
    }
    return this
  }
  toJSON(): TPrimitive {
    const data: Record<string, TPrimitive> = {}
    if (this.resourceType) {
      data["r"] = this.resourceType as string
    }
    data["p"] = this.path.toJSON() as TPrimitive
    return data
  }
}
