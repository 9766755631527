import { Slider, NumberInput, Grid, Title } from "@mantine/core"
import { AlignmentExpanded } from "model/widgetProperty/type"
import { memo } from "react"

interface IAlignmentExpandedInputProps {
  value: AlignmentExpanded
  onChange: () => void
  label: string
}

export const AlignmentExpandedInput = memo(({ value, onChange }: IAlignmentExpandedInputProps): JSX.Element => {
  const horizontal = value.getX()
  const vertical = value.getY()

  const _onChange = (val: number, axis: string) => {
    if (axis === "x") {
      if (value.getX() !== val) {
        value.setX(val)
        value.setExtended(true)
        return onChange()
      }
    }

    if (axis === "y") {
      value.setY(val)
      value.setExtended(true)
      onChange()
    }

    const x = value.getX(),
      y = value.getY()

    if ((x === undefined || Number.isNaN(x)) && (y === undefined || Number.isNaN(y))) {
      value.setExtended(false)
      value.reset()
      onChange()
    }
  }

  return (
    <Grid
      sx={{
        padding: "10px"
      }}
    >
      <Grid.Col
        span={9}
        sx={{
          padding: "8px 0 0 0"
        }}
      >
        <Title
          order={6}
          sx={{
            color: "#a3a3a3",
            fontSize: 12,
            marginBottom: 4,
            fontWeight: "normal"
          }}
        >
          Horizontal
        </Title>
        <Slider
          min={-1}
          max={1}
          color="violet"
          step={0.05}
          size="xs"
          onChange={(e) => _onChange(e, "x")}
          value={horizontal || 0}
          label="Horizontal"
        />
      </Grid.Col>
      <Grid.Col span={3}>
        <NumberInput
          styles={{
            root: {
              marginTop: 0,
              marginBottom: 0
            }
          }}
          sx={() => ({
            input: {
              background: "#fff",
              border: "1px solid #dddddd",
              textAlign: "center",
              color: "#000",
              fontWeight: "bold",
              fontSize: "14px",
              borderRadius: 8,
              height: 36,
              marginBottom: 0,
              padding: "0"
            }
          })}
          min={-1}
          size="xs"
          max={1}
          precision={3}
          step={0.05}
          value={horizontal}
          hideControls
          onChange={(e) => _onChange(Number(e), "x")}
        />
      </Grid.Col>
      <Grid.Col
        span={9}
        sx={{
          padding: "8px 0 0 0"
        }}
      >
        <Title
          order={6}
          sx={{
            color: "#a3a3a3",
            fontSize: 12,
            marginBottom: 4,
            fontWeight: "normal"
          }}
        >
          Vertical
        </Title>
        <Slider
          styles={{
            root: {
              padding: 0
            }
          }}
          min={-1}
          max={1}
          thumbSize={9}
          size="xs"
          color="violet"
          step={0.05}
          onChange={(e) => _onChange(e, "y")}
          value={vertical || 0}
          label="Vertical"
        />
      </Grid.Col>
      <Grid.Col span={3}>
        <NumberInput
          styles={{
            root: {
              marginTop: 0,
              marginBottom: 0
            }
          }}
          sx={() => ({
            input: {
              background: "#fff",
              border: "1px solid #dddddd",
              borderRadius: 10,
              textAlign: "center",
              color: "#000",
              fontWeight: "bold",
              fontSize: "14px",
              height: 36
            }
          })}
          min={-1}
          max={1}
          size="xs"
          precision={3}
          step={0.05}
          value={vertical}
          hideControls
          onChange={(e) => _onChange(Number(e), "y")}
        />
      </Grid.Col>
    </Grid>
  )
})
