import { Select as MantineSelect, SelectItem } from "@mantine/core"
import { IconChevronDown } from "@tabler/icons"
import { memo } from "react"

interface ICStringProps {
  value?: string
  label: string
  onChange: (value: string) => void
  data: SelectItem[]
}

const SelectInput = memo(({ value, label, onChange, data }: ICStringProps): JSX.Element => {
  return (
    <div style={{ width: "100%" }}>
      <MantineSelect
        styles={() => ({
          item: {
            "&[data-selected]": {
              "&, &:hover": {
                backgroundColor: "#6549F7",
                color: "#fff"
              }
            },
            "&[data-hovered]": {}
          }
        })}
        sx={() => ({
          label: {
            color: "rgba(34, 39, 48, 0.7)",
            fontWeight: "bold",
            padding: "0 0 4px 0"
          },
          input: {
            border: "1px solid #ddd",
            borderRadius: 8
          },
          background: "#fff",
          color: "#16141C"
          // marginRight: 10,
          // marginTop: 12
        })}
        size="xs"
        value={value}
        label={label}
        rightSection={<IconChevronDown size={14} />}
        data={data}
        onChange={onChange}
      />
    </div>
  )
})

export default SelectInput
