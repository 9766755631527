import React, { createContext } from "react"

import useAuth from "service/appstate/auth"
import useBuilder from "service/appstate/builder"

import { TAuthType } from "types/appstate/stateType"
import { TClient, TObserverFun } from "types/service/api"
import { TActions, TAtomRecoil } from "types/utils/actionsType"

export type TAppStateType = {
  isLoading?: boolean
  appStates: {
    authState: {
      isLoading?: boolean
      isAuthorized: boolean
    }
    builderState: {
      leftLayerTab: string
    }
  }
  appActions: {
    authActions: TActions
    builderActions: TActions
  }
  appAtoms: {
    builderAtoms: {
      ExpandedAssets: TAtomRecoil
    }
  }
  appSelectors: {
    builderSelectors: {
      ExpandAssetItem: TAtomRecoil
    }
  }
}
// 1) import newstate

export const AppStateContext = createContext<TAppStateType>({
  appActions: {
    authActions: {},
    builderActions: {}
  },
  appAtoms: {
    builderAtoms: {}
  },
  appSelectors: {},
  appStates: {
    authState: {
      isLoading: false,
      isAuthorized: false
    },
    builderState: {
      leftLayerTab: ""
    }
  }
} as TAppStateType)
export interface AppStateProps {
  settings: {
    api: {
      host: string
      aws: {
        Auth: TAuthType
      }
      subscribeResponse: (fn: TObserverFun) => void
      unsubscribeResponse: (fn: TObserverFun) => void
      addgRPCService: (name: string, Client: TClient, message: string) => void
    }
    state?: {
      isServiceInit: boolean
    }
    storage?: LocalForage
    dispatch?: (type: { type: string }) => void
  }
}
const AppStateState: React.FC<AppStateProps> = ({ children, settings }): JSX.Element => {
  // 2) init
  const [authState, authActions] = useAuth(settings)
  const [builderState, builderActions, builderAtoms, builderSelectors] = useBuilder()

  // 3) add states
  const appStates = {
    authState,
    builderState
  }

  // 4) add actions
  const appActions = {
    authActions,
    builderActions
  }

  // 5) add atoms
  const appAtoms = {
    builderAtoms
  }
  // 6) add selectors
  const appSelectors = {
    builderSelectors
  }

  return (
    <AppStateContext.Provider
      value={{
        appStates,
        appActions,
        appAtoms,
        appSelectors,

        // 6) add isLoading
        isLoading: authState.isLoading
      }}
    >
      {children}
    </AppStateContext.Provider>
  )
}
export default AppStateState
