import { TAuthActionType, TConfirmType, TFieldType, TForgotPasswordArgs } from "types/auth/authType"
import { ISignUpResult } from "amazon-cognito-identity-js"

const Actions = {
  currentAuthenticatedUser:
    (bypassCache = false) =>
    async ({ dispatch, api }: TAuthActionType): Promise<(type: { type: string }) => void | unknown> => {
      try {
        await api.aws.Auth.currentAuthenticatedUser({
          bypassCache
        })
        return Promise.resolve(() => dispatch({ type: "SUCCESS" }))
      } catch (err) {
        return Promise.reject(err)
      }
    },

  authenticated:
    (bypassCache = false) =>
    async ({ dispatch, api }: TAuthActionType): Promise<(type: { type: string }) => void | unknown> => {
      dispatch({ type: "LOADING" })
      try {
        const cognitoUser = await api.aws.Auth.currentAuthenticatedUser({
          bypassCache
        })
        dispatch({ type: "SUCCESS" })
        return Promise.resolve(cognitoUser)
      } catch (err) {
        dispatch({ type: "FAIL" })
        return Promise.reject(err)
      }
    },

  signIn:
    ({ username, password }: { username: string; password: string }) =>
    async ({ dispatch, api }: TAuthActionType): Promise<boolean | unknown> => {
      dispatch({ type: "LOADING" })
      try {
        await api.aws.Auth.signIn(username, password)

        dispatch({ type: "SUCCESS" })

        return Promise.resolve(true)
      } catch (err) {
        dispatch({ type: "FAIL" })
        return Promise.reject(err)
      }
    },

  signUp:
    (fields: TFieldType) =>
    async ({ dispatch, api }: TAuthActionType): Promise<ISignUpResult | unknown> => {
      dispatch({ type: "LOADING" })
      try {
        const result = await api.aws.Auth.signUp(fields)
        dispatch({ type: "LOADED" })

        return Promise.resolve(result)
      } catch (err) {
        dispatch({ type: "FAIL" })
        return Promise.reject(err)
      }
    },

  signOut:
    () =>
    async ({ dispatch, api }: TAuthActionType): Promise<void> => {
      dispatch({ type: "RESET" })
      try {
        await api.aws.Auth.signOut()
      } catch (err) {
        // continue regardless of error
      }
    },

  forgotPassword:
    (username: string) =>
    async ({ dispatch, api }: TAuthActionType): Promise<unknown> => {
      dispatch({ type: "LOADING" })
      try {
        const result = await api.aws.Auth.forgotPassword(username)
        dispatch({ type: "LOADED" })

        return Promise.resolve(result)
      } catch (err) {
        dispatch({ type: "FAIL" })
        return Promise.reject(err)
      }
    },
  forgotPasswordSubmit:
    (...args: TForgotPasswordArgs) =>
    async ({ dispatch, api }: TAuthActionType): Promise<boolean | unknown> => {
      dispatch({ type: "LOADING" })
      try {
        await api.aws.Auth.forgotPasswordSubmit(...args)
        dispatch({ type: "LOADED" })
        return Promise.resolve(true)
      } catch (err) {
        dispatch({ type: "FAIL" })
        return Promise.reject(err)
      }
    },

  resendConfirmationCode:
    (username: string) =>
    async ({ dispatch, api }: TAuthActionType): Promise<never | void> => {
      dispatch({ type: "LOADING" })
      try {
        const result = await api.aws.Auth.resendSignUp(username)
        dispatch({ type: "LOADED" })
        return Promise.resolve(result)
      } catch (err) {
        dispatch({ type: "FAIL" })
        return Promise.reject(err)
      }
    },
  confirmSignUp:
    (...args: TConfirmType[]) =>
    async ({ dispatch, api }: TAuthActionType): Promise<never | void> => {
      dispatch({ type: "LOADING" })
      try {
        const result = await api.aws.Auth.confirmSignUp(...args)
        dispatch({ type: "LOADED" })
        return Promise.resolve(result)
      } catch (err) {
        dispatch({ type: "FAIL" })
        return Promise.reject(err)
      }
    }
}
export default Actions
