import { atom, selector } from "recoil"
import { api } from "service/api"
import { Project } from "model/project"
import { Widget } from "model/widget"
import { ProjectFont } from "model/widgetProperty/type"
import { Page } from "model/page"
import { selectedPageId } from "../builder/atoms"
import { PageWidget } from "model/pageWidget"
import { loadIconFonts } from "utils/loadIcons"

export const projectIdAtom = atom({
  key: "ProjectId",
  default: ""
})

export const projectVersion = atom({
  key: "ProjectVersion",
  default: "1.0.0"
})

export const projectForceUpdaterAtom = atom({
  key: "ProjectForceUpdater",
  default: 0
})

export type TAssetProgressItem = {
  jobId: string
  id?: string
  complete: boolean
}

type TAssetProgressAtom = {
  filled: boolean
  data: TAssetProgressItem[]
}
export const assetsLoadProgressAtom = atom<TAssetProgressAtom>({
  key: "AssetsProgressAtom",
  default: {
    filled: false,
    data: []
  }
})

export const widgetListSelector = selector({
  key: "WidgetListSelector",
  get: async () => {
    const _widgetList = await api.getWidgetList()
    const widgetList = []
    for (const _widget of _widgetList) {
      const widget = _widget.toObject()
      widget.propertyList = _widget.getPropertyList().map((widgetProp) => {
        const val = widgetProp.getRawValue()?.getValue()
        return {
          key: widgetProp.getKey(),
          type: widgetProp.getType(),
          value: val ? JSON.parse(val) : undefined,
          enum: widgetProp.hasEnum() ? widgetProp.getEnum()?.getValue() : undefined,
          group: widgetProp.getGroup()
        }
      })

      widgetList.push(widget)
    }

    return widgetList
  }
})

export const currentProject = selector<Project | undefined>({
  key: "CurrentProject",
  get: async ({ get }) => {
    const id = get(projectIdAtom)

    if (!id) {
      return
    }
    const widgets = get(widgetListSelector)

    let project: Project

    try {
      const url = process.env.REACT_APP_STATIC_ASSEST_URL
      const _project = await api.getProject(id)
      project = new Project({ ..._project.toObject(), cdnBaseURL: url })
    } catch (error) {
      throw error
    }

    widgets.forEach((widget) => {
      project.setWidget(Widget.fromJSON(widget))
    })

    return project
  },
  dangerouslyAllowMutability: true
})

export const fontsList = selector({
  key: "FontsList",
  get: async ({ get }) => {
    const _projectVersion = get(projectVersion)

    let fontList

    try {
      fontList = await api.getFontsList(_projectVersion)
    } catch (error) {
      throw error
    }

    return fontList
  }
})

export const iconList = selector({
  key: "IconList",
  get: async ({ get }) => {
    const _projectVersion = get(projectVersion)

    let iconsList

    try {
      iconsList = await api.getIconsList(_projectVersion)
    } catch (error) {
      throw error
    }

    await loadIconFonts(_projectVersion)

    return iconsList
  }
})

export const ideStatusAtom = atom({
  key: "IdeStatus",
  default: false
})

export const resourceRequestAtom = atom({
  key: "ResourceRequest",
  default: ""
})

export const pageListForceUpdaterAtom = atom({
  key: "PageListForceUpdater",
  default: 0
})

// If page list value needed, should used with pageListForceUpdaterAtom
export const pagesListSelector = selector<Page[]>({
  key: "PageList",
  get: async ({ get }) => {
    const resourceRequest = get(resourceRequestAtom),
      ideStatus = get(ideStatusAtom)

    if (!ideStatus) {
      return []
    }

    if (!resourceRequest) {
      return []
    }

    const project = get(currentProject)

    if (!project) {
      return []
    }

    let response

    try {
      response = await api.getPagesList(resourceRequest)
    } catch (error) {
      throw error
    }

    if (!response) {
      return []
    }

    const _pages = response.map((elem) => {
      const entity = {
        id: elem.id,
        tagList: elem.tagList,
        title: elem.title,
        description: elem.description,
        createdAt: new Date(elem.createdAt?.seconds || 0),
        updatedAt: new Date(elem.updatedAt?.seconds || 0),
        isLocked: elem.isLocked
      }
      const page = new Page(entity)
      page.setProject(project)
      return page
    })

    return _pages
  },
  dangerouslyAllowMutability: true
})

export const currentPage = selector({
  key: "CurrentPage",
  get: async ({ get }) => {
    const ideStatus = get(ideStatusAtom)

    if (!ideStatus) {
      return
    }

    const currentPageId = get(selectedPageId)
    const pagesList = get(pagesListSelector)
    pagesList.forEach((item) => item?.data?.remove())
    const currentPage = pagesList.find((item) => item.id === currentPageId)

    if (!currentPage || !currentPageId) {
      return
    }

    const resourceRequest = get(resourceRequestAtom)

    if (!resourceRequest) {
      return
    }

    const data = await api.getPageData(resourceRequest, currentPageId)
    currentPage.data.remove()
    currentPage.data.fromJSON(JSON.parse(data.data))

    const fonts = [
      {
        fontFamily: "Roboto",
        subset: "latin",
        weight: "400",
        style: "normal",
        provider: "google"
      }
    ]

    currentPage.data.walk(async (node, ctx) => {
      const widget = node as PageWidget
      if (widget?.property?.font) {
        const _font = widget.property.font as ProjectFont,
          font = _font.get()

        let fontFamily = font.getFamily()

        const weight = font.getWeight(),
          style = font.getStyle(),
          provider = font.getProvider()

        if (!fontFamily) {
          ctx.skip()
          fontFamily = ""
        }

        const __font = {
          fontFamily: fontFamily,
          subset: "latin",
          weight,
          style,
          provider: provider || ""
        }

        fonts.push(__font)
      }
    })

    for (const font of fonts) {
      const { fontFamily, subset, provider, weight, style } = font

      if (!fontFamily || !provider) {
        continue
      }

      const family = fontFamily.replace(/ /g, "-")

      const url = process.env.REACT_APP_STATIC_ASSEST_URL

      const fontFace = new FontFace(
        fontFamily,
        `url(${url}/ide/res/1.0.0/font/${provider}/${family.toLocaleLowerCase()}/${subset}-${weight}-${style}.woff2?)`,
        { weight, style }
      )

      try {
        await fontFace.load()
        document.fonts.add(fontFace)
      } catch (error) {}
    }

    return currentPage
  },
  dangerouslyAllowMutability: true
})
