import { Page } from "model/page"
import { api } from "service/api"
import { SetterOrUpdater } from "recoil"

export const Actions = {
  startIde: async (resource: string, recoilSetter: SetterOrUpdater<boolean>): Promise<void> => {
    try {
      await api.startIde(resource)
      recoilSetter(true)
    } catch (err) {
      throw err
    }
  },
  leaveIde: async (resource: string, recoilSetter: SetterOrUpdater<boolean>): Promise<void> => {
    try {
      await api.leaveIde(resource)
      recoilSetter(false)
    } catch (err) {}
  },
  setPageData: async (resource: string, id: string, data: Page): Promise<void> => {
    try {
      await api.setPageData(resource, id, JSON.stringify(data.data))
    } catch (err) {
      return Promise.reject(err)
    }
  },
  createPage: async (title: string, resource: string): Promise<string> => {
    try {
      const response = await api.createPage(resource, title)

      return response.toObject().id
    } catch (err) {
      return Promise.reject(err)
    }
  },
  updatePageInfo: async (resource: string, id: string, newTitle: string): Promise<string> => {
    try {
      const response = await api.updatePageInfo(resource, id, newTitle)
      return response.toObject().id
    } catch (err) {
      return Promise.reject(err)
    }
  },
  deletePage: async (id: string, resource: string): Promise<string> => {
    try {
      const response = await api.deletePage(resource, id)

      return response.toObject().id
    } catch (err) {
      return Promise.reject(err)
    }
  },
  createComponent: async (resource: string, title: string): Promise<string> => {
    try {
      const response = await api.createComponent(resource, title)
      return response.toObject().id
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default Actions
