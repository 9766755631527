import { useState, useEffect, useCallback } from "react"
import { Page } from "model/page"
import { ActionIcon, Text, TextInput, Loader, Switch } from "@mantine/core"
import { IconTrash, IconPencil, IconCheck, IconX } from "@tabler/icons"
import IdeActions from "service/appstate/application/actions"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { pageListForceUpdaterAtom, pagesListSelector, resourceRequestAtom } from "service/appstate/project/atoms"

import EventEmitter from "utils/eventEmitter"
import { IPubSubResponse } from "types/pubsub/pubsubTypes"
import { selectedPageId } from "service/appstate/builder/atoms"
import { ModalConfirmInner } from "components/modalConfirm"
import useModal from "utils/useModal"
import { ENodeEventList } from "model/entityEvent"

interface IPageSettingsProps {
  page: Page
}

export const PageSettings = ({ page }: IPageSettingsProps): JSX.Element => {
  const resourceRequest = useRecoilValue(resourceRequestAtom)
  const setSelectedLayerId = useSetRecoilState(selectedPageId)
  const pagesList = useRecoilValue(pagesListSelector)
  const pageListForceUpdater = useSetRecoilState(pageListForceUpdaterAtom)

  const [isRenamePending, setRenamePending] = useState(false)
  const [isDeletePending, setDeletePending] = useState(false)
  const [pendingJobId, setPendidngJobId] = useState("")
  const [isRenameInit, setRenaimInit] = useState(false)
  const [tempName, tempNameOnChange] = useState("")
  const [, _forceUpdate] = useState(0)

  const [ModalConfirm, openModalConfirm, closeModalConfirm] = useModal()

  const deletePage = () => {
    if (page.id) {
      setDeletePending(true)
      IdeActions.deletePage(page.id, resourceRequest).then((id) => {
        setPendidngJobId(id)
      })
    }
  }

  const renamePage = (id?: string) => {
    if (id && tempName) {
      setRenamePending(true)
      IdeActions.updatePageInfo(resourceRequest, id, tempName).then((id) => {
        setPendidngJobId(id)
      })
    }
  }
  const forceUpdate = useCallback(() => {
    _forceUpdate(Math.random())
  }, [])

  const toggleSafeArea = () => {
    if (page.id) {
      page.data.isSafeArea = !page.data.isSafeArea
      page.data.e?.emit(ENodeEventList.NodePropertyUpdated, { node: page.data })
      forceUpdate()
    }
  }

  const initRename = () => {
    if (page.title) {
      tempNameOnChange(page.title)
      setRenaimInit(true)
    }
  }

  useEffect(() => {
    const pubSubCb = (data: IPubSubResponse) => {
      const { id, jobId } = data
      if (pendingJobId === jobId) {
        if (data.event === 3) {
          const deletedIndex = pagesList.findIndex((page) => page.id === id)
          pagesList.splice(deletedIndex, 1)

          let nextPageId = ""
          if (pagesList.length === 0) {
            nextPageId = ""
          } else {
            if (deletedIndex === 0) {
              nextPageId = pagesList[0]?.id || ""
            } else {
              nextPageId = pagesList[deletedIndex - 1]?.id || ""
            }
          }
          setSelectedLayerId(nextPageId)
          setPendidngJobId("")
          setDeletePending(false)
          pageListForceUpdater((val) => val + 1)
        }
        if (data.event === 2) {
          const pageIndex = pagesList.findIndex((item) => item.id === id),
            pageItem = pagesList.find((item) => item.id === id)

          if (pageItem) {
            pageItem.title = tempName
            pagesList.splice(pageIndex, 1, pageItem)
            pageListForceUpdater((val) => val + 1)
            setRenamePending(false)
            setRenaimInit(false)
            tempNameOnChange("")
          }
        }
      }
    }

    EventEmitter.on("streamProjectBranch/data", pubSubCb)

    return () => {
      EventEmitter.off("streamProjectBranch/data", pubSubCb)
    }
  }, [pendingJobId])

  return (
    <div>
      <div style={{ marginTop: "12px", display: "flex", alignItems: "center", marginBottom: 10 }}>
        <ModalConfirm>
          <ModalConfirmInner isPending={isDeletePending} onConfirm={deletePage} onClose={closeModalConfirm}>
            Are you sure you want to delete page<Text sx={{ fontSize: 20 }}>{page.title}</Text>
          </ModalConfirmInner>
        </ModalConfirm>

        {isRenameInit ? (
          <TextInput
            sx={{
              "input:focus, input:focus-within": {
                border: "1px solid #6549F7"
              }
            }}
            radius={10}
            mr={5}
            value={tempName}
            onChange={(e) => tempNameOnChange(e.target.value)}
          />
        ) : (
          <Text size={20} mr="auto" color="#000">
            {page.title}
          </Text>
        )}
        {isRenameInit ? (
          isRenamePending ? (
            <Loader size="xs" color="purple" />
          ) : (
            <>
              <ActionIcon
                mr={5}
                sx={{
                  "&: hover": {
                    backgroundColor: "#F7F5FF"
                  }
                }}
                onClick={() => renamePage(page.id)}
              >
                <IconCheck style={{ color: "#6549F7" }} />
              </ActionIcon>
              <ActionIcon
                sx={{
                  "&: hover": {
                    backgroundColor: "#F7F5FF"
                  }
                }}
                mr="auto"
                onClick={() => setRenaimInit(false)}
              >
                <IconX style={{ color: "#c65858" }} />
              </ActionIcon>
            </>
          )
        ) : (
          <ActionIcon
            sx={{
              "&: hover": {
                backgroundColor: "#F7F5FF"
              }
            }}
            mr={5}
            onClick={() => initRename()}
          >
            <IconPencil style={{ color: "#6549F7" }} />
          </ActionIcon>
        )}
        <ActionIcon
          sx={{
            "&: hover": {
              backgroundColor: "#F7F5FF"
            }
          }}
          onClick={() => openModalConfirm()}
        >
          <IconTrash color="red" />
        </ActionIcon>
      </div>
      <div style={{ display: "flex" }}>
        <Switch
          styles={{
            body: { flexFlow: "row-reverse" },
            labelWrapper: { marginRight: 20, placeSelf: "center" },
            label: { padding: 0 }
          }}
          label="Safe Area"
          checked={page.data.isSafeArea}
          onClick={() => toggleSafeArea()}
        />
      </div>
    </div>
  )
}
