import "reflect-metadata"
import { jsonProperty, SerializationSettings } from "ts-serializable"
import { Serializable } from "./serializable"

export class WidgetPropertyInfo extends Serializable {
  @jsonProperty(String)
  key?: string = undefined
  @jsonProperty(String)
  type?: string = undefined
  @jsonProperty(String, undefined)
  enum?: string = undefined
  @jsonProperty(String, undefined)
  group?: string = undefined
  @jsonProperty(Boolean, String, Number, Object, undefined)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any = undefined

  fromJSON(json: Record<string, unknown>, settings?: Partial<SerializationSettings> | undefined): this {
    if (!json) {
      return this
    }
    json = { ...json }
    if (typeof json.value == "undefined" && json.rawValue) {
      try {
        json.value = JSON.parse(json.rawValue as string)
        delete json.rawValue
      } catch (e) {}
    }
    return super.fromJSON(json, settings)
  }
}

export class Widget extends Serializable {
  @jsonProperty(String)
  title?: string = undefined
  @jsonProperty(String)
  description?: string = undefined
  @jsonProperty(String)
  type?: string = undefined
  @jsonProperty([String])
  tag?: string[] = undefined
  @jsonProperty([WidgetPropertyInfo])
  property?: WidgetPropertyInfo[] = undefined
  @jsonProperty(Number)
  order?: number = undefined

  fromJSON(json: Record<string, unknown>, settings?: Partial<SerializationSettings> | undefined): this {
    if (!json) {
      return this
    }
    json = { ...json }
    json.tag = json.tag || json.tagList || []
    json.property = json.property || json.propertyList || []
    json.order = json.order || 0
    return super.fromJSON(json, settings)
  }

  getPropertyByKey(key: string): WidgetPropertyInfo | undefined {
    if (this.property) {
      for (const i in this.property) {
        if (this.property[i].key == key) {
          return this.property[i]
        }
      }
    }
    return
  }

  hasCapability(tag: string): boolean {
    return this.tag?.indexOf(tag) !== -1
  }
}
