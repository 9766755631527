import { TPrimitive, PrimitiveSerializable } from "./primitive"

export class CString extends PrimitiveSerializable {
  protected v = ""
  isDefault(): boolean {
    return this.v === ""
  }
  reset(): this {
    this.v = ""
    return this
  }
  get(): string {
    return this.v
  }
  set(v: string): this {
    this.v = v
    return this
  }
  fromJSON(v: TPrimitive): this {
    return this.set(v as string)
  }
  toJSON(): TPrimitive {
    return this.v
  }
}
