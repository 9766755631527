import { fabric } from "fabric"
import { Grid } from "../geometry/grid"
import { TWidgetOptions } from "./types"

const onScroll = function (this: fabric.Rect & { geometry: Grid; ctx: fabric.Canvas }, e: fabric.IEvent<WheelEvent>) {
  const { geometry, ctx } = this
  if (!geometry) {
    return
  }

  const tl = { x: geometry.offset.x, y: geometry.offset.y },
    br = { x: geometry.offset.x + geometry.size.width, y: geometry.offset.y + geometry.size.height }

  if (!e.pointer) {
    return
  }

  const { x, y } = e.pointer

  if (tl.x <= x && x <= br.x && tl.y <= y && y <= br.y) {
    e.e.preventDefault()
    e.e.stopPropagation()

    if (geometry.overflow.maxX > 0) {
      geometry.scrollX = e.e.deltaX
      ctx.renderAll()
    }

    if (geometry.overflow.maxY > 0) {
      geometry.scrollY = e.e.deltaY
      ctx.renderAll()
    }

    ctx.fire("mouse:move", e)
    ctx.fire("mouse:down")

    // Fire mouse move for correct hovered element select
  }
}

export const GridViewWidget = fabric.util.createClass(fabric.Rect, {
  type: "Grid",
  initialize: function (options: TWidgetOptions & { geometry: Grid }) {
    if (!options?.pageWidget) {
      throw new Error("Page widget is required option")
    }

    const { pageWidget, ctx, geometry } = options

    this.c = ctx
    this.pageWidget = pageWidget
    this.id = pageWidget.id
    this.childClipper = this
    this.absolutePositioned = true

    this.fabricParent = ctx.getObjects().find((item) => {
      return item.id === pageWidget.parent?.id
    })

    // TODO somehow fix cliping with transparent bg
    this.fill = "white"
    this.opacity = 0.01

    this.geometry = geometry

    this.set({
      width: geometry.size.width,
      height: geometry.size.height,
      left: geometry.offset.x,
      top: geometry.offset.y
    })

    ctx.on("mouse:wheel", onScroll.bind(this))

    this.callSuper("initialize", options)
  },
  _render: function (ctx: CanvasRenderingContext2D) {
    const pageWidget = this.pageWidget

    const geometry = this.geometry

    this.set({
      width: geometry.size.width,
      height: geometry.size.height,
      left: geometry.offset.x,
      top: geometry.offset.y
    })

    if (!pageWidget.children || pageWidget?.children?.length === 0) {
      const axis = geometry.axis

      let _text = `Example\n${String.fromCharCode(8595)}\nGridView`

      if (axis === "horizontal") {
        _text = `Example ${String.fromCharCode(10132)} GridView`
      }
      const text = new fabric.Text(_text, {
        fontSize: 14,
        fill: "#737272",
        fontFamily: "Roboto",
        selectable: false,
        textAlign: "center"
      })

      const textWidth = text?.width || 0,
        textHeight = text?.height || 0

      const left = geometry.offset.x + geometry.size.width / 2 - textWidth / 2
      const top = geometry.offset.y + geometry.size.height / 2 - textHeight / 2

      text.set({ left, top })
      if (this.c) {
        if (this.emptyText) {
          this.c.remove(this.emptyText)
        }

        this.emptyText = text
        this.c.add(text)
      }
    }

    this.callSuper("_render", ctx)
  }
})
