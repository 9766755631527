import { fabric } from "fabric"
import { TWidgetOptions } from "./types"

export const ColumnWidget = fabric.util.createClass(fabric.Rect, {
  type: "Column",
  cacheProperties: fabric.Rect?.prototype?.cacheProperties?.concat("geometry"),
  stateProperties: fabric.Rect?.prototype?.stateProperties?.concat("geometry"),
  initialize: function (options: TWidgetOptions) {
    if (!options?.pageWidget) {
      throw new Error("Page widget is required option")
    }

    const { pageWidget, ctx, geometry } = options

    this.pageWidget = pageWidget
    this.c = ctx
    this.fill = "transparent"

    this.id = pageWidget.id

    this.fabricParent = ctx.getObjects().find((item) => {
      return item.id === pageWidget.parent?.id
    })

    this.geometry = geometry

    this.callSuper("initialize", options)
  },
  _render: function (ctx: CanvasRenderingContext2D) {
    const geometry = this.geometry

    this.width = geometry.size.width
    this.height = geometry.size.height
    this.top = geometry.offset.y
    this.left = geometry.offset.x

    const pageWidget = this.pageWidget

    if (!pageWidget.children) {
      const text = new fabric.Text(`Example\n${String.fromCharCode(8595)}\nColumn`, {
        fontSize: 14,
        fill: "#737272",
        fontFamily: "Roboto",
        selectable: false,
        textAlign: "center"
      })

      const textWidth = text?.width || 0,
        textHeight = text?.height || 0

      const left = geometry.offset.x + geometry.size.width / 2 - textWidth / 2
      const top = geometry.offset.y + geometry.size.height / 2 - textHeight / 2

      text.set({ left, top })
      if (this.c) {
        if (this.emptyText) {
          this.c.remove(this.emptyText)
        }

        this.emptyText = text
        this.c.add(text)
      }
    }

    this.callSuper("_render", ctx)
  }
})
