import { SegmentedControl, Stack, NumberInput, Title, Group } from "@mantine/core"
import { Size } from "model/widgetProperty/type"
import { memo } from "react"

interface ISizeInputProps {
  value: Size
  onChange: () => void
  label: string
}

const SizeInput = memo(({ value, label, onChange }: ISizeInputProps): JSX.Element => {
  const _typeOnChange = (type = "px", newValue?: number) => {
    if (newValue === undefined) {
      return
    }

    if (type === "percent") {
      value.setPercent(newValue)
    }
    if (type === "px") {
      value.setPixel(newValue)
    }
    if (type === "infinity") {
      value.setInfinity()
    }

    onChange()
  }

  const _valueOnChange = (val?: number) => {
    _typeOnChange(_getTypeValue(), val)
  }

  const _getTypeValue = () => {
    if (value.isPercent()) {
      return "percent"
    }
    if (value.isPixel()) {
      return "px"
    }
    if (value.isInfinity()) {
      return "infinity"
    }
  }

  return (
    <Stack
      align="flex-start"
      spacing="xs"
      sx={() => ({
        backgroundColor: "#fff",
        // padding: "12px 0px 0 0",
        width: "calc(50% - 6px)",
        gap: "12px"
      })}
    >
      <Group style={{ width: "100%" }} position="apart">
        <Title order={6} color="rgba(34, 39, 48, 0.7)" size={12}>
          {label}
        </Title>
        <SegmentedControl
          sx={() => ({
            label: {
              fontSize: "9px"
            },
            backgroundColor: "#F7F5FF"
          })}
          value={_getTypeValue()}
          data={[
            { label: "PX", value: "px" },
            { label: "%", value: "percent" },
            { label: "∞", value: "infinity" }
          ]}
          color="violet"
          size="xs"
          onChange={(e) => _typeOnChange(e, value.get() || 100)}
        />
      </Group>
      <NumberInput
        sx={() => ({
          label: {
            color: "rgba(34, 39, 48, 0.7)"
          },
          input: {
            background: "#fff",
            color: "#000",
            border: "1px solid #ddd",
            borderRadius: 8
          },
          background: "#fff",
          padding: 0,
          color: "rgba(34, 39, 48, 0.7)",
          width: "100%"
        })}
        value={value.isInfinity() ? Infinity : value.get()}
        disabled={value.isInfinity()}
        onChange={(e) => _valueOnChange(e)}
        hideControls
        size="xs"
        placeholder=""
      />
    </Stack>
  )
})

export default SizeInput
