import { Button, FileButton, Loader } from "@mantine/core"
import { AssetUploadUrlInfo } from "service/pb/ide_pb"
import { api } from "service/api"
import { useEffect, useState, CSSProperties } from "react"
import { useRecoilState } from "recoil"
import { assetsLoadProgressAtom } from "service/appstate/project/atoms"
import { IconArrowBarUp } from "@tabler/icons"

interface IAssetLoaderProps {
  resourceRequest: string
  multiple?: boolean
  style?: CSSProperties
  onChange?: (id: string | string[]) => void
}

export const AssetLoader = ({
  resourceRequest,
  multiple = false,
  style = { backgroundColor: "#6549F7", borderRadius: 8, height: 44, fontSize: 12, width: 160 },
  onChange
}: IAssetLoaderProps): JSX.Element => {
  const [isLoading, setLoading] = useState(false)
  const [assetProgress, setAssetProgress] = useRecoilState(assetsLoadProgressAtom)

  const dataURItoBlob = (dataURI: string): Blob => {
    const byteString = atob(dataURI.split(",")[1])

    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]

    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], { type: mimeString })
  }

  const cropFile = (idata: string, maxSize: number): Promise<Blob> => {
    const img = new Image()
    img.src = idata
    maxSize = maxSize || 300
    return new Promise((resolve) => {
      img.onload = async function () {
        const canvas = document.createElement("canvas")
        const ctx = canvas.getContext("2d")

        if (!ctx) {
          throw "Canvas context didnt exist"
        }

        const ratio = img.width / img.height
        let newwidth, newheight
        if (ratio >= 1) {
          newwidth = maxSize
          newheight = maxSize / ratio
        } else {
          newwidth = maxSize * ratio
          newheight = maxSize
        }

        canvas.width = newwidth
        canvas.height = newheight

        ctx.drawImage(img, 0, 0, newwidth, newheight)

        resolve(dataURItoBlob(canvas.toDataURL()))
      }
    })
  }

  const uploadFile = async (assetUploadInfo: AssetUploadUrlInfo, imageBlob: Blob): Promise<void> => {
    const url = assetUploadInfo.getUrl(),
      method = assetUploadInfo.getMethod(),
      headers = assetUploadInfo.getHeaderList()

    const _headers: Record<string, string> = {}

    headers.forEach((header) => {
      _headers[header.getKey()] = header.getValueList().toString()
    })

    try {
      await fetch(url, { method, headers: _headers, body: imageBlob })
    } catch (err) {
      throw err
    }

    return
  }

  const initFileUpload = async (_files: File[] | File | null) => {
    if (!_files) {
      return
    }

    const files: File[] = []

    if (!Array.isArray(_files)) {
      files.push(_files)
    } else {
      files.push(..._files)
    }

    setLoading(true)

    Promise.all(
      files.map(async (file) => {
        return new Promise((resolve) => {
          api.getAssetUploadUrl(resourceRequest, file).then((data) => {
            const original = data.getOriginal(),
              thumbnail = data.getThumbnail()

            if (!original || !thumbnail) {
              return
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = (e) => {
              if (e?.target?.result) {
                const originalDataUrl = e.target.result.toString()
                cropFile(originalDataUrl, 300)
                  .then(async (thumbNailDataUrl) => {
                    try {
                      await uploadFile(thumbnail, thumbNailDataUrl)
                    } catch (err) {
                      throw err
                    }

                    return
                  })
                  .then(async () => {
                    try {
                      await uploadFile(original, dataURItoBlob(originalDataUrl))
                    } catch (err) {
                      throw err
                    }
                  })
                  .then(() => {
                    return resolve(data.getHash())
                  })
                  .catch(() => {
                    //
                  })
              }
            }
          })
        })
      })
    ).then(async (val) => {
      const images = val as string[]

      for (const hash of images) {
        try {
          const jobIdData = await api.createAsset(resourceRequest, hash)

          setAssetProgress((currentProgress) => {
            const assetProgressArray = [...currentProgress.data, { jobId: jobIdData.getId(), complete: false }]
            return { ...currentProgress, data: assetProgressArray }
          })
        } catch (err) {
          throw err
        }
      }

      setAssetProgress((currentProgress) => {
        return { ...currentProgress, filled: true }
      })
    })
  }

  useEffect(() => {
    if (assetProgress.filled) {
      const isAllComplete = assetProgress.data.every((item) => item.complete)

      if (isAllComplete) {
        setLoading(false)
        if (onChange) {
          if (multiple) {
            onChange(assetProgress.data.map((item) => item.id as string))
          } else {
            onChange(assetProgress.data[0].id as string)
          }
        }

        setAssetProgress({ filled: false, data: [] })
      }
    }
  }, [assetProgress])

  return (
    <FileButton multiple={multiple} accept="image/png,image/jpeg" onChange={(e) => initFileUpload(e)}>
      {(props) => (
        <Button
          style={style}
          disabled={isLoading}
          leftIcon={isLoading ? <Loader color="white" size="sm" /> : <IconArrowBarUp />}
          {...props}
        >
          Upload image
        </Button>
      )}
    </FileButton>
  )
}
