import { Geometry } from "./geometry"
import { Contstraints } from "./constraints"
import { GeometrySize } from "./size"
import { PageWidget } from "model/pageWidget"

export class ConstrainedBox extends Geometry {
  private _additionalConstraints: Contstraints

  constructor(treeEntity: PageWidget | null, additionalConstraints: Contstraints, isPseudo = false) {
    super()

    this.isPseudo = isPseudo
    if (treeEntity) {
      this.treeEntity = treeEntity
    }
    this._additionalConstraints = additionalConstraints
  }

  get additionalConstraints(): Contstraints {
    return this._additionalConstraints || new Contstraints()
  }

  set additionalConstraints(value: Contstraints) {
    if (this._additionalConstraints === value) {
      return
    }
    this._additionalConstraints = value
  }

  getMinIntrinsicWidth(height: number): number {
    if (this._additionalConstraints?.hasBoundedWidth && this._additionalConstraints?.hasTightWidth) {
      return this._additionalConstraints.minWidth
    }

    const width = super.getMinIntrinsicWidth(height)

    if (!this._additionalConstraints?.hasInfiniteWidth) {
      return this._additionalConstraints?.constrainWidth(width) || width
    }

    return width
  }

  getMaxIntrinsicWidth(height: number): number {
    if (this._additionalConstraints?.hasBoundedWidth && this._additionalConstraints.hasTightWidth) {
      return this._additionalConstraints.minWidth
    }
    const width = super.getMaxIntrinsicWidth(height)

    if (!this._additionalConstraints?.hasInfiniteWidth) {
      return this._additionalConstraints?.constrainWidth(width) || width
    }
    return width
  }

  getMinIntrinsicHeight(width: number): number {
    if (this._additionalConstraints?.hasBoundedHeight && this._additionalConstraints?.hasTightHeight) {
      return this._additionalConstraints.minHeight
    }
    const height = super.getMinIntrinsicHeight(width)

    if (!this._additionalConstraints?.hasInfiniteHeight) {
      return this._additionalConstraints?.constrainHeight(height) || height
    }
    return height
  }

  getMaxIntrinsicHeight(width: number): number {
    if (this._additionalConstraints?.hasBoundedHeight && this._additionalConstraints.hasTightHeight) {
      return this._additionalConstraints.minHeight
    }

    const height = super.getMaxIntrinsicHeight(width)

    if (!this._additionalConstraints?.hasInfiniteHeight) {
      return this._additionalConstraints?.constrainHeight(height) || height
    }

    return height
  }

  performLayout(): void {
    const constraints = this.constraints

    const additionalConstraints = this.additionalConstraints
    if (!constraints || !additionalConstraints) {
      return
    }

    if (this.child != null) {
      this.child.layout(additionalConstraints?.enforce(constraints))
      this.size = this.child.size
      this.child.offset = this.offset
    } else {
      this.size = additionalConstraints.enforce(constraints).constrain(new GeometrySize())
    }
  }
}
