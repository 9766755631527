import { TPrimitive, PrimitiveSerializable, IDefault, IPrimitiveSerializable, IReset } from "./primitive"
import { IProject } from "../../project"

export interface ISize extends IDefault, IPrimitiveSerializable, IReset {
  get(): number
  isPixel(): boolean
  setPixel(v: number): this
  isPercent(): boolean
  setPercent(v: number): this
  isInfinity(): boolean
  setInfinity(v: number): this
}

export class Size extends PrimitiveSerializable implements ISize {
  protected _infty = "∞"
  protected _persent = "%"
  protected _pixel = "px"

  protected v = ""

  isDefault(): boolean {
    return this.v === "" || this.v === `0${this._pixel}` || this.v === `0${this._persent}`
  }

  get(): number {
    if (this.v && this.v !== this._infty) {
      return parseFloat(this.v)
    }
    return 0
  }

  reset(): this {
    this.v = ""
    return this
  }

  isPixel(): boolean {
    return !!this.v && new RegExp(`\\d(${this._pixel})?$`).test(this.v)
  }

  setPixel(v: number): this {
    this.v = "" + v + this._pixel
    return this
  }

  isPercent(): boolean {
    return !!this.v && new RegExp(`${this._persent}$`).test(this.v)
  }

  setPercent(v: number): this {
    this.v = "" + v + this._persent
    return this
  }

  isInfinity(): boolean {
    return this.v === this._infty
  }

  setInfinity(): this {
    this.v = this._infty
    return this
  }

  fromJSON(v: TPrimitive): this {
    this.v = v as string
    return this
  }
  toJSON(): TPrimitive {
    return `${this.v}`
  }
}

export interface IPlainSize {
  reset(): this
  getWidth(): ISize | undefined
  geHeight(): ISize | undefined
}

export class PlainSize extends PrimitiveSerializable implements IPlainSize {
  protected w: ISize
  protected h: ISize

  constructor(p?: IProject) {
    super(p)
    this.w = new Size(this.p)
    this.h = new Size(this.p)
  }

  isDefault(): boolean {
    return (!this.w || this.w.isDefault()) && (!this.h || this.h.isDefault())
  }

  reset(): this {
    this.w = new Size(this.p)
    this.h = new Size(this.p)
    return this
  }

  getWidth(): ISize {
    return this.w
  }

  geHeight(): ISize {
    return this.h
  }

  fromJSON(v: TPrimitive): this {
    const wh: string[] = (v as string).split(" ")
    if (wh[0]) {
      this.w = new Size(this.p).fromJSON(wh[0])
    }
    if (wh[1]) {
      this.h = new Size(this.p).fromJSON(wh[1])
    }
    return this
  }
  toJSON(): TPrimitive {
    return `${this.w?.toJSON()} ${this.h?.toJSON()}`
  }
}
