import { useRef, useEffect, useState, memo } from "react"
import { useVerticalEdge } from "pages/builder/Components/verticalEdge"
import ResizeLine from "pages/builder/leftpanel/Components/ResizeLine"
import TabsHeader from "pages/builder/leftpanel/Components/TabsHeader"
import TreeView from "pages/builder/leftpanel/Components/TreeView"
import Layers from "pages/builder/leftpanel/layers"
import Assets from "pages/builder/leftpanel/assets"
import styles from "styles/builder/leftpanel.module.css"
import { currentPage, currentProject, resourceRequestAtom } from "service/appstate/project/atoms"
import { useRecoilValue, useRecoilValueLoadable } from "recoil"
import { selectedMainTabAtom } from "service/appstate/builder/atoms"
import { useRecoilState } from "recoil"
import { SettingsSideBar } from "pages/builder/settings/sidebar"
import ImageAssets from "pages/builder/leftpanel/Components/ImageAssets"

const LeftPanel = () => {
  const [selectedMainTab, selectMainTab] = useRecoilState(selectedMainTabAtom)

  const resourceRequest = useRecoilValue(resourceRequestAtom)

  const projectState = useRecoilValueLoadable(currentProject)
  const project = projectState.state === "hasValue" ? projectState.getValue() : undefined

  const pageState = useRecoilValueLoadable(currentPage)
  const entity = pageState.state === "hasValue" ? pageState.getValue() : undefined

  const [heightPagesList, setHeightPagesList] = useState(100)
  const { widthLeftPanel, dispatch } = useVerticalEdge()
  const leftPanelContainer = useRef(null)

  useEffect(() => {
    const minWidth = leftPanelContainer.current
      ? window.getComputedStyle(leftPanelContainer.current, null).getPropertyValue("min-width").slice(0, -2)
      : 0

    const maxWidth = leftPanelContainer.current
      ? window.getComputedStyle(leftPanelContainer.current, null).getPropertyValue("max-width").slice(0, -2)
      : 0

    dispatch({
      type: "SETMIN",
      minWidth: Number(minWidth) ?? 0
    })
    dispatch({
      type: "SETMAX",
      maxWidth: Number(maxWidth) ?? 0
    })
  }, [dispatch])

  return (
    <div className={styles.panelContainer} style={{ width: widthLeftPanel }} ref={leftPanelContainer}>
      <div className={styles.content}>
        <div className={styles.verticalFlexContainer}>
          <TabsHeader />
          {selectedMainTab === "layers" && (
            <>
              <Layers
                heightPagesList={heightPagesList}
                widthLeftPanel={widthLeftPanel}
                resourceRequest={resourceRequest}
              />
              <ResizeLine setHeightPagesList={setHeightPagesList} />
              {entity ? <TreeView page={entity} pageId={entity?.id} /> : null}
            </>
          )}
          {project ? selectedMainTab === "assets" && <Assets project={project} setLeftTopTab={selectMainTab} /> : null}
          {selectedMainTab === "settings" ? <SettingsSideBar /> : null}
          {selectedMainTab === "images" ? <ImageAssets /> : null}
        </div>
      </div>
    </div>
  )
}

export default memo(LeftPanel)
