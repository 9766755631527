export enum AxisEnum {
  vertical = "vertical",
  horizontal = "horizontal"
}

export enum TextAlignmentEnum {
  left = "left",
  center = "center",
  right = "right",
  justify = "justify"
}

export enum MainAxisSizeEnum {
  max = "max",
  min = "min"
}

export enum MainAxisAlignmentEnum {
  start = "start",
  center = "center",
  end = "end",
  spaceEvenly = "spaceEvenly",
  spaceAround = "spaceAround",
  spaceBetween = "spaceBetween"
}

export enum CrossAxisAlignmentEnum {
  start = "start",
  center = "center",
  end = "end",
  stratch = "stratch"
}

export enum ResourceTypeEnum {
  network = "network",
  asset = "asset"
}

export enum BoxFitEnum {
  fill = "fill",
  contain = "contain",
  cover = "cover",
  fitWidth = "fitWidth",
  fitHeight = "fitHeight",
  none = "none",
  scaleDown = "scaleDown"
}

export enum BoxShapeEnum {
  rectangle = "rectangle",
  circle = "circle"
}

export enum WidgetDynamicValueSourceEnum {
  static = "static"
}

export enum FontWeightEnum {
  w100 = "100",
  w200 = "200",
  w300 = "300",
  w400 = "400",
  w500 = "500",
  w600 = "600",
  w700 = "700",
  w800 = "800",
  w900 = "900"
}

export enum FontStyleEnum {
  normal = "normal",
  italic = "italic"
}
