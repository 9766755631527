import { TPrimitive, PrimitiveSerializable, IDefault, IPrimitiveSerializable, IReset } from "./primitive"
import { IProject } from "../../project"
import { IProjectColor, ProjectColor } from "./color"
import { FontWeightEnum, FontStyleEnum } from "../enum"

export interface IFont extends IDefault, IPrimitiveSerializable, IReset {
  getProvider(): string | undefined
  setProvider(provider: string): this
  getFamily(): string | undefined
  setFamily(family: string): this
  getWeight(): FontWeightEnum
  setWeight(weight: string): this
  getStyle(): FontStyleEnum
  setStyle(style: string): this
  getSize(): number | undefined
  setSize(size: number): this
  getColor(): IProjectColor
  getSpacing(): number | undefined
  setSpacing(spacing: number): this
}

export class Font extends PrimitiveSerializable implements IFont {
  protected provider?: string = undefined
  protected family?: string = undefined
  protected weight = FontWeightEnum.w400
  protected style = FontStyleEnum.normal
  protected size = 14
  protected color: IProjectColor
  protected spacing = 0

  constructor(p?: IProject) {
    super(p)
    this.color = new ProjectColor(this.p)
  }

  reset(): this {
    this.provider = undefined
    this.family = undefined
    this.weight = FontWeightEnum.w400
    this.style = FontStyleEnum.normal
    this.size = 14
    this.color = new ProjectColor(this.p)
    this.spacing = 0
    return this
  }

  isDefault(): boolean {
    return (
      this.color.isDefault() &&
      !(
        this.provider ||
        this.family ||
        this.weight !== FontWeightEnum.w400 ||
        this.size !== 14 ||
        this.style !== FontStyleEnum.normal ||
        this.spacing !== 0
      )
    )
  }

  getProvider(): string | undefined {
    return this.provider
  }

  setProvider(provider: string): this {
    this.provider = provider
    return this
  }

  getFamily(): string | undefined {
    return this.family
  }

  setFamily(family: string): this {
    this.family = family
    return this
  }

  getWeight(): FontWeightEnum {
    return this.weight
  }

  setWeight(weight: FontWeightEnum): this {
    this.weight = weight
    return this
  }

  getStyle(): FontStyleEnum {
    return this.style
  }

  setStyle(style: FontStyleEnum): this {
    this.style = style
    return this
  }

  getSize(): number | undefined {
    return this.size
  }

  setSize(size: number): this {
    this.size = size
    return this
  }

  getColor(): IProjectColor {
    return this.color
  }

  getSpacing(): number | undefined {
    return this.spacing
  }

  setSpacing(spacing: number): this {
    this.spacing = spacing
    return this
  }

  fromJSON(v: TPrimitive): this {
    const data = v as Record<string, TPrimitive>
    if (data["p"]) {
      this.setProvider(data["p"] as string)
    }
    if (data["f"]) {
      this.setFamily(data["f"] as string)
    }
    if (data["w"]) {
      this.setWeight(data["w"] as FontWeightEnum)
    }
    if (data["s"]) {
      this.setStyle(data["s"] as FontStyleEnum)
    }
    if (data["z"]) {
      this.setSize(data["z"] as number)
    }
    if (data["c"]) {
      this.color = new ProjectColor(this.p).fromJSON(data["c"])
    }
    if (data["a"]) {
      this.setSpacing(data["a"] as number)
    }
    return this
  }
  toJSON(): TPrimitive {
    const data: Record<string, TPrimitive> = {}
    if (this.provider) {
      data["p"] = this.provider
    }
    if (this.family) {
      data["f"] = this.family
    }
    if (this.weight !== FontWeightEnum.w400) {
      data["w"] = this.weight as string
    }
    if (this.style !== FontStyleEnum.normal) {
      data["s"] = this.style as string
    }
    if (this.size) {
      data["z"] = this.size
    }
    if (this.color) {
      data["c"] = this.color.toJSON() as TPrimitive
    }
    if (this.spacing) {
      data["a"] = this.spacing
    }
    return data
  }
}

export interface IProjectFont extends IDefault, IPrimitiveSerializable {
  reset(): this
  hasType(): boolean
  setType(t: string): this
  getType(): string
  get(): IFont

  setProvider(provider: string): this
  setFamily(family: string): this
  setWeight(weight: FontWeightEnum): this
  setStyle(style: FontStyleEnum): this
  setSize(size: number): this
  setColor(color: string): this
  setSpacing(spacing: number): this
}

export class ProjectFont extends PrimitiveSerializable implements IProjectFont {
  // @property project font type from typography
  protected t?: string
  protected d: Record<string, TPrimitive> = {}
  // @property font
  protected ff: IFont

  constructor(p?: IProject) {
    super(p)
    this.ff = new Font(p)
  }

  reset(): this {
    this.t = undefined
    this.d = {}
    this.ff = new Font(this.p)
    return this
  }

  isDefault(): boolean {
    return !this.t && this.ff && this.ff.isDefault()
  }

  setType(t: string): this {
    const font = this.p?.getFontByKey(t)
    if (font) {
      this.t = t
      this.d = {}
      //this.f = new Font(this.p).fromJSON(font.toJSON())
    } else {
      this.reset()
    }
    return this
  }

  hasType(): boolean {
    return !!this.t
  }

  getType(): string {
    return this.t || ""
  }

  get(): IFont {
    const font = this.t && this.p?.getFontByKey(this.t)
    if (font) {
      return new Font(this.p).fromJSON(font.toJSON() as TPrimitive).fromJSON(this.d)
    } else {
      return this.ff
    }
  }

  setProvider(provider: string): this {
    const font = this.t && this.p?.getFontByKey(this.t)
    if (font) {
      if (font.getProvider() !== provider) {
        this.d["p"] = provider
      } else {
        delete this.d["p"]
      }
    } else {
      this.ff.setProvider(provider)
    }
    return this
  }

  setFamily(family: string): this {
    const font = this.t && this.p?.getFontByKey(this.t)
    if (font) {
      if (font.getFamily() !== family) {
        this.d["f"] = family
      } else {
        delete this.d["f"]
      }
    } else {
      this.ff.setFamily(family)
    }
    return this
  }

  setWeight(weight: FontWeightEnum): this {
    const font = this.t && this.p?.getFontByKey(this.t)
    if (font) {
      if (font.getWeight() !== weight) {
        this.d["w"] = weight
      } else {
        delete this.d["w"]
      }
    } else {
      this.ff.setWeight(weight)
    }
    return this
  }

  setStyle(style: FontStyleEnum): this {
    const font = this.t && this.p?.getFontByKey(this.t)
    if (font) {
      if (font.getStyle() !== style) {
        this.d["s"] = style
      } else {
        delete this.d["s"]
      }
    } else {
      this.ff.setStyle(style)
    }
    return this
  }

  setSize(size: number): this {
    const font = this.t && this.p?.getFontByKey(this.t)
    if (font) {
      if (font.getSize() !== size) {
        this.d["z"] = size
      } else {
        delete this.d["z"]
      }
    } else {
      this.ff.setSize(size)
    }
    return this
  }

  setColor(color: string): this {
    const font = this.t && this.p?.getFontByKey(this.t)
    if (font) {
      const pc = new ProjectColor(this.p).set(color)
      if (font.getColor().toJSON() !== pc.toJSON()) {
        this.d["c"] = pc.toJSON()
      } else {
        delete this.d["c"]
      }
    } else {
      this.ff.getColor().set(color)
    }
    return this
  }

  setSpacing(spacing: number): this {
    const font = this.t && this.p?.getFontByKey(this.t)
    if (font) {
      if (font.getSpacing() !== spacing) {
        this.d["a"] = spacing
      } else {
        delete this.d["a"]
      }
    } else {
      this.ff.setSpacing(spacing)
    }
    return this
  }

  fromJSON(v: TPrimitive): this {
    const data = v as Record<string, TPrimitive>
    if (data["t"]) {
      const t = data["t"] as string
      const font = this.p?.getFontByKey(t)
      if (font) {
        this.t = t
        delete data["t"]
        this.d = Object.assign({}, data)
        //this.f = font
      }
    }
    this.ff.fromJSON(data)
    return this
  }

  toJSON(): TPrimitive {
    let data: Record<string, TPrimitive> = {}
    // add project typography font diff
    const projectFont = this.t && this.p?.getFontByKey(this.t)
    if (projectFont) {
      data["t"] = this.t || ""
      Object.assign(data, this.d)
      //data[] = t.d

      // const projectFontData = projectFont.toJSON() as Record<string, TPrimitive>
      // const fontData = this.f.toJSON() as Record<string, TPrimitive>
      // if (fontData) {
      //   Object.keys(fontData).forEach((k) => {
      //     if (fontData[k] !== projectFontData[k]) {
      //       data[k] = fontData[k]
      //     }
      //   })
      // }
    } else {
      data = this.ff.toJSON() as Record<string, TPrimitive>
    }
    return data
  }
}
