import { TPrimitive, PrimitiveSerializable, IDefault, IPrimitiveSerializable, IReset } from "./primitive"

export interface ISpace extends IDefault, IPrimitiveSerializable, IReset {
  reset(): this
  set(l: number, t: number, r: number, b: number): this
  setAll(v: number): this
  getTop(): number
  setTop(v: number): this
  getRight(): number
  setRight(v: number): this
  getBottom(): number
  setBottom(v: number): this
  getLeft(): number
  setLeft(v: number): this
}

export class Space extends PrimitiveSerializable implements ISpace {
  protected t = 0
  protected r = 0
  protected b = 0
  protected l = 0

  reset(): this {
    this.t = 0
    this.r = 0
    this.b = 0
    this.l = 0
    return this
  }

  isDefault(): boolean {
    return !(this.t || this.r || this.b || this.l)
  }

  private _filter(v: number): number {
    return v
  }

  set(l: number, t: number, r: number, b: number): this {
    return this.setLeft(l).setTop(t).setRight(r).setBottom(b)
  }

  setAll(v: number): this {
    return this.set(v, v, v, v)
  }

  getTop(): number {
    return this.t
  }

  setTop(v: number): this {
    this.t = this._filter(v)
    return this
  }

  getRight(): number {
    return this.r
  }

  setRight(v: number): this {
    this.r = this._filter(v)
    return this
  }

  getBottom(): number {
    return this.b
  }

  setBottom(v: number): this {
    this.b = this._filter(v)
    return this
  }

  getLeft(): number {
    return this.l
  }

  setLeft(v: number): this {
    this.l = this._filter(v)
    return this
  }

  fromJSON(v: TPrimitive): this {
    const data: string[] = (v as string).split(" ")
    if (data[0]) {
      this.t = parseInt(data[0], 10)
      this.r = this.t
      this.b = this.t
      this.l = this.t
    }
    if (data[1]) {
      this.r = parseInt(data[1], 10)
      this.l = this.r
    }
    if (data[2]) {
      this.b = parseInt(data[2], 10)
    }
    if (data[3]) {
      this.l = parseInt(data[3], 10)
    }
    return this
  }
  toJSON(): TPrimitive {
    if (this.t == this.r && this.r == this.b && this.b == this.l) {
      return `${this.t}`
    } else if (this.t == this.b && this.r == this.l) {
      return `${this.t} ${this.r}`
    } else if (this.r == this.l) {
      return `${this.t} ${this.r} ${this.b}`
    }
    return `${this.t} ${this.r} ${this.b} ${this.l}`
  }
}
