import { memo } from "react"

import styles from "styles/builder/leftpanel.module.css"

import { Grid } from "@mantine/core"
import { selectedMainTabAtom } from "service/appstate/builder/atoms"
import { useRecoilState } from "recoil"

const TabsHeader = memo((): JSX.Element => {
  const [selectedMainTab, selectMainTab] = useRecoilState(selectedMainTabAtom)

  return (
    <Grid
      className={styles.tabsHeader}
      style={{
        boxShadow: "0 1px 0 0 rgb(0 0 0 / 10%)",
        zIndex: 5,
        marginTop: 0,
        marginBottom: 5,
        padding: "0 14px"
      }}
      justify="center"
    >
      <Grid.Col
        sx={{
          fontFamily: "Syne",
          padding: 0
        }}
        span={4}
        onClick={() => selectMainTab("layers")}
        className={[styles.tab, selectedMainTab === "layers" ? styles.tabActive : styles.tabDeactivated].join(" ")}
        data-label="Layers"
      />
      <Grid.Col
        sx={{
          fontFamily: "Syne",
          padding: 0
        }}
        span={4}
        onClick={() => selectMainTab("assets")}
        className={[styles.tab, selectedMainTab === "assets" ? styles.tabActive : ""].join(" ")}
        data-label="Assets"
      />
      <Grid.Col
        sx={{
          fontFamily: "Syne",
          padding: 0
        }}
        span={4}
        onClick={() => selectMainTab("settings")}
        className={[styles.tab, selectedMainTab === "settings" ? styles.tabActive : ""].join(" ")}
        data-label="Settings"
      />
    </Grid>
  )
})

export default TabsHeader
