// source: ide-resource.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.jupiter.builder.v1.GetIdeFontListResponse', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.GetIdeIconListResponse', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.GetIdeWidgetListResponse', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.IdeFont', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.IdeIcon', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.IdeWidget', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.IdeWidgetProperty', null, global);
goog.exportSymbol('proto.jupiter.builder.v1.VersionCursorRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.VersionCursorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jupiter.builder.v1.VersionCursorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.VersionCursorRequest.displayName = 'proto.jupiter.builder.v1.VersionCursorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.GetIdeWidgetListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jupiter.builder.v1.GetIdeWidgetListResponse.repeatedFields_, null);
};
goog.inherits(proto.jupiter.builder.v1.GetIdeWidgetListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.GetIdeWidgetListResponse.displayName = 'proto.jupiter.builder.v1.GetIdeWidgetListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.IdeWidget = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jupiter.builder.v1.IdeWidget.repeatedFields_, null);
};
goog.inherits(proto.jupiter.builder.v1.IdeWidget, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.IdeWidget.displayName = 'proto.jupiter.builder.v1.IdeWidget';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.IdeWidgetProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jupiter.builder.v1.IdeWidgetProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.IdeWidgetProperty.displayName = 'proto.jupiter.builder.v1.IdeWidgetProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.GetIdeFontListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jupiter.builder.v1.GetIdeFontListResponse.repeatedFields_, null);
};
goog.inherits(proto.jupiter.builder.v1.GetIdeFontListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.GetIdeFontListResponse.displayName = 'proto.jupiter.builder.v1.GetIdeFontListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.IdeFont = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jupiter.builder.v1.IdeFont.repeatedFields_, null);
};
goog.inherits(proto.jupiter.builder.v1.IdeFont, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.IdeFont.displayName = 'proto.jupiter.builder.v1.IdeFont';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.GetIdeIconListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jupiter.builder.v1.GetIdeIconListResponse.repeatedFields_, null);
};
goog.inherits(proto.jupiter.builder.v1.GetIdeIconListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.GetIdeIconListResponse.displayName = 'proto.jupiter.builder.v1.GetIdeIconListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jupiter.builder.v1.IdeIcon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jupiter.builder.v1.IdeIcon.repeatedFields_, null);
};
goog.inherits(proto.jupiter.builder.v1.IdeIcon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jupiter.builder.v1.IdeIcon.displayName = 'proto.jupiter.builder.v1.IdeIcon';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.VersionCursorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.VersionCursorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.VersionCursorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.VersionCursorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cursor: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.VersionCursorRequest}
 */
proto.jupiter.builder.v1.VersionCursorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.VersionCursorRequest;
  return proto.jupiter.builder.v1.VersionCursorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.VersionCursorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.VersionCursorRequest}
 */
proto.jupiter.builder.v1.VersionCursorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCursor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.VersionCursorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.VersionCursorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.VersionCursorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.VersionCursorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCursor();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string version = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.VersionCursorRequest.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.VersionCursorRequest} returns this
 */
proto.jupiter.builder.v1.VersionCursorRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 cursor = 2;
 * @return {number}
 */
proto.jupiter.builder.v1.VersionCursorRequest.prototype.getCursor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jupiter.builder.v1.VersionCursorRequest} returns this
 */
proto.jupiter.builder.v1.VersionCursorRequest.prototype.setCursor = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jupiter.builder.v1.GetIdeWidgetListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.GetIdeWidgetListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.GetIdeWidgetListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.GetIdeWidgetListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeWidgetListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    widgetList: jspb.Message.toObjectList(msg.getWidgetList(),
    proto.jupiter.builder.v1.IdeWidget.toObject, includeInstance),
    cursor: jspb.Message.getFieldWithDefault(msg, 2, 0),
    version: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.GetIdeWidgetListResponse}
 */
proto.jupiter.builder.v1.GetIdeWidgetListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.GetIdeWidgetListResponse;
  return proto.jupiter.builder.v1.GetIdeWidgetListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.GetIdeWidgetListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.GetIdeWidgetListResponse}
 */
proto.jupiter.builder.v1.GetIdeWidgetListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jupiter.builder.v1.IdeWidget;
      reader.readMessage(value,proto.jupiter.builder.v1.IdeWidget.deserializeBinaryFromReader);
      msg.addWidget(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCursor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.GetIdeWidgetListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.GetIdeWidgetListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.GetIdeWidgetListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeWidgetListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidgetList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.jupiter.builder.v1.IdeWidget.serializeBinaryToWriter
    );
  }
  f = message.getCursor();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated IdeWidget widget = 1;
 * @return {!Array<!proto.jupiter.builder.v1.IdeWidget>}
 */
proto.jupiter.builder.v1.GetIdeWidgetListResponse.prototype.getWidgetList = function() {
  return /** @type{!Array<!proto.jupiter.builder.v1.IdeWidget>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jupiter.builder.v1.IdeWidget, 1));
};


/**
 * @param {!Array<!proto.jupiter.builder.v1.IdeWidget>} value
 * @return {!proto.jupiter.builder.v1.GetIdeWidgetListResponse} returns this
*/
proto.jupiter.builder.v1.GetIdeWidgetListResponse.prototype.setWidgetList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.jupiter.builder.v1.IdeWidget=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.IdeWidget}
 */
proto.jupiter.builder.v1.GetIdeWidgetListResponse.prototype.addWidget = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jupiter.builder.v1.IdeWidget, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.GetIdeWidgetListResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeWidgetListResponse.prototype.clearWidgetList = function() {
  return this.setWidgetList([]);
};


/**
 * optional int64 cursor = 2;
 * @return {number}
 */
proto.jupiter.builder.v1.GetIdeWidgetListResponse.prototype.getCursor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jupiter.builder.v1.GetIdeWidgetListResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeWidgetListResponse.prototype.setCursor = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string version = 3;
 * @return {string}
 */
proto.jupiter.builder.v1.GetIdeWidgetListResponse.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.GetIdeWidgetListResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeWidgetListResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jupiter.builder.v1.IdeWidget.repeatedFields_ = [6,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.IdeWidget.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.IdeWidget.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.IdeWidget} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeWidget.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tagList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    propertyList: jspb.Message.toObjectList(msg.getPropertyList(),
    proto.jupiter.builder.v1.IdeWidgetProperty.toObject, includeInstance),
    order: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.IdeWidget}
 */
proto.jupiter.builder.v1.IdeWidget.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.IdeWidget;
  return proto.jupiter.builder.v1.IdeWidget.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.IdeWidget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.IdeWidget}
 */
proto.jupiter.builder.v1.IdeWidget.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addTag(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    case 8:
      var value = new proto.jupiter.builder.v1.IdeWidgetProperty;
      reader.readMessage(value,proto.jupiter.builder.v1.IdeWidgetProperty.deserializeBinaryFromReader);
      msg.addProperty(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.IdeWidget.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.IdeWidget.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.IdeWidget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeWidget.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTagList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getPropertyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.jupiter.builder.v1.IdeWidgetProperty.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeWidget.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeWidget} returns this
 */
proto.jupiter.builder.v1.IdeWidget.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeWidget.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeWidget} returns this
 */
proto.jupiter.builder.v1.IdeWidget.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeWidget.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeWidget} returns this
 */
proto.jupiter.builder.v1.IdeWidget.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeWidget.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeWidget} returns this
 */
proto.jupiter.builder.v1.IdeWidget.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeWidget.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeWidget} returns this
 */
proto.jupiter.builder.v1.IdeWidget.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string tag = 6;
 * @return {!Array<string>}
 */
proto.jupiter.builder.v1.IdeWidget.prototype.getTagList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.jupiter.builder.v1.IdeWidget} returns this
 */
proto.jupiter.builder.v1.IdeWidget.prototype.setTagList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.IdeWidget} returns this
 */
proto.jupiter.builder.v1.IdeWidget.prototype.addTag = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.IdeWidget} returns this
 */
proto.jupiter.builder.v1.IdeWidget.prototype.clearTagList = function() {
  return this.setTagList([]);
};


/**
 * optional bool is_enabled = 7;
 * @return {boolean}
 */
proto.jupiter.builder.v1.IdeWidget.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jupiter.builder.v1.IdeWidget} returns this
 */
proto.jupiter.builder.v1.IdeWidget.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated IdeWidgetProperty property = 8;
 * @return {!Array<!proto.jupiter.builder.v1.IdeWidgetProperty>}
 */
proto.jupiter.builder.v1.IdeWidget.prototype.getPropertyList = function() {
  return /** @type{!Array<!proto.jupiter.builder.v1.IdeWidgetProperty>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jupiter.builder.v1.IdeWidgetProperty, 8));
};


/**
 * @param {!Array<!proto.jupiter.builder.v1.IdeWidgetProperty>} value
 * @return {!proto.jupiter.builder.v1.IdeWidget} returns this
*/
proto.jupiter.builder.v1.IdeWidget.prototype.setPropertyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.jupiter.builder.v1.IdeWidgetProperty=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.IdeWidgetProperty}
 */
proto.jupiter.builder.v1.IdeWidget.prototype.addProperty = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.jupiter.builder.v1.IdeWidgetProperty, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.IdeWidget} returns this
 */
proto.jupiter.builder.v1.IdeWidget.prototype.clearPropertyList = function() {
  return this.setPropertyList([]);
};


/**
 * optional int64 order = 9;
 * @return {number}
 */
proto.jupiter.builder.v1.IdeWidget.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.jupiter.builder.v1.IdeWidget} returns this
 */
proto.jupiter.builder.v1.IdeWidget.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.IdeWidgetProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.IdeWidgetProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.IdeWidgetProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeWidgetProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pb_enum: (f = msg.getEnum()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    rawValue: (f = msg.getRawValue()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    group: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.IdeWidgetProperty}
 */
proto.jupiter.builder.v1.IdeWidgetProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.IdeWidgetProperty;
  return proto.jupiter.builder.v1.IdeWidgetProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.IdeWidgetProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.IdeWidgetProperty}
 */
proto.jupiter.builder.v1.IdeWidgetProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setEnum(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRawValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.IdeWidgetProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.IdeWidgetProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.IdeWidgetProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeWidgetProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnum();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRawValue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getGroup();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeWidgetProperty.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeWidgetProperty} returns this
 */
proto.jupiter.builder.v1.IdeWidgetProperty.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeWidgetProperty.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeWidgetProperty} returns this
 */
proto.jupiter.builder.v1.IdeWidgetProperty.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue enum = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.jupiter.builder.v1.IdeWidgetProperty.prototype.getEnum = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.jupiter.builder.v1.IdeWidgetProperty} returns this
*/
proto.jupiter.builder.v1.IdeWidgetProperty.prototype.setEnum = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jupiter.builder.v1.IdeWidgetProperty} returns this
 */
proto.jupiter.builder.v1.IdeWidgetProperty.prototype.clearEnum = function() {
  return this.setEnum(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jupiter.builder.v1.IdeWidgetProperty.prototype.hasEnum = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue raw_value = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.jupiter.builder.v1.IdeWidgetProperty.prototype.getRawValue = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.jupiter.builder.v1.IdeWidgetProperty} returns this
*/
proto.jupiter.builder.v1.IdeWidgetProperty.prototype.setRawValue = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jupiter.builder.v1.IdeWidgetProperty} returns this
 */
proto.jupiter.builder.v1.IdeWidgetProperty.prototype.clearRawValue = function() {
  return this.setRawValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jupiter.builder.v1.IdeWidgetProperty.prototype.hasRawValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string group = 5;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeWidgetProperty.prototype.getGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeWidgetProperty} returns this
 */
proto.jupiter.builder.v1.IdeWidgetProperty.prototype.setGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jupiter.builder.v1.GetIdeFontListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.GetIdeFontListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.GetIdeFontListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.GetIdeFontListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeFontListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fontList: jspb.Message.toObjectList(msg.getFontList(),
    proto.jupiter.builder.v1.IdeFont.toObject, includeInstance),
    cursor: jspb.Message.getFieldWithDefault(msg, 2, 0),
    version: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.GetIdeFontListResponse}
 */
proto.jupiter.builder.v1.GetIdeFontListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.GetIdeFontListResponse;
  return proto.jupiter.builder.v1.GetIdeFontListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.GetIdeFontListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.GetIdeFontListResponse}
 */
proto.jupiter.builder.v1.GetIdeFontListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jupiter.builder.v1.IdeFont;
      reader.readMessage(value,proto.jupiter.builder.v1.IdeFont.deserializeBinaryFromReader);
      msg.addFont(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCursor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.GetIdeFontListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.GetIdeFontListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.GetIdeFontListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeFontListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFontList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.jupiter.builder.v1.IdeFont.serializeBinaryToWriter
    );
  }
  f = message.getCursor();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated IdeFont font = 1;
 * @return {!Array<!proto.jupiter.builder.v1.IdeFont>}
 */
proto.jupiter.builder.v1.GetIdeFontListResponse.prototype.getFontList = function() {
  return /** @type{!Array<!proto.jupiter.builder.v1.IdeFont>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jupiter.builder.v1.IdeFont, 1));
};


/**
 * @param {!Array<!proto.jupiter.builder.v1.IdeFont>} value
 * @return {!proto.jupiter.builder.v1.GetIdeFontListResponse} returns this
*/
proto.jupiter.builder.v1.GetIdeFontListResponse.prototype.setFontList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.jupiter.builder.v1.IdeFont=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.IdeFont}
 */
proto.jupiter.builder.v1.GetIdeFontListResponse.prototype.addFont = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jupiter.builder.v1.IdeFont, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.GetIdeFontListResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeFontListResponse.prototype.clearFontList = function() {
  return this.setFontList([]);
};


/**
 * optional int64 cursor = 2;
 * @return {number}
 */
proto.jupiter.builder.v1.GetIdeFontListResponse.prototype.getCursor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jupiter.builder.v1.GetIdeFontListResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeFontListResponse.prototype.setCursor = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string version = 3;
 * @return {string}
 */
proto.jupiter.builder.v1.GetIdeFontListResponse.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.GetIdeFontListResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeFontListResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jupiter.builder.v1.IdeFont.repeatedFields_ = [4,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.IdeFont.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.IdeFont.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.IdeFont} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeFont.toObject = function(includeInstance, msg) {
  var f, obj = {
    provider: jspb.Message.getFieldWithDefault(msg, 1, ""),
    family: jspb.Message.getFieldWithDefault(msg, 2, ""),
    v: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subsetList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    weightList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    styleList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    tagList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    order: jspb.Message.getFieldWithDefault(msg, 8, 0),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.IdeFont}
 */
proto.jupiter.builder.v1.IdeFont.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.IdeFont;
  return proto.jupiter.builder.v1.IdeFont.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.IdeFont} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.IdeFont}
 */
proto.jupiter.builder.v1.IdeFont.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvider(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFamily(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setV(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSubset(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addWeight(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addStyle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addTag(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrder(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.IdeFont.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.IdeFont.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.IdeFont} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeFont.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFamily();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getV();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubsetList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getWeightList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getStyleList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getTagList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string provider = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeFont.prototype.getProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeFont} returns this
 */
proto.jupiter.builder.v1.IdeFont.prototype.setProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string family = 2;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeFont.prototype.getFamily = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeFont} returns this
 */
proto.jupiter.builder.v1.IdeFont.prototype.setFamily = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string v = 3;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeFont.prototype.getV = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeFont} returns this
 */
proto.jupiter.builder.v1.IdeFont.prototype.setV = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string subset = 4;
 * @return {!Array<string>}
 */
proto.jupiter.builder.v1.IdeFont.prototype.getSubsetList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.jupiter.builder.v1.IdeFont} returns this
 */
proto.jupiter.builder.v1.IdeFont.prototype.setSubsetList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.IdeFont} returns this
 */
proto.jupiter.builder.v1.IdeFont.prototype.addSubset = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.IdeFont} returns this
 */
proto.jupiter.builder.v1.IdeFont.prototype.clearSubsetList = function() {
  return this.setSubsetList([]);
};


/**
 * repeated string weight = 5;
 * @return {!Array<string>}
 */
proto.jupiter.builder.v1.IdeFont.prototype.getWeightList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.jupiter.builder.v1.IdeFont} returns this
 */
proto.jupiter.builder.v1.IdeFont.prototype.setWeightList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.IdeFont} returns this
 */
proto.jupiter.builder.v1.IdeFont.prototype.addWeight = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.IdeFont} returns this
 */
proto.jupiter.builder.v1.IdeFont.prototype.clearWeightList = function() {
  return this.setWeightList([]);
};


/**
 * repeated string style = 6;
 * @return {!Array<string>}
 */
proto.jupiter.builder.v1.IdeFont.prototype.getStyleList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.jupiter.builder.v1.IdeFont} returns this
 */
proto.jupiter.builder.v1.IdeFont.prototype.setStyleList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.IdeFont} returns this
 */
proto.jupiter.builder.v1.IdeFont.prototype.addStyle = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.IdeFont} returns this
 */
proto.jupiter.builder.v1.IdeFont.prototype.clearStyleList = function() {
  return this.setStyleList([]);
};


/**
 * repeated string tag = 7;
 * @return {!Array<string>}
 */
proto.jupiter.builder.v1.IdeFont.prototype.getTagList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.jupiter.builder.v1.IdeFont} returns this
 */
proto.jupiter.builder.v1.IdeFont.prototype.setTagList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.IdeFont} returns this
 */
proto.jupiter.builder.v1.IdeFont.prototype.addTag = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.IdeFont} returns this
 */
proto.jupiter.builder.v1.IdeFont.prototype.clearTagList = function() {
  return this.setTagList([]);
};


/**
 * optional int64 order = 8;
 * @return {number}
 */
proto.jupiter.builder.v1.IdeFont.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.jupiter.builder.v1.IdeFont} returns this
 */
proto.jupiter.builder.v1.IdeFont.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool is_enabled = 9;
 * @return {boolean}
 */
proto.jupiter.builder.v1.IdeFont.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jupiter.builder.v1.IdeFont} returns this
 */
proto.jupiter.builder.v1.IdeFont.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jupiter.builder.v1.GetIdeIconListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.GetIdeIconListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.GetIdeIconListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.GetIdeIconListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeIconListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    iconList: jspb.Message.toObjectList(msg.getIconList(),
    proto.jupiter.builder.v1.IdeIcon.toObject, includeInstance),
    cursor: jspb.Message.getFieldWithDefault(msg, 2, 0),
    version: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.GetIdeIconListResponse}
 */
proto.jupiter.builder.v1.GetIdeIconListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.GetIdeIconListResponse;
  return proto.jupiter.builder.v1.GetIdeIconListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.GetIdeIconListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.GetIdeIconListResponse}
 */
proto.jupiter.builder.v1.GetIdeIconListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jupiter.builder.v1.IdeIcon;
      reader.readMessage(value,proto.jupiter.builder.v1.IdeIcon.deserializeBinaryFromReader);
      msg.addIcon(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCursor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.GetIdeIconListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.GetIdeIconListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.GetIdeIconListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.GetIdeIconListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIconList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.jupiter.builder.v1.IdeIcon.serializeBinaryToWriter
    );
  }
  f = message.getCursor();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated IdeIcon icon = 1;
 * @return {!Array<!proto.jupiter.builder.v1.IdeIcon>}
 */
proto.jupiter.builder.v1.GetIdeIconListResponse.prototype.getIconList = function() {
  return /** @type{!Array<!proto.jupiter.builder.v1.IdeIcon>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jupiter.builder.v1.IdeIcon, 1));
};


/**
 * @param {!Array<!proto.jupiter.builder.v1.IdeIcon>} value
 * @return {!proto.jupiter.builder.v1.GetIdeIconListResponse} returns this
*/
proto.jupiter.builder.v1.GetIdeIconListResponse.prototype.setIconList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.jupiter.builder.v1.IdeIcon=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.IdeIcon}
 */
proto.jupiter.builder.v1.GetIdeIconListResponse.prototype.addIcon = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jupiter.builder.v1.IdeIcon, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.GetIdeIconListResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeIconListResponse.prototype.clearIconList = function() {
  return this.setIconList([]);
};


/**
 * optional int64 cursor = 2;
 * @return {number}
 */
proto.jupiter.builder.v1.GetIdeIconListResponse.prototype.getCursor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jupiter.builder.v1.GetIdeIconListResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeIconListResponse.prototype.setCursor = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string version = 3;
 * @return {string}
 */
proto.jupiter.builder.v1.GetIdeIconListResponse.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.GetIdeIconListResponse} returns this
 */
proto.jupiter.builder.v1.GetIdeIconListResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jupiter.builder.v1.IdeIcon.repeatedFields_ = [2,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jupiter.builder.v1.IdeIcon.prototype.toObject = function(opt_includeInstance) {
  return proto.jupiter.builder.v1.IdeIcon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jupiter.builder.v1.IdeIcon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeIcon.toObject = function(includeInstance, msg) {
  var f, obj = {
    provider: jspb.Message.getFieldWithDefault(msg, 1, ""),
    styleList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    key: jspb.Message.getFieldWithDefault(msg, 3, ""),
    codepoint: jspb.Message.getFieldWithDefault(msg, 4, 0),
    v: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tagList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    order: jspb.Message.getFieldWithDefault(msg, 7, 0),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jupiter.builder.v1.IdeIcon}
 */
proto.jupiter.builder.v1.IdeIcon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jupiter.builder.v1.IdeIcon;
  return proto.jupiter.builder.v1.IdeIcon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jupiter.builder.v1.IdeIcon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jupiter.builder.v1.IdeIcon}
 */
proto.jupiter.builder.v1.IdeIcon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvider(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addStyle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCodepoint(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setV(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addTag(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrder(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jupiter.builder.v1.IdeIcon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jupiter.builder.v1.IdeIcon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jupiter.builder.v1.IdeIcon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jupiter.builder.v1.IdeIcon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStyleList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCodepoint();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getV();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTagList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string provider = 1;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeIcon.prototype.getProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeIcon} returns this
 */
proto.jupiter.builder.v1.IdeIcon.prototype.setProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string style = 2;
 * @return {!Array<string>}
 */
proto.jupiter.builder.v1.IdeIcon.prototype.getStyleList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.jupiter.builder.v1.IdeIcon} returns this
 */
proto.jupiter.builder.v1.IdeIcon.prototype.setStyleList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.IdeIcon} returns this
 */
proto.jupiter.builder.v1.IdeIcon.prototype.addStyle = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.IdeIcon} returns this
 */
proto.jupiter.builder.v1.IdeIcon.prototype.clearStyleList = function() {
  return this.setStyleList([]);
};


/**
 * optional string key = 3;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeIcon.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeIcon} returns this
 */
proto.jupiter.builder.v1.IdeIcon.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 codepoint = 4;
 * @return {number}
 */
proto.jupiter.builder.v1.IdeIcon.prototype.getCodepoint = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jupiter.builder.v1.IdeIcon} returns this
 */
proto.jupiter.builder.v1.IdeIcon.prototype.setCodepoint = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string v = 5;
 * @return {string}
 */
proto.jupiter.builder.v1.IdeIcon.prototype.getV = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.jupiter.builder.v1.IdeIcon} returns this
 */
proto.jupiter.builder.v1.IdeIcon.prototype.setV = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string tag = 6;
 * @return {!Array<string>}
 */
proto.jupiter.builder.v1.IdeIcon.prototype.getTagList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.jupiter.builder.v1.IdeIcon} returns this
 */
proto.jupiter.builder.v1.IdeIcon.prototype.setTagList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jupiter.builder.v1.IdeIcon} returns this
 */
proto.jupiter.builder.v1.IdeIcon.prototype.addTag = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jupiter.builder.v1.IdeIcon} returns this
 */
proto.jupiter.builder.v1.IdeIcon.prototype.clearTagList = function() {
  return this.setTagList([]);
};


/**
 * optional int64 order = 7;
 * @return {number}
 */
proto.jupiter.builder.v1.IdeIcon.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.jupiter.builder.v1.IdeIcon} returns this
 */
proto.jupiter.builder.v1.IdeIcon.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool is_enabled = 8;
 * @return {boolean}
 */
proto.jupiter.builder.v1.IdeIcon.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jupiter.builder.v1.IdeIcon} returns this
 */
proto.jupiter.builder.v1.IdeIcon.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


goog.object.extend(exports, proto.jupiter.builder.v1);
