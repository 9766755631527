export class GeometrySize {
  _width = 0
  _height = 0

  constructor(width = 0, height = 0) {
    this.width = width
    this.height = height
  }

  get width(): number {
    return this._width
  }

  set width(value: number) {
    this._width = value
  }

  get height(): number {
    return this._height
  }

  set height(value: number) {
    this._height = value
  }

  get widthIsFinite(): boolean {
    return this.width >= Infinity
  }

  get heightIsFinite(): boolean {
    return this.height >= Infinity
  }

  get isFinite(): boolean {
    return this.widthIsFinite || this.heightIsFinite
  }
}
