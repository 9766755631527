import { IEventDispatchType, TCallbackType, TEventDataType, TEventTrigger, TRefMouseOn } from "types/utils/actionsType"
import { IListDispatch } from "types/pages/dashboardTypes"
import { IPubSubResponse } from "types/pubsub/pubsubTypes"

type TCallbackFun = () => void
export class EventEmitterClass {
  _events: {
    [key: string]: TCallbackFun[]
  }

  constructor() {
    this._events = {}
  }

  on(type: string, cb: (e: never, ref?: TRefMouseOn | undefined) => void): this {
    if (!this._events[type]) this._events[type] = []
    if (~this._events[type].indexOf(cb as () => void)) return this
    this._events[type].push(cb as () => void)
    return this
  }
  off(type: string, cb: IEventDispatchType | IListDispatch | ((arg: IPubSubResponse) => void)): this {
    if (!this._events[type]) return this
    if (!cb) {
      this._events[type] = []
      return this
    }
    const index = this._events[type].indexOf(cb as () => void)
    if (!~index) return this
    this._events[type].splice(index, 1)
    return this
  }
  trigger(type: string, ...data: TEventDataType[]): TEventTrigger {
    if (!this._events[type]) return this
    this._events[type].forEach(
      function (cb: TCallbackType) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cb.apply(this, [...data])
      }.bind(this)
    )
  }
}

const EventEmitter = new EventEmitterClass()
export default EventEmitter
