import { fabric } from "fabric"
import { ILogger } from "js-logger"
import { DeviceInfo } from "./device"
import { PageData } from "model/page"
import { ENodeEventList, TNodeEvent } from "model/entityEvent"
import { WidgetFactory } from "./component"
import { injectGeometry } from "./geometry"
import { Contstraints } from "./geometry/constraints"
import { Hightlight } from "./component/hightlight"
import { Geometry } from "./geometry/geometry"
import { PageWidget } from "model/pageWidget"
import { IdeIcon } from "service/pb/ide-resource_pb"
import { EmptyScreenWidget } from "./component/emptyScreen"

interface IScreenOptions {
  logger?: ILogger
  canvasOptions?: fabric.ICanvasOptions
}

export class Screen {
  private c: fabric.Canvas
  private logger?: ILogger
  private device?: DeviceInfo
  private isKeyboardVisible = false
  private hoveredShape?: fabric.Rect & { elementName?: string }
  private selectedShape?: fabric.Rect & { elementName?: string }
  private tree?: PageData
  lastSelectedEl?: string
  private iconsList: IdeIcon.AsObject[]
  private geometry: Geometry[] = []
  private originalSize = {
    width: 0,
    height: 0
  }

  constructor(id: string, options: IScreenOptions, iconsList: IdeIcon.AsObject[]) {
    this.c = new fabric.Canvas(id, options.canvasOptions)
    this.c.hoverCursor = "default"
    this.c.selection = false
    this.logger = options.logger
    this.iconsList = iconsList
    this.originalSize = {
      width: options.canvasOptions?.width || 0,
      height: options.canvasOptions?.height || 0
    }
  }

  private get constraints() {
    let height = this.originalSize.height

    if (this.device) {
      if (this.isKeyboardVisible) {
        height = this.device.height - this.device.keyboardHeight
      } else {
        height = this.device.height
      }
    }

    return new Contstraints({
      minWidth: 0,
      minHeight: 0,
      maxWidth: this.originalSize.width,
      maxHeight: height
    })
  }

  get body(): PageWidget | undefined {
    return this.tree?.child
  }

  get rootGeometry(): Geometry | undefined {
    return this.geometry.find((item) => item.extract().id === this.body?.id)?.extract()
  }

  init(tree?: PageData): void {
    if (tree) {
      this.tree = tree
    }

    const screenConstraints = new Contstraints({
      minWidth: 0,
      minHeight: 0,
      maxWidth: this.originalSize.width,
      maxHeight: this.originalSize.height
    })

    if (this.body) {
      injectGeometry(this.body, this.geometry, screenConstraints, true, this.iconsList)

      this.body.walk((node) => {
        const _pageWidget = node as PageWidget
        if (_pageWidget.type) {
          const pageWidgetGeometry = this.geometry.find((item) => item.extract().id === node.id)
          if (pageWidgetGeometry) {
            const widget = WidgetFactory.get(_pageWidget.type, {
              pageWidget: _pageWidget,
              ctx: this.c,
              geometry: pageWidgetGeometry.extract(),
              iconsList: this.iconsList
            })
            if (widget) {
              widget.selectable = false
              this.c.add(widget)
            }
          }
        }
      })
    } else {
      this.c.add(
        new EmptyScreenWidget({
          canvas: this.c,
          screenWidth: this.originalSize.width,
          screenHeight: this.originalSize.height
        })
      )
    }

    this.c.renderAll()

    this.initEvent()

    if (this.tree?.getSelected()) {
      this.c.fire("mouse:move")
      this.c.fire("mouse:down")
    }
  }

  cleanup(): void {
    this.geometry = []
    this.selectedShape && delete this.selectedShape
    this.hoveredShape && delete this.hoveredShape
    this.tree?.e?.off(ENodeEventList.TreeImported, this.onTreeImported)
    this.tree?.e?.off(ENodeEventList.NodeAdded, this.onNodeAdded)
    this.tree?.e?.off(ENodeEventList.NodeDeleted, this.onNodeDeleted)
    this.tree?.e?.off(ENodeEventList.NodePropertyUpdated, this.onNodePropertyUpdated)
    this.tree?.e?.off(ENodeEventList.NodeSelected, this.onNodeSelected)
    this.tree?.e?.off(ENodeEventList.NodeOver, this.onNodeHovered)
    this.tree?.e?.off(ENodeEventList.NodeOut, this.onUnhover)
    this.c.off("drop", this.onDrop)
    this.c.off("mouse:move", this.onMouseMove)
    this.c.off("mouse:down", this.onMouseDown)
    this.c.off("mouse:out", this.onMouseOut)
    this.c.remove(...this.c.getObjects())
  }

  setKeyboardVisible(v: boolean): void {
    this.isKeyboardVisible = v

    this.rootGeometry?.layout(this.constraints)

    this.c.renderAll()

    this.c.fire("mouse:move")
    this.c.fire("mouse:down")
  }

  setZoom(z: number): void {
    const zoomedWidth = this.originalSize.width * z
    const zoomedHeight = this.originalSize.height * z

    this.c.setWidth(zoomedWidth)
    this.c.setHeight(zoomedHeight)

    this.c.setZoom(z)
  }

  setDevice(device: DeviceInfo): void {
    this.device = device

    const zoom = this.c.getZoom()

    this.originalSize = {
      width: device.width,
      height: device.height
    }

    this.c.setWidth(this.originalSize.width * zoom)
    this.c.setHeight(this.originalSize.height * zoom)

    this.cleanup()
    this.init()
  }

  removeWidget(id?: string): void {
    const targetObject = this.c.getObjects()

    const removedWidget = targetObject.find((item) => {
      return item.id === id
    })

    if (removedWidget) {
      this.c.remove(removedWidget)
    }

    const removedChildren = targetObject.filter((item) => {
      return item.fabricParent?.id === id
    })

    removedChildren.forEach((item) => {
      this.removeWidget(item.id)
    })

    this.c.remove(this.selectedShape as fabric.Object)
    this.c.remove(this.hoveredShape as fabric.Object)
    delete this.selectedShape
    delete this.hoveredShape
  }

  onNodeDeleted = (): void => {
    this.cleanup()
    this.init()
  }

  onNodeAdded = (): void => {
    this.cleanup()
    this.init()
  }

  onTreeImported = (): void => {
    this.lastSelectedEl = undefined
    this.cleanup()
    this.init()
  }

  onDrop = (e: fabric.IEvent): void => {
    e.e.preventDefault()
    e.e.stopPropagation()
    const event = e.e as DragEvent
    const transaferedData = event.dataTransfer?.getData("text/plain")

    if (!transaferedData) {
      return
    }
    const widgetType = JSON.parse(transaferedData).type

    if (!widgetType) {
      return
    }

    const point = {
      x: event.offsetX,
      y: event.offsetY
    }

    const selectedEl = this.rootGeometry?.isCursorInRect(point)

    let targetPageWidget
    if (!selectedEl) {
      targetPageWidget = this.tree
    } else {
      targetPageWidget = this.tree?.findById(selectedEl)
    }

    const canHasChild = targetPageWidget?.canAddChildByType(widgetType)

    const addedWidget = this.tree?.getWidgetByType(widgetType)

    if (canHasChild === "" && addedWidget?.type) {
      targetPageWidget?.addWidget(addedWidget)
    } else {
      let errorMessage = "Error"

      switch (canHasChild) {
        case "one-child": {
          errorMessage = "Widget can only has one child"
          break
        }
        case "no-child": {
          errorMessage = "Widget has no children/child capabillity"
          break
        }
      }

      return alert(errorMessage)
    }
  }

  onMouseDown = (e: fabric.IEvent<Event>): void => {
    if (!this.body) {
      return
    }

    const zoom = this.c.getZoom()

    if (e.pointer) {
      const point = {
        x: e.pointer.x / zoom,
        y: e.pointer.y / zoom
      }

      this.lastSelectedEl = this.rootGeometry?.isCursorInRect(point)
    }

    if (!this.lastSelectedEl) {
      return this.tree?.unsetSelected()
    }

    const selectedItem = this.c.getObjects().find((item) => {
      return item.id === this.lastSelectedEl
    })

    if (selectedItem) {
      const pageWidget = this.tree?.findById(this.lastSelectedEl)
      const selectedInTree = this.tree?.getSelected()
      if (pageWidget) {
        if (selectedInTree?.id !== pageWidget.id) {
          this.tree?.setSelected(pageWidget)
        } else {
          this.onNodeSelected({ node: pageWidget })
        }
      }
    } else {
      this.tree?.unsetSelected()
    }
  }

  onMouseMove = (e: fabric.IEvent<Event>): void => {
    if (!this.body || !e.pointer) {
      this.c.remove(this.hoveredShape as fabric.Object)
      delete this.hoveredShape
      return
    }

    const point = {
      x: e.pointer.x / this.c.getZoom(),
      y: e.pointer.y / this.c.getZoom()
    }

    if (!this.rootGeometry) {
      return
    }

    const res = this.rootGeometry?.isCursorInRect(point)

    if (res) {
      const pageWidget = this.tree?.findById(res)
      if (pageWidget) {
        if (pageWidget?.id !== this.tree?.getHovered()?.id) {
          this.tree?.setHovered(pageWidget)
        }
      }
    }
  }

  onNodePropertyUpdated = (): void => {
    this.geometry = []

    if (this.body) {
      const screenConstraints = new Contstraints({
        minWidth: 0,
        minHeight: 0,
        maxWidth: this.originalSize.width,
        maxHeight: this.originalSize.height
      })

      injectGeometry(this.body, this.geometry, screenConstraints, true, this.iconsList)

      this.c.getObjects().forEach((widget) => {
        const geometry = this.geometry.find((item) => item.extract().id === widget.id)
        if (geometry) {
          widget.set({ dirty: true, geometry: geometry.extract() })
        }
      })

      this.c.renderAll()

      this.c.fire("mouse:move")
      this.c.fire("mouse:down")
    }
  }

  onNodeHovered = (e: TNodeEvent): void => {
    const pageWidget = e.node as PageWidget

    if (!pageWidget) {
      return
    }

    const hoveredItem = this.c.getObjects().find((item) => {
      return item.id === pageWidget.id
    }) as fabric.Object & { id: string }

    if (hoveredItem && hoveredItem.id) {
      if (this.hoveredShape) {
        this.hoveredShape
          .set({
            width: hoveredItem.width ? hoveredItem.width - 3 : 0,
            height: hoveredItem.height ? hoveredItem.height - 3 : 0,
            top: hoveredItem.top,
            left: hoveredItem.left,
            elementName: hoveredItem.type
          })
          .bringToFront()
      } else {
        this.hoveredShape = new Hightlight({
          width: hoveredItem.width ? hoveredItem.width - 3 : 0,
          height: hoveredItem.height ? hoveredItem.height - 3 : 0,
          top: hoveredItem.top,
          left: hoveredItem.left,
          fill: "transparent",
          stroke: "#ee8b60",
          strokeWidth: 3,
          selectable: false,
          elementName: hoveredItem.type
        }) as fabric.Object

        this.c.add(this.hoveredShape)
      }

      this.c.renderAll()
    } else {
      this.c.remove(this.hoveredShape as fabric.Object)
      delete this.hoveredShape
      this.c.renderAll()
    }
  }

  onNodeSelected = (e?: TNodeEvent): void => {
    const pageWidget = e?.node as PageWidget

    const selectedItem = this.c.getObjects().find((item) => {
      return item.id === pageWidget.id
    })

    this.lastSelectedEl = pageWidget.id

    if (!selectedItem) {
      return
    }

    if (this.selectedShape) {
      this.selectedShape
        .set({
          width: selectedItem.width ? selectedItem.width - 3 : 0,
          height: selectedItem.height ? selectedItem.height - 3 : 0,
          top: selectedItem.top,
          left: selectedItem.left,
          elementName: selectedItem.type
        })
        .bringToFront()
    } else {
      this.selectedShape = new Hightlight({
        width: selectedItem.width ? selectedItem.width - 3 : 0,
        height: selectedItem.height ? selectedItem.height - 3 : 0,
        top: selectedItem.top,
        left: selectedItem.left,
        fill: "transparent",
        stroke: "#249689",
        strokeWidth: 3,
        selectable: false,
        elementName: selectedItem.type
      }) as fabric.Rect

      this.c.add(this.selectedShape)
    }

    this.c.renderAll()
  }

  onNodeUnselected = (): void => {
    this.onNodeSelected()
  }

  onUnhover = (): void => {
    if (this.hoveredShape) {
      this.c.remove(this.hoveredShape as fabric.Object)
      delete this.hoveredShape
    }
  }

  onMouseOut = (e: fabric.IEvent<Event>): void => {
    const event = e.e as MouseEvent,
      x = event.offsetX,
      y = event.offsetY

    if (e.target === null || x < 0 || x > this.originalSize.width || y < 0 || y > this.originalSize.height) {
      this.tree?.unsetHovered()
    }
  }

  initEvent(): void {
    this.tree?.e?.on(ENodeEventList.TreeImported, this.onTreeImported)
    this.tree?.e?.on(ENodeEventList.NodeAdded, this.onNodeAdded)
    this.tree?.e?.on(ENodeEventList.NodeDeleted, this.onNodeDeleted)
    this.tree?.e?.on(ENodeEventList.NodePropertyUpdated, this.onNodePropertyUpdated)
    this.tree?.e?.on(ENodeEventList.NodeSelected, this.onNodeSelected)
    this.tree?.e?.on(ENodeEventList.NodeOver, this.onNodeHovered)
    this.tree?.e?.on(ENodeEventList.NodeOut, this.onUnhover)

    document.addEventListener("keydown", (e) => {
      if (["Backspace", "Delete"].indexOf(e.key) !== -1 && e.shiftKey) {
        this.tree?.removeSelectedNode()
      }
    })

    this.c.on("drop", this.onDrop)

    this.c.on("mouse:out", this.onMouseOut)

    this.c.on("mouse:move", this.onMouseMove)

    this.c.on("mouse:down", this.onMouseDown)
  }
}
