import { AuthStateType } from "types/auth/authType"

const Reduser: (state: AuthStateType, action: { type: string }) => { isLoading: boolean; isAuthorized: boolean } = (
  state: AuthStateType,
  action: { type: string }
) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        isLoading: true
      }
    case "LOADED":
      return {
        ...state,
        isLoading: false
      }
    case "SUCCESS":
      return {
        ...state,
        isLoading: false,
        isAuthorized: true
      }
    case "FAIL":
      return {
        ...state,
        isLoading: false
      }
    case "RESET":
      return {
        ...state,
        isAuthorized: false
      }
    default:
      throw new Error()
  }
}
export default Reduser
