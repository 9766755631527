import { SetStateAction, useEffect, useState } from "react"

import styles from "styles/builder/leftpanel.module.css"
import { Button, Grid, TextInput } from "@mantine/core"
import { IconLayoutGrid, IconList, IconSearch } from "@tabler/icons"

type propType = {
  listView: boolean
  toggleListView: () => void
  handleFiltering: (keyword: string) => void
}

const AssetsSearch = (props: propType): JSX.Element => {
  const [searchValue, setSearchValue] = useState("")
  const handleChange = (e: { target: { value: SetStateAction<string> } }) => {
    setSearchValue(e.target.value)
  }

  useEffect(() => {
    props.handleFiltering(searchValue.trim())
    // eslint-disable-next-line
  }, [searchValue])

  return (
    <div className={styles.assetsHeaderContainer}>
      <div className={styles.searchWithViewModeToggle}>
        <Grid
          sx={{
            padding: "0 24px"
          }}
          justify="center"
          align="center"
          grow
        >
          <Grid.Col
            span={9}
            sx={{
              padding: 0
            }}
          >
            <TextInput
              sx={{
                input: {
                  height: 40
                }
              }}
              placeholder="Search for wiget..."
              size="xs"
              radius="md"
              value={searchValue}
              onChange={handleChange}
              icon={<IconSearch size={15} />}
            />
          </Grid.Col>
          <Grid.Col
            span={1}
            sx={{
              padding: 0
            }}
          >
            <Button
              variant="white"
              styles={{
                root: {
                  borderRadius: 10,
                  width: 25,
                  height: 25,
                  padding: "0"
                }
              }}
              sx={{
                marginLeft: 15
              }}
              color="dark"
              onClick={() => props.toggleListView()}
            >
              {props.listView ? <IconList size={25} /> : <IconLayoutGrid size={25} />}
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    </div>
  )
}
export default AssetsSearch
