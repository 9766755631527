import { Menu, Button } from "@mantine/core"
import { IconChevronDown } from "@tabler/icons"
export type DeviceInfo = {
  type: string
  title: string
  width: number
  height: number
  topSafeSpace: number
  bottomSafeSpace: number
  keyboardHeight: number
}

interface DeviceListSelectorProps {
  value?: string | number
  list: DeviceInfo[]
  observer: (v: string) => void
}

export const DeviceListSelector = ({ list, observer }: DeviceListSelectorProps): JSX.Element => {
  const onChange = (value: string) => {
    observer(value)
  }

  return (
    <Menu>
      <Menu.Target>
        <Button
          color="dark"
          rightIcon={<IconChevronDown size={25} />}
          styles={{
            root: {
              borderRadius: "0px 10px 10px 0px",
              margin: 0,
              padding: 0,
              height: 46,
              top: 10,
              span: {
                margin: "0 10px 0 0"
              }
            }
          }}
        ></Button>
      </Menu.Target>
      <Menu.Dropdown
        sx={{
          width: "15%"
        }}
      >
        {list.map((item) => {
          return (
            <Menu.Item value={item.type} key={item.type} onClick={() => onChange(item.type)}>
              {item.title}
            </Menu.Item>
          )
        })}
      </Menu.Dropdown>
    </Menu>
  )
}
