import "reflect-metadata"
import { jsonProperty, jsonIgnore, SerializationSettings } from "ts-serializable"
import { Serializable } from "./serializable"
import treeCrawl from "tree-crawl"
import { Emitter } from "mitt"
import { TNodeEventTypeList, ENodeEventList, TNodeEvent, TNodeIdEvent, TNodeMoveEvent } from "./entityEvent"
import { v4 as uuidv4 } from "uuid"
import { IProjectWidget } from "./project"

const PathStringDelimeter = "."

type Iteratee = (o: EntityNode, ctx: treeCrawl.Context<EntityNode>) => void
type TEntityNodePath = string[]

export interface IEntityNodeTree {
  // walk through tree and call fn on each node
  walk: (fn: Iteratee) => void

  // add parent link after load data from json
  fixParent: () => void

  // get parent
  getParent: () => EntityNode | undefined

  // get child
  getChild: () => EntityNode | undefined

  // set child
  setChild: (en: EntityNode) => void

  // get children
  getChildren: () => EntityNode[] | undefined

  // set children
  setChildren: (...en: EntityNode[]) => void

  // add child to children list
  addChildBeforeIndex: (en: EntityNode, i?: number) => void

  // move child before requested child index in children list
  moveChildBeforeIndex: (ci: number, i?: number) => boolean
}

export interface IEntityNodeEmitter {
  e?: Emitter<TNodeEventTypeList>
  _emitOnRoot(type: ENodeEventList, event: TNodeEvent | TNodeIdEvent | TNodeMoveEvent): void
}

export interface IEntityNodeSelected {
  selected?: EntityNode
  setSelected(en: EntityNode): void
  getSelected(): EntityNode | undefined
  unsetSelected(en: EntityNode): void
}

export interface IEntityNodeHovered {
  hovered?: EntityNode
  setHovered(en: EntityNode): void
  getHovered(): EntityNode | undefined
  unsetHovered(en: EntityNode): void
}

export interface IEntityNodeBuffered {
  buffered?: EntityNode
  setBuffered(en: EntityNode): void
  getBuffered(): EntityNode | undefined
  unsetBuffered(en: EntityNode): void
}

const _getChildEdge = function (node: EntityNode): EntityNode[] {
  if (node.children) {
    return node.children
  } else {
    const res: EntityNode[] = []
    for (const k in node) {
      if (k.match(/child$/i)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const ch: EntityNode = (node as Record<string, any>)[k] as EntityNode
        if (ch) {
          res.push(ch)
        }
      }
    }
    return res
  }
}

const _getPathFromString = function (path: string): TEntityNodePath {
  return path.split(PathStringDelimeter)
}

export class EntityNode extends Serializable implements IEntityNodeTree, IEntityNodeEmitter {
  @jsonProperty(String)
  id?: string = undefined

  parent?: EntityNode

  child?: EntityNode = undefined
  children?: EntityNode[] = undefined

  fromJSON(json: Record<string, unknown>, settings?: Partial<SerializationSettings> | undefined): this {
    if (!json) {
      return this
    }
    json = { ...json }
    for (const k in json) {
      if (json[k] === undefined) {
        delete json[k]
      }
    }
    json.id = json.id || uuidv4()
    return super.fromJSON(json, settings)
  }

  walk(fn: Iteratee): void {
    treeCrawl(this, fn, { getChildren: _getChildEdge })
  }

  fixParent(): void {
    this.walk((node, ctx) => {
      node.parent = ctx.parent || undefined
    })
  }

  findById(id: string): EntityNode | undefined {
    let res: EntityNode | undefined
    this.walk((node, ctx) => {
      if (node.id === id) {
        res = node
        ctx.break()
      }
    })
    return res
  }

  protected _getRoot(): EntityNode | undefined {
    let curr = this as EntityNode
    let parent = curr.parent
    while (parent) {
      curr = parent
      parent = curr.parent
    }
    return curr
  }

  isRoot(): boolean {
    return !this.parent
  }

  // get parent
  getParent(): EntityNode | undefined {
    return this.parent
  }

  // get child
  getChild(): EntityNode | undefined {
    return this.child
  }

  // set child
  setChild(en: EntityNode): void {
    //if (this.children) {
    // delete children
    this.removeChild()
    //}
    en.id = en.id || uuidv4()
    en.parent = this
    this.child = en
    this._emitOnRoot(ENodeEventList.NodeAdded, { node: en })
  }

  // get children
  getChildren(): EntityNode[] | undefined {
    return this.children
  }

  // set children
  setChildren(...en: EntityNode[]): void {
    //if (this.child) {
    // delete child
    this.removeChild()
    //}
    for (let i = 0; i < en.length; i++) {
      en[i].id = en[i].id || uuidv4()
      en[i].parent = this
    }
    this.children = en
    // TODO: optimize
    this.children.forEach((en) => {
      this._emitOnRoot(ENodeEventList.NodeAdded, { node: en })
    })
  }

  getChildrenList(): EntityNode[] {
    return _getChildEdge(this) as EntityNode[]
  }

  // add child to children list
  _addChildBeforeIndex(en: EntityNode, i?: number): boolean {
    en.id = en.id || uuidv4()
    en.parent = this
    if (!this.children) {
      this.children = [en]
    } else if (i !== undefined && i >= 0 && i < this.children.length) {
      this.children.splice(i, 0, en)
    } else {
      this.children.push(en)
    }
    return true
  }

  addChildBeforeIndex(en: EntityNode, i?: number): void {
    if (this._addChildBeforeIndex(en, i)) {
      // emit event
      this._emitOnRoot(ENodeEventList.NodeAdded, { node: en })
    }
  }

  addChildBefore(en: EntityNode, beforeEn: EntityNode): void {
    this.addChildBeforeIndex(en, this.children?.indexOf(beforeEn))
  }

  pushChild(en: EntityNode): void {
    this.addChildBeforeIndex(en)
  }

  // move child before requested child index in children list
  moveChildBeforeIndex(ci: number, bi?: number): boolean {
    if (!this.children || ci < 0 || ci >= this.children.length || (bi !== undefined && bi === ci)) {
      return false
    }
    // add child / duplicate
    const en = this.children[ci]
    this._addChildBeforeIndex(en, bi)
    // remove original
    this.children.splice(ci + (bi !== undefined && bi >= 0 && bi < this.children.length && bi < ci ? 1 : 0), 1)
    // notify on moved
    this._emitOnRoot(ENodeEventList.NodeMoved, { node: en })
    return true
  }

  // remove child from children list by index
  removeChildAtIndex(i: number): boolean {
    if (!this.children || i < 0 || i >= this.children.length) {
      return false
    }
    const id = this.children[i].id
    this.children.splice(i, 1)
    if (this.children.length === 0) {
      delete this.children
    }
    // notify on removed
    this._emitOnRoot(ENodeEventList.NodeDeleted, { id: id, parent: this as IEntityNodeTree })
    return true
  }

  removeChild(): boolean {
    if (this.child) {
      const id = this.child.id
      delete this.child
      this._emitOnRoot(ENodeEventList.NodeDeleted, { id: id, parent: this as IEntityNodeTree })
      return true
    } else if (this.children) {
      const idList: string[] = []
      this.children.forEach((en) => {
        if (en.id) {
          idList.push(en.id)
        }
      })
      delete this.children
      this._emitOnRoot(ENodeEventList.NodeDeleted, { idList: idList, parent: this as IEntityNodeTree })
      return true
    }
    return false
  }

  removeChildren(en: EntityNode): boolean {
    return this.removeChildAtIndex(this.children ? this.children.indexOf(en) : -1)
  }

  wrapWithChild(en: EntityNode): boolean {
    let isWrapped = false
    en.child = this
    en.parent = this.parent
    if (this.parent?.child) {
      this.parent.child = en
      this.parent = en
      isWrapped = true
    } else if (this.parent?.children) {
      const i = this.parent.children.indexOf(this)
      if (i !== -1) {
        this.parent.children[i] = en
        this.parent = en
        isWrapped = true
      }
    }
    if (isWrapped) {
      this._emitOnRoot(ENodeEventList.NodeWrapped, { node: en })
    }
    return isWrapped
  }

  wrapWithChildren(en: EntityNode, ...add: EntityNode[]): boolean {
    let isWrapped = false
    en.children = [this, ...add]
    en.parent = this.parent
    if (this.parent?.child) {
      this.parent.child = en
      en.children.forEach((el) => {
        el.parent = en
      })
      isWrapped = true
    } else if (this.parent?.children) {
      const i = this.parent.children.indexOf(this)
      if (i !== -1) {
        this.parent.children[i] = en
        en.children.forEach((el) => {
          el.parent = en
        })
        isWrapped = true
      }
    }
    if (isWrapped) {
      this._emitOnRoot(ENodeEventList.NodeWrapped, { node: en })
    }
    return isWrapped
  }

  // clone
  clone(): EntityNode {
    const node = new EntityNode().fromJSON({ ...this.toJSON() })
    node.id = uuidv4()
    node?.walk((en) => (en.id = uuidv4()))
    node.fixParent()
    return node
  }

  // TODO: implement method to move node form one parent to another
  moveToParentChild(/*en: EntityNode, parent: EntityNode*/): boolean {
    return false
  }

  // TODO: implement method to move node form one parent to another
  moveToParentChildren(/*en: EntityNode, parent: EntityNode, i?: number*/): boolean {
    return false
  }

  remove(): boolean {
    if (this.parent) {
      if (this.parent.child && this.parent.child === this) {
        return this.parent.removeChild()
      } else if (this.parent.children) {
        return this.parent.removeChildren(this)
      } else {
        // process other child
        const key = this.getParentChildKey()
        const id = this.id
        if (key) {
          Reflect.deleteProperty(this.parent, key)
          // emit event
          this._emitOnRoot(ENodeEventList.NodeDeleted, { id })
        }
      }
    }
    return false
  }

  getParentChildKeyList(): TEntityNodePath {
    const path: TEntityNodePath = []
    const curr = this as EntityNode
    const parent = curr.parent
    if (parent) {
      if (parent.child && parent.child === curr) {
        path.push("child")
      } else if (parent.children && parent.children.indexOf(curr) !== -1) {
        path.push(parent.children.indexOf(curr).toString())
        path.push("children")
      } else {
        // process other possible child keys
        for (const k in parent) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (k.match(/child$/i) && (parent as Record<string, any>)[k] === curr) {
            path.push(k)
            break
          }
        }
      }
    }
    return path
  }

  getParentChildKey(): string {
    return this.getParentChildKeyList()?.pop() || ""
  }

  // get path of current node from root
  getPath(): TEntityNodePath {
    const path: TEntityNodePath = []
    let curr = this as EntityNode
    let parent = curr.parent
    while (parent) {
      path.push(...curr.getParentChildKeyList())
      curr = parent
      parent = curr.parent
    }
    return path.reverse()
  }

  getPathString(): string {
    return this.getPath().join(PathStringDelimeter)
  }

  // get node by node path e.g. ["child", "children", "0", "child", "children", "2"]`
  getNodeOnPath(path: TEntityNodePath): EntityNode | undefined {
    let curr = this as EntityNode
    for (const i in path) {
      const k = path[i]
      if (
        (["child", "children"].indexOf(k) !== -1 || k.match(/^\d+$/) || k.match(/child$/i)) &&
        curr.hasOwnProperty(k)
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const child = (curr as Record<string, any>)[k] as EntityNode
        if (child) {
          curr = child
        } else {
          return
        }
      } else {
        return
      }
    }
    return curr
  }

  // get node by string path e.g. `child.children.0.child.children.2`
  getNodeOnPathString(path: string): EntityNode | undefined {
    return this.getNodeOnPath(_getPathFromString(path))
  }

  // IEntityNodeEmitter implementation
  @jsonIgnore()
  e?: Emitter<TNodeEventTypeList>
  _emitOnRoot(type: ENodeEventList, event: TNodeEvent | TNodeIdEvent | TNodeMoveEvent): void {
    const root = this._getRoot()
    if (root) {
      root.e?.emit(type, event)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _fixProperty(_p?: IProjectWidget): void {
    return
  }
}

// NOTE: hardcode to resolve undefined class on decorators for circular type linking
Reflect.defineMetadata("ts-serializable:jsonTypes", [EntityNode], EntityNode.prototype, "child")
Reflect.defineMetadata("ts-serializable:jsonTypes", [[EntityNode]], EntityNode.prototype, "children")
