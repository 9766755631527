import { Geometry } from "./geometry"
import { GeometrySize } from "./size"
import { Icon as IconProperty } from "model/widgetProperty/type"
import { PageWidget } from "model/pageWidget"
import { IdeIcon } from "service/pb/ide-resource_pb"

export class Icon extends Geometry {
  private _icon: IconProperty
  private _ctx: CanvasRenderingContext2D
  private iconCodePoint?: number

  constructor(treeEntity: PageWidget, icon: IconProperty, iconList: IdeIcon.AsObject[]) {
    super()

    this._icon = icon
    this.treeEntity = treeEntity
    this._ctx = document.createElement("canvas").getContext("2d") as CanvasRenderingContext2D
    const fontStyleString = this._generateFontPropString()

    this._ctx.font = fontStyleString
    this.iconCodePoint = iconList.find((item) => item.key === icon.getKey())?.codepoint
  }

  private _generateFontPropString(): string {
    const icon = this._icon

    const provider = icon.getProvider()

    const fontSize = icon.getSize() || 16
    const fontFamily = provider?.replace(":", "-")

    return fontSize + "px " + fontFamily
  }

  performLayout(): void {
    const icon = this._icon.getKey()
    const iconSize = this._icon.getSize() || 0

    let width = iconSize,
      height = iconSize

    if (icon && this.iconCodePoint) {
      const iconUnicode = String.fromCharCode(this.iconCodePoint)
      if (iconUnicode) {
        const metrics = this._ctx.measureText(iconUnicode)
        width = metrics.width
        height = height
      }
    }

    this.size = new GeometrySize(width, height)
  }
}
