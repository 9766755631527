import { useState } from "react"
import FontSelectInput from "components/fontInput"
import Select from "components/builder/Select"
import CString from "components/builder/CString"
import CNumber from "components/builder/CNumber"
import TextArea from "components/builder/TextArea"
import ProjectColorInput from "components/builder/ProjectColor"
import CBoolean from "components/builder/CBoolean"
import {
  PlainSize,
  Size,
  AlignmentExpanded,
  Icon,
  Space,
  Image,
  PrimitiveArray,
  ProjectFont,
  IProjectColor,
  ProjectColor,
  WidgetDynamicValue
} from "model/widgetProperty/type"
import SizeInput from "components/builder/Size"
import { PlainSizeInput } from "components/builder/PlainSize"
import { AlignmentExpandedInput } from "components/builder/AlignmentExpanded"
import IconSelectInput from "components/iconInput"
import SpaceInput from "components/builder/Space"
import { Collapse, ChevronIcon, Title, Group } from "@mantine/core"
import { transformCammelCase } from "utils/string"
import GradientInput from "../../../components/builder/GradientInput"
import { AssetSelect } from "components/builder/AssetSelect"

export interface IProperty {
  title: string
  id?: string
  type: string
  enum?: Record<string, unknown>
  value?:
    | string
    | number
    | boolean
    | PlainSize
    | Size
    | AlignmentExpanded
    | Icon
    | Space
    | Image
    | ProjectFont
    | IProjectColor
    | PrimitiveArray<ProjectColor>
    | WidgetDynamicValue
  class: string
  group: string
}

interface IPropertyPannelProps {
  propertyList: IProperty[]
  onChange: (item: IProperty) => void
}

interface IPropertyGroupProps extends IPropertyPannelProps {
  groupName: string
}

const PropertyGroup = ({ groupName, propertyList, onChange }: IPropertyGroupProps) => {
  const [isOpened, toggleOpened] = useState(false)

  const _onChange = (item: IProperty, e?: unknown) => {
    onChange({ ...item, value: e } as IProperty)
  }

  const renderInputs = () => {
    return propertyList.map((item: IProperty) => {
      if (item.class === "CString") {
        if (item.enum) {
          return (
            <Select
              key={item.title}
              label={transformCammelCase(item.title)}
              value={item.value?.toString()}
              data={Object.keys(item.enum).map((item) => {
                return {
                  value: item,
                  label: transformCammelCase(item)
                }
              })}
              onChange={(e) => _onChange(item, e)}
            />
          )
        } else {
          return (
            <CString
              value={item.value?.toString()}
              key={item.title}
              label={transformCammelCase(item.title)}
              onChange={(e) => _onChange(item, e.target.value)}
            />
          )
        }
      } else if (item.class === "CNumber") {
        return (
          <CNumber
            onChange={(e) => _onChange(item, e)}
            key={item.title}
            value={Number(item.value)}
            label={transformCammelCase(item.title)}
          />
        )
      } else if (item.class === "ProjectColor") {
        return (
          <ProjectColorInput
            onChange={() => _onChange(item)}
            key={item.title}
            value={item.value as IProjectColor}
            label={transformCammelCase(item.title)}
            withProjectColors
          />
        )
      } else if (item.class === "WidgetDynamicValue") {
        return (
          <TextArea
            value={item.value?.toString()}
            key={item.title}
            label={transformCammelCase(item.title)}
            onChange={(e) => _onChange(item, e)}
          />
        )
      } else if (item.class === "ProjectFont") {
        return (
          <FontSelectInput
            withTheme
            font={item.value as ProjectFont}
            key={item.title}
            onChange={() => _onChange(item)}
          />
        )
      } else if (item.class === "CBoolean") {
        return (
          <CBoolean
            checked={Boolean(item.value)}
            key={item.title}
            label={transformCammelCase(item.title)}
            onChange={(e) => _onChange(item, e.target.checked)}
          />
        )
      } else if (item.class === "Size") {
        return (
          <SizeInput
            key={item.title}
            label={transformCammelCase(item.title)}
            value={item.value as Size}
            onChange={() => _onChange(item)}
          />
        )
      } else if (item.class === "PlainSize") {
        return (
          <PlainSizeInput
            key={item.title}
            label={transformCammelCase(item.title)}
            value={item.value as PlainSize}
            onChange={() => _onChange(item)}
          />
        )
      } else if (item.class === "AlignmentExpanded") {
        return (
          <AlignmentExpandedInput
            key={item.title + item.id}
            label={transformCammelCase(item.title)}
            value={item.value as AlignmentExpanded}
            onChange={() => _onChange(item)}
          />
        )
      } else if (item.class === "Icon") {
        return (
          <IconSelectInput
            key={item.title + item.id}
            label={transformCammelCase(item.title)}
            value={item.value as Icon}
            onChange={() => _onChange(item)}
          />
        )
      } else if (item.class === "Space") {
        return (
          <SpaceInput
            key={item.title + item.id}
            label={transformCammelCase(item.title)}
            value={item.value as Space}
            group={item.group}
            onChange={() => _onChange(item)}
          />
        )
      } else if (item.class === "AssetSelect") {
        return (
          <AssetSelect
            key={item.title + item.id}
            label={transformCammelCase(item.title)}
            value={item.value as Image}
            onChange={() => _onChange(item)}
            data={
              item.enum
                ? Object.keys(item.enum).map((item) => {
                    return {
                      value: item,
                      label: transformCammelCase(item)
                    }
                  })
                : []
            }
          />
        )
      } else if (item.class === "GradientColorList") {
        return (
          <GradientInput
            key={item.title}
            value={item.value as PrimitiveArray<ProjectColor>}
            label={transformCammelCase(item.title)}
            onChange={() => _onChange(item)}
          />
        )
      }
    })
  }

  return (
    <div style={{ margin: "10px 0" }}>
      <Group position="apart" onClick={() => toggleOpened(!isOpened)}>
        <Title
          order={6}
          color="black"
          sx={{
            fontFamily: "Syne"
          }}
        >
          {" "}
          {transformCammelCase(groupName)}{" "}
        </Title>
        <ChevronIcon cursor="pointer" fill="#000" fontSize={22} />
      </Group>
      <Collapse style={{ padding: "10px 0", color: "#000", borderBottom: "1px solid #ddd" }} in={isOpened}>
        <div style={{ display: "flex", flexWrap: "wrap", alignItems: "end", color: "#000", gap: 12 }}>
          {renderInputs()}
        </div>
      </Collapse>
    </div>
  )
}

const PropertyPannel = ({ onChange, propertyList }: IPropertyPannelProps): JSX.Element => {
  const renderGroups = () => {
    const groups: Record<string, IProperty[]> = {}

    propertyList.forEach((item) => {
      if (groups[item.group]) {
        groups[item.group].push(item)
      } else {
        groups[item.group] = [item]
      }
    })

    const result: JSX.Element[] = []

    for (const group in groups) {
      result.push(<PropertyGroup key={group} groupName={group} onChange={onChange} propertyList={groups[group]} />)
    }

    return result
  }
  return <div> {renderGroups()} </div>
}

export default PropertyPannel
