import { useReducer, useEffect, useMemo } from "react"
import Reducer from "service/appstate/auth/reducer"
import Actions from "service/appstate/auth/actions"
import AsyncActions from "utils/asyncActions"
import { TAuthType } from "types/appstate/stateType"
import { TAuthActionTypes, TAuthErr } from "types/auth/authType"
import { TObserverFun } from "types/service/api"
export interface IAuthProps {
  api: {
    host: string | undefined
    aws: { Auth: TAuthType }
    subscribeResponse: (fn: TObserverFun) => void
    unsubscribeResponse: (fn: TObserverFun) => void
  }
}
const useAuth = (settings: IAuthProps): [{ isLoading: boolean; isAuthorized: boolean }, TAuthActionTypes] => {
  const { api } = settings

  const initialState = {
    isAuthorized: false,
    isLoading: false
  }

  const [state, dispatch] = useReducer(Reducer, initialState)
  const { isAuthorized } = state
  const actions = useMemo(
    () => AsyncActions({ state, dispatch, api }, Actions),

    // eslint-disable-next-line
    []
  )

  useEffect((): (() => never | void) => {
    let timerId: NodeJS.Timeout | null = null

    if (!isAuthorized) {
      if (timerId) clearTimeout(timerId)
      timerId = global.setTimeout(async () => {
        try {
          await actions.authenticated()
        } catch (err) {
          actions.signOut()
        }
      }, 1000)
    }

    return () => timerId && clearTimeout(timerId)
    // eslint-disable-next-line
  }, [isAuthorized])

  useEffect(() => {
    const observerResponse = (error: TAuthErr) => {
      if (error) {
        // eslint-disable-next-line
        console.error("observerResponse", error)
      }
    }

    api.subscribeResponse(observerResponse)
    return () => {
      api.unsubscribeResponse(observerResponse)
    }
    // eslint-disable-next-line
  }, [])

  return [state, actions]
}
export default useAuth
