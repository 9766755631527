import { Fragment, useState, useCallback } from "react"
import { createPortal } from "react-dom"

import styles from "styles/builder/toolbar.module.css"
import { TPositionType } from "types/pages/tabTypes"
import { TCenterBarChildType } from "types/pages/builderTypes"
import { IconChevronDown } from "@tabler/icons"
export interface IDropdownProps {
  position: TPositionType
}
const Dropdown = ({ position }: IDropdownProps) => {
  return createPortal(
    <div className={styles.dropdown} style={{ left: position - 110 }}>
      <div className={styles.dropdown_box}>
        <ul>
          <li>
            <a href="">Show version history</a>
          </li>
          <li>
            <a href="">Publish styles and components</a>
          </li>
          <li>
            <a href="">
              Export... <span>Ctrl+Shift+E</span>
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <a href="">Duplicate</a>
          </li>
          <li>
            <a href="">Rename</a>
          </li>
          <li>
            <a href="">Delete</a>
          </li>
        </ul>
        <ul>
          <li>
            <a href="">Restore default thumbnail</a>
          </li>
        </ul>
        <ul>
          <li>
            <a href="">Move to project...</a>
          </li>
        </ul>
      </div>
    </div>,
    document.getElementById("root") as Element
  )
}

const CenterBar = ({ children }: TCenterBarChildType): JSX.Element => {
  const [isOpen, setOpen] = useState(false)

  const open = useCallback(
    (clientX) => {
      setOpen(clientX)
    },
    [setOpen]
  )

  const close = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleClick = (e: { stopPropagation: () => void; clientX: number }) => {
    if (!isOpen) {
      e.stopPropagation()

      open(e.clientX)
      document.addEventListener(
        "click",
        function callee() {
          close()
          document.removeEventListener("click", callee)
        },
        { once: true /*, capture: true*/ }
      )
    }
  }
  return (
    <Fragment>
      {isOpen && <Dropdown position={isOpen} />}
      <div className={styles.flexibleSpace}>
        <span className={styles.ellipsis}>{children}</span>
        <div className={styles.chevron_container}>
          <span className={styles.svg_container}>
            <IconChevronDown size={25} onClick={handleClick} />
          </span>
        </div>
      </div>
    </Fragment>
  )
}

export default CenterBar
