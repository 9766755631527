// import { Fragment, useRef, useEffect, useState } from "react"
import { Scrollbars } from "react-custom-scrollbars-2"
import CSS from "csstype"
import { ReactNode } from "react"

// import list from "styles/builder/list.module.css"
//https://grsmto.github.io/simplebar/examples/
export interface IScrollContainerProp {
  style?: CSS.Properties
  children: ReactNode
}
const ScrollContainer = (props: IScrollContainerProp): JSX.Element => {
  /*const [state, setState] = useState({
    canScroll: false,
    scrollbarTop: 0,
    scrollbarHeight: 0,
    bottomSpacerStyle: {
      display: "none",
      height: 0
    }
  })*/
  return (
    <Scrollbars autoHide style={props.style}>
      {props.children}
    </Scrollbars>
  )
  // return (
  //   <div className={list.outerContainer} style={props.style}>
  //     <div className={list.scrollContainer}>
  //       <div className={list.innerContainer}>{props.children}</div>
  //     </div>
  //   </div>
  // )
}
export default ScrollContainer
