import { IProject } from "../../project"

export type TPrimitive = string | number | boolean | null | Record<string, unknown>
//  | Array<string | number | boolean | null | Record<string, unknown>>

export interface IDefault {
  isDefault(): boolean
}

export interface IReset {
  reset(): this
}

export interface IPrimitiveSerializable {
  toJSON(): TPrimitive | TPrimitive[]
  fromJSON(v: TPrimitive | TPrimitive[]): this
}

export class PrimitiveSerializable implements IPrimitiveSerializable, IDefault, IReset {
  protected p?: IProject

  constructor(p?: IProject) {
    this.p = p
  }
  public static fromJSON<T extends PrimitiveSerializable>(this: new () => T, v: TPrimitive): T {
    return new this().fromJSON(v)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fromJSON(v: TPrimitive): this {
    return this
  }
  toJSON(): TPrimitive {
    return null
  }
  isDefault(): boolean {
    return false
  }
  reset(): this {
    return this
  }
}
