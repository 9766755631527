import { AlphaSlider, Modal, ColorPicker, TextInput, Button, Group, ColorSwatch } from "@mantine/core"
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react"
import { Color, IColor, IProjectColor, IProjectFont, ProjectColor, ProjectFont } from "model/widgetProperty/type"
import { useRecoilValue } from "recoil"
import { currentProject, projectForceUpdaterAtom } from "service/appstate/project/atoms"
import { transformCammelCase } from "utils/string"

interface IColorPickerModalProps {
  isOpened: boolean
  value: IProjectColor | IProjectFont | IColor
  onChange: (color: string, alpha: number) => void
  onClose: () => void
  reset: () => void
  globalOnChange: () => void
  withProjectColors?: boolean
}

const ColorPickerModal = ({
  isOpened,
  onClose,
  value,
  onChange,
  reset,
  globalOnChange,
  withProjectColors
}: IColorPickerModalProps): JSX.Element => {
  const [color, setColor] = useState("#000")
  const [alpha, alphaOnChange] = useState(1)
  const project = useRecoilValue(currentProject)
  useRecoilValue(projectForceUpdaterAtom)

  const getColor = (): IColor => {
    if (value instanceof ProjectColor) {
      return value.get()
    } else if (value instanceof ProjectFont) {
      return value.get().getColor().get()
    } else {
      return value as IColor
    }
  }

  const projectColors = project?.colorList || []

  useEffect(() => {
    if (value && isOpened) {
      setColor(getColor().getRGBHEX())
      alphaOnChange(getColor().getA())
    }
  }, [isOpened, value])

  const projectColorSelected = (projectColorKey: string) => {
    if (value instanceof ProjectFont) {
      value.setColor(projectColorKey)
    } else if (value instanceof ProjectColor || value instanceof Color) {
      value.set(projectColorKey)
    }

    globalOnChange()
    onClose()
  }

  const swatches = projectColors.map((color) => {
    const _color = color.color.getRGBAHEX()
    return (
      <ColorSwatch
        title={color.key}
        style={{ cursor: "pointer" }}
        radius={4}
        size={50}
        key={_color}
        color={_color}
        onClick={() => projectColorSelected(color.key)}
      />
    )
  })

  const onChangeEnd = () => {
    //
  }

  return (
    <Modal
      title="Choose a color"
      styles={() => ({
        title: {
          fontSize: 29,
          fontFamily: "Syne",
          fontWeight: 700
        }
      })}
      centered
      opened={isOpened}
      onClose={onClose}
      size="lg"
    >
      <ColorPicker
        value={color}
        onChange={setColor}
        sx={() => ({
          width: "100%"
        })}
        size="xl"
      />

      <AlphaSlider
        size="xl"
        mt={8}
        value={alpha}
        color={color || "#fff"}
        onChange={alphaOnChange}
        onChangeEnd={() => onChangeEnd()}
      />
      {withProjectColors ? (
        <Group position="apart" mt={20}>
          {swatches}
        </Group>
      ) : null}

      <Group mt={50} position="apart">
        <Button style={{ backgroundColor: "#fa5252" }} onClick={() => reset()}>
          Clear Color
        </Button>
        <Button style={{ backgroundColor: "#7950f3" }} onClick={() => onChange(color, alpha)}>
          Use Color
        </Button>
      </Group>
    </Modal>
  )
}

interface IProjectColorProps {
  value: IProjectColor | IProjectFont | IColor
  label: string
  onChange: () => void
  withProjectColors?: boolean
}

const ProjectColorInput = ({ value, label, onChange, withProjectColors }: IProjectColorProps): JSX.Element => {
  const [isModalOpened, toggleModal] = useState(false)
  const [isFocused, toggleFocused] = useState(false)
  const [tempValue, tempValueOnChange] = useState("")

  useRecoilValue(projectForceUpdaterAtom)

  const valueOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const val = event.target.value

    tempValueOnChange(val)
  }

  const onValuePicked = (color: string, alpha: number) => {
    let hexAlpha = Math.round(alpha * 255).toString(16)

    if (hexAlpha.length === 1) {
      hexAlpha = hexAlpha + hexAlpha
    }

    const fontString = "#" + hexAlpha + color.replace("#", "")

    if (value)
      if (value instanceof ProjectFont) {
        value.setColor(fontString.toUpperCase())
      } else if (value instanceof ProjectColor || value instanceof Color) {
        value.set(fontString.toUpperCase())
      }

    toggleModal(false)
    onChange()
  }

  const resetValue = () => {
    if (value instanceof ProjectFont) {
      value.setColor("#00FFFFFF")
    } else if (value instanceof ProjectColor || value instanceof Color) {
      value.set("#00FFFFFF")
    }
    toggleModal(false)
    onChange()
  }

  const onBlur = () => {
    if (tempValue) {
      const threeDigsReg = /^([0-9A-F]{3}){1,2}$/i,
        sixDigsReg = /^[0-9A-F]{6}$/i
      let val = tempValue.toUpperCase().replace("#", "")

      if (val.length === 3 && threeDigsReg.test(val)) {
        val = val
          .split("")
          .map(function (hex) {
            return hex + hex
          })
          .join("")
      }

      if (sixDigsReg.test(val)) {
        if (value instanceof ProjectFont) {
          value.setColor("#" + val)
        } else if (value instanceof ProjectColor || value instanceof Color) {
          value.set("#" + val)
        }

        onChange()
      }

      tempValueOnChange("")
    }

    toggleFocused(false)
  }

  const onKeyPressed = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
      onBlur()
    }
  }

  const getValueString = () => {
    let valueString = ""

    if (value instanceof ProjectColor) {
      valueString = value.isType() ? transformCammelCase(value.getType()) : value.get().getRGBAHEX()
    } else if (value instanceof Color) {
      valueString = value.getRGBHEX()
    } else if (value instanceof ProjectFont) {
      const val = value.get().getColor()
      valueString = val.isType() ? transformCammelCase(val.getType()) : val.get().getRGBAHEX()
    }

    return valueString
  }

  const getColor = (): IColor => {
    if (value instanceof ProjectColor) {
      return value.get()
    } else if (value instanceof ProjectFont) {
      const val = value.get().getColor().get()
      return val
    } else {
      return value as IColor
    }
  }

  return (
    <>
      <ColorPickerModal
        value={value}
        isOpened={isModalOpened}
        onChange={onValuePicked}
        onClose={() => toggleModal(false)}
        reset={resetValue}
        globalOnChange={onChange}
        withProjectColors={withProjectColors}
      />
      <div style={{ width: "calc(50% - 6px)" }}>
        <TextInput
          rightSection={
            <div
              onClick={() => toggleModal(true)}
              style={{
                backgroundColor: getColor().getRGBAHEX(),
                width: 16,
                height: 16,
                borderRadius: 4,
                cursor: "pointer",
                border: "1px solid #000"
              }}
            />
          }
          onKeyPress={(e) => onKeyPressed(e)}
          onFocus={() => toggleFocused(true)}
          onBlur={() => onBlur()}
          sx={() => ({
            // marginTop: "12px",
            marginRight: 0,
            label: {
              color: "rgba(34, 39, 48, 0.7)",
              fontWeight: "bold",
              padding: "0 0 4px 0"
            },
            input: {
              background: "#fff",
              border: "1px solid #ddd",
              borderRadius: 8,
              color: "#000"
            }
          })}
          value={isFocused ? tempValue : getValueString()}
          label={transformCammelCase(label)}
          size="xs"
          onChange={valueOnChange}
          placeholder="HEX value..."
        />
      </div>
    </>
  )
}

export default ProjectColorInput
