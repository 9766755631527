import { fabric } from "fabric"
import { getImageBoxFitProperty } from "./utils"
import { CString, Image } from "model/widgetProperty/type"
import { TWidgetOptions } from "./types"

export interface IImageWidgetProps {
  image?: Image
  boxFit?: CString
}

export const ImageWidget = fabric.util.createClass(fabric.Rect, {
  type: "Image",
  cacheProperties: fabric.Rect?.prototype?.cacheProperties?.concat("geometry"),
  stateProperties: fabric.Rect?.prototype?.stateProperties?.concat("geometry"),
  initialize: function (options: TWidgetOptions) {
    if (!options?.pageWidget) {
      throw new Error("Page widget is required option")
    }

    const { pageWidget, geometry } = options

    this.id = pageWidget.id
    this.pageWidget = pageWidget

    const property = pageWidget.property as IImageWidgetProps

    this.fill = "transparent"

    const image = property.image?.getURL()

    this.boxFit = property.boxFit?.get() || "fill"

    this.set({
      width: geometry.size.width,
      height: geometry.size.height,
      left: geometry.offset.x,
      top: geometry.offset.y,
      geometry,
      strokeWidth: 0
    })

    this.imageLoaded = false

    if (image && !property.image?.isDefault()) {
      this.image = image
      fabric.util.loadImage(
        image,
        (img) => {
          this.set("imgElement", img)
          this.imageLoaded = true
          this.dirty = true
          //if canvas is global
          this.canvas?.renderAll()
        },
        {
          crossOrigin: "annonymous"
        }
      )
    }

    this.callSuper("initialize", options)
  },
  _render: function (ctx: CanvasRenderingContext2D) {
    const pageWidget = this.pageWidget
    const property = pageWidget.property as IImageWidgetProps

    const geometry = this.geometry

    this.width = geometry.size.width
    this.height = geometry.size.height
    this.top = geometry.offset.y
    this.left = geometry.offset.x

    const rx = this.rx ? Math.min(this.rx, this.width / 2) : 0,
      ry = this.ry ? Math.min(this.ry, this.height / 2) : 0,
      w = this.width,
      h = this.height,
      x = -this.width / 2,
      y = -this.height / 2,
      isRounded = rx !== 0 || ry !== 0,
      /* "magic number" for bezier approximations of arcs (http://itc.ktu.lt/itc354/Riskus354.pdf) */
      k = 1 - 0.5522847498
    ctx.beginPath()

    ctx.moveTo(x + rx, y)

    ctx.lineTo(x + w - rx, y)
    isRounded && ctx.bezierCurveTo(x + w - k * rx, y, x + w, y + k * ry, x + w, y + ry)

    ctx.lineTo(x + w, y + h - ry)
    isRounded && ctx.bezierCurveTo(x + w, y + h - k * ry, x + w - k * rx, y + h, x + w - rx, y + h)

    ctx.lineTo(x + rx, y + h)
    isRounded && ctx.bezierCurveTo(x + k * rx, y + h, x, y + h - k * ry, x, y + h - ry)

    ctx.lineTo(x, y + ry)
    isRounded && ctx.bezierCurveTo(x, y + k * ry, x + k * rx, y, x + rx, y)

    ctx.closePath()

    this._renderPaintInOrder(ctx)

    const image = property?.image?.getURL()
    this.boxFit = property.boxFit?.get() || "fill"

    if (image && !property.image?.isDefault()) {
      if (image !== this.image) {
        this.image = image
        this.imageLoaded = false
        fabric.util.loadImage(
          image,
          (img) => {
            this.set("imgElement", img)
            this.imageLoaded = true

            //if canvas is global
            if (this.canvas) {
              this.dirty = true
              this.canvas?.renderAll()
            }
          },
          {
            crossOrigin: "annonymous"
          }
        )
      }
    }

    if (this.imageLoaded && this.imgElement) {
      ctx.clip()

      const imageSizeOffset = getImageBoxFitProperty(this.imgElement, { x, y, w, h }, this.boxFit)

      const { sx, sy, sw, sh, dx, dy, dw, dh } = imageSizeOffset

      ctx.drawImage(this.imgElement, sx, sy, sw, sh, dx, dy, dw, dh)
    }
  }
})
