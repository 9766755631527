import { useEffect } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import { assetsLoadProgressAtom, projectForceUpdaterAtom } from "service/appstate/project/atoms"
import BuilderActions from "service/appstate/builder/actions"
import EventEmitter from "utils/eventEmitter"
import { IPubSubResponse } from "types/pubsub/pubsubTypes"
import { Project } from "model/project"

export const useAssetUploadObserver = (resourceRequest: string, project?: Project): void => {
  const [assetProgress, setAssetProgress] = useRecoilState(assetsLoadProgressAtom)
  const projectForceEmitter = useSetRecoilState(projectForceUpdaterAtom)

  const pubSubCb = (data: IPubSubResponse) => {
    const { entity, event, jobId, id } = data

    if (!project) {
      return
    }

    if (entity === 6 && event === 1) {
      if (assetProgress.data.find((item) => item.jobId === jobId)) {
        if (assetProgress.filled) {
          const newData = assetProgress.data.map((progressItem) => {
            let newItem = progressItem
            if (progressItem.jobId === jobId) {
              newItem = { ...progressItem, complete: true, id }
            }

            return newItem
          })

          const isAllComplete = newData.every((item) => item.complete)

          if (isAllComplete) {
            BuilderActions.applyProjectAssets(resourceRequest, project, () =>
              projectForceEmitter((val) => val + 1)
            ).then(() => {
              setAssetProgress({ ...assetProgress, data: newData })
            })
          } else {
            setAssetProgress({ ...assetProgress, data: newData })
          }
        }
      } else {
        BuilderActions.applyProjectAssets(resourceRequest, project, () => projectForceEmitter((val) => val + 1))
      }
    }
  }

  useEffect(() => {
    EventEmitter.on("streamProjectBranch/data", pubSubCb)

    return () => {
      EventEmitter.off("streamProjectBranch/data", pubSubCb)
    }
  }, [assetProgress, project])
}
