import React, { ChangeEvent, forwardRef } from "react"
import { Group, Text, Avatar, Select, SelectItem } from "@mantine/core"
import { Image } from "model/widgetProperty/type"
import { useRecoilValueLoadable, useRecoilValue } from "recoil"
import { currentProject, resourceRequestAtom } from "service/appstate/project/atoms"
import { Loader } from "react-feather"
import SelectInput from "../Select"
import TextInput from "../CString"
import { ResourceTypeEnum } from "model/widgetProperty/enum"
import { AssetLoader } from "components/assetLoader"

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string
  label: string
}

const SelectValue = forwardRef<HTMLDivElement, ItemProps>(({ image, label, ...others }: ItemProps, ref) => (
  <div ref={ref} {...others}>
    <Group noWrap>
      <Avatar src={image} />

      <div>
        <Text size="xs">{label}</Text>
      </div>
    </Group>
  </div>
))

interface IAssetSelectProps {
  value: Image
  onChange: () => void
  label: string
  data: SelectItem[]
}

export const AssetSelect = ({ label, onChange, value, data }: IAssetSelectProps): JSX.Element => {
  const projectState = useRecoilValueLoadable(currentProject)
  const resourceRequest = useRecoilValue(resourceRequestAtom)
  const project = projectState.state === "hasValue" ? projectState.getValue() : null

  if (!project) {
    return <Loader />
  }

  const assetList = project.assetList

  const _onChange = (id: string | null) => {
    if (!id) {
      return
    }

    value.getPath()?.set(id)
    onChange()
  }

  const resourseTypeOnChange = (val: string) => {
    value.setResourceType(val as ResourceTypeEnum)
    onChange()
  }

  const pathOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    if (value) {
      value.getPath()?.set(val)
      onChange()
    }
  }

  const assetLoaded = (id: string) => {
    if (id) {
      value.getPath()?.set(id)
      onChange()
    }
  }

  const currentAsset = assetList.find((item) => item.id === value.getPath()?.get())?.id
  return (
    <>
      <SelectInput
        label="Resource Type"
        value={value.getResourceType().toString()}
        onChange={resourseTypeOnChange}
        data={data}
      />
      {value.getResourceType().toLowerCase() === "asset" ? (
        <>
          <Select
            label={label}
            placeholder="Pick one"
            itemComponent={SelectValue}
            data={assetList.map((item) => {
              return {
                image: project.getAssetCDNUrl(item.id, item.fileName, "origin"),
                label: item.fileName,
                value: item.id
              }
            })}
            sx={() => ({
              width: "100%",
              label: {
                color: "rgba(34, 39, 48, 0.7)",
                fontWeight: "bold",
                padding: "0 0 4px 0"
              },
              input: {
                border: "1px solid #ddd",
                borderRadius: 8
              },
              background: "#fff",
              color: "#16141C"
              // marginRight: 10,
              // marginTop: 12
            })}
            size="xs"
            value={currentAsset}
            onChange={(e) => _onChange(e)}
            searchable
            maxDropdownHeight={300}
            nothingFound="Nothing Found"
            filter={(value, item) => item.label?.toLowerCase().includes(value.toLowerCase().trim()) || false}
          />
          <AssetLoader
            style={{ width: "100%", marginTop: "12px", backgroundColor: "#6549F7", borderRadius: 10 }}
            resourceRequest={resourceRequest}
            onChange={(id) => assetLoaded(id as string)}
          />
        </>
      ) : (
        <TextInput value={value.getURL()} width="100%" label="Image Path" onChange={pathOnChange} />
      )}
    </>
  )
}
